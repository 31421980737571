<div id="role" class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 i18n *ngIf="!editData" class="text-[25px] font-bold">Add New Role</h1>
    <h1 i18n *ngIf="editData" class="text-[25px] font-bold">Edit Role</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <form class="grid grid-cols-2 gap-4 gap-x-10" [formGroup]="roleForm">
    <div>
      <label i18n class="required">Role Category</label>
      <mat-radio-group class="flex role-col" formControlName="RoleGroupId">
        <div *ngFor="let group of roleGroup$ | async">
          <mat-radio-button class="mx-3" [value]="group.id" color="primary">
            {{ group.name.substring(0, group.name.length - 5) }}
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div class="col-span-full">
      <mat-form-field class="bg-white" appearance="fill">
        <label i18n class="font-normal required" for="RoleName">Role Name</label>
        <input matInput placeholder="Role Name" i18n-placeholder autocomplete="off" type="text" formControlName="RoleName" maxlength="25"
          required>
      </mat-form-field>
    </div>
    <div class="col-span-full">
      <mat-form-field class="bg-white" appearance="fill">
        <label i18n class="font-normal required" for="RoleDescription">Description</label>
        <input matInput placeholder="Description" i18n-placeholder autocomplete="off" type="text" formControlName="RoleDescription"
          maxlength="50" required>
      </mat-form-field>
    </div>
  </form>

  <div mat-dialog-actions class="flex justify-end">
    <drdp-btn i18n class="pr-3" color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn i18n mat-button (click)="onSubmit()" [disabled]="roleForm.invalid" cdkFocusInitial>Save</drdp-btn>
  </div>
</div>