<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold pr-8"><span i18n>Send Message Board Re: </span>{{data.studentName}}</h1>
    <i
      mat-button
      mat-dialog-close
      class="text-drdpblue-300 cursor-pointer uil uil-times-circle"
    ></i>
  </div>
  <div mat-dialog-content>
      <form
        class="gap-4 gap-x-10 mt-3"
        [formGroup]="messageForm"
      >
        <div *ngIf="!data.recipientId" class="w-1/2">
          <drdp-select-portfolio-users 
              [sceId]="data.enrollmentId"
              [multiSelect]="true"
              [required]="true"
              (portfolioUsers)="handleRecipientSelection($event)">
          </drdp-select-portfolio-users>
        </div>
        <div class="mt-4 w-full">
          <textarea 
              rows="5" 
              maxlength="2000"
              class="text-sm w-full bg-gray-100 rounded-lg p-2 border border-drdpblue-300 focus:outline-none" 
              placeholder="Write new message here"
              i18n-placeholder
              formControlName="message">
          </textarea>
        </div>
      </form>
    </div>
    <div mat-dialog-actions class="flex justify-end">
      <drdp-btn i18n color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
      <drdp-btn
        i18n
        class="ml-3"
        mat-button
        [disabled]="messageForm.invalid"
        cdkFocusInitial
        (click)="onSend()"
      >
        Send Message</drdp-btn
      >
    </div>
</div>
