<drdp-container additionClasses="w-full mt-4">
  <drdp-container color="lightBlue">
    <form [formGroup]="searchForm" #ngForm="ngForm">
      <div *ngIf="isPilot" class="pl-2">
        <mat-checkbox
          color="primary"
          formControlName="isAllPilot"
        >
        </mat-checkbox>
        <label class="inline">
          All Pilot
        </label>
      </div>
      <div
        class="flex grid grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-cols-5 p-2 gap-2 md:gap-3 flex-row content-center">
        <div class="col-span-1 self-end">
          <drdp-select-state [initialStateId]="initStateId" (state)="handleState($event)" [required]="true"
            [clear]="clearDropdown" [invalid]="true" #stateSelect>
          </drdp-select-state>
        </div>
        <div *ngIf="!isAllPilot.value" class="col-span-1 self-end">
            <div *ngIf="!disableDropdown">
              <drdp-select-agency (agency)="handleChosenAgency($event)" [stateId]="stateId.value"
                [initialAgencyId]="initAgencyId" [required]="true" [clear]="clearDropdown" #agencySelect>
              </drdp-select-agency>
            </div>
            <div *ngIf="disableDropdown">
              <drdp-select-agency (agency)="handleChosenAgencies($event)" [stateId]="stateId.value"
                [required]="true" [clear]="clearDropdown" [multiSelect]="true" #agencySelect>
              </drdp-select-agency>
            </div>
        </div>
        <div class="col-span-1 self-end">
          <div *ngIf="!disableDropdown && !isAllPilot.value">
            <drdp-select-rating-period #ratingPeriodSelect (ratingPeriod)="handleChosenRatingPeriod($event)"
            [initialRatingPeriodId]="ratingPeriodId.value" [agencyId]="agencyId.value" [required]="true" [showPilotRps]="isPilot" [default2025]="isPilot"
            [clear]="clearDropdown">
          </drdp-select-rating-period>
          </div>
          <div *ngIf="disableDropdown || isAllPilot.value">
            <drdp-select-global-rating (ratingPeriod)="handleChosenRatingPeriod($event)" 
                                      [required]="true"
                                      [showAll]="!isPilot"
                                      [showPilotRps]="isPilot">
            </drdp-select-global-rating>
          </div>
        </div>
        <div *ngIf="!disableDropdown && !isAllPilot.value" class="col-span-1 self-end">
          <drdp-select-site (site)="handleChosenSite($event)" [agencyId]="agencyId.value" [multiSelect]="true"
            [required]="true" [multiSelectInitVals]="false" [clear]="clearDropdown" #sitesSelect>
          </drdp-select-site>
        </div>

        <div *ngIf="!disableDropdown && !isAllPilot.value" class="col-span-1 self-end">
          <drdp-filter-select #classroomSelect label="Classroom" i18n-label [options]="classroomList" [selectType]="select.Class"
            [required]="true" (onSelected)="handleChosenClassroom($event)" [multiSelect]="true">
          </drdp-filter-select>
        </div>

        <div *ngIf="!isAllPilot.value" class="col-span-1 self-end">
          <drdp-select-age-grade #ageGroupInstrumentSelect [initialageGradeId]="ageGradeInstrumentId.value"
            (ageGrade)="handleAgeGroupInstrument($event)" [stateId]="stateId.value" [multiSelect]="false"
            [clear]="clearDropdown" [required]="true" label="Age Group" i18n-label [isPilot]="isPilot">
          </drdp-select-age-grade>

        </div>
        <div *ngIf="!disableDropdown && !isAllPilot" class="hidden lg:block lg:col-span-3 xl:col-span-3 self-end"></div>
        <div class="flex flex-row justify-evenly pt-10 lg:col-span-2 xl:col-span-1 md:self-end xl:my-auto mt-2 md:mt-0">
          <div class="col-span-1">
            <drdp-btn type="submit">
              <span class="px-2" (click)="search()" i18n>Download</span>
            </drdp-btn>
          </div>
          <div class="col-span-1">
            <drdp-btn color="outline" (click)="clear()" i18n> Clear </drdp-btn>
          </div>
        </div>
      </div>
    </form>
  </drdp-container>
</drdp-container>