import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ClassService } from '@core/services/class.service';
import { take } from 'rxjs';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { IClass } from '@core/interfaces/iclasses';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { Component, Inject, OnInit } from '@angular/core';
import {
  IStudentRequest,
  IStudentRequestBatch,
} from '@core/interfaces/istudent-request';
import { IStudentSearchResponseDto } from '@core/interfaces/istudent';
import { ToastService } from '@core/services/toast.service';
import { CustomValidators } from '@core/validators/custom.validator';

@Component({
  selector: 'drdp-batch-re-enroll-child',
  templateUrl: './batch-re-enroll-child.component.html',
  styleUrls: ['./batch-re-enroll-child.component.scss'],
})
export class BatchReEnrollChildComponent implements OnInit {
  user = this.authService.getCurrentUser();
  ratingOptions: IRatingPeriod[] = [];
  submitBtnText = '';
  isAdmin: boolean = false;
  reEnrollmentForm: FormGroup | any;
  existingClasses: IClass[] = [];
  displayResult = false;
  isProcessing = false;
  students?: IStudentSearchResponseDto[];
  rpStartDate!: number;
  rpEndDate!: number;
  agencyId?: number;
  endDateInfo = $localize `The last day the child will be enrolled in the current class enrollment.`;
  enrollmentDateInfo = $localize `The day the child will start in the new class enrollment.`;

  get ratingPeriodId() {
    return this.reEnrollmentForm.get('ratingPeriodId');
  }
  get classExitDate() {
    return this.reEnrollmentForm.get('classExitDate');
  }
  get classEnrollmentDate() {
     return this.reEnrollmentForm.get('classEnrollmentDate');
  }

  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private studentRequestService: StudentRequestService,
    private classService: ClassService,
    private toastService: ToastService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<BatchReEnrollChildComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.initializeForms();
    this.agencyId = this.data.agencyId;

    this.isAdmin = this.permissionService.checkPermission(
      Permission.AdminEnrollmentByPass
    );

    this.submitBtnText = this.isAdmin
      ? $localize `Save and Re-Enroll`
      : $localize `Save and Send For Approval`;

    this.classService
      .getClassesByClassroom(this.data.classroomId)
      .pipe(take(1))
      .subscribe((result) => {
        const ratingPeriods = result.flatMap((obj) => obj.ratingPeriod);
        const currentRatingPeriod = ratingPeriods.find(
          (r: any) => r.id == this.data.ratingPeriodId
        );

        if (currentRatingPeriod) {
          this.ratingOptions = ratingPeriods.filter(
            (r: IRatingPeriod) =>
              new Date(r.startDate) > new Date(currentRatingPeriod.startDate) &&
              r.id != this.data.ratingPeriodId
          );
          this.students = this.data.students;
          this.existingClasses = result;
          this.displayResult = true;
        }
      });
  }

  initializeForms(): void {
    this.reEnrollmentForm = this.fb.group({
      ratingPeriodId: [null, Validators.required],
      classEnrollmentDate: [null, [Validators.required]],
      classExitDate: [null, [Validators.required]],
    }, {validator: [CustomValidators.dateValidator('classExitDate', 'classEnrollmentDate')]
    });
  }

  handleDate(event: any, controlName: string): void {
    if (event === '' || !event) {
      this.reEnrollmentForm.get(controlName).setValue(null);
    } else {
     this.reEnrollmentForm.get(controlName).setValue(event);
    }
    this.checkEnrollmentDate();
  }

  checkEnrollmentDate(): void {
    if (this.classEnrollmentDate.value && this.ratingPeriodId.value) {
      const checkDate = new Date(this.classEnrollmentDate.value).getTime();
      if (checkDate < this.rpStartDate || checkDate > this.rpEndDate) {
        this.classEnrollmentDate.setErrors({dateNotWithinRatingPeriod: true});
      } else {
        this.classEnrollmentDate.setErrors(null);
      }
    }
  }

  handleRatingPeriod(event: IRatingPeriod): void {
    this.ratingPeriodId.setValue(event.id);
    this.rpStartDate = new Date(event.minDate).getTime();
    this.rpEndDate = new Date(event.maxDate).getTime();
    this.checkEnrollmentDate();
  }

  submit(): void {
    if (this.reEnrollmentForm.invalid) {
      this.toastService.error($localize `Please enter all required fields.`);
      return;
    }

    const reEnrollClass = this.existingClasses.filter(
      (ec) => ec.ratingPeriodId == this.ratingPeriodId.value
    )[0];

    const payload: IStudentRequestBatch = {
      studentRequestDtos: [],
      requestReasonId: StudentRequestReason.ReEnrollStudent,
      isBatch: true,
    };

    this.isProcessing = true;
    const successMessage = this.isAdmin
      ? $localize `Students successfully re-enrolled`
      : $localize `Re-enrollment request has been sent`;

    if (this.students?.length) {
      const studentRequest: IStudentRequest[] = this.students.map(
        (student: IStudentSearchResponseDto) => ({
          fromClassId: this.data.fromClassId,
          toClassId: reEnrollClass.id,
          studentId: student.id,
          requestReasonId: StudentRequestReason.ReEnrollStudent,
          siteId: student.siteId,
          studentName: `${student.firstName} ${student.lastName}`,
          classEnrollmentDate: this.classEnrollmentDate.value,
          classExitDate: this.classExitDate.value,
        })
      );
      payload.studentRequestDtos = studentRequest;
      payload.siteId = studentRequest[0].siteId;


    this.studentRequestService.checkDuplicateBatch(payload)
    .pipe(take(1))
    .subscribe((duplicates) => {
      if(duplicates && duplicates.length > 0) {
        this.dialogRef.close({ success: false, duplicates: duplicates });
      }
      else {
          this.studentRequestService
          .processBatchRequest(payload, successMessage)
          .pipe(take(1))
          .subscribe((_) => {
            this.isProcessing = false;
            this.dialogRef.close({ success: true });
          });
        }
      });
    }
  }
}
