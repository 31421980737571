<div class="p-5">
  <div>
    <drdp-title title="Group Report" i18n-title></drdp-title>
    <div>
      <p i18n>
        Select rating periods and age group to create a group report (additional filters are optional). Any rating options other than developmental levels, will not be calculated in the report and will affect the number of children reflected. All classes are automatically included based on the sites selected. To select a subset of classes, click on the appropriate classes in the Classes drop down menu.
      </p>
    </div>
  </div>
  <drdp-report-search [multiSelectClassrooms]="true" [multiSelectSites]="true" [hasSubfilters]="true" [multiSelectRatingPeriods]="true"
    (showReportClicked)="showReport($event)" (downloadReportClicked)="downloadReport($event)"
    (clearClicked)="handleClear()" [requiredFields]="requiredFields"></drdp-report-search>
  <div *ngIf="!!reportData" [innerHtml]="reportData! | safeHtml"></div>
</div>

