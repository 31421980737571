import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SelectType } from '@core/enums/select';
import { StudentDemographic } from '@core/enums/student-demographic-form';
import { IModalEmitData } from '@core/interfaces/imodal';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';
import { IStudentSearchRequestDto } from '@core/interfaces/istudent';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { RatingService } from '@core/services/rating.service';
import { StateService } from '@core/services/state.service';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-pilot-test-children',
  templateUrl: './pilot-test-children.component.html',
  styleUrls: ['./pilot-test-children.component.scss']
})
export class PilotTestChildrenComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  childSearch: FormGroup | any;
  user = this.authService.getCurrentUser();
  isSuperUser: boolean = this.authService.isSuperUser();
  isSiteAdmin: boolean = this.authService.isSiteAdmin();
  isTeacher: boolean = this.authService.isTeacher();
  clearDropdowns = false;
  pilotChildrenList: any[] = [];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  tableData?: any;
  ssidLabel = 'SSID';
  ssidPlaceholder = 'SSID';
  currentSchoolYear?: ISchoolYear;

  private previousAgencyId: number | null = null;
  private previousRatingPeriodId: number | null = null;

  get firstName() { return this.childSearch.get('firstName') }
  get lastName() { return this.childSearch.get('lastName') }
  get stateId() { return this.childSearch.get('stateId') }
  get agencyId() { return this.childSearch.get('agencyId') }
  get SSID() { return this.childSearch.get('SSID') }
  get localId() { return this.childSearch.get('localId') }
  get ageGradeInstrumentId() { return this.childSearch.get('ageGradeInstrumentId') }
  get DOB() { return this.childSearch.get('DOB') }
  get drdpId() { return this.childSearch.get('drdpId')}
  get ratingPeriodId() { return this.childSearch.get('ratingPeriodId')}
  get classroomIds() { return this.childSearch.get('classroomIds')}
  get siteIds() { return this.childSearch.get('siteIds')}

  tableColumns = [
    { columnDef: 'lastName', header: $localize `Last Name`, type: 'text' },
    { columnDef: 'firstName', header: $localize `First Name`, type: 'text' },
    { columnDef: 'dob', header: 'DOB', type: 'date' },
    { columnDef: 'agencyName', header: $localize `Agency`, type: 'text' },
    { columnDef: 'siteName', header: $localize `Site`, type: 'text' },
    { columnDef: 'className', header: $localize `Class`, type: 'text' },
    { columnDef: 'teacherOfRecordName', header: $localize `Teacher`, type: 'text' },
    { columnDef: 'enrollmentStartDate', header: $localize `Agency Enrollment`, type: 'date' },
    { columnDef: 'enrollmentEndDate', header: $localize `Agency Withdrawal`, type: 'date' },
    { columnDef: 'ageGradeInstrumentName', header: $localize `Age/Instrument`, type: 'text' },
    { columnDef: 'View Student', header: $localize `View Student`, type: 'view' },
    { columnDef: 'isPilotTest', header: $localize `Assign`, type: 'checkBox', checkClass: 'checkbox accent-drdpblue-300 cursor-pointer', disabledWhen: (row: any) => row.hasRatings  }
  ];

  public get selectType() { return SelectType; }
  constructor(private fb: FormBuilder, 
              private authService: AuthService, 
              private studentService: StudentService,
              private toastService: ToastService,
              private router: Router,
              private stateService: StateService,
              private ratingService: RatingService,
              private lookupService: LookupService) { }

  ngOnInit(): void {
    this.lookupService.getCurrentSchoolYear().pipe().subscribe((res: any) => {
      this.currentSchoolYear = res;
    });
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  initializeTableData(res?: any):void {
    this.dataSource = new MatTableDataSource(res.items)
    this.totalData = res.totalData;
    this.tableData = res.items;
   }

  getSsidLabel(stateId: number): void {
    this.stateService
      .getStateConfigurations(stateId)
      .pipe(
        take(1),
        tap((config: IStateConfiguration[]) => {
          const ssid = config.find(
            (x: any) => x.columnName === StudentDemographic.SSID
          );
          this.ssidLabel = ssid?.label ? ssid?.label : this.ssidLabel;
          this.ssidPlaceholder = ssid?.label ? ssid?.label : this.ssidLabel;
        })
      )
      .subscribe();
  }

  initializeForm():void {
    this.childSearch = this.fb.group({
      firstName: [null, [Validators.maxLength(100)]],
      lastName: [null, [Validators.maxLength(100)]],
      stateId: [null],
      agencyId: [null],
      ageGradeInstrumentId: [null],
      SSID: [null, [Validators.maxLength(100)]],
      localId: [null, [Validators.maxLength(100)]],
      drdpId: [null, [Validators.maxLength(100)]],
      DOB: [null],
      ratingPeriodId: [null, [Validators.required]],
      classroomIds: [null],
      siteIds: [null],
    });

    const { stateId, agencyId, sites } = this.user;

    if (stateId) this.childSearch.patchValue({ stateId: stateId });
    if (agencyId) this.childSearch.patchValue({ agencyId: agencyId });
    if (sites) {
      const siteIds = sites?.map((sites: any) => sites.siteId);
      this.childSearch.patchValue({ siteIds: siteIds });
    } 
    
  }

  handleDropdown(event: any, type: string): void {
    switch(type) {
      case SelectType.States:
        this.stateId.setValue(event?.id);
        break;
      case SelectType.Agencies:
        this.agencyId.setValue(event?.id);
        break;
      case SelectType.Sites:
        const siteIds = event?.map((sites: any) => sites.id);
        this.siteIds.setValue(siteIds);
        break;
      case SelectType.RatingPeriods:
        this.ratingPeriodId.setValue(event?.id);
        break;
      case SelectType.Class:
        const classIds = event?.map((classroom: any) => classroom.id);
        this.classroomIds.setValue(classIds);
        break;
      case SelectType.AgeInstrument:
        this.ageGradeInstrumentId.setValue(event?.id);
        break;
    }
  }

  handleDate(event: any): void {
    this.DOB.setValue(event);
  }

  search(): void {
    this.paginateTable(true);
  }

  onClear(): void {
    this.clearDropdowns = true;
    this.childSearch.reset();
  }

  assignRole(event: any): void {
    const { data } = event;
    if (this.pilotChildrenList.some((child: any) => child.id === data.id)) {
      const index = this.pilotChildrenList.findIndex((child: any) => child.id === data.id);
      this.pilotChildrenList.splice(index, 1);
    } else {
      this.pilotChildrenList.push(data);
    }
  }

  submitPilotChildren(): void {
    if (!this.pilotChildrenList.length) {
      return this.toastService.error($localize `No new assignments to save.`);
    }

    this.studentService.updateStudentPilotRole(this.pilotChildrenList).pipe(
      take(1),
      tap(_ => this.pilotChildrenList = [])
    ).subscribe();
  }

  paginateTable(resetPage: boolean): void {
    if (this.ratingPeriodId.value == null) {
      this.toastService.error($localize `Rating Period is required`);
      return;
    }

    const currentAgencyId = this.agencyId.value;
    const currentRatingPeriodId = this.ratingPeriodId.value;

    this.previousAgencyId = currentAgencyId;
    this.previousRatingPeriodId = currentRatingPeriodId;

    const siteIds = this.siteIds.value?.length
      ? this.siteIds.value.toString()
      : null;
    const pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    const itemsPerPage = resetPage ? 10 : this.paginator.pageSize;

    const payload: IStudentSearchRequestDto = {
      ...this.childSearch.value,
      siteIds,
      pageIndex,
      itemsPerPage,
    };

    this.studentService.search(payload).pipe(
      take(1),
      tap((res: any) => {
        const { children } = res;
        if (resetPage) this.paginator.firstPage();
        this.initializeTableData(children);
        if (children.totalData > 25) this.pageSizeOptions.push(children.totalData);
      })
    ).subscribe();
  }

  openViewChild(viewData: IModalEmitData): void {
    if (!this.isSuperUser && viewData.data.enrollmentEndDate) {
      this.toastService.error('Child is no longer enrolled with your agency.');
      return;
    }
    this.router.navigate(['/view-child/' + viewData.data.id]);
  }
}