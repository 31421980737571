import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { IRatingDomainDto } from '@views/user/input-rating/interfaces/irating-domain';
import { IMeasureRatingLevel } from '@views/user/input-rating/interfaces/imeasure-rating-level';
import { getSearchUrl } from './helper.service';
import { ITeacherSurvey } from '@views/user/input-rating/interfaces/iteacher-survey';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root',
})
export class RatingService {
  private readonly studentSearchIds = 'student-search-ids';
  private readonly nextStudent = 'student-next';
  private readonly previousStudent = 'student-previous';
  constructor(private data: DataService, private locale: LocaleService) {}

  saveChildRatings(request: any, message: string) {
    return this.data.post('rating', request, message);
  }

  unlockRating(id: number) {
    return this.data.post(
      `rating/unlock/${id}`,
      null,
      $localize`Ratings successfully unlocked for this child!`
    );
  }

  setStudentNextIndex(index: any) {
    localStorage.setItem(this.nextStudent, index);
  }

  setStudentPreviousIndex(index: any) {
    localStorage.setItem(this.previousStudent, index);
  }

  setStudentResultIds(data: number[]) {
    localStorage.setItem(this.studentSearchIds, JSON.stringify(data));
  }

  getStudentResultIds() {
    return JSON.parse(localStorage.getItem(this.studentSearchIds) ?? '[]');
  }

  getNextStudentIndex() {
    return localStorage.getItem(this.nextStudent);
  }

  getPreviousStudentIndex() {
    return localStorage.getItem(this.previousStudent);
  }

  getStudentMeasuresAndRatings(
    templateId: number,
    studentClassEnrollmentId: number,
    carId?: number
  ): Observable<IRatingDomainDto[]> {
    let url = `rating/enrollment/${studentClassEnrollmentId}/template/${templateId}/car/`;
    if (carId) url += `${carId}`;
    return this.data.get(url);
  }

  getStudentMeasuresAndRatingsScantron(
    templateId: number,
    parentTemplateId: number,
    studentClassEnrollmentId: number,
    carId?: number
  ): Observable<IRatingDomainDto[]> {
    let url = `rating/enrollment/${studentClassEnrollmentId}/scantron/${templateId}/parent/${parentTemplateId}/car/`;
    if (carId) url += `${carId}`;
    return this.data.get(url);
  }

  getMeasureLevels(
    templateId: number,
    measureId: number
  ): Observable<IMeasureRatingLevel> {
    return this.data.get(
      `rating/template/${templateId}/measure/${measureId}/levels`
    );
  }

  lockClass(payload: any) {
    return this.data.post(
      `rating/lock/class`,
      payload,
      $localize`Ratings successfully locked for this class!`
    );
  }

  unlockClass(payload: any) {
    return this.data.post(
      `rating/unlock/class`,
      payload,
      $localize`Ratings successfully unlocked for this class!`
    );
  }

  getClassRatings(params: any, message?: string): Observable<any> {
    const url = getSearchUrl('rating/search?', params);
    return this.data.get(url, message);
  }

  generatePdf(id: number) {
    return this.data.postWithOptions(`rating/download/${id}`, null, {
      responseType: 'blob',
    });
  }

  printPdf(id: number) {
    return this.data.postWithOptions(`rating/print/${id}?culture=${this.locale.getLocale().code}`, null, {
      responseType: 'blob',
    });

  }

  getRatingDownloadUrl(id: number): Observable<any> {
    return this.data.get(`rating/download-url/${id}`);
  }

  getLockCount(): Observable<any> {
    return this.data.get(`rating/lock-count`);
  }

  getClassroomRatings(payload: any, message?: string): Observable<any> {
    return this.data.post(
      `rating/classroom-ratings`,
      payload
    );
  }

  saveTeacherSurvey(payload: ITeacherSurvey, message?: string): Observable<boolean> {
    return this.data.post('rating/teacher-survey', payload, message);
  }
  
  getTeacherSurvey(cardId: number, measureId: number): Observable<ITeacherSurvey> {
    return this.data.get(`rating/teacher-survey/car/${cardId}/measure/${measureId}`);
  }
}
