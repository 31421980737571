import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UploadTypes } from '@core/enums/upload-types';
import { IChildTemplate, IChildTemplateUpload } from '@core/interfaces/IdownloadTemplate';
import { AuthService } from '@core/services/auth.service';
import { DownloadTemplateService } from '@core/services/downloadTemplate.service';
import { take, tap } from 'rxjs';

@Component({
  selector: 'drdp-child-upload',
  templateUrl: './child-upload.component.html',
  styleUrls: ['./child-upload.component.scss']
})
export class ChildUploadComponent implements OnInit {
  @Input() stateId?: number;
  stateCode = '';
  public get uploadTypes() { return UploadTypes }
  constructor(private downloadTemplateService: DownloadTemplateService, private authService: AuthService) { }

  ngOnInit(): void {
    this.stateCode = this.authService.getUserStateCode();
  }

  downloadFile() {
    if (this.stateId) {
      this.downloadTemplateService.downloadTemplate(this.stateId, this.uploadTypes.student, { responseType: 'blob', observe: 'response' }).pipe(
        take(1),
        tap((res: any) => {
          let fileName = `All-Instrument-Upload-${this.stateCode}.xlsx`;             
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(res.body);
          link.download = fileName;
          link.click();
        })
      ).subscribe();
    }
  }

  onFileSelected(event: any) {
    const file:File = event.target.files[0];
    if (file) {
      if (this.stateId) {
        var formData = new FormData();
        formData.append('template.StateId', JSON.stringify(this.stateId));
        formData.append('template.UploadTypeId', JSON.stringify(this.uploadTypes.student));
        formData.append('template.Id', JSON.stringify(0));
        formData.append('file', file);

        this.downloadTemplateService.uploadTemplate(formData, $localize `Upload Template Successful`).pipe(
          take(1)
        ).subscribe();
      }
    }
  }
}
