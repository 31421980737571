<div class="p-5">
  <div>
    <drdp-title title="Rating Completion Report" i18n-title></drdp-title>
  </div>
  <drdp-container [additionClasses]="'min-h-[44rem]'">
    <div class="w-full">
      <drdp-container color="lightBlue">
        <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-8 gap-y-4" [formGroup]="ratingCompletionSearch"
          (ngSubmit)="handleSearch()">
          <div *ngIf="hasAccessToPilot" class="lg:col-span-5 md:col-span-2 col-span-1">
            <mat-checkbox color="primary" (change)="handleGlobal()" formControlName="isAllPilot">
            </mat-checkbox>
            <label class="inline">
              All Pilot
            </label>
          </div>
          <div class="col-span-1 my-auto">
            <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleDropdown($event, selectType.States)"
              [clear]="clearDropdowns" [required]="true">
            </drdp-select-state>
          </div>

          <div *ngIf="!isAllPilot.value" class="col-span-1 my-auto">
            <drdp-select-agency #agencySelect [initialAgencyId]="agencyId.value" (agency)="handleDropdown($event, selectType.Agencies)"
              [stateId]="stateId.value" [clear]="clearDropdowns" [required]="true">
            </drdp-select-agency>
          </div>

          <div *ngIf="!isAllPilot.value" class="col-span-1 my-auto">
            <drdp-select-site [initialSiteIds]="siteIds.value" (site)="handleDropdown($event, selectType.Sites)" [agencyId]="agencyId.value"
              [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearDropdowns" [required]="true">
            </drdp-select-site>
          </div>

          <div class="col-span-1 self-end">
            <drdp-select-global-rating (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)" [required]="true" 
              [initialRatingPeriodId]="globalRatingPeriodId.value" [showPilotRps]="isAllPilot.value" [clear]="clearDropdowns">
            </drdp-select-global-rating>
          </div>
          <div *ngIf="!isAllPilot.value" class="col-span-1 self-end">
            <drdp-select-class (classroom)="handleDropdown($event, selectType.Class)" [initialClassroomIds]="classroomIds.value" [siteIds]="siteIds.value"
              [globalRatingPeriodId]="globalRatingPeriodId.value" [clear]="clearDropdowns" [required]="true" [useClassroomId]="true">
            </drdp-select-class>
          </div>
          <div class="lg:col-span-2 flex flex-row md:flex-col-1 gap-5 lg:flex-row lg:justify-start lg:items-end">
            <div class="mb-0 md:mb-2 lg:mb-0">
              <drdp-btn i18n type="submit" [disabled]="ratingCompletionSearch.invalid" class="lg:mr-2">Search</drdp-btn>
            </div>
            <div>
              <drdp-btn i18n color="outline" type="button" (click)="onClear()">Clear</drdp-btn>
            </div>
          </div>
        </form>
      </drdp-container>
      <drdp-add-edit-table
        [dataSource]="dataSource"
        [tableData]="tableData"
        [columns]="tableColumns"
        viewIcon="uil uil-file text-drdpblue-300"
        (iconActionItem)="onIconClick($event)"
      >
      </drdp-add-edit-table>
      <div>
        <mat-paginator
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          [length]="totalData"
          [pageIndex]="pageIndex"
          (page)="paginateTable(false)">
        </mat-paginator>
      </div>
    </div>
  </drdp-container>
</div>
