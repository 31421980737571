<div class="p-5">
  <div class="mb-5 mr-3">
    <drdp-title title="Classrooms" i18n-title></drdp-title>
  </div>
  <drdp-container [additionClasses]="'min-h-[44rem]'">
    <div class="w-full">
      <drdp-container
        *ngIf="accessToClassroomSearch"
        color="lightBlue"
        additionClasses="min-h-[7rem] w-full"
      >
        <form
          class="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 p-5 gap-2 md:gap-3 flex-row content-center my-auto lg:items-end"
        >
          <div class="col-span-1">
            <drdp-select-state
              #stateSelect
              [initialStateId]="stateId.value"
              (state)="handleState($event)"
              [clear]="clearDropdowns"
              [required]="true"
            >
            </drdp-select-state>
          </div>
          <div class="col-span-1">
            <drdp-select-agency
              #agencySelect
              (agency)="handleAgency($event)"
              [stateId]="stateId.value"
              [initialAgencyId]="agencyId.value"
              [clear]="clearDropdowns"
              [required]="!accessToAllAgencies"
            >
            </drdp-select-agency>
          </div>
          <div class="col-span-1">
            <drdp-select-site
              [initialSiteId]="siteId.value"
              (site)="handleSites($event)"
              [agencyId]="agencyId.value"
              [multiSelect]="false"
              [multiSelectInitVals]="false"
              [clear]="clearDropdowns"
            >
            </drdp-select-site>
          </div>
          <div class="col-span-1">
            <drdp-select-rating-period
              [initialRatingPeriodId]="ratingPeriodId.value"
              (ratingPeriod)="handleRatingPeriod($event)"
              [agencyId]="agencyId.value"
              [clear]="clearDropdowns"
            >
            </drdp-select-rating-period>
          </div>
          <div class="col-span-1">
            <drdp-select-class
              [initialClassroomId]="classroomId.value"
              (classroom)="handleClassroom($event)"
              [siteIds]="siteId.value"
              [ratingPeriodId]="ratingPeriodId.value"
              [multiSelect]="false"
              [clear]="clearDropdowns"
            >
            </drdp-select-class>
          </div>
          <div
            class="flex flex-row col-span-1 justify-evenly content-center mt-5 lg:justify-start md:col-span-2"
          >
            <div class="mb-0 lg:mr-2">
              <drdp-btn
                [disabled]="classroomsForm.invalid"
                (click)="search(true)"
              >
                <span class="px-2" i18n>Search</span>
              </drdp-btn>
            </div>
            <div>
              <drdp-btn color="outline" (click)="onClear()" i18n>Clear</drdp-btn>
            </div>
          </div>
        </form>
      </drdp-container>
      <drdp-add-edit-table
        [dataSource]="dataSource"
        [tableData]="tableData"
        [columns]="tableColumns"
        (iconActionItem)="onIconClick($event)"
      >
      </drdp-add-edit-table>
    </div>
  </drdp-container>
  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="totalData"
    [pageIndex]="pageIndex"
    (page)="loadClasses(false)"
  >
  </mat-paginator>
</div>
