<div class="h-full flex flex-col justify-between">
    <drdp-filter-select 
      #schoolYearSelect
      label="School Year" 
      i18n-label
      [options]="schoolYearOptions" 
      [selectType]="select.SchoolYear"
      [required]="required" 
      [disabled]="disabled"
      [isValid]="isValid"
      (onSelected)="onYearSelect($event)"
      [initialValues]="initialYearId">
    </drdp-filter-select>
  </div>