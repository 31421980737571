import { Component, OnInit, ViewChild } from '@angular/core';
import { IAgency, IAgencySearchPayload } from '@core/interfaces/iagency';
import { AgencyService } from '@core/services/agency.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditAgencyComponent } from './add-edit-agency/add-edit-agency.component';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { IModalEmitData } from '@core/interfaces/imodal';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AuthService } from '@core/services/auth.service';
import { Permission } from '@core/enums/permissions';
import { take } from 'rxjs';
import { PermissionService } from '@core/services/permission.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { SelectTypeComponent } from '@shared/components/dropdowns/select-type/select-type.component';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { ModalType } from '@core/enums/modal';

@Component({
  selector: 'drdp-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss'],
})
export class AgenciesComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;
  @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
  @ViewChild('typeSelect') typeSelect: SelectTypeComponent | undefined;
  pageSizeOptions = [5, 10, 25];
  pageSize: number = 10;
  pageIndex: number = 1;
  tableData?: any;
  totalData: number = 0;
  searchAgencyId: number | null = null;
  searchStateId: number | null = null;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  user: any = this.authService.getCurrentUser();
  hasAccessToAll = this.permissionService.checkPermission(Permission.AccessAllAgencies);
  canSearchAgencies: boolean = this.permissionService.checkPermission(
    Permission.SearchAgencies
  );
  payload: any = {};

  searchForm!: FormGroup;
  initStateId: number = 0;
  initAgencyId: number = 0;

  isMenuOpen = false;
  tableColumns = [
    { columnDef: 'agencyName', header: $localize `Agency Name`, type: 'text' },
    { columnDef: 'cdsCode', header: $localize `Agency Code`, type: 'text' },
    {
      columnDef: 'siteCodeTypeName',
      header: $localize `Agency Code Type`,
      type: 'text',
    },
    { columnDef: 'stateCode', header: $localize `State`, type: 'text' },
    { columnDef: 'siteTypeName', header: $localize `Type`, type: 'text' },
    { columnDef: 'vendorNames', header: $localize `Vendor`, type: 'bulletList' },
    { columnDef: 'edit', header: $localize `Edit`, type: 'edit' },
  ];

  public get Permission() {
    return Permission;
  }

  public get ModalType() {
    return ModalType;
  }

  get agencyName() {
    return this.searchForm.get('agencyName')
  }
  get typeId() {
    return this.searchForm.get('typeId');
  }
  get stateId() {
    return this.searchForm.get('stateId');
  }
  get agencyId() {
    return this.searchForm.get('agencyId');
  }

  constructor(
    private fb: FormBuilder,
    private agencyService: AgencyService,
    public modal: MatDialog,
    public authService: AuthService,
    private permissionService: PermissionService
  ) { }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    if(this.paginator)
      this.paginator._intl.itemsPerPageLabel = '';
  }

  ngOnInit(): void {
    this.initializeForms();
    this.createInitialPayload();
    this.loadTableData();

    this.paginateAgency(true);
  }

  initializeForms(): void {
    this.initStateId = this.user.stateId;
    this.initAgencyId = this.user.agencyId;

    this.searchForm = this.fb.group({
      agencyName: [''],
      typeId: [null],
      stateId: [this.initStateId ?? null],
      agencyId: [this.initAgencyId ?? null],
    });
  }

  createInitialPayload() {
    this.payload = {
      agencyName: '',
      agencyId: this.user.agencyId,
      stateId: this.user.stateId,
      typeId: 0,
      itemsPerPage: 10,
      pageIndex: 1,
    };
  }

  loadTableData() {
    this.getAgencyList(true, this.payload);
  }

  getInitialAgencyId(): number | null {
    return this.hasAccessToAll ? null : this.user.agencyId;
  }

  getAgenciesByUser(): void {
    this.agencyService
      .getCurrentAgenciesByUser()
      .pipe(take(1))
      .subscribe((res: IAgency[]) => {
        this.dataSource.data = res;
        this.table?.reRender(res);
      });
  }

  getAgencyList(resetPage?: boolean, payload?: any): void {
    this.agencyService
      .getAgenciesByPage(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          if (resetPage) {
            this.paginator?.firstPage();
          }
          this.initializeTableData(res);
          if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
        }
      });
  }

  initializeTableData(res: any): void {
    this.tableData = res.data;
    this.tableData.forEach((item: any) => {
      if (item.vendorNames) {
        item.vendorNames = item.vendorNames.split('|').map((name: string) => name.trim());
      }
    });
    this.dataSource = new MatTableDataSource(this.tableData);
    this.totalData = res.totalData;
  }

  openModal(editData?: IModalEmitData, type?: string): void {
    const modalRef = this.modal.open(AddEditAgencyComponent, {
      data: editData
        ? editData
        : type
          ? { id: 0, isHeadStartGrantee: type == 'grantee' }
          : undefined,
    });

    modalRef.afterClosed().subscribe((result: any) => {
      if (result.success) {
        this.agencySelect?.refreshList();
        this.loadTableData();
      }
    });
  }

  search() {
    this.pageSizeOptions = [5, 10, 25];
    this.pageSize = 10;
    this.paginator.pageSize = 10;

    this.payload = {
      agencyName: this.agencyName?.value?.trim() ?? '',
      agencyId: this.agencyId?.value,
      stateId: this.stateId?.value,
      typeId: this.typeId?.value,
      itemsPerPage: 10,
      pageIndex: 1
    };

    this.getAgencyList(true, this.payload);
  }

  onClear() {
    this.searchForm.reset({
      agencyName: '',
      typeId: null,
      stateId: this.initStateId,
      agencyId: this.getInitialAgencyId(),
    });

    this.dataSource.data = [];
    this.totalData = 0;
    this.payload = {
      agencyName: '',
      agencyId: this.user.agencyId,
      stateId: this.user.stateId,
      typeId: 0,
      itemsPerPage: 10,
      pageIndex: 1,
    };
    this.paginator.firstPage();

    this.agencySelect?.clearSelected();
    this.stateSelect?.clearSelected();
    this.typeSelect?.clearSelected();

    this.loadTableData();
  }

  paginateAgency(event: any): void {
    if (this.paginator && this.paginator.pageSize !== undefined) {
      const payload = this.payload;
      const previousPageSize = payload.itemsPerPage;
      payload.itemsPerPage = this.paginator.pageSize;
      payload.pageIndex = this.paginator.pageIndex + 1;

      const isPageSizeChanged = previousPageSize !== payload.itemsPerPage;

      this.getAgencyList(isPageSizeChanged, payload);
    }
  }

  handleState(event: any) {
    this.stateId?.setValue(event?.id);
  }

  handleAgency(event: any) {
    this.agencyId?.setValue(event?.id);
  }

  handleType(event: any) {
    this.typeId?.setValue(event?.id);
  }
}
