<div class="p-5">
    <h1 i18n class="pb-2 text-3xl">Share Child</h1>
    <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
        viewIcon="uil uil-file text-drdpblue-300" (deleteActionItem)="deleteModal($event?.data?.id)">
    </drdp-add-edit-table>
    <div *ngIf="canEdit" class="mt-10">
        <drdp-btn (click)="openModal()">
            <i class="uil uil-plus cursor-pointer"></i>
            <span i18n>Add Provider</span>
        </drdp-btn>
    </div>
</div>
