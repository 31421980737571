import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'drdp-delete-roles',
  templateUrl: './delete-roles.component.html',
  styleUrls: ['./delete-roles.component.scss']
})
export class DeleteRolesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteRolesComponent>,
    @Inject(MAT_DIALOG_DATA) public removeRole: any) {
      dialogRef.disableClose = true
    }

    name:string = this.removeRole.data.roleName
    deleteMessage: any = $localize `Are you sure you want to want to delete` + `<span class="font-bold">${this.name}</span>?`
  ngOnInit(): void {
  }

  onSubmit(){ 
    let payload = {
      index: this.removeRole.index,
      roleId: this.removeRole.data.id
    }
    return payload
  }

}
