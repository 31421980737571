<div *ngIf="childData" class="p-5">
  <div class="grid">
    <div class="cols-span-1">
      <h1 class="md:pb-2 text-3xl"><span i18n>Viewing Child:</span> {{childData.firstName}} {{childData.lastName}}</h1>
    </div>
  </div>
  <div class="flex flex-wrap md:mt-5">
    <div class="w-full mt-3">
      <drdp-view-child-tabs
        [childData]="childData"
        (returnToView)="setChildDataInView()">
      </drdp-view-child-tabs>
      </div>
    </div>
</div>
