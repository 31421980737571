<div *ngIf="studentInfo">
  <div class="grid grid-cols-1 md:grid-cols-3 gap-3 mt-5 px-0">
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>Child:</div>
      <div class="w-3/5 font-bold">{{studentInfo.studentName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>District/Agency:</div>
      <div class="w-3/5 font-bold">{{studentInfo.agencyName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>Classroom Enrollment Date:</div>
      <div class="w-3/5 font-bold">{{studentInfo.enrollmentDate | date: 'longDate'}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>Class:</div>
      <div class="w-3/5 font-bold">{{studentInfo.classroomName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>School or Site:</div>
      <div class="w-3/5 font-bold">{{studentInfo.siteName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>Assessment Date:</div>
      <div class="w-3/5 font-bold">{{studentInfo.assessmentDate | date: 'longDate'}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>Teacher:</div>
      <div class="w-3/5 font-bold">{{studentInfo.teacherName}}</div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-2/5" i18n>Age Group Instrument:</div>
      <div class="w-3/5 font-bold">{{studentInfo.ageGradeInstrumentName}} <span
          *ngIf="studentInfo.isCustom">(Custom)</span></div>
    </div>
    <div class=" flex flex-wrap">
      <div class="w-2/5" i18n>Lock Date:</div>
      <div class="w-3/5 font-bold">{{studentInfo.lockingDate | localDate | date: 'longDate'}}</div>
    </div>

  </div>

  <div class="md:flex place-content-between place-items-center self-center mt-5"
    *ngIf="hasMeasures && !studentInfo?.isPilotEnrollment">
    <p class="text-sm">
      <ng-container *ngIf="studentInfo.templateUrl"> <span i18n>The </span><a class="text-blue-700 font-bold"
          [href]=" studentInfo.templateUrl" target="_blank"><span i18n>DRDP instrument </span></a>
        <span i18n>must be used with this rating record to ensure accuracy when rating.</span>
      </ng-container>
    </p>
  </div>

  <div class="flex flex-wrap justify-end items-end space-x-2 mt-2">
    <div><a class="text-sm cursor-pointer" (click)="goBack()"><i
      class="uil uil-angle-left-b text-base"></i><span i18n>Back to View Class</span></a></div>
    <drdp-btn *ngIf="isScantron && !studentInfo.isLocked && canSaveRatings"
      (click)="onActionClick(InputRatingAction.Save)" color="outline">
      <i class="text-drdpblue-300 text-xl uil uil-save"></i>
      <span i18n>Save</span></drdp-btn>
    <drdp-btn (click)="onActionClick(InputRatingAction.Previous)" color="outline"> <i
        class="text-drdpblue-300 text-xl uil uil-previous"></i>
      <span i18n>Previous</span></drdp-btn>
      <drdp-btn (click)="onActionClick(InputRatingAction.Next)" color="outline"> <i
        class="text-drdpblue-300 text-xl uit uil-step-forward"></i>
        <span i18n>Next</span></drdp-btn>
        <drdp-btn (click)="onActionClick(InputRatingAction.Print)" color="outline">
          <i class="text-drdpblue-300 text-xl uil uil-print"></i>
          <span i18n>Print</span>
      </drdp-btn>
    <drdp-btn *ngIf="!studentInfo.isLocked && canLockRatings"
      (click)="onActionClick(InputRatingAction.Lock)" color="outline">
      <i class="text-drdpblue-300 text-xl uil uil-lock"></i><span i18n>Lock</span></drdp-btn>
    <drdp-btn *ngIf="studentInfo.isLocked && canUnlockRatings"
      (click)="onActionClick(InputRatingAction.Unlock)" color="outline">
      <i class="text-drdpblue-300 text-xl uil uil-unlock"></i>
      <span i18n>Unlock</span></drdp-btn>
  </div>
</div>
