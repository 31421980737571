
<div class="flex justify-between border-b">
  <h1 class="text-[25px] font-bold">{{title}}</h1>
  <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
</div>
<div mat-dialog-content>
  
<!-- <drdp-add-class *ngIf="showDeleteWarningEditor"></drdp-add-class> -->
<!-- <div>
  <p>Are you sure you want to delete?</p>
</div> -->
  <ng-content></ng-content>
  
</div>
<!-- <div mat-dialog-actions class="flex justify-end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <drdp-btn mat-button [mat-dialog-close]="true" cdkFocusInitial>Save</drdp-btn> 
 
</div> -->
