<div>
    <label *ngIf="label.length > 0" class="font-bold" [ngClass]="{'required': required}">
      {{ label }}
    </label>

    <mat-form-field appearance="fill" class="drdp-input filter">
      <mat-select panelClass="group-select"
                 [ngClass]="{'border-drdpblue-300': isValid, 'invalid': !isValid}"
                 [multiple]="multiSelect"
                 [placeholder]="selectLabel"
                 [value]="selected"
                 [disabled]="disabled"
                 [compareWith]="compareWith"
                 [formControl]="optionControl">

        <div *ngIf="isFilter" class="flex flex-row m-3 mb-5 px-2 rounded-lg border-drdpblue-300 border">
          <label for="filterInput">Filter: </label>
          <input matInput type="text" [(ngModel)]="filterText" (keydown)="$event.stopPropagation()" (keyup)="onFilterChange($event)">
        </div>

        <mat-select-trigger *ngIf="multiSelect">
          <span i18n>Selected</span> {{label}}: {{selected.length}}
        </mat-select-trigger>

        <ng-container *ngIf="!isFilter">
          <ng-container *ngFor="let group of options?.data">
            <ng-container *ngIf="group.options.length > 0">
              <mat-optgroup [label]="group.name">
                <mat-option *ngFor="let item of group.options"
                [value]="item.id" class="smaller"
                (click)="handleOption(item)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isFilter">
          <ng-container >
            <ng-container *ngIf="paginatedFilteredOptions?.data[pageIndex].options?.length > 0">
              <mat-optgroup [label]="paginatedFilteredOptions?.data[pageIndex].name">
                <mat-option *ngFor="let item of paginatedFilteredOptions?.data[pageIndex].options"
                  [value]="item.id" class="smaller"
                  (click)="handleOption(item)">
                {{ item.name }}
              </mat-option>
            </mat-optgroup>
          </ng-container>
        </ng-container>
      </ng-container>

        <div class="w-60">
          <mat-paginator [pageSize]="pageSize" [length]="totalData" [hidePageSize]="true"
          (page)="page($event)">
          </mat-paginator>
        </div>

      </mat-select>
    </mat-form-field>
</div>
