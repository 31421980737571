import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


// export type EditorType = 'class' | 'otherForm' | 'otherExamples'| 'none';
// export interface DialogData {
//   title: string;
//   editor: EditorType;
// }
@Component({
  selector: 'drdp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  // constructor(public dialogRef: MatDialogRef<ModalComponent>,
  //   @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
 @Input() title:string=''
//  editor:EditorType =this.data.editor
  
  ngOnInit(): void {
    
  }

  // get showClassEditor(){
  //   return this.editor=='class'
  // }
  
  buttonClick(){

  }
}
