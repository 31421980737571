<div class="rounded-sm fix-action-row modal-body">
  <div class="flex justify-between border-b mb-2">
    <h1 i18n class="text-[25px] font-bold mr-2">Add School Year</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <form class="grid grid-cols-1" [formGroup]="schoolYearForm">

      <div class="col-span-1 items-center w-[100%]">
        <label i18n class="font-normal" for="schoolYearName">School Year: </label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="yyyy-yy" autocomplete="off" id="schoolYearName" type="text"
            formControlName="schoolYearName" required>
        </mat-form-field>
      </div>
      <div class="col-span-1 items-center w-[100%]">
        <span i18n *ngIf="schoolYearName.errors?.pattern && (schoolYearName.value.length < 7 || schoolYearName.value.length > 6)"
          class="text-center text-sm text-red-700">
          Please enter a valid school year format : yyyy-yy
        </span>
      </div>
    </form>
  </div>

  <div class="spacer"></div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <button i18n mat-button mat-dialog-close>Cancel</button>
    <drdp-btn i18n mat-button (click)="onSubmit()" [disabled]="schoolYearForm.invalid" cdkFocusInitial>
      Save
    </drdp-btn>
  </div>
</div>