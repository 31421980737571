import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IModalEmitData } from '@core/interfaces/imodal';
import { ISites } from '@core/interfaces/isites';
import { UserService } from '@core/services/user.service';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { AddEditUserAccountsComponent } from './add-edit-user-accounts/add-edit-user-accounts.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import { take } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { ISelectable } from '@core/interfaces/iselectable';
import { IAgency } from '@core/interfaces/iagency';
import {
  IAdminSearchUsersRequest,
  IAdminSearchUsersResponseDto,
  IAdminSearchUsersResult,
  IStatusType,
} from '@core/interfaces/iuser';
import { IRoles } from '@core/interfaces/iroles';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { SelectAgencyComponent } from '@shared/components/dropdowns/select-agency/select-agency.component';
import { SelectStateComponent } from '@shared/components/dropdowns/select-state/select-state.component';
import { ToastService } from '@core/services/toast.service';
import { Role } from '@core/enums/roles';

@Component({
  selector: 'drdp-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('siteSelect') siteSelect?: FilterSelectComponent | undefined;
  @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
  @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  userSearch: FormGroup | any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  tableData?: any;
  user = this.authService.getCurrentUser();
  payload: Partial<IAdminSearchUsersRequest> = {};
  canCreateAccount = false;
  clearDropdowns = false;
  canEditSameLevel: boolean = false;
  canEditSameLevelNotPromote: boolean = false;

  get searchStr() {
    return this.userSearch.get('searchStr');
  }
  get agencyId() {
    return this.userSearch.get('agencyId');
  }
  get stateId() {
    return this.userSearch.get('stateId');
  }
  get siteIds() {
    return this.userSearch.get('siteIds');
  }
  get userStatusIds() {
    return this.userSearch.get('userStatusIds');
  }
  get roleIds() {
    return this.userSearch.get('roleIds');
  }

  tableColumns = [
    { columnDef: 'firstName', header: $localize `First Name`, type: 'text' },
    { columnDef: 'lastName', header: $localize `Last Name`, type: 'text' },
    { columnDef: 'emailName', header: $localize `Email`, type: 'text' },
    { columnDef: 'roleName', header: $localize `Roles`, type: 'text' },
    { columnDef: 'statusTypeName', header: $localize `Account Status`, type: 'text' },
    { columnDef: 'preview', header: $localize `Preview`, type: 'view' },
    { columnDef: 'edit', header: $localize `Edit`, type: 'edit' },
  ];

  constructor(
    private users: UserService,
    public modal: MatDialog,
    private fb: FormBuilder,
    private authService: AuthService,
    private permission: PermissionService,
    private toast: ToastService
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  ngOnInit(): void {
    this.canCreateAccount = this.permission.checkPermission(
      Permission.CreateAccounts
    );
    this.canEditSameLevel = this.permission.checkPermission(
      Permission.EditSameLevelAccounts
    );
    this.canEditSameLevelNotPromote = this.permission.checkPermission(
      Permission.EditSameLevelNotPromoteSameLevel
      );

    this.initializeForm();
  }

  handleState(state: ISelectable): void {
    this.stateId.setValue(state?.id);
  }

  handleAgency(agency: IAgency): void {
    this.agencyId.setValue(agency?.id);

    if (this.authService.getCurrentUser().agencyId !== this.agencyId.value) {
      this.siteIds.setValue(null);
    }
  }

  handleSites(sites: ISites[]): void {
    if (!sites) {
      this.siteIds.setValue(null);
      return;
    }
    this.siteIds.setValue(sites.map((e: ISites) => e.id));
  }

  handleStatus(event: IStatusType[]): void {
    if (!event.length) {
      this.userStatusIds.setValue(null);
      return;
    }
    this.userStatusIds.setValue(event.map((e: any) => e.id));
  }

  handleroles(event: IRoles[]): void {
    if (!event.length) {
      this.roleIds.setValue(null);
      return;
    }
    this.roleIds.setValue(event.map((e: any) => e.id));
  }

  initializeForm(): void {
    this.userSearch = this.fb.group({
      searchStr: [null],
      agencyId: [null],
      stateId: [null],
      siteIds: [null],
      userStatusIds: [null],
      roleIds: [null]
    });

    this.userSearch.patchValue({
      stateId: this.authService.getUserStateId(),
      agencyId: this.authService.getCurrentUser().agencyId,
      siteIds: this.authService.getCurrentUser().sites.map((site) => site.siteId)
    });
  }

  openModalAdd() {
    this.modal.open(AddEditUserAccountsComponent);
  }

  openViewModal(viewData: IModalEmitData): void {
    const modalRef = this.modal.open(ViewUserComponent, {
      data: viewData ? viewData : undefined,
    });
    modalRef.afterClosed().subscribe();
  }

  canEditUser(data: IAdminSearchUsersResponseDto) {
    const sameAccessLevel = this.user.accessLevel === data.accessLevel;
    const isSameUser = this.user.userId === data.id;
    const editRoleIds = data.roleIds.split(',');


    return (
      this.user.accessLevel > data.accessLevel ||
      isSameUser ||
      (sameAccessLevel && (this.canEditSameLevel || this.canEditSameLevelNotPromote))
    );
  }

  openEditModal(editData?: IModalEmitData): void {
    if (editData) {
      const data = editData.data;

      if (this.canEditUser(data)) {
        const modalRef = this.modal.open(AddEditUserAccountsComponent, {
          data: editData,
        });

        modalRef.afterClosed().subscribe((result) => {
          if (result.success) {
            if (
              this.user.userId == result.payload.id
            ) {
              this.authService.logout().pipe(take(1)).subscribe();
            } else {
              this.paginateTable(true);
            }
          }
        });
      } else {
        this.toast.error($localize `You do not have permission to edit this user's information. Please contact your Lead Agency Administrator for assistance.`);
        return;
      }
    }
  }

  initializeTableData(res: IAdminSearchUsersResult): void {
    this.dataSource = new MatTableDataSource(res.items);
    this.totalData = res.totalData;
    this.tableData = res.items;
  }

  searchAccounts(): void {
    this.paginateTable(true);
  }

  paginateTable(resetPage: boolean): void {
    this.users
      .globalSearch(this.createSearchPayload(resetPage))
      .subscribe((res: IAdminSearchUsersResult) => {
        if (resetPage) this.paginator.firstPage();
        this.initializeTableData(res);
        if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
      });
  }

  onClear(): void {
    this.clearDropdowns = true;
    this.dataSource = new MatTableDataSource();
    this.userSearch.reset();
    this.userSearch.patchValue({
      stateId: this.authService.getUserStateId(),
      agencyId: this.authService.getCurrentUser().agencyId
    });
    if (!this.stateSelect?.disabled) {
      this.stateId.setValue(null);
    }

    if (!this.agencySelect?.disabled) {
      this.agencyId.setValue(null);
    }

  }

  createSearchPayload(resetPage: boolean): IAdminSearchUsersRequest {
    this.payload = this.userSearch.value;
    resetPage
      ? (this.payload.pageIndex = 0)
      : (this.payload.pageIndex = this.paginator.pageIndex + 1);
    resetPage
      ? (this.payload.itemsPerPage = 10)
      : (this.payload.itemsPerPage = this.paginator.pageSize);
    return this.payload as IAdminSearchUsersRequest;
  }
}
