<div class="p-5">
  <div class="flex flex-row justify-between gap-3">
    <drdp-title [title]="title"></drdp-title>
    <a class="self-center text-sm order-last cursor-pointer" routerLink="/portfolio-messages"><i
      class="uil uil-angle-left-b text-base"></i><span i18n>Back to Portfolio Messages</span></a>
  </div>

  <div class="my-5 flex flex-row justify-between gap-3">
    <div class="flex gap-3">
      <drdp-btn i18n [color]="messageBg" type="button" (click)="handleTabChange(false)">Messages</drdp-btn>
      <drdp-btn i18n [color]="archiveBg" type="button" (click)="handleTabChange(true)">Archived</drdp-btn>
    </div>
    <div class="flex gap-3">
      <div class="text-right">
        <drdp-btn color="outline" (click)="addToMessageBoard()" type="button">
          <i class="text-drdpblue-300 text-xl uil uil-envelope"></i>
          <span i18n>Add to Message Board</span></drdp-btn>
      </div>
      <div class="text-right">
        <drdp-btn color="outline" (click)="viewStudentEvidence()" type="button">
          <i class="text-drdpblue-300 text-xl uil uil-notes"></i>
          <span i18n>View Student Evidence</span></drdp-btn>
      </div>
    </div>
  </div>
  <div *ngFor="let message of portfolioMessages">
    <drdp-message-board-tile [message]="message" [senderName]="senderName" [isArchived]="isArchived" (archiveMessage)="archivePortfolioMessage($event)">
    </drdp-message-board-tile>
  </div>

  <div>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [length]="totalData"
      [pageIndex]="pageIndex"
      (page)="paginateTable(false)">
    </mat-paginator>
  </div>
</div>
