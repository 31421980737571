<div *ngIf="!isPortfolio">
  <div i18n class="mt-4" *ngIf="!ratingSheet.measureRatingLevel || ratingSheet.levelCount == 0">
    No developmental levels available for this measure.
  </div>
  <div>

    <div class="w-full">
      <div class="flex w-full" *ngIf="dialogData.isPilotEnrollment">
        <!-- Not Yet -->
        <div class="p-2" *ngIf="ratingSheet.measureRatingLevel?.notYet"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.notYet)">
          <div class="flex items-center">
            <input type="radio" name="filter" class="radio-btn" [value]="measureConditionName.NotYet"
              [(ngModel)]="ratingSheet.measureConditionName" [ngModelOptions]="{standalone: true}" />
            <label class="pl-2" for="notYet"> {{ratingSheet.measureRatingLevel?.notYet?.pilotDescription}}</label>
          </div>
        </div>
        <!-- Conditional  -->
        <div class="p-2" *ngIf="ratingSheet.measureRatingLevel?.conditional"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.conditional)">
          <div class="flex items-center">
            <input type="radio" name="filter" class="radio-btn" [value]="measureConditionName.Conditional"
              [(ngModel)]="ratingSheet.measureConditionName" [ngModelOptions]="{standalone: true}" />
            <label class="pl-2" for="conditional">
              {{ratingSheet.measureRatingLevel?.conditional?.pilotDescription}}</label>
          </div>
        </div>
        <!-- EM  -->
        <div class="p-2" *ngIf="ratingSheet.measureRatingLevel?.EM"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.EM)">
          <div class="flex items-center">
            <input type="radio" name="filter2" [disabled]="!ratingSheet.canEmerging" class="radio-btn"
              [value]="measureConditionName.Emerging" [(ngModel)]="ratingSheet.measureConditionName"
              [ngModelOptions]="{standalone: true}" />
            <label class="pl-2" for="em">{{ratingSheet.measureRatingLevel?.EM?.pilotDescription}}</label>
          </div>
        </div>
      </div>
      <div class="flex flex-row w-full mt-2" [ngClass]="{'overflow-x-auto': dialogData.isPilotEnrollment}" *ngIf="ratingSheet?.measureRatingLevel && ratingSheet.levelCount > 0">
        <!-- Not Yet -->
        <div class="p-2 pt-4 text-center" *ngIf="!dialogData.isPilotEnrollment && ratingSheet.measureRatingLevel?.notYet"
          [class]="{'selected': ratingSheet.measureConditionId == measureCondition.NotYet}"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.notYet)">
          <input type="radio" name="filter" class="radio-btn" [value]="measureConditionName.NotYet"
            [(ngModel)]="ratingSheet.measureConditionName" [ngModelOptions]="{standalone: true}" />
          <label class="text-lg w-20" for="notYet">{{ratingSheet.measureRatingLevel?.notYet?.description}}</label>
        </div>
        <div *ngFor="let level of ratingSheet.measureRatingLevel?.developmentalLevels"
          class="p-2 min-w-[9rem] max-w-[14rem] pt-4" (click)="selectLevel(level)"
          [ngClass]="{'selected': level.id == ratingSheet.selectedValue}">
          <div class="text-center">
            <input *ngIf="!level.isDisabled" type="radio" name="filter" class="radio-btn" [value]="level.id" [id]="level.levelCode ?? level.id"
              [(ngModel)]="ratingSheet.selectedValue" [ngModelOptions]="{standalone: true}" #levelRadioButtons/>
            <div class="text-lg font-bold" *ngIf="level.isDisabled" [id]="level.levelCode ?? level.id" #levelRadioButtons> N/A </div>
            <label class="text-lg" [for]="level.id">{{level.name}}</label>
          </div>
          <p class="text-xs">{{level.description}}</p>
          <div *ngIf="level.example" class="text-sm font-medium cursor-pointer mt-2"
            (click)="level.showExample = !level.showExample">
            Possible Examples<i class="uil uil-angle-right"></i>
          </div>
          <div class="example text-xs pl-3 whitespace-normal break-words" *ngIf="level.showExample"
            [innerHtml]="level.example">
          </div>
        </div>

        <!-- Conditional  -->
        <div class="p-2 pt-4" *ngIf="!dialogData.isPilotEnrollment && ratingSheet.measureRatingLevel?.conditional"
          [class]="{'selected': ratingSheet.measureConditionId == measureCondition.Conditional}"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.conditional)">
          <div class="text-center">
            <input type="radio" name="filter" class="radio-btn" [value]="measureConditionName.Conditional"
              [(ngModel)]="ratingSheet.measureConditionName" [ngModelOptions]="{standalone: true}" />
          </div>
          <label class="text-lg" for="conditional">{{ratingSheet.measureRatingLevel?.conditional?.description}}</label>
        </div>

        <!-- EM  -->
        <div class="p-2 pt-4" *ngIf="!dialogData.isPilotEnrollment && ratingSheet.measureRatingLevel?.EM"
          [class]="{'selected': ratingSheet?.measureConditionId == measureCondition.Emerging}"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.EM)">
          <div class="text-center">
            <input type="radio" name="filter2" [disabled]="!ratingSheet.canEmerging" class="radio-btn"
              [value]="measureConditionName.Emerging" [(ngModel)]="ratingSheet.measureConditionName"
              [ngModelOptions]="{standalone: true}" />
          </div>
          <label class="text-lg w-28" for="em">{{ratingSheet.measureRatingLevel?.EM?.description}}</label>
        </div>

        <!-- UR  -->
        <div class="p-2 flex-shrink-0 w-full w-1/6 max-w-xs pt-4"
          *ngIf="!dialogData.isPilotEnrollment && ratingSheet.measureRatingLevel?.UR"
          [class]="{'selected': ratingSheet.measureConditionId == measureCondition.Unratable}"
          (click)="selectCondition(ratingSheet.measureRatingLevel?.UR)">
          <div class="text-center">
            <input type="radio" name="filter" class="radio-btn" [value]="measureConditionName.Unratable"
              [(ngModel)]="ratingSheet.measureConditionName" [ngModelOptions]="{standalone: true}" />
          </div>
          <label class="text-base" for="ur">{{ratingSheet.measureRatingLevel?.UR?.description}}</label>

          <ng-container
            *ngIf="ratingSheet.measureConditionId == measureCondition.Unratable && dialogData.reasons && dialogData.reasons.length > 0">
            <p class="text-xs font-medium">In the rare circumstance that you are unable to rate a measure:</p>
            <label class="font-normal text-sm" for="reason">Reason</label>
            <mat-select placeholder="Reason" [(ngModel)]="ratingSheet.reasonId" id="reason">
              <mat-option *ngFor="let reason of dialogData.reasons" [value]="reason.id" (click)="selectReason(reason)">
                {{ reason.reasonComment }}
              </mat-option>
            </mat-select>

            <div *ngIf="ratingSheet.showComment" class="mt-2">
              <label class="font-normal text-sm" for="comment">Comment</label>
              <mat-form-field appearance="fill">
                <input matInput [(ngModel)]="ratingSheet.comment" placeholder="Add your comment here" maxlength="100"
                  id="comment" autocomplete="off" type="text" />
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
