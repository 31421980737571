<div class="rounded-lg fixActionRow">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold" i18n>Manage Sub Features</h1>
    <i mat-button [mat-dialog-close]="closeModal()" class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <div class="content">
      <div class="p-5 w-full mb-6">
        <h3 class="global-font-bold" i18n>Current Sub Features</h3>
        <drdp-add-edit-table #currentSub
                             [dataSource]="currentSubDataSource"
                             [tableData]="currentSubTableData"
                             [columns]="currentSubColumns"
                             (deleteActionItem)="deletePermission($event)">
        </drdp-add-edit-table>
      </div>
      <div class="p-5 w-full mb-6">
        <h3 class="global-font-bold" i18n>Add Sub Features</h3>
        <drdp-add-edit-table #addSub
                             [dataSource]="addSubDataSource"
                             [tableData]="addSubTableData"
                             [columns]="addSubColumns"
                             (addActionItem)="addPermission($event)">
        </drdp-add-edit-table>
      </div>
    </div>
  </div>
</div>
