<div class="rounded-lg">

    <div  id="addProviderDialog" class="flex justify-between border-b">
        <h1 i18n class="text-[25px] font-bold">Add Provider</h1>
        <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>
    <div id="add-child-shared-provider" mat-dialog-content>
        <form class="grid grid-cols-2 gap-x-10 p-3" [formGroup]="childSharedProviderForm">
            <div class="col-span-1 items-center w-[100%]">
                <drdp-select-providers (provider)="handleTeacher($event)"
                                        [initialProviderId]="teacherId.value"
                                        [isValid]="!teacherId.touched || teacherId.valid"
                                        [required]="true">

                </drdp-select-providers>
            </div>

            <div class="col-span-1 items-center w-[100%]">
                <label i18n class="font-normal required" for="name">Provider Name</label>
                <mat-form-field class="bg-white" appearance="fill">
                    <input placeholder="Provider Name" i18n-placeholder matInput type="text" readonly="true" formControlName="name"
                        maxlength="200">
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="flex justify-end align-bottom">
        <button i18n mat-button mat-dialog-close>Cancel</button>
        <drdp-btn i18n type="submit" mat-button [disabled]="childSharedProviderForm.invalid" cdkFocusInitial
            (click)="onSubmit()">Add Provider</drdp-btn>
    </div>

</div>