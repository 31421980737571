import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { ToastService } from '@core/services/toast.service';
import { AddSchoolyearComponent } from './add-schoolyear/add-schoolyear.component';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { forkJoin, Subscription } from 'rxjs';
import { IModalEmitData } from '@core/interfaces/imodal';

@Component({
  selector: 'drdp-schoolyears',
  templateUrl: './schoolyears.component.html',
  styleUrls: ['./schoolyears.component.scss'],
})
export class SchoolyearsComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  private subscriptions = new Subscription();
  schoolYears: ISchoolYear[] = [];
  recentGlobalRatingPeriods: any[] = [];
  currentSchoolYear!: ISchoolYear;
  canAddSchoolYear: boolean = false;
  currentDateYear: number = new Date().getFullYear();
  totalData = 0;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  yearToUpdate: string = '';
  yearToUpdateDesc: string = '';
  ratingAmount: number = 16;

  tableColumns = [
    { columnDef: 'schoolYearName', header: $localize `School Year`, type: 'text' },
    { columnDef: 'isCurrent', header: $localize  `Active`, type: 'checkBox' },
  ];

  constructor(
    private ratingPeriods: RatingPeriodService,
    public modal: MatDialog,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    var getSchoolYears = this.ratingPeriods.getAllSchoolYears();
    var getRecentGlobalRatingPeriods =
      this.ratingPeriods.getRecentGlobalRatingPeriods(this.ratingAmount);

    this.subscriptions.add(
      forkJoin([getSchoolYears, getRecentGlobalRatingPeriods]).subscribe(
        ([years, periods]) => {
          this.schoolYears = years.sort((a: any, b: any) =>
            parseInt(a.schoolYearName) < parseInt(b.schoolYearName) ? 1 : -1
          );

          this.currentSchoolYear = this.schoolYears[0];
          this.canAddSchoolYear =
            periods.filter((p: any) => p.schoolYearId == this.schoolYears[0].id)
              .length == 4;
          this.initializeTableData(this.schoolYears);
        }
      )
    );
  }

  initializeTableData(data: any): void {
    this.dataSource.data = data;
    this.tableData = data;
    this.totalData = this.tableData.length;
  }

  openModal(): void {
    if (!this.canAddSchoolYear) {
      this.toast.error(
        $localize `Latest school year must have global rating periods to add a new school year.`
      );
    } else {
      var preModalData: any = this.currentSchoolYear;
      preModalData.index = 0;
      const data: IModalEmitData = preModalData;
      const modalRef = this.modal.open(AddSchoolyearComponent, {
        data: data ?? undefined,
      });

      modalRef.afterClosed().subscribe((res: any) => {
        if (res.formValid) {
          this.saveNewSchoolYear(res.payload);
        }
      });
    }
  }

  saveNewSchoolYear(schoolYear: any): void {
    this.subscriptions.add(
      this.ratingPeriods
        .addSchoolYear(schoolYear, $localize `Added School Year`)
        .subscribe((res: boolean) => {
          if (res) {
            this.schoolYears.unshift(schoolYear);

            this.dataSource = new MatTableDataSource(this.schoolYears);
            this.tableData = this.schoolYears;
            this.totalData++;
            this.canAddSchoolYear = false;
          }
        })
    );
  }

  chooseActiveYear($event: any) {
    var currActiveIndex = this.schoolYears.findIndex((x) => x.isCurrent);
    var temp = this.schoolYears[currActiveIndex];
    temp.isCurrent = false;
    this.schoolYears[currActiveIndex] = temp;

    var newActiveYearIndex = this.schoolYears.findIndex(
      (x) => x.schoolYearName == $event.data.schoolYearName
    );
    var newActive = this.schoolYears[newActiveYearIndex];
    newActive.isCurrent = true;
    this.schoolYears[newActiveYearIndex] = newActive;

    this.dataSource.data = this.schoolYears;
    this.tableData = this.schoolYears;

    this.yearToUpdate = newActive.schoolYearName;
    this.yearToUpdateDesc = newActive.schoolYearDescription;
  }

  updateActiveSchoolYear(): void {
    if (parseInt(this.yearToUpdateDesc) < this.currentDateYear - 1) {
      this.toast.error($localize `Cannot set past school years to active`);
    } else {
      this.subscriptions.add(
        this.ratingPeriods
          .setActiveSchoolYear(this.yearToUpdate, $localize `New Active School Year`)
          .subscribe()
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
