import { Component, OnInit } from '@angular/core';
import { PermissionService } from '@core/services/permission.service';
import { RoleService } from '@core/services/role.service';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsFilterComponent } from './permissions-filter/permissions-filter.component';
import {
  IPermission,
  IPermissionAssignment,
  IPermissionGroupAssignmentRes,
} from '@core/interfaces/ipermission';
import { IRoles } from '@core/interfaces/iroles';
import { ToastService } from '@core/services/toast.service';
import { Observable, take } from 'rxjs';
@Component({
  selector: 'drdp-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  show = false;
  originalRolesList?: IRoles[];
  roles?: IRoles[];
  roleIds: number[] = [];
  permissionGroup$?: Observable<IPermissionGroupAssignmentRes[]>;
  roles$?: Observable<IRoles[]>;
  assignmentList?: IPermissionAssignment[];
  uncategorizedList?: IPermission[];
  constructor(
    private permissionService: PermissionService,
    private RoleService: RoleService,
    public dialog: MatDialog,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.getPermissionGroup();
    this.getPermissionAssignment();
    this.getAllRoles();
    this.getUncategorized();
  }

  getPermissionGroup(): void {
    this.permissionGroup$ = this.permissionService.getSuperPermissionGroups();
  }

  getPermissionAssignment(): void {
    this.permissionService
      .getPermissionAssignments()
      .subscribe((res: IPermissionAssignment[]) => {
        this.assignmentList = res;
      });
  }

  getAllRoles(): void {
    this.RoleService.getAllByOrder().subscribe((res: IRoles[]) => {
      this.originalRolesList = res;
      this.roles = res;
    });
  }

  getUncategorized(): void {
    this.permissionService.getUncategorizedPermission().pipe(
      take(1)
    ).subscribe((res: IPermission[]) => {
      this.uncategorizedList = res;
    });
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(PermissionsFilterComponent, {
      data: { orignalRolesList: this.originalRolesList, roleId: this.roleIds },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((ids) => {
      if (ids) {
        this.roleIds = ids;
        this.filterRoles(this.roleIds);
      }
    });
  }

  filterRoles(roleIds: number[]): void {
    this.RoleService.filterRoles(roleIds).subscribe((res: IRoles[]) => {
      this.roles = res;
    });
  }

  updatePermission(permissionAssignment: any): void {
    this.permissionService
      .updatePermissionAssignment(permissionAssignment, $localize `Permission Updated`)
      .pipe(take(1))
      .subscribe((req) => {
        this.toast.info($localize `Please log out to apply permission changes`);
        this.getPermissionAssignment();
      });
  }

  downloadRoleReport(): void {
    if (!this.roles?.length) {
      return this.toast.warn($localize `No Roles Selected`);
    }

    this.RoleService.downloadRolesReport(this.roles).subscribe((res: any) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(res.body);
      a.setAttribute('download', 'Roles.xlsx');
      a.click();
    });
  }
}
