import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { SelectType } from '@core/enums/select'
import { ISelectable, ISelectType } from '@core/interfaces/iselectable';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'drdp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnChanges {

  private eventsSubscription?: Subscription;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild('select', { read: ElementRef }) selectElement: ElementRef | undefined;

  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() options: any;
  @Input() selectType: SelectType | undefined;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() initialItemId?: number | null;
  @Input() initialItemsId: any;
  @Input() search?: Observable<boolean>;
  @Input() required = false;
  @Input() disabled = false;
  @Input() isValid = true;

  @Output() onClose = new EventEmitter();
  @Output() onSelected = new EventEmitter();
  selectedItem!: ISelectable | null;
  multiSelectedItems: ISelectable[] = [];
  multiSelectLabel = '';
  selectable: ISelectable[] = [];
  pageIndex: number = 0;
  pageSize: number = 10;
  dropdownOpen: boolean = false;
  totalData: number = 0;
  itemsInPage: any[] = [];
  filterText: string = '';
  realign: any;

  public get selectionOption() { return SelectType }

  constructor() { }

  ngOnInit(): void {
    this.multiSelectLabel = this.getMultiSelectLabel(this.multiSelectedItems);
    this.eventsSubscription = this.search?.subscribe((clear) => this.clearSearch(clear));
  }

  ngOnChanges(): void {
    if (this.options) {
      // @todo - options that are selected in another dropdown need to be cleared when optionsList is affected/reset
      this.toSelectable();
      this.totalData = this.selectable.length;
      this.itemsInPage = this.paginate(this.selectable);
      if (this.multiSelect && this.multiSelectInitVals) {
       this.multiSelectedItems = this.selectable.filter(x => this.initialItemsId?.includes(x.id));
       this.multiSelectLabel = this.getMultiSelectLabel(this.multiSelectedItems);
      }

      if (this.initialItemId) {
        this.selectedItem = this.selectable.filter(x => x.id == this.initialItemId)[0];
      }
      else {
        this.selectedItem = null;
      }
    }
  }

  toSelectable(): void {
    let selectName = '';

    if (this.selectType) {
      selectName = this.selectType;

      this.selectable = this.options.map((item: any) => {
        return <ISelectable> {
          id: item.id,
          name: item[selectName],
          type: this.selectType
        }
      });
    }
  }

  filter(keyword: any): void {
    keyword = keyword.toUpperCase();
    var filteredOptions = this.selectable
      .filter((x: any) => x.name
      .toUpperCase()
      .includes(keyword))

    this.pageIndex = 0;
    this.totalData = filteredOptions.length;
    this.itemsInPage = this.paginate(filteredOptions);
  }

  paginate(selectables: ISelectable[]) {
    return selectables.reduce((arr: any, items: any, i: number) =>
    (i % this.pageSize ? arr[arr.length - 1].push(items) : arr.push([items])) && arr, []);
  }

  handleSelect(value: ISelectable) {
    if (this.disabled) {
      return;
    }
    if (this.multiSelect) {
      var itemIndex = this.multiSelectedItems.indexOf(value);
      if (itemIndex !== -1) {
        this.multiSelectedItems.splice(itemIndex, 1);
      } else {
        this.multiSelectedItems.push(value);
      }
      this.multiSelectLabel = this.getMultiSelectLabel(this.multiSelectedItems);
      this.onSelected.emit(this.multiSelectedItems);
    } else {
      if (this.selectedItem == value) {
        this.selectedItem = null;
      } else {
        this.selectedItem = value;
      }
    }
  }

  toggleDropdown($event: any): void {
    if (this.disabled) {
      return;
    }
    var position = this.selectElement?.nativeElement.getBoundingClientRect()
    if (position && position.right > (window.innerWidth * 0.80)) {
      this.realign = { right: 0 }
    }

    this.dropdownOpen = !this.dropdownOpen;
    if (!this.dropdownOpen) {
      if (this.multiSelect && this.multiSelectedItems.length > 0)
        this.onClose.emit(this.multiSelectedItems);
      else if (this.multiSelect && this.multiSelectedItems.length == 0)
        this.onClose.emit([]);

      if (!this.multiSelect)
        this.onClose.emit(this.selectedItem);
    }
  }

  clear(): void {
    this.selectedItem = null;
    this.filterText = '';
    this.filter(this.filterText);
  }

  clearAll(): void {
    this.multiSelectedItems.length = 0;
    this.multiSelectedItems = [];
    this.multiSelectLabel = this.getMultiSelectLabel(this.multiSelectedItems);
    this.filterText = '';
    this.filter(this.filterText);
    this.onSelected.emit(this.multiSelectedItems);
    // @todo - Racca: clearAll doesn't wipe filter
  }

  clearSearch(clearSearch:  boolean): void {
    if (clearSearch) {
      this.clear();
      this.clearAll();
    }
  }

  checkAll(): void {
    this.multiSelectedItems = [...this.selectable];
    this.multiSelectLabel = this.getMultiSelectLabel(this.multiSelectedItems);
    this.onSelected.emit(this.multiSelectedItems);
  }

  page(): void {
    this.pageIndex = this.paginator.pageIndex;
  }

  getMultiSelectLabel(items: ISelectable[]): string {
    const dropDownEntityName = Object.keys(this.selectionOption)[Object.values(this.selectionOption).indexOf(this.selectType!)];
    if (items.length) return $localize `Selected ` + `${dropDownEntityName}: ${items.length}`;
    else return $localize `Select ` + `${dropDownEntityName}`;
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }

//once we use the component directly, we dont need this.
  selectionChanged2(options: any) {
    this.onSelected.emit(options);
  }

}
