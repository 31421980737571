import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { ITableColumn } from '@core/interfaces/itable';
import { TableColumnType } from '@core/enums/table';
import { setIconDisplayObject } from '@core/services/helper.service';
import { ReportService } from '@core/services/report.service';
import { Router } from '@angular/router';
import { IRatingCompletionChildrenResponseDto, IRatingCompletionChildrenSearch } from '../interfaces/irating-completion-search';

@Component({
    selector: 'drdp-view-children-modal',
    templateUrl: './view-children-modal.component.html',
    styleUrls: ['./view-children-modal.component.scss']
  })
  export class ViewChildrenModalComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  classroomName?: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalData: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  payload: IRatingCompletionChildrenSearch = {};
  tableColumns: ITableColumn[] = [
    {
      columnDef: 'firstName',
      header: $localize `First Name`,
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'lastName',
      header: $localize `Last Name`,
      type: TableColumnType.text,
      isSortable: true
    },
    {
      columnDef: 'viewChild',
      header: $localize `View Child`,
      icon: 'file',
      type: TableColumnType.icon,
      colorClass: 'text-drdpblue-300',
      cursorClass: 'cursor-pointer',
    }
  ];


  constructor(public dialogRef: MatDialogRef<ViewChildrenModalComponent>,
    private reportService: ReportService, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.classroomName = this.data.classroomName;
    this.payload.classroomId = this.data.classroomId;
    this.payload.globalRatingPeriodId = this.data.globalRatingPeriodId;
    this.paginate(true);
  }

  getStudents(): void {
    this.reportService
      .getRatingCompletionChildren(this.payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          res.items = res.items.map((data: IRatingCompletionChildrenResponseDto[]) => ({
            ...data,
            displayIcons: setIconDisplayObject(this.tableColumns, data)
          }));

          this.dataSource = new MatTableDataSource(res.items);
          this.tableData = res.items;
          this.totalData = res.totalData;
        }
      });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  onIconClick(event: any): void {
    this.router.navigateByUrl(`view-child/${event?.data?.studentId}`);
    this.dialogRef.close();
  }

  paginate(resetPage?: boolean): void {
    this.payload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    this.payload.itemsPerPage = resetPage ? 10 : this.paginator.pageSize;
    this.getStudents();
  }

}
