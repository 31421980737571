import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { Observable } from "rxjs";
import { IUploadChildSaveResult, IUploadValidateHeaders } from "@core/interfaces/iupload-center";

@Injectable({
    providedIn: 'root'
})

export class UploadService {
    private path: string = 'uploadcenter'
    private fileToUpload!: File;
    
    constructor(public dataService: DataService) {

    }

    parseExcelToJson(allXlsxData: any[], headers: any[]) {
        
        var xlsxDataArray: any[] = []

        for (var i = 0; i < allXlsxData.length; i++) {
                var tempXlsxDataArray: any[] = [];
                for (var j = 0; j < headers.length; j++) {
                    var rowvalue = allXlsxData[i][headers[j]];
                    rowvalue = rowvalue || '';
                    var headerValue = headers[j].replaceAll(" ", "");

                    let xlsxDataObj: any = {};
                    xlsxDataObj['id'] = j;
                    xlsxDataObj['isEditable'] = false;
                    xlsxDataObj['errorMessages'] = [];
                    xlsxDataObj['warningMessages'] = [];
                    xlsxDataObj['header'] = headerValue;
                    xlsxDataObj['data'] = rowvalue.toString().trim();

                    tempXlsxDataArray.push(xlsxDataObj);
                }
                var rowValueObj: any = {};
                rowValueObj['hasError'] = false;
                rowValueObj['cellValues'] = tempXlsxDataArray;
                xlsxDataArray.push(rowValueObj);
        }

        return xlsxDataArray;
    }

    setUploadFile(file: File): void {
        this.fileToUpload = file;
    }

    getUploadFile(): File {
        return this.fileToUpload;
    }

    checkFile(rows: any): Observable<any> {

        const file = this.createFileFromJson(rows);

        const formData = new FormData();
        formData.append('file', file, 'data.txt');

        return this.dataService.postWithoutLocale(`${this.path}/check-file`, formData);
    }

    checkFileClass(rows: any): Observable<any> {

        const file = this.createFileFromJson(rows);

        const formData = new FormData();
        formData.append('file', file, 'data.txt');

        return this.dataService.postWithoutLocale(`${this.path}/check-file-class`, formData);
    }

    checkFileUser(rows: any): Observable<any> {

        const file = this.createFileFromJson(rows);

        const formData = new FormData();
        formData.append('file', file, 'data.txt');

        return this.dataService.postWithoutLocale(`${this.path}/check-file-user`, formData);
    }

    validateHeaders(headers: any): Observable<IUploadValidateHeaders> {
        return this.dataService.postWithoutLocale(`${this.path}/validate-headers`, headers);
    }

    validateClassHeaders(headers: any): Observable<IUploadValidateHeaders> {
        return this.dataService.postWithoutLocale(`${this.path}/validate-headers-class`, headers);
    }
    
    validateUserHeaders(headers: any): Observable<IUploadValidateHeaders> {
        return this.dataService.postWithoutLocale(`${this.path}/validate-headers-user`, headers);
    }

    save(file: File, rows: any): Observable<IUploadChildSaveResult[]> {
        var formData = new FormData();
        formData.append('file', file);

        const jsonFile = this.createFileFromJson(rows);

        formData.append('jsonFile', jsonFile, 'data.txt');

        return this.dataService.postWithoutLocale(`${this.path}/save`, formData);
    }

    saveMoreChild(file: File) : Observable<boolean> {
        var formData = new FormData();
        formData.append('file', file);

        return this.dataService.postWithoutLocale(`${this.path}/save-more-child`, formData);
    }

    saveUploadClass(file:File, rows: any) : Observable<any[]> {
        var formData = new FormData();
        formData.append('file', file);

        const jsonFile = this.createFileFromJson(rows);

        formData.append('jsonFile', jsonFile, 'data.txt');
        
        return this.dataService.postWithoutLocale(`${this.path}/save-upload-class`, formData);
    }

    saveUploadUser(file:File, rows: any) : Observable<any[]> {
        var formData = new FormData();
        formData.append('file', file);

        const jsonFile = this.createFileFromJson(rows);

        formData.append('jsonFile', jsonFile, 'data.txt');
        
        return this.dataService.postWithoutLocale(`${this.path}/save-upload-user`, formData);
    }

    createFileFromJson(jsonObject: any) : File {
        const jsonString = JSON.stringify(jsonObject);
        const blob = new Blob([jsonString], { type: 'text/plain' });

        // Create a file object from the Blob
        const file = new File([blob], 'data.txt', { type: 'text/plain' });

        return file;
    }
}
