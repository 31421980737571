import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IClass, IClassroomSearchRequest } from '@core/interfaces/iclasses';
import { IModalEmitData } from '@core/interfaces/imodal';
import { AuthService } from '@core/services/auth.service';
import { Permission } from '@core/enums/permissions';
import { PermissionService } from '@core/services/permission.service';
import { ClassroomService } from '@core/services/classroom.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { AddClassModalComponent } from './add-class-modal/add-class-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { ModalActions } from "@core/constants/modal-actions";
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { Subscription, take } from 'rxjs';
import { ISearchInput } from '@core/interfaces/isearch-input';

@Component({
  selector: 'drdp-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss'],
})
export class ClassroomComponent implements OnInit, OnDestroy {
  constructor(
    private classroomService: ClassroomService,
    public dialog: MatDialog,
    private authService: AuthService,
    private classEnrollmentService: StudentClassEnrollmentService,
    public PermissionService: PermissionService
  ) {}

  public get Permission() {
    return Permission;
  }
  private subscriptions = new Subscription();
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  pageSizeOptions = [5, 10, 25];
  pageSize = 10;
  totalData = 0;
  tableData?: any;
  pageIndex: number = 0;

  payload: IClassroomSearchRequest = {};

  user = this.authService.getCurrentUser();
  isSuperUser = false;
  isSiteAdmin = false;
  canEdit = this.PermissionService.checkPermission(Permission.EditClassrooms);
  canDelete = this.PermissionService.checkPermission(
    Permission.DeleteClassrooms
  );

  tableColumns = [
    { columnDef: 'className', header: $localize `Classroom`, type: 'text' },
    { columnDef: 'siteName', header: $localize `Site`, type: 'text' },
  ];

  searchInput: ISearchInput = {
    searchName: true,
    searchAgency: true,
    searchState: true,
    searchSite: true,
    searchMultiSite: true
  };

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  ngOnInit(): void {
    this.isSuperUser = this.authService.isSuperUser();
    this.isSiteAdmin = this.authService.isSiteAdmin();
    if (this.canEdit) {
      this.tableColumns.push({
        columnDef: 'edit',
        header: $localize `Edit`,
        type: 'edit',
      });
    }
    if (this.canDelete) {
      this.tableColumns.push({
        columnDef: 'delete',
        header: $localize `Delete`,
        type: 'delete',
      });
    }
    this.createInitialPayload();
  }

  createInitialPayload() {
    this.payload = {
      agencyId: this.user.agencyId,
      stateId: this.user.stateId,
      pageIndex: 0,
      itemsPerPage: 10,
    };
  }

  checkStudentEnrollment(classId: number) {
    return this.classEnrollmentService
      .checkStudentEnrollment(classId)
      .subscribe();
  }

  searchTable(event: any): void {
    const payload = this.createSearchPayload(true, event);
    this.subscriptions.add(
      this.classroomService.search(payload).subscribe((res: IClass) => {
        if (res) {
          this.paginator.firstPage();
          this.initializeTableData(res);
        }
      })
    );
  }

  openModal(editData?: IModalEmitData): void {
    const modalRef = this.dialog.open(AddClassModalComponent, {
      data: editData ? editData : undefined,
    });

    modalRef.afterClosed().subscribe((success: any) => {
      if (success) {
        this.initializeTable();
      }
    });
  }

  initializeTable() {
    this.classroomService
      .search(this.payload)
      .pipe(take(1))
      .subscribe((res: IClass) => {
        if (res) {
          this.initializeTableData(res);
        }
      });
  }

  initializeTableData(res: any): void {
    this.dataSource = new MatTableDataSource(res.classroom);
    this.totalData = res.totalData;
    this.tableData = res.classroom;
  }

  onClear() {
    if (this.isSiteAdmin && !this.isSuperUser) {
      this.payload.agencyId = this.user.agencyId;
      this.payload.stateId = this.user.stateId;
      this.payload.classroomName = null;
      this.payload.siteIds = null;
    } else {
      this.payload = {};
    }
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
    this.pageSize = 10;
    this.paginator.pageSize = 10;
  }

  deleteModal(event: IModalEmitData): void {
    const selectedClassId = event.data.classId;
    this.classroomService
      .checkClassroomHasEnolledStudents(selectedClassId)
      .subscribe((res: boolean) => {
        if (res) {
          event.data.modalInfo = {
            title: `${event.data.className} ${$localize`cannot be deleted`}`,
            message: $localize`There is one or more children currently enrolled in this classroom.  The classroom can not be deleted.  For assistance, please contact WestEd at 1-800-770-6339 or email DRDPOnline@wested.org.`,
            name: event.data.className,
            primaryBtnClass: 'blue',
            primaryBtnText: ModalActions.OK,
          };
          const modalRef = this.dialog.open(ConfirmationModalComponent, {
            data: event ? event : undefined,
          });
          modalRef.afterClosed().subscribe();
        } else {
          event.data.modalInfo = {
            title: `${$localize`Delete`} ${event.data.className}`,
            message: `${$localize`Are you sure you want to want to delete`} <span class="font-bold">${event.data.className}</span>?`,
            name: event.data.className,
            primaryBtnClass: 'red',
            primaryBtnText: ModalActions.Delete,
            showCancel: true,
          };
          const modalRef = this.dialog.open(ConfirmationModalComponent, {
            data: event ? event : undefined,
          });
          modalRef.afterClosed().subscribe((res) => {
            if (res) {
              this.removeClassroomName(selectedClassId, event.data.className);
            }
          });
        }
      });
  }

  removeClassroomName(classroomId: any, classroomName: string) {
    this.subscriptions.add(
      this.classroomService
        .deleteClassroom(classroomId, `${$localize`Classroom Name`} ${classroomName} ${$localize`Deleted`}`)
        .subscribe((res: boolean) => {
          this.initializeTable();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  createSearchPayload(resetPage: boolean, event: any): IClassroomSearchRequest {
    if (this.isSiteAdmin && !this.isSuperUser) {
      this.payload.classroomName = event.SearchName ?? null;
      this.payload.stateId = event.StateId ?? this.payload.stateId ?? null;
      this.payload.agencyId = event.StateId
        ? event.AgencyId
        : this.payload.agencyId ?? null;
      this.payload.siteIds = event.SiteIds ?? null;
    } else {
      this.payload.classroomName = event.SearchName ?? null;
      this.payload.agencyId = event.AgencyId ?? null;
      this.payload.stateId = event.StateId ?? null;
      this.payload.siteIds = event.SiteIds ?? null;
    }

    this.payload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;

    this.payload.itemsPerPage = this.paginator.pageSize;
    return this.payload;
  }

  paginateTable(resetPage: boolean, search?: any): void {
    this.payload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    this.payload.itemsPerPage = this.paginator.pageSize;

    this.classroomService.search(this.payload).subscribe((res: any) => {
      if (res) {
        if (resetPage) this.paginator.firstPage();
        this.initializeTableData(res);
        if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
      }
    });
  }
}
