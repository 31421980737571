<div class="p-5">
  <div class="flex justify-between items-center">
    <drdp-title title="Companion Documents" i18n-title></drdp-title>
  </div>

  <div class="flex flex-wrap gap-4 py-3">
    <drdp-container color="whiteDash" additionClasses="h-11rem w-40 px-1">
      <button (click)="downloadDocument(companionTypes.Guidance_For_DRDP_Domain_Scaled_Scores)" class="w-full pb-4">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-download"></i>
        <p class="text-[15px]" title="Guidance for DRDP Domain Scaled Scores"><span i18n>Guidance for DRDP Domain Scaled Scores</span></p>
        <p class="text-drdpblue-300 text-[15px]"><span i18n>Download</span></p>
      </button>
    </drdp-container>

    <drdp-container color="whiteDash" additionClasses="h-11rem w-40 px-1">
      <button (click)="downloadDocument(companionTypes.Group_Report_Guidance)" class="w-full pb-4">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-download"></i>
        <p class="text-[15px]" title="Group Report Guidance"><span i18n>Group Report Guidance</span></p>
        <p class="text-drdpblue-300 text-[15px]"><span i18n>Download</span></p>
      </button>
    </drdp-container>

    <drdp-container color="whiteDash" additionClasses="h-11rem w-40 px-1">
      <button (click)="downloadDocument(companionTypes.Domain_Scale_Cut_Points_for_Sharing)" class="w-full pb-4">
        <i class="text-drdpblue-300 text-[60px] uil uil-file-download"></i>
        <p class="text-[15px]" title="Domain Scale Cut Points for Sharing"><span i18n>Domain Scale Cut Points for Sharing</span></p>
        <p class="text-drdpblue-300 text-[15px]"><span i18n>Download</span></p>
      </button>
    </drdp-container>
  </div>
</div>


