import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ISites } from '@core/interfaces/isites';
import { AgencyService } from '@core/services/agency.service';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { ClassroomService } from '@core/services/classroom.service';
import { LookupService } from '@core/services/lookup.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { SiteService } from '@core/services/site.service';
import { StudentService } from '@core/services/student.service';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { ToastService } from '@core/services/toast.service';
import { SelectType } from '@core/enums/select';
import { Observable, Subscription } from 'rxjs';
import { IClass } from '@core/interfaces/iclasses';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';

@Component({
  selector: 'drdp-assign-child',
  templateUrl: './assign-child.component.html',
  styleUrls: [
    './assign-child.component.scss',
    './../add-new-child.component.scss',
    './../../../../shared/components/drdp-tables/add-edit/add-edit.component.scss',
  ],
})
export class AssignChildComponent implements OnInit {
  @Input() enrollDroppedChild: boolean = false;
  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  get siteId() {
    return this.assignStudentForm.get('siteId');
  }
  get agencyId() {
    return this.assignStudentForm.get('agencyId');
  }
  get ratingPeriodId() {
    return this.assignStudentForm.get('ratingPeriodId');
  }
  get classId() {
    return this.assignStudentForm.get('classId');
  }
  get classEnrollmentDate() {
    return this.assignStudentForm.get('classEnrollmentDate');
  }

  classroomPlaceholder: string = 'Set site for classroom options';
  rpPlaceholder: string = 'Set agency for rating periods';
  sitePlaceholder: string = 'Set agency for sites';
  currentYear: number = 0;
  assignStudentForm: FormGroup | any;
  sites: ISites[] = [];
  ratingPeriods: IRatingPeriod[] = [];
  classrooms: IClass[] = [];
  optionsLoaded: boolean = false;
  selectedRpMinDate!: Date;
  selectedRpMaxDate!: Date;
  isSubmitting: boolean = false;
  private subscriptions = new Subscription();

  user: ILoginResponse = this.auth.getCurrentUser();
  stateSearchId? = this.user.stateId;
  userAgencyId? = this.user.agencyId;

  assignmentDropdowns$!: Observable<any>;
  initialAgencyId!: number;
  canViewMultipleAgencies!: boolean;
  selectClass = SelectType.Class;

  addStudentPayload: any | undefined = null;

  isDropdownRequired = {
    ratingPeriod: true,
    site: true,
    classroom: true,
  };

  constructor(
    public fb: FormBuilder,
    public lookup: LookupService,
    public agency: AgencyService,
    public siteService: SiteService,
    public auth: AuthService,
    public ratingPeriod: RatingPeriodService,
    public classroomService: ClassroomService,
    public classService: ClassService,
    public student: StudentService,
    public studentClassEnrollment: StudentClassEnrollmentService,
    public toastService: ToastService,
    public dialogRef: MatDialogRef<AssignChildComponent>,
    @Inject(MAT_DIALOG_DATA) public addChildFormData: any
  ) {}

  ngOnInit(): void {
    this.addStudentPayload = this.addChildFormData;
    this.initializeForm();
    this.canViewMultipleAgencies =
      this.auth.isSuperAdmin() || this.auth.isCustomerService();
  }

  initializeForm(): void {
    this.assignStudentForm = this.fb.group({
      agencyId: [null, [Validators.required]],
      siteId: [null, Validators.required],
      ratingPeriodId: [null, Validators.required],
      classId: [null, Validators.required],
      classEnrollmentDate: [null, [Validators.required]],
    });
  }

  handleDropdownSelection(event: any): void {
    this.agencyId.setValue(event?.agencyId);
    this.siteId.setValue(event?.siteId);
    this.classId.setValue(event?.classroomId);
    this.ratingPeriodId.setValue(event?.ratingPeriodId);
    this.selectedRpMinDate = event?.ratingPeriodMinDate;
    this.selectedRpMaxDate = event?.ratingPeriodMaxDate;
    this.validateDate();
  }

  validateDate() {
    const classEnrollmentDateValue = this.classEnrollmentDate.value;
    if (classEnrollmentDateValue) {
      const classroomEnrollmentDate = new Date(classEnrollmentDateValue).getTime();
      const agencyEnrollmentDate = new Date(this.addStudentPayload.enrollmentStartDate).getTime();

      if (classroomEnrollmentDate < agencyEnrollmentDate) {
        this.classEnrollmentDate.setErrors({agencyDate: true });
      } else if (this.ratingPeriodId.value) {
        const enrollmentDate = new Date(classEnrollmentDateValue);
        enrollmentDate.setHours(0, 0, 0, 0);
        const classEnrollmentDate = enrollmentDate.getTime();
        if ( classEnrollmentDate < this.selectedRpMinDate.getTime() || classEnrollmentDate > this.selectedRpMaxDate.getTime()) {
          this.classEnrollmentDate.setErrors({ dateRange: true });
        } else {
          this.classEnrollmentDate.setErrors(null);
        }
      } else {
        this.classEnrollmentDate.setErrors(null);
      }
    }
  }

  onSubmit(): void {
    if (this.isSubmitting) return;
    if (!this.assignStudentForm.valid) {
      this.toastService.error($localize `Please complete the form before submitting.`);
      return;
    }
    this.isSubmitting = true;
    if (this.addStudentPayload.enrollDroppedChild) {
      const payload = {
        drdpId: this.addStudentPayload.drdpId,
        classId: this.classId.value,
        ratingPeriodId: this.ratingPeriodId.value,
        classEnrollmentDate: this.classEnrollmentDate.value,
        agencyId: this.agencyId.value,
        agencyStartDate: this.addStudentPayload.enrollmentStartDate
      };

      this.subscriptions.add(
        this.studentClassEnrollment
          .enrollDroppedChild(payload)
          .subscribe((success: boolean) => {
            if (success) this.dialogRef.close(true);
            else this.isSubmitting = false;
          })
      );
    } else {
      this.addStudentPayload.studentClassEnrollments = [
        this.assignStudentForm.value,
      ];

      this.subscriptions.add(
        this.student
          .addAndEnrollStudent(this.addStudentPayload)
          .subscribe((res: any) => {
            if (res > 0) {
              this.dialogRef.close(res);
            } else {
              this.isSubmitting = false;
            }
          })
      );
    }
  }

  handleDate(event: any): void {
    this.classEnrollmentDate.setValue(event);
    this.validateDate();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
