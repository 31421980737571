import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatDatesToString, isDateBetween } from '@core/services/helper.service';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-assessment-date-modal',
  templateUrl: './assessment-date-modal.component.html',
  styleUrls: ['./assessment-date-modal.component.scss'],
})
export class AssessmentDateModalComponent implements OnInit {
  constructor(
    private toast: ToastService,
    public dialogRef: MatDialogRef<AssessmentDateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  selectedDate: any = null;
  startDate: Date = new Date(this.dialogData.minDate);
  endDate: Date = new Date(this.dialogData.maxDate);
  today: Date = new Date();
  assessmentDateInfo = $localize `The day the child's assessments were saved. Could also be the same day as Lock Date.`;

  ngOnInit(): void {
    this.selectedDate = this.dialogData.assessmentDate
      ? new Date(this.dialogData.assessmentDate)
      : new Date();
  }

  onSubmit() {
    if (!this.selectedDate) {
      this.toast.error($localize `Assessment Date is required to lock.`);
      return;
    }

    const checkDate = new Date(this.selectedDate);

    if (!isDateBetween(this.startDate, this.endDate, checkDate)) {
      this.toast.error(
        $localize `The selected assessment date does not match the correct rating term.`
      );
      return;
    }

    this.dialogRef.close({
      assessmentDate: formatDatesToString(this.selectedDate),
    });
  }

  handleDate(event: any): void {
    this.selectedDate = formatDatesToString(event);
  }
}
