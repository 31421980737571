<div [formGroup]="dateForm">
    <mat-form-field id="dob" class="basis-1/2" [ngClass]="{'removeLine': isReadOnly, ' border-b border-black' : !isReadOnly}" appearance="fill">
      <label class="global-font-bold pb-2" [class]="labelClasses" [ngClass]="{'required': isRequired}" [for]="label">{{ label }}</label>
      <input matInput autocomplete="off" [name]="label" [matDatepicker]="datePicker" formControlName="date" [min]="minDate" [max]="maxDate" aria-label="date picker"
        (dateChange)="handleDate($event.value)" [readonly]="isReadOnly" [required]="isRequired" maxlength="10" [placeholder]="placeholder">
        <mat-error i18n *ngIf="date.hasError('required')">Field required.</mat-error>
        <mat-error i18n *ngIf="date.hasError('invalidDates')">Date cannot be before minimum date provided.</mat-error>
      <mat-datepicker-toggle matSuffix [for]="datePicker" [disabled]="isReadOnly"></mat-datepicker-toggle>
      <mat-datepicker #datePicker></mat-datepicker>
    </mat-form-field>
  </div>
