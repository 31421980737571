<div id="selectRating" class="rounded-lg  modal-body">
  <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle ml-auto"></i>
  <div class="grid border-b" [ngClass]="{'pb-5': dialogData.isPilotEnrollment, 'grid-rows-3 gap-2': !dialogData.isPilotEnrollment}" *ngIf="ratingSheet.measureRatingLevel">
    <p class="col-span-2 text-lg font-semibold">{{ratingSheet.measureRatingLevel.code}}:
      {{ratingSheet.measureRatingLevel.name}}</p>
    <p>
      {{ratingSheet.measureRatingLevel.description}}
    </p>
    <div class="col-span-1 flex justify-end">
      <drdp-btn color="cancel" class="mr-2" mat-button mat-dialog-close i18n>Cancel</drdp-btn>
      <drdp-btn color="save" class="mr-2" (click)="saveRating()" i18n>Save</drdp-btn>
    </div>
  </div>

  <div mat-dialog-content>
    <drdp-portfolio-evidence-management #evidenceManagement [measureId]="dialogData.id" [evidences]="measureEvidence"
      [ageGradeId]="dialogData.ageGradeInstrumentId" [ageGradeInstrumentCode]="dialogData.ageGradeInstrumentCode"
      [isDomain]="false" [isPortfolio]="false" [ratingSheet]="ratingSheet" [dialogData]="dialogData"
      (rating)="onSubmit($event)" [hasStructuredPrompt]="dialogData.isStructuredPrompt" [isPilot]="dialogData.isPilotEnrollment">
    </drdp-portfolio-evidence-management>
  </div>
</div>
