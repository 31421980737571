<drdp-container>
    <div class="pl-1 w-1/5">
        <h1 i18n class="text-xl font-bold border-b">Past Term Dates</h1>
    </div>
    <div *ngIf="processedPerList.length > 0">
        <div class="border-b" *ngFor="let year of processedPerList; let i = index">
            <drdp-add-edit-table [dataSource]="getTableDataSource(i)"
            [tableData]="getTableData(i)"
            [columns]="formTableColumns">
            </drdp-add-edit-table>
        </div>
    </div>
</drdp-container>

