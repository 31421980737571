<div class="p-5">
  <div>
    <drdp-title title="District OSEP Report" i18n-title></drdp-title>
    <div>
      <p i18n>
        OSEP Federally Mandated Year-End Report. Preschool Education Outcomes. Please select the required fields below
        to view or download.
      </p>
    </div>
  </div>

  <drdp-container additionClasses="w-full mt-4">
    <drdp-container color="lightBlue">
      <form [formGroup]="searchForm">
        <div class="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-3 flex-row content-center mb-4">
          <div class="col-span-1 self-end">
            <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleState($event)"
              [required]="true" [clear]="clearDropdown">
            </drdp-select-state>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-agency #agencySelect (agency)="handleAgency($event)" [stateId]="stateId.value"
              [initialAgencyId]="agencyId.value" [required]="true" [clear]="clearDropdown">
            </drdp-select-agency>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-school-year #schoolYearSelect (schoolYear)="handleSchoolYear($event)" [clear]="clearDropdown"
              [required]="true">
            </drdp-select-school-year>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-site (site)="handleSite($event)" [agencyId]="agencyId.value" [multiSelect]="true"
              [multiSelectInitVals]="false" [required]="true" [clear]="clearDropdown">
            </drdp-select-site>
          </div>
        </div>
        <div class="flex flex-row gap-1.5 justify-end lg:col-span-2 xl:col-span-1 md:self-end xl:my-auto mt-4 md:mt-0">
          <div class="col-span-1">
            <drdp-btn [disabled]="!searchForm.valid" type="submit">
              <span i18n class="px-2" (click)="showReport()">Show Report</span>
            </drdp-btn>
          </div>
          <div class="col-span-1">
            <drdp-btn i18n type="button" [disabled]="!searchForm.valid" (click)="downloadReport()">Download Report</drdp-btn>
          </div>
          <div class="col-span-1">
            <drdp-btn i18n color="outline" (click)="handleClear()">Clear</drdp-btn>
          </div>
        </div>
      </form>
    </drdp-container>
  </drdp-container>

  <div *ngIf="!!reportData" [innerHtml]="reportData! | safeHtml"></div>

  <!-- End of report -->
</div> <!-- End of entire component -->
