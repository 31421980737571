<div class="p-5">
  <div class="flex justify-between">
    <drdp-title title="Agencies" i18n-title></drdp-title>
    <span>
      <drdp-btn *drdpCheckPermission="Permission.CreateAgencies" (click)="openModal(undefined, ModalType.Agency)">
        <i class="uil uil-plus">
        </i><span i18n>Add New Agency</span>
      </drdp-btn>
    </span>
  </div>
  <drdp-container [additionClasses]="'min-h-[44rem]'">
    <drdp-container *ngIf="hasAccessToAll" color="lightBlue">
      <form [formGroup]="searchForm" (ngSubmit)="search()">
        <div class="p-3 flex grid md:grid-cols-5 gap-2 flex-row content-between">
          <ng-container class="contents">
            <div class="col-span-1">
              <label class="font-bold">Search by:</label>
              <div class="border-b border-black">
                <input class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300"
                  matInput
                  placeholder="Agency Name"
                  searchInput="searchInput"
                  formControlName="agencyName"/>
              </div>
            </div>
            <div class="col-span-1">
              <drdp-select-state
              #stateSelect
              [initialStateId]="stateId?.value"
              (state)="handleState($event)">
              </drdp-select-state>
            </div>
            <div class="col-span-1">
              <drdp-select-agency
                #agencySelect
                (agency)="handleAgency($event)"
                [stateId]="stateId?.value"
                [initialAgencyId]="agencyId?.value">
              </drdp-select-agency>
            </div>
            <div class="col-span-1">
              <drdp-select-type
                #typeSelect
                (type)="handleType($event)"
                [initialTypeId]="typeId?.value">
              </drdp-select-type>
            </div>
            <div class="col-span-1 md:col-start-5 self-end justify-center md:justify-self-end">
              <drdp-btn i18n type="submit" class="mr-2">Search</drdp-btn>
              <drdp-btn i18n type="button" (click)="onClear()" color="outline">Clear</drdp-btn>
            </div>
          </ng-container>
        </div>
      </form>
    </drdp-container>
    <drdp-add-edit-table
      [dataSource]="dataSource"
      [tableData]="tableData"
      [columns]="tableColumns"
      [isSuperAdmin]="hasAccessToAll"
      (editActionItem)="openModal($event)">
    </drdp-add-edit-table>
  </drdp-container>
  <mat-paginator
    *ngIf="hasAccessToAll"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="totalData"
    [pageIndex]="pageIndex"
    (page)="paginateAgency(false)">
  </mat-paginator>
</div>