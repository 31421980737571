import { Injectable } from '@angular/core';
import { IAgency } from '@core/interfaces/iagency';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { getSearchUrl } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  constructor(private dataService: DataService) { }

  getAgency(id?: number): Observable<IAgency> {
    return this.dataService.get(`agency/${id}`);
  }

  getAgenciesByPage(payload?: any, message?: string): Observable<any> {
    const url = getSearchUrl('agency/search?', payload);
    return this.dataService.get(url, message);
  }

  getCurrentAgenciesByUser(): Observable<IAgency[]> {
    const user = JSON.parse(localStorage.getItem('drdp-user') ?? '{}');
    return this.dataService.get(`agency/user/${user.userId}`);
  }

  getCurrentAgenciesByUserId(userId: number): Observable<IAgency[]> {
    return this.dataService.get(`agency/user/${userId}`);
  }

  getLinkedAgencies(userId: number): Observable<any> {
    return this.dataService.get(`agency/linked-agencies/${userId}`);
  }

  addNewAgency(payload: IAgency, message?: string): Observable<boolean> {
    return this.dataService.post('agency', payload, message);
  }

  updateAgency(payload: IAgency, message?: string): Observable<boolean> {
    return this.dataService.put('agency', payload, message);
  }

  getAllAgencies(): Observable<IAgency[]> {
    return this.dataService.get(`agency/all`);
  }

  getAgenciesByState(stateId: number, isPilot: boolean = false): Observable<IAgency[]> {
    let url = `agency/state/${stateId}`;
    if (isPilot != undefined) url += `?isPilot=${isPilot}`;
    return this.dataService.get(url);
  }

  getAgenciesByUser(userId: number, message?: string): Observable<IAgency[]> {
    return this.dataService.get(`agency/user/${userId}`);
  }

  getGranteeAgencies(): Observable<IAgency[]> {
    return this.dataService.get(`agency/grantee/all`);
  }

  getGranteeAgenciesByState(stateId: number): Observable<IAgency[]> {
    return this.dataService.get(`agency/${stateId}/grantees`);
  }

  unlockAgency(id: number): Observable<boolean> {
    return this.dataService.put(
      `agency/unlock/${id}`,
      null,
      $localize `Agency Rating View Unlocked.`
    );
  }

  getContractorAgenciesByState(stateId: number): Observable<IAgency[]> {
    return this.dataService.get(`agency/${stateId}/contractors`);
  }
}
