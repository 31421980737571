import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { SelectType } from '@core/enums/select';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { ISelectable } from "@core/interfaces/iselectable";
import { isArray } from "lodash";
import { GlobalRatings } from '@core/enums/global-ratings';

@Component({
  selector: 'drdp-select-rating-period',
  templateUrl: './select-rating-period.component.html',
  styleUrls: ['./select-rating-period.component.scss'],
})
export class SelectRatingPeriodComponent implements OnInit {
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @Output() ratingPeriod = new EventEmitter<any>();
  @Input() initialRatingPeriodId?: number | null;
  @Input() schoolYear?: number | null;
  @Input() agencyId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = $localize `Rating Period`;
  @Input() displayFutureRps: boolean = false;
  @Input() displayCurrentRps: boolean = false;
  @Input() displayCurrentRpsByStartEnd: boolean = false;
  @Input() omitFutureRps: boolean = false;
  @Input() showPilotRps: boolean = false;
  @Input() default2025: boolean = false;
  userAgencyId?: number;
  ratingPeriodOptions?: IRatingPeriod[] = [];

  public get select() {
    return SelectType;
  }
  constructor(private ratingPeriodService: RatingPeriodService) {}

  ngOnInit(): void {
    if (this.agencyId) this.getRatingPeriod(this.agencyId);
  }

  clearSelected() {
    if (!this.disabled) this.ratingPeriodSelect?.clearSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.ratingPeriodSelect?.clearSearch(true);
    }

    if (changes['agencyId'] || changes['schoolYear'] || changes['showPilotRps']) {
      if (this.agencyId) {
        this.getRatingPeriod(this.agencyId, this.schoolYear);
      }
      else if (this.schoolYear) {
        this.getRatingPeriodsBySchoolYear(this.schoolYear);
      }
      else this.ratingPeriodOptions = [];
    }
  }

  getRatingPeriodsBySchoolYear(schoolYear:any) {
    this.ratingPeriodService
        .getBySchoolYear(schoolYear)
        .pipe(take(1))
        .subscribe((ratingPeriod: IRatingPeriod[]) => {
          this.ratingPeriodOptions = ratingPeriod;
        });
  }

  getRatingPeriod(agencyId: number, schoolYear?: number | null): void {
    if (schoolYear) {
      this.ratingPeriodService
        .getBySchoolYearAndAgency(schoolYear, agencyId)
        .pipe(take(1))
        .subscribe((ratingPeriod: IRatingPeriod[]) => {
          this.ratingPeriodOptions = this.processRatingPeriods(ratingPeriod);
        });
    } else {
      this.ratingPeriodService
        .getByAgency(agencyId)
        .pipe(take(1))
        .subscribe((ratingPeriod: IRatingPeriod[]) => {
          this.ratingPeriodOptions = this.processRatingPeriods(ratingPeriod);
      });
    }
  }

  processRatingPeriods(ratingPeriods: IRatingPeriod[]): IRatingPeriod[] {
    if (this.displayFutureRps) {
      const futureRps = ratingPeriods.filter(option => this.isCurrentDateBelowMinStartDate(option.startDate));
      const initialRatingPeriod = futureRps.find(rp => rp.id === this.initialRatingPeriodId);
      if (initialRatingPeriod) return futureRps;
    }
    if (this.displayCurrentRps)
      ratingPeriods = ratingPeriods.filter(option => this.isCurrentRatingPeriod(option.minDate, option.maxDate));

    if (this.displayCurrentRpsByStartEnd)
      ratingPeriods = ratingPeriods.filter(option => this.isCurrentRatingPeriod(option.startDate, option.endDate));

    if (this.omitFutureRps) {
      ratingPeriods = ratingPeriods.filter(option => !this.isCurrentDateBelowMinStartDate(option.minDate.toString()));
      const initialRatingPeriod = ratingPeriods.find(rp => rp.id === this.initialRatingPeriodId);
      if (initialRatingPeriod) return ratingPeriods;
    }
    
    if (this.showPilotRps) {
      ratingPeriods = ratingPeriods.filter(option => option.globalRatingPeriod?.isPilotEnabled);
      if (this.default2025) {
        this.ratingPeriod.emit(ratingPeriods.find(rp => rp.globalRatingPeriodId == GlobalRatings.Fall2024));
      }
    }
    return ratingPeriods.filter((rp: IRatingPeriod) => rp.isActive);
  }

  isCurrentDateBelowMinStartDate (minStartDate: string): boolean {
    const currentDate = new Date();
    const dateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    return dateWithoutTime < new Date(minStartDate);
  };

  isCurrentRatingPeriod(minDate: Date | string, maxDate: Date | string): boolean {
    const current = new Date();
    const currentWithoutTime = new Date(current.getFullYear(), current.getMonth(), current.getDate());
    return currentWithoutTime >= new Date(minDate) && currentWithoutTime <= new Date(maxDate);
  }

  onRatingPeriodSelect(event: ISelectable | ISelectable[] | null): void {
    if (!!event && isArray(event)) {
      this.ratingPeriod.emit(this.ratingPeriodOptions?.filter(rp => event?.find(sc => sc.id === rp.id)));
    } else if (!!event) {
      const matchingOptions = this.ratingPeriodOptions?.filter(rp => event.id === rp.id) ?? [];
      this.ratingPeriod.emit(matchingOptions?.length > 0 ? matchingOptions[0] : null);
    } else {
      this.ratingPeriod.emit(null);
    }
  }
}
