import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import {
  IClassStudent,
  IViewClassStudentsRequest,
} from '@core/interfaces/iclasses';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { ToastService } from '@core/services/toast.service';
import { Subscription } from 'rxjs';
import { setIconDisplayObject } from '@core/services/helper.service';
import { ITableColumn } from '@core/interfaces/itable';
import { StudentService } from '@core/services/student.service';
import { take } from 'rxjs';
import { DropChildComponent } from '../../../drop-child/drop-child.component';
import { TmplAstRecursiveVisitor } from '@angular/compiler';

const tableIconAction = {
  transferChild: 'transferChild',
  dropChild: 'dropChild',
  viewChild: 'viewChild',
};

@Component({
  selector: 'drdp-students-bulk-un-enrollment',
  templateUrl: './students-bulk-un-enrollment.component.html',
  styleUrls: ['./students-bulk-un-enrollment.component.scss'],
})
export class StudentBulkUnEnrollmentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private subscriptions = new Subscription();
  classroomName?: string;
  ratingPeriodName?: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData!: any[];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  totalData: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  payload!: IViewClassStudentsRequest;
  classId!: number;
  isAdmin: boolean = false;
  user = this.auth.getCurrentUser();
  agencyId: number = 0;
  siteId: number = 0;
  ratingPeriodId: number = 0;
  selectedStudent: any[] = [];

  tableColumns: ITableColumn[] = [
    {
      columnDef: 'isSelected',
      header: $localize `Select`,
      type: 'checkBox',
      checkClass: 'checkbox',
    },
    {
      columnDef: 'firstName',
      header: $localize `First Name`,
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'lastName',
      header: $localize `Last Name`,
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'enrollmentStartDate',
      header: $localize `Agency Enrollment`,
      type: 'date',
      isSortable: true,
    },
    {
      columnDef: 'enrollmentEndDate',
      header: $localize `Agency Withdrawal`,
      type: 'date',
      isSortable: true,
    },
  ];

  constructor(
    private classService: ClassService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public modal: MatDialog,
    private student: StudentService,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.auth.isSuperAdmin();
    this.getClassIdFromUrl();
    this.setClassroomAndRatingPeriodName();
    this.getClassStudents();
  }

  setClassroomAndRatingPeriodName(): void {
    this.classService
      .getStudentsByClass(this.classId)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          this.classroomName = res.classroom.name;
          this.ratingPeriodName = res.ratingPeriod.ratingPeriodName;
          this.ratingPeriodId = res.ratingPeriod.id;
          this.agencyId = res.classroom.agencyId;
          this.siteId = res.siteId;
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  getClassStudents(): void {
    this.payload = {
      classId: this.classId,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize,
      onlyActive: true,
    };

    this.classService
      .getClassStudents(this.payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          res.items = res.items.map((data: any) => ({
            ...data,
            displayIcons: setIconDisplayObject(this.tableColumns, data),
            isSelected: false,
          }));
          this.dataSource = new MatTableDataSource(res.items);
          this.tableData = res.items;
          this.totalData = res.totalData;
        }
      });
  }

  getClassIdFromUrl(): void {
    this.classId = Number(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  paginate(): void {
    this.pageSize = this.paginator.pageSize;
    this.pageIndex = this.paginator.pageIndex;

    this.getClassStudents();
  }

  handleSelectedChild(event: any): void {
    const { index, property } = event;
    const data = this.tableData[index];

    data.isSelected = !data.isSelected;
  }

  selectAll() {
    this.tableData.forEach((item) => {
      item.isSelected = true;
    });
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSave() {
    var selectedStudents = this.tableData.filter(
      (student) => student.isSelected
    );

    if (selectedStudents.length < 1) {
      this.toast.error($localize `No selected student/s.`);
      return;
    }

    selectedStudents = selectedStudents.map((data: any) => ({
      ...data,
      classId: this.classId,
      siteId: this.siteId,
      agencyId: this.agencyId,
      ratingPeriodId: this.ratingPeriodId,
    }));

    const data = {
      students: selectedStudents,
      isBatch: true,
    };

    const modalRef = this.modal.open(DropChildComponent, {
      data: data ? data : undefined,
    });
    modalRef.afterClosed().subscribe((success) => {
      if (success) {
        this.getClassStudents();
      }
    });
  }

  onCancel() {
    this.router.navigate([`/view-students/${this.classId}`]);
  }
}
