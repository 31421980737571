<div
  class="lg:flex place-content-between place-items-center border-b border-drdpgrey-100 self-center m-[1.5%] pb-[2.4%] hidden">
  <div class="max-w-fit">
    <drdp-header-dropmenu
      [vm]="userDropMenu"
      [sites]="dropMenuItems"
    ></drdp-header-dropmenu>
  </div>
  <div class="flex">
    <button [matMenuTriggerFor]="languageMenu" class="px-2">
      <mat-icon class="text-drdpblue-300 text-xl">language</mat-icon>{{currentLocale.label}}
    </button>
    <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
      <button *ngFor="let locale of availableLocales" mat-menu-item (click)="languageSelected(locale)">{{locale.label}}</button>
    </mat-menu>
    <div class="px-2" *ngFor="let links of headerBtns">
      <drdp-menu-link
        [label]="links.label"
        [icon]="links.icon"
        [link]="links.routeLink"
        [blue]="true"
      ></drdp-menu-link>
    </div>
    <button class="px-2" (click)="logout()">
      <i class="uil uil-signout text-drdpblue-300 text-xl"></i>
      <span i18n>Logout</span>
    </button>
  </div>
</div>

<div class="lg:hidden">
  <drdp-side-menu></drdp-side-menu>
</div>
