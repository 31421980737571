import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataType, FieldType } from '@core/enums/data-type';
import { IDemographicInformation } from '@core/interfaces/idemographic-information';
import { LookupService } from '@core/services/lookup.service';
import { Observable } from 'rxjs';
import { StateConfigurationComponent } from '../state-configuration.component';

@Component({
  selector: 'drdp-custom-configuration',
  templateUrl: './custom-configuration.component.html',
  styleUrls: ['./custom-configuration.component.scss'],
})
export class CustomConfigurationComponent implements OnInit {
  customConfigForm: FormGroup | any;
  title?: string;
  sectionOptions$?: Observable<IDemographicInformation[]>;
  fieldTypeOptions$?: Observable<any>;
  isOptionRequired = false;
  isMaxRequired = false;

  get columnName() {
    return this.customConfigForm.get('columnName');
  }
  get dataType() {
    return this.customConfigForm.get('dataType');
  }
  get customFieldTypeId() {
    return this.customConfigForm.get('customFieldTypeId');
  }
  get isActive() {
    return this.customConfigForm.get('isActive');
  }
  get isRequired() {
    return this.customConfigForm.get('isRequired');
  }
  get iscustom() {
    return this.customConfigForm.get('iscustom');
  }
  get label() {
    return this.customConfigForm.get('label');
  }
  get options() {
    return this.customConfigForm.get('options');
  }
  get sectionID() {
    return this.customConfigForm.get('sectionID');
  }
  get stateID() {
    return this.customConfigForm.get('stateID');
  }
  get orderBy() {
    return this.customConfigForm.get('orderBy');
  }
  get maxLength() {
    return this.customConfigForm.get('maxLength');
  }

  public get dataTypeConst() {
    return DataType;
  }
  public get fieldTypeConst() {
    return FieldType;
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<StateConfigurationComponent>,
    private lookupService: LookupService,
    @Inject(MAT_DIALOG_DATA) public modal: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.initializeForms();
    this.title = this.modal.data
      ? $localize `Edit Custom Configuration`
      : $localize `Add Custom Configuration`;
    this.sectionOptions$ = this.lookupService.getDemographicInformation();
    this.fieldTypeOptions$ = this.lookupService.getFieldTypes();
  }

  initializeForms(): void {
    this.customConfigForm = this.fb.group({
      id: [0],
      customFieldTypeId: [null, Validators.required],
      isActive: [true, Validators.required],
      isRequired: [false, Validators.required],
      isCustom: [true],
      label: [null, [Validators.required]],
      options: [null, [Validators.maxLength(50), this.customOptionValidator()]],
      sectionId: [null, Validators.required],
      stateId: [this.modal.data ? this.modal.data.stateId : this.modal],
      orderBy: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      maxLength: [
        null,
        [
          Validators.pattern('^[0-9]*$'),
          Validators.max(1000),
          Validators.maxLength(4),
        ],
      ],
    });

    if (this.modal.data) {
      let {
        id,
        customFieldTypeId,
        isActive,
        isRequired,
        isCustom,
        label,
        options,
        sectionId,
        orderBy,
        maxLength,
      } = this.modal.data;

      this.customConfigForm.patchValue({
        id,
        customFieldTypeId,
        isActive,
        isRequired,
        isCustom,
        label,
        options,
        sectionId,
        orderBy,
        maxLength,
      });
    }
  }

  onfieldTypeChange(event: any): void {
    if (
      event.value == FieldType.dropdownSingle ||
      event.value == FieldType.dropdownMultiple
    ) {
      this.options.setValidators([
        Validators.required,
        Validators.maxLength(50),
        this.customOptionValidator(),
      ]);
      this.isOptionRequired = true;
    } else {
      this.options.clearValidators();
      this.isOptionRequired = false;
    }
    this.options.updateValueAndValidity();

    if (event.value == FieldType.text) {
      this.maxLength.setValidators([
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.max(1000),
        Validators.maxLength(4),
      ]);
      this.isMaxRequired = true;
    } else {
      this.maxLength.setValidators([Validators.pattern('^[0-9]*$')]);
      this.isMaxRequired = false;
    }
    this.maxLength.updateValueAndValidity();
  }

  customOptionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;

      const splitOptions = value.split('|');
      const hasTwoOrMoreOptions = splitOptions.length >= 2;
      let isOptionsValid = true;

      for (let option of splitOptions) {
        if (option.trim().length > 0) {
          isOptionsValid = true;
        } else {
          isOptionsValid = false;
        }
      }

      const customOptionsValid = hasTwoOrMoreOptions && isOptionsValid;
      return !customOptionsValid ? { error: true } : null;
    };
  }

  onSubmit() {
    let payload = {
      StateId: this.modal.data ? this.modal.data.stateId : this.modal,
      StateConfigList: [this.customConfigForm.value],
    };

    return payload;
  }
}
