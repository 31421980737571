import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { ToastService } from '@core/services/toast.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditRatingPeriodComponent } from './add-edit-rating-period/add-edit-rating-period.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IModalEmitData } from '@core/interfaces/imodal';
import { AuthService } from '@core/services/auth.service';
import { Subscription, take, tap } from 'rxjs';
import { ISearchInput } from '@core/interfaces/isearch-input';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-rating-periods',
  templateUrl: './rating-periods.component.html',
  styleUrls: ['./rating-periods.component.scss'],
})
export class RatingPeriodsComponent implements OnInit, OnDestroy {
  constructor(
    private _ratingPeriods: RatingPeriodService,
    public toast: ToastService,
    public modal: MatDialog,
    private authService: AuthService,
    private permissionService: PermissionService
  ) {}
  private subscriptions = new Subscription();
  dataLength: number = 0;
  ratingPeriod: string = '';
  schoolYear: number = 0;
  currentSchoolYearId: number = 0;

  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizeOptions = [5, 10, 25];
  pageIndex = 1;
  pageSize = 10;
  totalData = 0;
  tableData?: any;
  searchTerm?: string;
  searchSchoolYearId?: number;
  searchAgencyId?: number;
  searchStateId?: number;
  canEditRatingPeriod?: boolean = true;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isAdmin = true;
  user = this.authService.getCurrentUser();
  canEdit = this.permissionService.checkPermission(
    Permission.EditRatingPeriods
  );
  isToggleActive = this.permissionService.checkPermission(Permission.DeactivateRatingPeriod);

  hasFullSearchOptions: boolean =
    this.authService.isSuperAdmin() || this.authService.isCustomerService();

  tableColumns = [
    { columnDef: 'ratingPeriodName', header: $localize `Name`, type: 'text' },
    {
      columnDef: 'schoolYear.schoolYearName',
      header: $localize `School Year`,
      type: 'text',
    },
    { columnDef: 'startDate', header: $localize `Start Date`, type: 'date' },
    { columnDef: 'endDate', header: $localize `End Date`, type: 'date' },
    {
      columnDef: 'globalRatingPeriod.minStartDate',
      header: $localize `Minimum Date`,
      type: 'date',
    },
    {
      columnDef: 'globalRatingPeriod.maxEndDate',
      header: $localize `Maximum Date`,
      type: 'date',
    },
    {
      columnDef: 'isActive',
      header: $localize `Status`,
      type: 'toggleSwitch',
      readOnly: !this.isToggleActive
    },
  ];

  searchInput: ISearchInput = {
    searchName: true,
    searchAgency: this.hasFullSearchOptions,
    searchState: this.hasFullSearchOptions,
    searchYear: true,
  };

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  ngOnInit(): void {
    if (this.canEdit) {
      this.tableColumns.push({
        columnDef: 'schoolYear.isCurrent',
        header: $localize `Edit`,
        type: 'select',
      });
    }
    this.getCurrentSchoolYear();
  }

  openModal(editData?: IModalEmitData): void {
    if (editData) {
      const { data } = editData;
      if (!data.isActive) {
        this.toast.error($localize `Unable to edit Rating Period while inactive.`);
        return;
      }
    }

    const modalRef = this.modal.open(AddEditRatingPeriodComponent, {
      data: editData ? editData : undefined,
    });

    // Data passed from Modal
    this.subscriptions.add(
      modalRef.afterClosed().subscribe((result: any) => {
        // Add
        if (!editData && result.formValid) {
          this.saveNewRatingPeriod(result);
        }

        // Update
        if (editData && result.formValid) {
          this.updateRatingPeriod(result);
        }
      })
    );
  }

  getCurrentSchoolYear() {
    this.subscriptions.add(
      this._ratingPeriods.getActiveSchoolYear().subscribe((res) => {
        this.currentSchoolYearId = res.id;
        this.getRatingPeriods(true);
      })
    );
  }

  saveNewRatingPeriod(period: any): void {
    this.subscriptions.add(
      this._ratingPeriods
        .addRatingPeriod(period.payload, $localize `Added Rating Period`)
        .subscribe((res: boolean) => {
          if (res) {
            this.totalData++;
            this.getRatingPeriods(true);
          }
        })
    );
  }

  updateRatingPeriod(period: any): void {
    this.subscriptions.add(
      this._ratingPeriods
        .updateRatingPeriod(period.payload, $localize `Updated Rating Period`)
        .subscribe((res: boolean) => {
          if (res) {
            this.getRatingPeriods(true);
          }
        })
    );
  }

  toggleStatus(ratingPeriod: any): void {
    const { data } = ratingPeriod;
    data.isActive = !data.isActive;

    this._ratingPeriods.updateRatingPeriod(data).pipe(
      take(1),
      tap((res: boolean) => {
        if (res) {
          this.getRatingPeriods(true);
        }
      })
      ).subscribe();
  }

  search(searchTerm?: any): void {
    this.searchTerm = searchTerm
      ? (this.searchTerm = searchTerm.SearchName)
      : (this.searchTerm = '');
    this.searchSchoolYearId =
      searchTerm?.SchoolYearId ?? this.currentSchoolYearId;
    this.searchStateId = searchTerm?.StateId ?? this.user.stateId ?? 0;
    this.searchAgencyId = searchTerm?.AgencyId ?? this.user.agencyId ?? 0;

    this.getRatingPeriods(true);
  }

  initializeTableData(res: any): void {
    this.dataSource = new MatTableDataSource(res.rating);
    this.totalData = res.totalData;
    this.tableData = res.rating;
  }

  getRatingPeriods(resetPage?: Boolean): void {
    const payload: any = {};
    payload.agencyId = typeof this.searchAgencyId !== 'undefined' ? this.searchAgencyId : this.user.agencyId;
    payload.stateId = this.searchStateId || this.user.stateId;
    this.searchTerm ? (payload.RatingPeriod = this.searchTerm) : null;
    payload.SchoolYearID = this.searchSchoolYearId ?? this.currentSchoolYearId;
    resetPage
      ? (payload.PageIndex = 1)
      : (payload.PageIndex = this.paginator.pageIndex + 1);
    resetPage
      ? (payload.ItemsPerPage = 10)
      : (payload.ItemsPerPage = this.paginator.pageSize);

    this.subscriptions.add(
      this._ratingPeriods
        .getRatingPeriodWithParam(payload)
        .subscribe((res: any) => {
          if (res) {
            if (resetPage) this.paginator.firstPage();
            this.initializeTableData(res);
          }
        })
    );
  }

  onClear() {
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
