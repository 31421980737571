import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CheckPermissionDirective } from './directives/check-permission.directive';

@NgModule({
  declarations: [
    CheckPermissionDirective
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  exports: [
    CheckPermissionDirective
  ],
  providers: []
})
export class CoreModule { }
