<div class="flex justify-between border-b mb-6">
  <h1 i18n class="text-[25px] font-bold">Delete Roles?</h1>
  <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
</div>

<div mat-dialog-content>
  <p [innerHTML]="deleteMessage"></p>
</div>

<div mat-dialog-actions class="flex justify-end">
  <drdp-btn i18n class="mr-2" color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
  <drdp-btn i18n color="red" mat-button [mat-dialog-close]="onSubmit()" cdkFocusInitial>Delete</drdp-btn>

</div>