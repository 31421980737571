import { Component, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '@core/services/role.service';
import { IModalEmitData } from '@core/interfaces/imodal';
import { MatPaginator } from '@angular/material/paginator';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditRolesComponent } from './add-edit-roles/add-edit-roles.component';
import { MatDialog } from '@angular/material/dialog';
import { IRoles } from '@core/interfaces/iroles';
import { RoleGroup } from '@core/enums/roles';

@Component({
  selector: 'drdp-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
})
export class UserRolesComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  payload: any = {};
  tableData?: any;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  rolesInfo: IRoles[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  isAdmin: boolean = false;
  canViewRoles: boolean = true;
  canCreateRoles: boolean = true;

  tableColumns = [
    { columnDef: 'roleName', header: $localize `Name`, type: 'text', isSortable: true },
    {
      columnDef: 'roleGroupName',
      header: $localize `Category`,
      type: 'text',
      isSortable: true,
    },
    {
      columnDef: 'roleDescription',
      header: $localize `Description`,
      type: 'text',
      isSortable: true,
    },
  ];

  get roleGroup() {
    return RoleGroup;
  }
  constructor(private roleService: RoleService, public modal: MatDialog) {}
  ngOnInit(): void {
    this.paginateTable(true);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = ` `
  }

  initializeTableData(res: any): void {
    this.dataSource = new MatTableDataSource(res.roles);
    this.totalData = res.totalData;
    this.tableData = res.roles;
  }

  openModal(editData?: IModalEmitData): void {
    const modalRef = this.modal.open(AddEditRolesComponent, {
      data: editData ? editData : undefined,
    });
    modalRef.afterClosed().subscribe((result) => {
      if (!editData && result.success) {
        this.paginateTable(false);
      }
    });
  }

  paginateTable(resetPage: boolean): void {
    this.roleService
      .searchRoles(this.createSearchPayload(resetPage))
      .subscribe((res: any) => {
        if (resetPage) this.paginator.firstPage();
        this.initializeTableData(res);
        if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
      });
  }

  createSearchPayload(resetPage: boolean): any {
    if (resetPage) {
      this.payload.pageIndex = 0;
      this.payload.itemsPerPage = 10;
    } else {
      this.payload.pageIndex = this.paginator.pageIndex + 1;
      this.payload.itemsPerPage = this.paginator.pageSize;
    }
    return this.payload;
  }
}
