<div class="h-full flex flex-col justify-between">
  <drdp-filter-select
    #yesNoSelect
    [label]="label"
    [multiSelect]="multiSelect"
    [options]="selectOptions"
    [selectType]="select.YesNo"
    [required]="required"
    [disabled]="disabled"
    (onSelected)="onSelect($event)"
    [isValid]="isValid"
    overrideLabel="an option"
    i18n-overrideLabel
    [initialValues]="initialId || initialIds"
    [additionalClasses]="additionalClasses"
  >
  </drdp-filter-select>
</div>
