import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { StudentDemographic } from '@core/enums/student-demographic-form';
import { IRegionType } from '@core/interfaces/iregion-type';
import { ISelectableGroups } from '@core/interfaces/iselectable';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';
import { LookupService } from '@core/services/lookup.service';
import { StateService } from '@core/services/state.service';
import { take, tap } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-region',
  templateUrl: './select-region.component.html',
  styleUrls: ['./select-region.component.scss']
})
export class SelectRegionComponent implements OnInit, OnChanges {
  @ViewChild('regionSelect') regionSelect: FilterSelectComponent | undefined;
  @Output() region = new EventEmitter<any>();
  @Input() stateId?: number;
  @Input() initialRegionId?: any | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = $localize `Region`;
  regionTypeOptions?: ISelectableGroups;
  initPageindex = 1;
  initPageSize = 10;
  public get select() {return SelectType }
  constructor(private lookupService: LookupService, public stateService: StateService) { }

  ngOnInit(): void {
    if (this.stateId) {
      this.getRegions(this.stateId, this.initPageindex, this.initPageSize);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.stateId) {
      this.getRegions(this.stateId, this.initPageindex, this.initPageSize);
      this.getStateConfigs(this.stateId);
    }
  }

  getRegions(stateId: number, pageIndex: number, pageSize: number): void {
    this.lookupService.getRegionTypes(stateId, pageIndex, pageSize)
      .pipe(take(1))
      .subscribe((regionTypes: any) => {
        this.regionTypeOptions = regionTypes;
      });
  }

  getStateConfigs(stateId?: number) {
    if (stateId) {
      this.stateService.getStateConfigurations(stateId).pipe(
        take(1),
        tap((stateConfig: IStateConfiguration[]) => {
          if (stateConfig) {
            const config =  stateConfig.find((config: IStateConfiguration) => config.columnName == StudentDemographic.RegionLabel)
            if (config) this.label = config?.label ?? this.label;
          }
        })
      ).subscribe();
    }
  }

  onNewPage(event: any): void {
    const {pageIndex, pageSize} = event;
    this.initPageindex = pageIndex;
    this.initPageSize = pageSize;
    if (this.stateId) {
      this.getRegions(this.stateId, pageIndex, pageSize);
    }
  }

  onRegionSelect(event: IRegionType): void {
    this.region.emit(event);
  }
}
