import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, of, tap } from 'rxjs';

import { LocaleService } from '@core/services/locale.service';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const downloadOptions = {
  headers: new HttpHeaders({ 'Accept': 'text/plain', 'Content-Type': 'text/csv' }),
  responseType: 'text' as 'text',
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient,
              private toast: ToastService,
              private spinner: SpinnerService,
              private locale: LocaleService) { }

  get(path: string, message?: string): Observable<any> {
    this.spinner.show();
    httpOptions.headers = httpOptions.headers.set('Accept-Language', this.locale.getLocale().code);
    return this.http.get(path, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    )
  }

  getWithOptions(path: string, options: any, message?: string): Observable<any> {
    this.spinner.show()
    return this.http.get(path, options)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    )
  }

  downloadFile(path: string, message?: string) {
      this.spinner.show()
      return this.http.get(path, downloadOptions)
        .pipe(
          tap(() => this.handleSuccess(message)),
          catchError((err: any) => this.handleError(err)),
          finalize(()=>   this.spinner.hide())
      )
  }

  post(path: string, payload: any, message?: string): Observable<any> {
    this.spinner.show()
    httpOptions.headers = httpOptions.headers.set('Accept-Language', this.locale.getLocale().code);
    return this.http.post(path, payload, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    );
  }

  postWithoutLocale(path: string, payload: any, message?: string): Observable<any> {
    this.spinner.show()
    return this.http.post(path, payload)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    );
  }

  postWithOptions(path: string, payload: any, options: any, message?: string): Observable<any> {
    this.spinner.show()
    httpOptions.headers = httpOptions.headers.set('Accept-Language', this.locale.getLocale().code);
    return this.http.post(path, payload, options)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    );
  }

  delete(path: string, message?: string): Observable<any> {
    this.spinner.show()
    return this.http.delete(path, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    );
  }

  patch(path: string, payload: any, message?: string): Observable<any> {
    this.spinner.show()
    return this.http.patch(path, payload)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    );
  }

  put(path: string, payload: unknown, message?: string): Observable<any> {
    this.spinner.show()
    httpOptions.headers = httpOptions.headers.set('Accept-Language', this.locale.getLocale().code);
    return this.http.put(path, payload, httpOptions)
      .pipe(
        tap(() => this.handleSuccess(message)),
        catchError((err: any) => this.handleError(err)),
        finalize(()=>   this.spinner.hide())
    );
  }

  private handleSuccess(message?: string) {
    if (message) {
      this.toast.success(message)
    }
  }

  private handleError(err: HttpErrorResponse) {
    let message = $localize `Error, something went Wrong.`;
    if (err.error) {
      message = (err.error && typeof err.error === 'string') ? err.error : err.error.ErrorMessage ? err.error.ErrorMessage : err.message;
    }
    this.toast.error(message)
    return of(false);
  }
}
