<div>
    <div class="flex justify-between border-b">
        <h1 class="text-[25px] font-bold">{{modalHeader}}</h1>
        <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>

    <div mat-dialog-content>
        <div class="content">
            <p i18n *ngIf="!createPassword; else passwordForm" class="pt-5 pb-3">
              Are you sure you want to reset this user's password?
            </p>
            <ng-template #passwordForm>
                <form [formGroup]="resetPwForm">
                    <div class="flex flex-row justify-center">
                      <div>
                          <div
                            class="flex my-6 border rounded-lg border-drdpblue-300 shadow-drdpblue-100">
                            <div
                              class="rounded-l basis-1/12 flex items-center justify-center bg-drdpblue-300 text-white">
                                <i class="uil uil-lock"></i>
                            </div>
                            <input
                              class="form-control basis-11/12 appearance-none w-5/6 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              maxlength="250"
                              placeholder="Enter new password"
                              i18n-placeholder
                              [type]="inputType"
                              formControlName="newPassword"
                              matTooltip="{{ passwordRequirements.join('\n') }}"
                              [matTooltipDisabled]="resetPwForm.controls['newPassword'].valid"
                              matTooltipPosition = "left"
                              (input)="tooltip.show()"
                              #tooltip="matTooltip">
                            <div class="items-center flex">
                              <i class="mx-1 uil"
                                (click)="hidden = !hidden"
                                [ngClass]="{'uil-eye-slash': hidden, 'uil-eye': !hidden }">
                              </i>
                            </div>
                          </div>
                          <div
                            class="flex border rounded-lg border-drdpblue-300 shadow-drdpblue-100">
                            <div class="rounded-l basis-1/12 flex items-center justify-center bg-drdpblue-300 text-white">
                              <i class="uil uil-lock"></i>
                            </div>
                            <input
                              class="form-control basis-11/12 appearance-none w-5/6 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              maxlength="250"
                              placeholder="Confirm new password"
                              i18n-placeholder
                              [type]="confirmInputType"
                              formControlName="confirmPassword">
                            <div class="items-center flex">
                              <i
                                class="mx-1 uil"
                                (click)="confirmHidden = !confirmHidden"
                                [ngClass]="{'uil-eye-slash': confirmHidden, 'uil-eye': !confirmHidden }">
                              </i>
                            </div>
                          </div>
                          <span i18n *ngIf="resetPwForm.controls['newPassword'].touched && resetPwForm.controls['confirmPassword'].errors?.passwordMismatch"
                            class="text-center text-sm text-red-700">
                            Passwords do not match
                          </span>
                          <div *ngIf="resetFailed" class=" bg-[#F3DEDE] py-1 text-center">
                            <p i18n>Failed to reset.</p>
                          </div>
                      </div>
                    </div>
                  </form>
            </ng-template>
        </div>
    </div>
    <div class="spacer"></div>
    <div mat-dialog-actions class="flex">
        <drdp-btn i18n *ngIf="!createPassword" color="blue" mat-button mat-dialog-close (click)="resetConfirm()" cdkFocusInitial>
            Reset and Confirm
        </drdp-btn>
        <drdp-btn i18n class="mx-3" *ngIf="!createPassword; else changePassword" color="blue" mat-button (click)="resetCreate()" cdkFocusInitial>
            Reset and Create New
        </drdp-btn>
        <ng-template #changePassword>
            <drdp-btn i18n color="blue" mat-button mat-dialog-close (click)="updatePassword()"
                [disabled]="resetPwForm.invalid" cdkFocusInitial>
                Change Password
            </drdp-btn>
        </ng-template>
        <drdp-btn i18n color="cancel" class="ml-3" mat-button mat-dialog-close>
            Cancel
        </drdp-btn>
    </div>
</div>
