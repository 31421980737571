export interface IViewStudentByClassModalData {
  classroomName?: string | null,
  classId: number,
  ratingPeriod: string,
  ageGradeInstrumentId: number,
  onlyLocked: boolean,
  onlyActive: boolean,
  canSelectAll?: boolean,
  agencyIds?: number,
  siteId?: number,
  globalRatingPeriodId?: number
}
