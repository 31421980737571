<div class="p-5">
  <drdp-title title="States" i18n-title></drdp-title>

  <drdp-container>
    <drdp-search textInputPlaceholder="State Name" i18n-textInputPlaceholder [searchInput]="searchInput" (search)="searchTable($event)" (clear)="searchTable($event)"></drdp-search>

    <div class="p-5 w-full">

        <drdp-add-edit-table [dataSource]="dataSource"
                             [columns]="tableColumns"
                             (toggleSwitchActionItem)="changeActiveStatus($event)"
                             (editActionItem)="editConfiguration($event)"
                             >
        </drdp-add-edit-table>

    </div>
  </drdp-container>

  <mat-paginator
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize"
                 [length]="totalData"
                 >
  </mat-paginator>
</div>
