<div *ngIf="data" class="w-max">
    <div class="flex justify-between border-b">
        <h1 class="text-[25px] font-bold">Search for Available DRDP Child</h1>
        <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>
    
    <div mat-dialog-content>
        <drdp-add-edit-table
            [dataSource]="dataSource"
            [tableData]="tableData"
            [columns]="tableColumns"
            (toggleSwitchActionItem)="handleSelectedChild($event)">
        </drdp-add-edit-table>
    </div>
    <div class="flex flex-row justify-end pt-5">
        <drdp-btn mat-button (click)="submitSelectedChild()">Add Child Info</drdp-btn>
    </div>
</div>

