import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import {
  IPortfolioConversationRequestDto,
  IPortfolioMessage,
  IPortfolioMessageRecipient
} from '@core/interfaces/iportfolio-message';
import { IStudent, IStudentClassEnrollmentDetails } from '@core/interfaces/istudent';
import { IUser } from '@core/interfaces/iuser';
import { PortfolioMessageService } from '@core/services/portfolio-message.service';
import { StudentService } from '@core/services/student.service';
import { UserService } from '@core/services/user.service';
import { defer, forkJoin, take, tap } from 'rxjs';
import { PortfolioMessageBoxComponent } from '../../portfolio-class/portfolio-message-box/portfolio-message-box.component';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { Modal } from '@core/enums/modal';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { PermissionService } from '@core/services/permission.service';

@Component({
  selector: 'drdp-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.scss'],
})
export class MessageBoardComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public get Permission() {
    return Permission;
  }
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  portfolioMessages: IPortfolioMessage[] = [];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  messageSelected?: IPortfolioConversationRequestDto;
  routeParams = this.router.snapshot.params;
  student?: IStudent;
  sender?: IUser;
  senderName = '';
  title = '';
  blackBg = 'black';
  whiteBg = 'white';
  messageBg = this.blackBg;
  archiveBg = this.whiteBg;
  previousPage = `portfolio-messages`;
  isArchived = false;
  classId? = 0;
  canViewPortfolioMessages: boolean = this.permissionService.checkPermission(
    Permission.AccessToPortfolioMessages
  )

  constructor(
    private portfolioMessageService: PortfolioMessageService,
    private studentService: StudentService,
    private userService: UserService,
    private sceService: StudentClassEnrollmentService,
    private router: ActivatedRoute,
    private route: Router,
    public modal: MatDialog,
    private permissionService: PermissionService
  ) {
    this.initializeInfo(this.initializePayload());
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
    if (this.canViewPortfolioMessages) this.updateMessageStatus();
  }

  initializePayload(updateMessages?: boolean) {
    const routeParams = this.router.snapshot.params;
    const sceId = +routeParams['studentClassEnrollmentId'];
    const studentId = +routeParams['studentId'];
    const createdById = +routeParams['senderId'];
    const recipientId = +routeParams['recipientId'];

    const payload = {
      sceId: sceId,
      studentId: studentId,
      createdById: createdById,
      recipientId: recipientId,
      isArchived: this.isArchived,
      itemsPerPage: this.pageSize,
      pageIndex: this.pageIndex,
    };

    if (updateMessages) {
      return payload;
    } else {
        return {
        ...payload,
        itemsPerPage: this.pageSize,
        pageIndex: this.pageIndex,
      };
    }
  }

  initializeInfo(payload: IPortfolioConversationRequestDto) {
    const student$ = defer(() =>
      this.studentService.getStudentById(payload.studentId).pipe(take(1))
    );
    const user$ = defer(() =>
      this.userService.getUserById(payload.createdById).pipe(take(1))
    );
    const sce$ = defer(() =>
      this.sceService.getStudentClassEnrollment(payload.sceId).pipe(take(1))
    );

    forkJoin([student$, user$, sce$]).subscribe({
      next: ([studentRes, userRes, sce]: [IStudent, IUser, IStudentClassEnrollmentDetails]) => {
        this.student = studentRes;
        this.sender = userRes;
        this.senderName = `${this.sender.firstName} ${this.sender.lastName}`;
        this.title = `${this.student.firstName} ${this.student.lastName} with ${this.senderName}`;
        this.classId = sce.classId;
      },
      error: _=> {
        this.title = '';
      },
    });
    this.getConversation(payload);
  }

  getConversation(payload: IPortfolioConversationRequestDto) {
    this.portfolioMessageService
      .getPortfolioConversation(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.setData(res)
      });
  }

  handleTabChange(isArchived: boolean) {
    const payload = this.initializePayload();
    this.isArchived = isArchived;
    this.messageBg = isArchived ? this.whiteBg : this.blackBg;
    this.archiveBg = isArchived ? this.blackBg : this.whiteBg;
    payload.isArchived = isArchived;
    this.getConversation(payload);

  }

  paginateTable(resetPage?: boolean): void {
    const payload = this.initializePayload();
    payload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    payload.itemsPerPage = resetPage ? 10 : this.paginator.pageSize;
    this.getConversation(payload);
  }

  setData(res: any) {
    this.totalData = res.totalData;
    this.portfolioMessages = res.items;
  }

  addToMessageBoard() {
    const routeParams = this.router.snapshot.params;
    this.modal.open(PortfolioMessageBoxComponent, {
      data: {
        enrollmentId: this.portfolioMessages[0].studentClassEnrollmentId,
        studentName: this.student?.firstName + ' ' + this.student?.lastName,
        portfolioId: this.portfolioMessages[0].portfolioId,
        recipientId: +routeParams['senderId']
      }
    });
  }

  viewStudentEvidence() {
    const routeParams = this.router.snapshot.params;
    const sceId = +routeParams['studentClassEnrollmentId'];
    const portfolioId = this.portfolioMessages[0].portfolioId;
    const ageGradeId = this.student?.ageGradeInstrumentId
    
    this.route.navigateByUrl(
      `portfolio/review-evidence/class/${this.classId}/student-enrollment/${sceId}/age-grade-instrument/${ageGradeId}?portfolioId=${portfolioId}`
    );
  }

  translateEnum(archive: any): string {
    switch (archive) {
        case Modal.Archive:
            return $localize `Archive Message`;
        default:
            return ''; 
    }
}

  archivePortfolioMessage(message: IPortfolioMessage) {
    let modal = { data: { modalInfo: {} } };
    modal.data.modalInfo = {
      title: $localize `Archive this message?`,
      message: $localize `This message will be moved to the Archived tab.`,
      primaryBtnClass: 'blue',
      primaryBtnText: this.translateEnum(Modal.Archive),
      showCancel: true,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: modal,
    });

    modalRef.afterClosed().subscribe((res) => {
      const routeParams = this.router.snapshot.params;
      const recipientId = +routeParams['recipientId'];
      const messageRecipient = message.messageRecipients.find((x: IPortfolioMessageRecipient) => x.userId === recipientId);
      if (res && messageRecipient) {
        this.portfolioMessageService
          .archivePortfolioMessage(messageRecipient, $localize `Message has been archived.`)
          .pipe(
            take(1),
            tap((res: boolean) => {
              if (res) {
                this.getConversation(this.initializePayload());
              }
            })
          )
          .subscribe();
      }
    });
  }

  updateMessageStatus() {
    this.portfolioMessageService
      .updateReadPortfolioMessages(this.initializePayload(true))
      .pipe(take(1))
      .subscribe();
  }

}
