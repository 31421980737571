<div class="pl-5">
  <div *ngIf="isPortfolio" class="grid grid-cols-2 gap-3">
    <div class="col-span-1">
      <p *ngIf="measureId" class="text-sm text-neutral-500">{{description}}</p>
    </div>
    <div *drdpCheckPermission="permission.EditObservations">
      <div *ngIf="!isEvidenceEmpty && !isFutureEvidence" class="col-span-1 flex justify-end">
        <drdp-btn *ngIf="!isEdit" class="text-sm" color="outline" (click)="handleEdit()"><i
            class="uil uil-pen"></i><ng-container i18n>Edit Evidence</ng-container></drdp-btn>
        <drdp-btn *ngIf="isEdit" class="text-sm" color="default" (click)="handleSave()"><i
            class="uil uil-pen"></i> <ng-container i18n>Save Evidence</ng-container></drdp-btn>
      </div>
    </div>
  </div>
  <div class="flex gap-3 py-2">
    <button *ngIf="!isPortfolio" type="button" class="flex justify-between p-2 w-32"
      [class.bg-drdpblue-500]="isActive.rating" [class.bg-white]="!isActive.rating" [class.rounded-lg]="true"
      (click)="handleTab(evidenceTab.Rating)">
      <i class="uil uil-comment-alt-notes text-drdpblue-300"></i><ng-container i18n>Rating Sheet</ng-container>
    </button>
    <button type="button" class="flex justify-between p-1 w-28" [class.bg-drdpblue-500]="isActive.photo"
      [class.bg-white]="!isActive.photo" [class.rounded-lg]="true" (click)="handleTab(evidenceTab.Photo)">
      <i class="uil uil-images text-drdpblue-300"></i><ng-container i18n>Photos</ng-container><p
        class="w-5 h-5 rounded-full self-center text-white text-sm bg-drdpblue-300">{{count.photo}}</p>
    </button>

    <button type="button" class="flex justify-between p-1 w-28" [class.bg-drdpblue-500]="isActive.video"
      [class.bg-white]="!isActive.video" [class.rounded-lg]="true" (click)="handleTab(evidenceTab.Video)">
      <i class="uil uil-video text-drdpblue-300"></i><ng-container i18n>Video</ng-container><p
        class="w-5 h-5 rounded-full self-center text-white text-sm bg-drdpblue-300">{{count.video}}</p>
    </button>

    <button type="button" class="flex justify-between p-1 w-28" [class.bg-drdpblue-500]="isActive.audio"
      [class.bg-white]="!isActive.audio" [class.rounded-lg]="true" (click)="handleTab(evidenceTab.Audio)">
      <i class="uil uil-volume text-drdpblue-300"></i><ng-container i18n>Audio</ng-container><p
        class="w-5 h-5 rounded-full self-center text-white text-sm bg-drdpblue-300">{{count.audio}}</p>
    </button>

    <button type="button" class="flex justify-between p-1 w-28" [class.bg-drdpblue-500]="isActive.note"
      [class.bg-white]="!isActive.note" [class.rounded-lg]="true" (click)="handleTab(evidenceTab.Notes)">
      <i class="uil uil-notes text-drdpblue-300"></i><ng-container i18n>Notes</ng-container><p
        class="w-5 h-5 rounded-full self-center text-white text-sm bg-drdpblue-300">{{count.note}}</p>
    </button>

    <button *ngIf="hasStructuredPrompt" type="button" class="flex justify-between p-1 w-48" [class.bg-drdpblue-500]="isActive.structuredPrompt"
      [class.bg-white]="!isActive.note" [class.rounded-lg]="true" (click)="handleTab(evidenceTab.StructuredPrompt)">
      <i class="uil uil-notes text-drdpblue-300"></i>Structured Prompts<p
        class="w-5 h-5 rounded-full self-center text-white text-sm bg-drdpblue-300">{{count.structurePrompt}}</p>
    </button>
  </div>
  <hr>
  <div *ngIf="isActive.rating">
    <drdp-rating-sheet [ratingSheet]="ratingSheet" [dialogData]="dialogData" [isPortfolio]="isPortfolio"
      (rating)="updateRatingSheet($event)">
    </drdp-rating-sheet>
  </div>
  <div class="flex overflow-x-auto w-full scrollbar scrollbar-thumb-white scrollbar-track-gray-100">
    <div *ngIf="isActive.photo">
      <drdp-evidence-photos [edit]="isEdit" [domainGrouping]="domainGroupings" [evidences]="evidences"
        [sasToken]="sasToken" [domainId]="domainId" [measureId]="measureId" [isDomain]="isDomain" [isFutureEvidence]="isFutureEvidence"
        [isPortfolio]="isPortfolio" (evidenceAction)="handleEvidenceUpdateList($event)"
        (ratingAction)="openInputRatingModal($event)" (refreshEvidences)="updateEvidence()" (toggleAssignModal)="toggleAssignmentModal($event)" >
      </drdp-evidence-photos>
    </div>
    <div *ngIf="isActive.video">
      <drdp-evidence-video [edit]="isEdit" [domainGrouping]="domainGroupings" [evidences]="evidences"
        [sasToken]="sasToken" [domainId]="domainId" [measureId]="measureId" [isDomain]="isDomain" [isFutureEvidence]="isFutureEvidence"
        [isPortfolio]="isPortfolio" (evidenceAction)="handleEvidenceUpdateList($event)" (refreshEvidences)="updateEvidence()"
        (ratingAction)="openInputRatingModal($event)"
        (toggleAssignModal)="toggleAssignmentModal($event)">
      </drdp-evidence-video>
    </div>
    <div *ngIf="isActive.audio">
      <drdp-evidence-audio [edit]="isEdit" [domainGrouping]="domainGroupings" [evidences]="evidences"
        [sasToken]="sasToken" [domainId]="domainId" [measureId]="measureId" [isDomain]="isDomain" [isFutureEvidence]="isFutureEvidence"
        [isPortfolio]="isPortfolio" (evidenceAction)="handleEvidenceUpdateList($event)" (refreshEvidences)="updateEvidence()"
        (ratingAction)="openInputRatingModal($event)"
        (toggleAssignModal)="toggleAssignmentModal($event)">
      </drdp-evidence-audio>
    </div>
  </div>
  <div *ngIf="isActive.note">
    <drdp-evidence-notes [edit]="isEdit" [domainGrouping]="domainGroupings" [evidences]="evidences"
      [sasToken]="sasToken" [domainId]="domainId" [measureId]="measureId" [isDomain]="isDomain" [isFutureEvidence]="isFutureEvidence"
      [isPortfolio]="isPortfolio" (evidenceAction)="handleEvidenceUpdateList($event)" (refreshEvidences)="updateEvidence()"
      (ratingAction)="openInputRatingModal($event)"
      (toggleAssignModal)="toggleAssignmentModal($event)">
    </drdp-evidence-notes>
  </div>
  <div *ngIf="isActive.structuredPrompt">
    <drdp-evidence-structured-prompt [edit]="isEdit" [domainGrouping]="domainGroupings" [evidences]="evidences"
      [sasToken]="sasToken" [domainId]="domainId" [measureId]="measureId" [isDomain]="isDomain" [isFutureEvidence]="isFutureEvidence"
      [isPortfolio]="isPortfolio" (evidenceAction)="handleEvidenceUpdateList($event)" (refreshEvidences)="updateEvidence()"
      (ratingAction)="openInputRatingModal($event)"
      (toggleAssignModal)="toggleAssignmentModal($event)">
    </drdp-evidence-structured-prompt>
  </div>
</div>
