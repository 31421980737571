<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 i18n class="text-[25px] font-bold">Edit Rating Period</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <form class="grid grid-cols-2 gap-4 gap-x-10" [formGroup]="ratingPeriodForm">

      <div class="col-span-full mt-2">
        <div *ngIf="data">
          <span i18n class="global-font-bold">Rating Period Name</span>
          <p>{{this.data.data.ratingPeriodName}}</p>
        </div>
      </div>

      <div class="col-span-full md:col-span-1">
        <drdp-date-picker label="Start Date" i18n-label [initialDate]="StartDate?.value" [isRequired]="true" [minDate]="minStart"
          [maxDate]="maxEnd" (emitDate)="handleDate($event, 'StartDate')">
        </drdp-date-picker>
      </div>

      <div class="col-span-full md:col-span-1" mb-8>
        <drdp-date-picker label="End Date" i18n-label [initialDate]="EndDate?.value" [isRequired]="true" [minDate]="minStart"
          [maxDate]="maxEnd" (emitDate)="handleDate($event, 'EndDate')">
        </drdp-date-picker>
        <mat-error i18n *ngIf="ratingPeriodForm.hasError('dateMismatch')"> Invalid date range. The End Date cannot be after
          the Start Date.</mat-error>
      </div>

    </form>

  </div>
  <div mat-dialog-actions class="flex justify-end">
    <drdp-btn i18n class="mx-2" color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn i18n *ngIf="ratingPeriodForm.valid" mat-button [mat-dialog-close]="onSubmit()" [disabled]="false"
      cdkFocusInitial>Save</drdp-btn>
    <drdp-btn i18n *ngIf="!ratingPeriodForm.valid" mat-button [disabled]="true" cdkFocusInitial>Save</drdp-btn>
  </div>
</div>