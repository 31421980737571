<div *ngIf="studentInfo">
  <div class="flex flex-col mt-5" *ngFor="let domain of studentDomains">
    <drdp-container additionClasses="min-h-[10rem]">
      <div class="md:flex place-content-between place-items-center self-center">
        <div class="font-semibold text-xl">
          <img class="domain-icon" src="assets/images/domains/{{domain.iconName}}" [alt]="domain.domainCode">
          {{domain.domainCode}} - {{domain.domainName}}
        </div>
        <div class="flex justify-end items-end text-xl">
          {{domain.domainTotal}}
        </div>
      </div>
      <div class="grid grid-cols-1  md:grid-cols-3 gap-4 mb-4">
        <div class="mt-5 px-1 flex flex-col" *ngFor="let measure of domain.measures">
          <div class="flex-grow">
            <h2 class="font-semibold ml-2">{{ measure.code }} : {{ measure.name }}</h2>
          </div>
          <div class="mt-3 justify-end">
            <input class="bg-transparent py-1 px-2 border border-gray-900 w-2/4 placeholder-black" aria-label="Rating selected"
              [placeholder]="measure.selectedLevelName ?? noneSelected" [id]="measure.id" type="text" readonly>

            <button *ngIf="!measure.preRatingValue && !measure.ratingValue && !measure.measureConditionId"
              class="bg-transparent text-blue-700 font-semibold py-1 px-4 border border-blue-500"
              [disabled]="!canCreateRating"
              (click)="selectRating(domain,measure)" i18n>Select Rating</button>

            <button *ngIf="measure.preRatingValue && !measure.ratingValue && !measure.measureConditionId"
              class="bg-sky-500 text-blue-950 font-semibold py-1 px-4 border border-sky-500"
              [disabled]="!canCreateRating"
              (click)="selectRating(domain,measure)" i18n>Review Pre-Rating</button>

            <button *ngIf="measure.ratingValue || measure.measureConditionId"
              [ngClass]="{'cursor-not-allowed': studentInfo.isLocked}"
              [disabled]="!canCreateRating"
              class="bg-blue-900 text-blue-50 py-1 px-4 border border-blue-900"
              (click)="selectRating(domain, measure)"><span i18n>Final Rating</span></button>
          </div>
          <a  *ngIf="(measure.ratingValue || measure.measureConditionId) && !studentInfo.isLocked && studentInfo.isPilotEnrollment"
              class="text-drdpblue-300 underline pt-1 cursor-pointer" (click)="editTeacherSurvey(measure)">
              Edit Survey Response
          </a>
          <a  *ngIf="(measure.ratingValue || measure.measureConditionId) && studentInfo.isLocked && studentInfo.isPilotEnrollment"
              class="text-drdpgrey-200 underline pt-1 cursor-pointer" (click)="viewTeacherSurvey(measure)">
              View Survey Response
          </a>
        </div>
      </div>
    </drdp-container>
  </div>
</div>
