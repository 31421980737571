import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalActions } from '@core/constants/modal-actions';
import { SelectType } from '@core/enums/select';
import { TableColumnType } from '@core/enums/table';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'drdp-add-edit-table',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnChanges, OnInit {
  lodash = _;

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @Input() dataSource!: MatTableDataSource<any>;
  @Input() set tableData(data: any[]) {
    this.setTableDataSource(data);
  }
  @Input() label: string = '';
  @Input() labelSpace: boolean = false;
  @Input() checkboxSpace : boolean = false;
  @Input() breakSpace : boolean = false;
  @Input() inputRatingSpace : boolean = false;
  @Input() columns?: any;
  @Input() applyPipes?: any[];
  @Input() noDataMessage?: string;
  @Input() viewIcon?: string;
  @Input() selectType?: SelectType;
  @Input() selectOptions?: any;
  @Input() tableId: string = 'drdp-table';
  @Input() toggleMsg: string = $localize `Are you sure you want to switch toggle?`;
  @Input() info: string = '';
  @Output() addActionItem = new EventEmitter();
  @Output() editActionItem = new EventEmitter();
  @Output() selectActionItem = new EventEmitter();
  @Output() toggleSwitchActionItem = new EventEmitter();
  @Output() deleteActionItem = new EventEmitter();
  @Output() viewActionItem = new EventEmitter();
  @Output() iconActionItem = new EventEmitter();
  @Output() actionIconClicked = new EventEmitter();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>> =
    new EventEmitter();
  @Output() editDateItem = new EventEmitter();
  @Output() sort = new EventEmitter();
  displayedColumns: string[] = [];
  requiredLabel: boolean = false;
  labelId: string = '';
  defaultNoDataMessage: string = $localize `:addEditComponent:There are no records to display`;

  public get Table() {
    return TableColumnType;
  }

  constructor(public modal: MatDialog) { }

  ngOnInit(): void {
    if (this.label != '') {
      this.labelId = this.label.replace(/\s/g, "") + new Date().getMilliseconds();
    }
  }

  ngOnChanges(): void {
    this.displayedColumns = this.columns.map(
      (x: { columnDef: string }) => x.columnDef
    );
  }

  public reRender(data?: any): void {
    if (data) {
      this.dataSource.data = data;
    } else {
      this.dataSource.data = this.tableData;
    }
  }

  addEmitter(value: any, index: number): void {
    let emitObj = {
      data: value,
      index: index,
    };
    this.addActionItem.emit(emitObj);
  }

  editEmitter(value: any, index: number, action?: string): void {
    let emitObj = {
      data: value,
      index: index,
      action: action,
    };
    this.editActionItem.emit(emitObj);
  }

  selectEmitter(item: any, index: number): void {
    let emitObj = {
      data: item,
      index: index,
    };
    this.selectActionItem.emit(emitObj);
  }

  checkEmitter(value: any, index: number, property: any, event: any): void {
    let emitObj = {
      data: value,
      property: property,
      index: index,
      event: event,
    };
    this.toggleSwitchActionItem.emit(emitObj);
  }

  toggleSwitchEmitter(event: MatSlideToggleChange, value: any, index: number, property: any): void {
    let emitObj = {
      data: value,
      property: property,
      index: index,
    };

    if (!property.readOnly && value && !event.source.checked) {
      emitObj.data.modalInfo = {
        title: $localize `Deactivate`,
        message: this.toggleMsg,
        primaryBtnClass: 'blue',
        primaryBtnText: ModalActions.Confirm,
        showCancel: true,
      };

      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: emitObj,
      });

      modalRef.afterClosed().subscribe((res) => {
        if (res) {
          // If the user confirms, emit the event
          event.source.checked = event.checked;
          this.toggleSwitchActionItem.emit(emitObj);
        } else {
          // If the user cancels, revert the toggle state
          event.source.checked = !event.checked;
        }
      });
    } else {
      this.toggleSwitchActionItem.emit(emitObj);
    }
  }

  deleteEmitter(value: any, index: number): void {
    let emitObj = {
      data: value,
      index: index,
    };
    this.deleteActionItem.emit(emitObj);
  }

  viewEmitter(value: any, index: number, header: string): void {
    // Emits action/data to parent component ex: MyClassesComponent
    let emitObj = {
      data: value,
      index: index,
      header: header
    };
    this.viewActionItem.emit(emitObj);
  }

  iconEmitter(data: any, index: number, type: string): void {
    let emitObj = {
      data: data,
      type: type,
      index: index,
    };
    this.iconActionItem.emit(emitObj);
  }

  actionIconSelected(data: any, index: number, action: string): void {
    if (action) {
      const emitObj = {
        data: data,
        action: action,
        index: index,
      };
      this.actionIconClicked.emit(emitObj);
    }
  }

  dateChangeEmitter(
    value: any,
    index: number,
    property: string,
    action?: string,
    row?: any
  ) {
    let dateChange = {
      value: value,
      property: property,
      index: index,
      action: action,
      row: row,
    };

    this.editActionItem.emit(dateChange);
  }

  setTableDataSource(data: any) {
    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.sortingDataAccessor = (
      data: any,
      sortHeaderId: string
    ): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
      return data[sortHeaderId];
    };
    this.dataSource.sort = this.matSort;
  }

  sortTable(sortParameters: Sort) {
    // defining name of data property, to sort by, instead of column name
    sortParameters.active = this.columns.find(
      (column: any) => column.header === sortParameters.active
    );
    this.sort.emit(sortParameters);
  }
}
