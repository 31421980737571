<drdp-container additionClasses="w-full mt-4">
  <drdp-container color="lightBlue">
    <form [formGroup]="searchForm">
      <div
        class="flex grid grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-cols-5 p-2 gap-2 md:gap-3 flex-row content-center">
        <div class="col-span-1 self-end">
          <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="handleState($event)" [required]="requiredFields!.state"
                             [clear]="clearDropdown">
          </drdp-select-state>
        </div>
        <div class="col-span-1 self-end">
          <drdp-select-agency #agencySelect (agency)="handleAgency($event)" [stateId]="stateId.value"
                              [initialAgencyId]="agencyId.value" [required]="requiredFields!.agency" [clear]="clearDropdown">
          </drdp-select-agency>
        </div>
        <div class="col-span-1 self-end">
          <drdp-select-global-rating (ratingPeriod)="handleRatingPeriod($event)" [multiSelect]="multiSelectRatingPeriods"
            [required]="requiredFields!.globalRatingPeriod"
            [clear]="clearDropdown" [showAll]="true">
          </drdp-select-global-rating>
        </div>

        <div class="col-span-1 self-end">
          <drdp-select-site (site)="handleSite($event)" [agencyId]="agencyId.value" [multiSelect]="multiSelectSites"
                            [multiSelectInitVals]="false" [required]="requiredFields!.site" [clear]="clearDropdown">
          </drdp-select-site>
        </div>

        <div class="col-span-1 self-end">
          <drdp-filter-select label="Classroom" i18n-label [options]="classroomAssignments" [selectType]="select.Class"
                              (onSelected)="handleClassroomAssignment($event)" [multiSelect]="multiSelectClassrooms"
                              [required]="requiredFields!.classroom">
          </drdp-filter-select>
        </div>

        <div class="col-span-1 self-end">
          <drdp-select-age-grade label="Age Group" i18n-label [initialageGradeId]="ageGradeInstrumentId.value"
                                 (ageGrade)="handleAgeGroupInstrument($event)" [stateId]="stateId.value" [multiSelect]="false"
                                 [required]="requiredFields!.ageGroup" [clear]="clearDropdown" [isPilot]="isPilotClassroom">
          </drdp-select-age-grade>

        </div>

        <div *ngIf="hasSubfilters" class="col-span-2 self-end flex flex-col">
          <div>
            <span i18n class="font-bold">Additional Filters</span>
          </div>

          <mat-radio-group aria-label="Select an option" class="flex gap-3" formControlName="additionalFilter">
            <mat-radio-button i18n [value]="subFilter.None" color="primary">None</mat-radio-button>
            <mat-radio-button i18n [value]="subFilter.Subgroups" color="primary">Subgroups</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div
        *ngIf="additionalFilter.value == subFilter.Subgroups"
        class="flex grid grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-cols-5 p-2 gap-2 md:gap-3 flex-row content-center pb-6">
        <div class="col-span-1 self-end">
          <drdp-date-picker
              label="DOB Start Date"
              i18n-label
              [initialDate]="dobStart.value"
              placeholder="Start Date"
              i18n-placeholder
              [isReadOnly]="false"
              (emitDate)="handleDobStart($event)"
            >
            </drdp-date-picker>

        </div>

        <div class="col-span-1 self-end">
          <drdp-date-picker
              label="DOB End Date"
              i18n-label
              [initialDate]="dobEnd.value"
              placeholder="End Date"
              i18n-placeholder
              [isReadOnly]="false"
              (emitDate)="handleDobEnd($event)"
            >
            </drdp-date-picker>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-gender
              [stateId]="stateId.value"
              [initialGenderIds]="gender.value"
              (gender)="handleGender($event)"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              >

          </drdp-select-gender>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-yes-no
              label="Hispanic or Latino"
              i18n-label
              [initialIds]="latino.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (optionSelected)="handleHispanic($event)"
            >
          </drdp-select-yes-no>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-race
              label="Race"
              i18n-label
              [stateId]="stateId.value"
              [initialRaceIds]="race.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (race)="handleRace($event)"
            >
          </drdp-select-race>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-yes-no
              label="Free or Reduced Meals"
              i18n-label
              [initialIds]="reducedLunch.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (optionSelected)="handleReducedLunch($event)"
            >
          </drdp-select-yes-no>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-iep-plan
              label="IEP Status"
              i18n-label
              [initialIds]="iep.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (optionSelected)="handleIep($event)"
            >
          </drdp-select-iep-plan>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-program-type
              [ageGradeId]="ageGradeInstrumentId.value"
              [initialProgramTypeIds]="programType.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (programType)="handleProgramType($event)"
            >
          </drdp-select-program-type>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-language
              label="Child's Home Language"
              i18n-label
              [initialLanguageIds]="language.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (language)="handleLanguage($event)"
            >
          </drdp-select-language>

        </div>

        <div class="col-span-1 self-end">
          <drdp-select-yes-no
              label="Language other than English spoken at home"
              i18n-label
              [initialIds]="eld.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [required]="false"
              (optionSelected)="handleEld($event)"
            >
          </drdp-select-yes-no>

        </div>
      </div>
      <div class="hidden lg:block lg:col-span-3 xl:col-span-3 self-end"></div>
      <div class="flex flex-row gap-1.5 justify-end lg:col-span-2 xl:col-span-1 md:self-end xl:my-auto mt-2 md:mt-0">
        <div class="col-span-1">
          <drdp-btn [disabled]="!searchForm.valid" type="submit">
            <span i18n class="px-2" (click)="search()">Show Report</span>
          </drdp-btn>
        </div>
        <div class="col-span-1">
          <drdp-btn *ngIf="showLocale" type="button" [disabled]="!searchForm.valid || isDownloadDisabled"
                    (click)="isDownloadMenuOpen = !isDownloadMenuOpen"
                    [matMenuTriggerFor]="(searchForm.valid && !isDownloadDisabled) ? reportMenu : null" i18n>Download Report</drdp-btn>
          <drdp-btn *ngIf="!showLocale" type="button" [disabled]="!searchForm.valid || isDownloadDisabled"
                    (click)="downloadReport('en')" i18n>Download Report</drdp-btn>
        </div>
        <div class="col-span-1">
          <drdp-btn color="outline" (click)="clear()" i18n>Clear</drdp-btn>
        </div>
        <mat-menu #reportMenu="matMenu" [overlapTrigger]="false" [hasBackdrop]="false" (closed)="isDownloadMenuOpen = false">
          <div>
            <drdp-btn additionalClasses="mt-2" (click)="downloadReport('en')" i18n>English</drdp-btn>
          </div>
          <div>
            <drdp-btn additionalClasses="mt-2" (click)="downloadReport('es')" i18n>Spanish</drdp-btn>
          </div>
        </mat-menu>
      </div>
    </form>
  </drdp-container>
</drdp-container>
