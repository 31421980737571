import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortfolioService } from '@core/services/portfolio.service';
import { ToastService } from '@core/services/toast.service';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import { Observable, of, pipe, take } from 'rxjs';
import {
  AddEvidenceFormStep,
  PortfolioEvidenceType,
} from '@core/enums/portfolio';
import {
  IPortfolioAddEvidenceParams,
  IPortfolioAddModel,
  IPortfolioEvidence,
  IPortfolioPhotoEvidence,
} from '@core/interfaces/iportfolio';
import { BlobStorageService } from '@core/services/blob-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { PortfolioInputRatingModalComponent } from '../portfolio-input-rating-modal/portfolio-input-rating-modal.component';
import { ITemplateMeasure } from '@views/user/configuration/rating-view-setup/interfaces/itemplate-measure';
import { cleanFileName, trimHTTPS } from '@core/services/helper.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Modal } from '@core/enums/modal';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { IGlobalRatingPeriod } from '@core/interfaces/iratingperiod';
import { IMasterDomain, IMasterMeasure } from '@core/interfaces/imaster-domain';
import { LookupService } from '@core/services/lookup.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'drdp-portfolio-add-evidence',
  templateUrl: './portfolio-add-evidence.component.html',
  styleUrls: ['./portfolio-add-evidence.component.scss'],
})
export class PortfolioAddEvidence implements OnInit {
  templateDomains: IBaseTemplateDomain[] = [];
  originalTemplateDomains: IBaseTemplateDomain[] = [];
  PortfolioEvidenceType = PortfolioEvidenceType;
  selectedIndex: number | null = null;
  selectedEvidenceType: number = 0;
  displayUrl: string = '';
  files: IPortfolioPhotoEvidence[] = [];
  blobFiles: File[] = [];
  url: string = '';
  fileName: string = '';
  displayFileName: string = '';
  acceptedFiles: string = '';
  blobFileName: string = '';
  params: IPortfolioAddEvidenceParams | null = null;
  studentNames: string = '';
  container: string = 'portfolioEvidences';
  addPortfolioModel!: IPortfolioAddModel;
  addPortfolioModels: IPortfolioAddModel[] = [];
  notes: string = '';
  isTilesDisabled: boolean = false;
  formStep: number = 1;
  containerName: string = 'portfolio-evidences';
  progress: number = 0;
  isFileUploading: boolean = false;
  returnUrl!: string;
  disableSave = false;
  selectedFile: File | null = null;
  selectedFileUrl: string | ArrayBuffer | null = null;
  blobResults: any[] = [];
  allowMultiple: boolean = false;
  selectedPhotoEvidence: boolean = false;
  fileValid: boolean = false;
  studentEnrollmentId: number = 0;
  step = AddEvidenceFormStep;
  collectedOn: string | null = null;
  minStartDate: Date = new Date();
  today: Date = new Date();
  isFutureRatingPeriod: boolean = false;
  isPilot: boolean = false;
  masterDomains?: IMasterDomain[];
  masterMeasures?: IMasterMeasure[];
  ratingPeriodForm: FormGroup | any;

  uploadSettings: any = {
    uploadText: '',
    uploadIcon: '',
  };

  evidences: IPortfolioEvidence[] = [
    {
      name: $localize `Add Photo`,
      icon: 'uil-images',
      type: PortfolioEvidenceType.Photo,
      allowedType: 'jpeg, png, gif',
    },
    {
      name: $localize `Add Video`,
      icon: 'uil-video',
      type: PortfolioEvidenceType.Video,
      allowedType: 'mp4, mpeg, webm, ogg, mov',
    },
    {
      name: $localize `Add Audio`,
      icon: 'uil-volume',
      type: PortfolioEvidenceType.Audio,
      allowedType: 'mpeg, wav, ogg',
    },
    {
      name: $localize `Add Note`,
      icon: 'uil-notes',
      type: PortfolioEvidenceType.Note,
    }
  ];

  allowedTypes: Record<PortfolioEvidenceType, string[]> = {
    [PortfolioEvidenceType.Photo]: ['image/jpeg', 'image/png', 'image/gif'],
    [PortfolioEvidenceType.Video]: [
      'video/mp4',
      'video/mpeg',
      'video/webm',
      'video/ogg',
      'video/quicktime',
    ],
    [PortfolioEvidenceType.Audio]: ['audio/mpeg', 'audio/wav', 'audio/ogg'],
    [PortfolioEvidenceType.Note]: [],
    [PortfolioEvidenceType.StructuredPrompt]: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'application/msword',
      'application/pdf',
    ],
  };

  constructor(
    private portfolioService: PortfolioService,
    private lookupService: LookupService,
    private route: ActivatedRoute,
    private router: Router,
    public modal: MatDialog,
    private fb: FormBuilder,
    private toast: ToastService,
    private blobStorageService: BlobStorageService,
    private ratingPeriodService: RatingPeriodService,
    public dialog: MatDialog
  ) {
    this.ratingPeriodService
      .getMinGlobalRatingPeriod()
      .pipe(take(1))
      .subscribe((res: IGlobalRatingPeriod) => {
        this.minStartDate = new Date(res.minStartDate);
      });
    const isPilot = this.route.snapshot.queryParams['isPilot'];
    this.isPilot = isPilot === 'true';
  }

  ngOnInit(): void {
    window.addEventListener(
      'dragover',
      (e) => {
        e && e.preventDefault();
      },
      false
    );
    window.addEventListener(
      'drop',
      (e) => {
        e && e.preventDefault();
      },
      false
    );

    this.initForm();
  }

  initForm(): void {
    var addPortfolioParams =
      this.portfolioService.getPortfolioAddEvidenceParams();

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    if (!addPortfolioParams) {
      this.toast.error($localize `Add portfolio params cannot be null.`);
      this.router.navigateByUrl(this.returnUrl);
      return;
    }

    this.params = addPortfolioParams;
    this.studentNames = this.params.studentsInformation
      .map((s) => s.name)
      .join(', ');
    this.studentEnrollmentId =
      this.params.studentsInformation[0].studentClassEnrollmentId;

    this.getDomainAndMeasureName(this.params.ageGradeInstrumentId);
  }

  getDomainAndMeasureName(ageGradeInstrumentId?: number) {
    if (this.isFutureRatingPeriod) {
      this.lookupService
        .getMasterDomains()
        .pipe(take(1))
        .subscribe((res: IMasterDomain[]) => {
          this.masterDomains = res
            .filter(domain => !domain.onlyPilot)
            .map(domain => ({
              ...domain,
              isAllSelected: false,
            }));
        });


      this.lookupService
        .getMasterMeasures()
        .pipe(take(1))
        .subscribe((res: IMasterMeasure[]) => {
          this.masterMeasures = res.map((measure) => ({
            ...measure,
            isSelected: false,
          }));
        });
    } else {
      if (ageGradeInstrumentId) {
        this.portfolioService
          .getBaseMeasures(ageGradeInstrumentId)
          .pipe(take(1))
          .subscribe((results: IBaseTemplateDomain[]) => {
            this.templateDomains = results;
            this.originalTemplateDomains = results;

            const hasMath = this.originalTemplateDomains.some(
              (domain) => domain.isStructuredPrompt);

            if (hasMath) {
              this.evidences.push({
                name: 'Add Structured Prompt',
                icon: 'uil-images',
                type: PortfolioEvidenceType.StructuredPrompt,
                allowedType: 'jpeg, png, gif, doc, pdf',
              });
            }
          });
      }
    }
  }

  initializeForms(): void {
    this.ratingPeriodForm = this.fb.group({
      ratingPeriod: [null, [Validators.required]],
    });
  }

  onTilesClick(index: number, type: number): void {
    if (this.isTilesDisabled) {
      this.toast.error($localize `File should be empty when moving to other tabs.`);
      return;
    }

    if (index === this.selectedIndex) {
      this.selectedIndex = null;
      return;
    }

    this.selectedIndex = index;
    switch (type) {
      case PortfolioEvidenceType.Photo:
        this.uploadSettings.uploadText = $localize `Photo`;
        this.uploadSettings.uploadIcon = 'uil-images';
        this.acceptedFiles = 'image/*';
        this.selectedEvidenceType = type;
        this.allowMultiple = true;
        this.selectedPhotoEvidence = true;
        this.templateDomains = this.originalTemplateDomains;
        break;
      case PortfolioEvidenceType.Video:
        this.uploadSettings.uploadText = $localize `Video`;
        this.uploadSettings.uploadIcon = 'uil-video';
        this.acceptedFiles = 'video/*';
        this.selectedEvidenceType = type;
        this.allowMultiple = false;
        this.selectedPhotoEvidence = false;
        this.templateDomains = this.originalTemplateDomains;
        break;
      case PortfolioEvidenceType.Audio:
        this.uploadSettings.uploadText = $localize `Audio`;
        this.uploadSettings.uploadIcon = 'uil-volume';
        this.acceptedFiles = 'audio/*';
        this.selectedEvidenceType = type;
        this.allowMultiple = false;
        this.selectedPhotoEvidence = false;
        this.templateDomains = this.originalTemplateDomains;
        break;
      case PortfolioEvidenceType.Note:
        this.selectedEvidenceType = type;
        this.selectedPhotoEvidence = false;
        this.templateDomains = this.originalTemplateDomains;
        break;
      case PortfolioEvidenceType.StructuredPrompt:
        this.uploadSettings.uploadText = 'Structured Prompt';
        this.uploadSettings.uploadIcon = 'uil-images';
        this.acceptedFiles = 'image/*, .doc, .pdf';
        this.selectedEvidenceType = type;
        this.allowMultiple = false;
        this.selectedPhotoEvidence = false;
        this.templateDomains = this.originalTemplateDomains
          .filter((domain) => domain.isStructuredPrompt)
          .map((domain) => ({
            ...domain,
            measures: domain.measures.filter((measure) => measure.isStructuredPrompt),
          }));
        break;
    }
  }

  checkAndSetUploadRequirements(file: File) {
    this.fileValid = false;
    if (!this.isFileValid(file)) {
      this.toast.error($localize `This file is not an approved type.`);
      return;
    }

    if (!this.isFileSizeValid(file)) {
      this.toast.error($localize `File is larger than the 1GB upload limit.`);
      return;
    }
    const uniqueId =
      Math.random().toString(36).substring(2) + Date.now().toString(36);
    this.blobFileName = uniqueId + '-' + file.name;
    this.isTilesDisabled = true;
    this.isFileUploading = true;
    this.fileValid = true;
  }

  isFileValid(file: File): boolean {
    if (!file) {
      return false;
    }
    const selectedEvidenceType: PortfolioEvidenceType =
      this.selectedEvidenceType;
    const selectedTypes = this.allowedTypes[selectedEvidenceType];

    return selectedTypes.includes(file.type);
  }

  isFileSizeValid(file: File): boolean {
    if (!file) {
      return false;
    }

    const selectedEvidenceType: PortfolioEvidenceType =
      this.selectedEvidenceType;
    const maxFileSizeInBytes = 1024 * 1024 * 1024; // 1GB

    if (
      (selectedEvidenceType === PortfolioEvidenceType.Video ||
        selectedEvidenceType === PortfolioEvidenceType.Audio) &&
      file.size >= maxFileSizeInBytes
    ) {
      return false;
    }

    return true;
  }

  checkPhotoLimitMax(): void {
    if (this.blobFiles.length >= 20) {
      this.toast.error($localize `Max limit of 20 photos reached.`);
      return;
    }
  }

  openDuplicateFileModal() {
    const modal = {
      data: {
        modalInfo: {
          title: $localize `Duplicate File`,
          message:
          $localize `A file with that name already exists. Please rename and try again.`,
          primaryBtnClass: 'blue',
          primaryBtnText: Modal.OK,
        },
      },
    };

    const dialogRef = this.modal.open(ConfirmationModalComponent, {
      data: modal,
    });
    return dialogRef.afterClosed();
  }

  processPhotos(files: any) {
    for (let i = 0; i < files.length; i++) {
      this.checkAndSetUploadRequirements(files[i]);
      this.selectedFile = files[i];
      if (this.selectedFile && this.fileValid) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.selectedFileUrl = e.target!.result;
          if (
            this.files.some(
              (file: IPortfolioPhotoEvidence) => file.fileName === files[i].name
            )
          ) {
            this.openDuplicateFileModal();
            return;
          }
          this.files.push({
            fileName: files[i].name,
            displayUrl: this.selectedFileUrl,
            blobFileName: this.blobFileName,
            url: this.blobFileName,
          });
          this.blobFiles.push(files[i]);
          this.url = this.blobFileName;
        };
        reader.readAsDataURL(this.selectedFile);
      }
    }
  }

  fileDropHandler(event: any) {
    event.preventDefault();
    if (!this.selectedPhotoEvidence) {
      const file = event.dataTransfer.files && event.dataTransfer.files[0];
      this.checkAndSetUploadRequirements(file);
      if (this.fileValid) this.uploadFileProcess(file);
    } else {
      const files = event.dataTransfer.files;
      this.checkPhotoLimitMax();
      this.processPhotos(files);
    }
    this.isFileUploading = false;
  }

  onSelectFile(event: any) {
    if (!this.selectedPhotoEvidence) {
      const file = event.target.files && event.target.files[0];
      this.checkAndSetUploadRequirements(file);
      if (this.fileValid) this.uploadFileProcess(file);
    } else {
      const files = event.target.files;
      this.checkPhotoLimitMax();
      this.processPhotos(files);
    }
    this.isFileUploading = false;
  }

  onFileClick(event: any) {
    if (this.url && !this.selectedPhotoEvidence) {
      this.toast.error($localize `Remove existing file before uploading a new one.`);
      event.preventDefault();
    }
  }

  uploadFileProcess(file: File | File[]) {
    let files: File[] = [];
    if (!Array.isArray(file)) files.push(file);
    else files = file;
    this.portfolioService
      .getBlobSasToken(this.containerName)
      .pipe(take(1))
      .subscribe((sasToken: any) => {
        if (sasToken?.value) {
          this.uploadToBlobStorage(files, sasToken?.value);
        } else {
          this.toast.error($localize `Error getting sasToken`);
        }
      });
  }

  updateFilesArr(link: IPortfolioPhotoEvidence) {
    this.files = this.files.filter(
      (file: IPortfolioPhotoEvidence) => file.fileName !== link.fileName
    );
  }

  uploadToBlobStorage(files: File[], sasToken: string) {
    this.isFileUploading = true;
    this.blobStorageService
      .uploadMultipleFiles(
        files,
        this.containerName,
        sasToken,
        (ev) => {
          this.progress = (ev.loadedBytes / files[0].size) * 100;
        },
        this.studentEnrollmentId
      )
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result) {
          if (this.selectedPhotoEvidence) {
            this.blobResults = result;
            this.processMultipleFiles();
          } else {
            this.displayUrl = result[0].displayUrl;
            this.url = result[0].url;
            this.fileName = result[0].fileName;
            this.displayFileName = cleanFileName(result[0].fileName);
          }
          this.isFileUploading = false;
          this.progress = 0;
        }
      });
  }

  deleteFileProcess() {
    this.portfolioService
      .getBlobSasToken(this.containerName)
      .pipe(take(1))
      .subscribe((sasToken: any) => {
        if (sasToken?.value) {
          this.deleteFileFromBlobStorage(sasToken?.value);
        } else {
          this.toast.error($localize `Error getting sasToken`);
        }
      });
  }

  deleteFileFromBlobStorage(sasToken: string) {
    if (this.blobFileName.trim() !== '') {
      this.blobStorageService
        .deleteFile(this.fileName, this.containerName, sasToken)
        .pipe(take(1))
        .subscribe((res) => {
          if (res) {
            this.url = '';
            this.fileName = '';
            this.blobFileName = '';
          }
        });
    }
  }

  deleteFromBlobFiles(file: any) {
    this.blobFiles = this.blobFiles.filter(
      (f: File) => f.name !== file.fileName
    );

    if (this.blobFiles.length === 0) {
      this.isTilesDisabled = false;
      this.url = '';
    }
  }

  updateAddPortfolioModels(file: IPortfolioPhotoEvidence) {
    this.addPortfolioModels = this.addPortfolioModels.filter(
      (addPortfolioModel: IPortfolioAddModel) =>
        addPortfolioModel.fileName !== file.fileName
    );
  }

  onFileRemove(photoEvidence?: IPortfolioPhotoEvidence) {
    if (photoEvidence) {
      this.deleteFromBlobFiles(photoEvidence);
      this.updateFilesArr(photoEvidence);
      this.updateAddPortfolioModels(photoEvidence);
    } else {
      this.deleteFileProcess();
      this.isTilesDisabled = false;
    }
  }

  onDomainSelectChange(value: boolean, domain: IBaseTemplateDomain) {
    if (value) {
      domain.measures.forEach((m) => (m.isSelected = false));
    }
  }

  onFutureDomainSelectChange(value: boolean, domain: IMasterDomain) {
    if (value) {
      this.masterMeasures
        ?.filter((d: IMasterMeasure) => d.masterDomainId === domain.id)
        .forEach((m: IMasterMeasure) => (m.isSelected = false));
    }
  }

  onMeasureSelectChange(
    value: boolean,
    domain: IBaseTemplateDomain | IMasterDomain
  ) {
    if (value) {
      domain.isAllSelected = false;
    }
  }

  handleDate(event: any): void {
    if (
      new Date(event).getTime() >= this.minStartDate.getTime() &&
      new Date(event).getTime() <= this.today.getTime()
    ) {
      this.collectedOn = event;
    } else {
      this.collectedOn = null;
      this.toast.error(
        $localize `Collection date must be within school year's global rating periods.`
      );
    }
  }

  formOneValidation(): boolean {
    if (this.selectedEvidenceType === this.PortfolioEvidenceType.Note) {
      if (this.notes.trim() === '') {
        this.toast.error($localize `Notes can't be empty.`);
        return false;
      }
    } else {
      if (this.url.trim() === '') {
        this.toast.error($localize `File can't be empty.`);
        return false;
      }
    }

    if (!this.hasSelectedDomainOrMeasure()) {
      this.toast.error($localize `Please select atleast one measure or domain.`);
      return false;
    }

    if (!this.collectedOn) {
      this.toast.error($localize `Please choose the date of collected evidence.`);
      return false;
    }

    return true;
  }

  formTwoValidation(): boolean {
    const hasSelectedMeasuresInAllItems =
      this.addPortfolioModel.evidences.every(this.hasSelectedMeasure);

    if (!hasSelectedMeasuresInAllItems) {
      this.toast.error($localize `Atleast one measure selected for each child.`);
      return false;
    }

    return true;
  }

  hasMeasureValue(item: any): boolean {
    return item.measures
      .filter((measure: any) => measure.isSelected)
      .every((measure: any) => measure.measureValue !== undefined);
  }

  hasSelectedMeasure(item: any): boolean {
    return item.measures.some((measure: any) => measure.isSelected);
  }

  hasSelectedDomainOrMeasure(): boolean {
    return this.templateDomains.some((domain) => {
      return (
        this.isAddformHasSelectedDomain() ||
        this.isAddFormHasSelectedFutureDomain() ||
        this.isAddFormHasSelectedMeasure() ||
        this.isAddFormHasSelectedFutureMeasure()
      );
    });
  }

  isAddformHasSelectedDomain(): boolean {
    return this.templateDomains.some((domain) => {
      return domain.isAllSelected;
    });
  }

  isAddFormHasSelectedFutureDomain(): boolean | any {
    return this.masterDomains?.some((domain) => {
      return domain.isAllSelected;
    });
  }

  isAddFormHasSelectedMeasure(): boolean {
    return this.templateDomains.some((domain) => {
      return domain.measures.some((measure) => measure.isSelected);
    });
  }

  isAddFormHasSelectedFutureMeasure(): boolean | any {
    return this.masterMeasures?.some((measure) => {
      return measure.isSelected;
    });
  }

  resetSelectedDomainAndMeasure() {
    this.templateDomains.forEach((domain) => {
      domain.isAllSelected = false;
      domain.measures.forEach((measure) => (measure.isSelected = false));
    });
    this.masterDomains?.forEach((domain) => {
      domain.isAllSelected = false;
    });
    this.masterMeasures?.forEach((measure) => (measure.isSelected = false));
  }

  toggleFutureRatingPeriod() {
    this.isFutureRatingPeriod = !this.isFutureRatingPeriod;
    this.resetSelectedDomainAndMeasure();

    if (this.isFutureRatingPeriod) {
      this.initializeForms();
      this.getDomainAndMeasureName();
    }
  }

  processFile() {
    this.processEvidences(this.addPortfolioModel, this.templateDomains);
  }

  updateAddPortfolioModel() {
    this.addPortfolioModel = {
      evidences: [],
      url: trimHTTPS(this.url),
      notes: this.notes,
      evidenceTypeId: this.selectedEvidenceType,
      fileName: this.fileName,
      collectedOn: this.collectedOn!,
    };
    this.addPortfolioModel.evidences = [...this.params!.studentsInformation];
  }

  processMultipleFiles() {
    this.files.forEach((file: IPortfolioPhotoEvidence, i: number) => {
      if (
        !this.addPortfolioModels.some(
          (addPortfolioModel: IPortfolioAddModel) =>
            addPortfolioModel.fileName === file.fileName
        )
      ) {
        this.addPortfolioModels.push({
          evidences: [],
          url: trimHTTPS(this.blobResults[i].url),
          notes: this.notes,
          evidenceTypeId: this.selectedEvidenceType,
          fileName: file.fileName,
          collectedOn: this.collectedOn!,
        });
      }

      this.addPortfolioModels[i].evidences = [
        ...this.params!.studentsInformation,
      ];
      if (this.isFutureRatingPeriod) {
        this.processFutureEvidences(
          this.addPortfolioModels[i],
          this.masterDomains!
        );
      } else {
        this.processEvidences(this.addPortfolioModels[i], this.templateDomains);
      }
    });
    this.checkSelectedDomainMeasure();
  }

  processEvidences(
    addPortfolioModel: IPortfolioAddModel,
    templateDomains: IBaseTemplateDomain[]
  ): void {
    if (addPortfolioModel.evidences && addPortfolioModel.evidences.length > 0) {
      for (let i = 0; i < addPortfolioModel.evidences.length; i++) {
        addPortfolioModel.evidences[i].measures = [];
        addPortfolioModel.evidences[i].domains = [];
        for (let j = 0; j < templateDomains.length; j++) {
          const domains = templateDomains;
          if (domains[j].isAllSelected) {
            addPortfolioModel.evidences[i].domains.push(domains[j]);
          } else {
            var selectedMeasures = domains[j].measures.filter(
              (measure: ITemplateMeasure) => measure.isSelected
            );
            selectedMeasures = selectedMeasures.map((measure) => ({
              ...measure,
              isSelected: false,
            }));
            addPortfolioModel.evidences[i].measures.push(...selectedMeasures);
          }
        }
      }
    }
  }

  processFutureEvidences(
    addPortfolioModel: IPortfolioAddModel,
    templateDomains: IMasterDomain[]
  ): void {
    if (addPortfolioModel.evidences && addPortfolioModel.evidences.length > 0) {
      for (let i = 0; i < addPortfolioModel.evidences.length; i++) {
        addPortfolioModel.evidences[i].measures = [];
        addPortfolioModel.evidences[i].domains = [];
        for (let j = 0; j < templateDomains.length; j++) {
          const domains = templateDomains;
          if (domains[j].isAllSelected) {
            addPortfolioModel.evidences[i].domains.push(domains[j]);
          } else {
            var selectedMeasures = this.masterMeasures?.filter(
              (measure: IMasterMeasure) =>
                measure.isSelected && measure.masterDomainId === domains[j].id
            );
            selectedMeasures = selectedMeasures?.map((measure) => ({
              ...measure,
              isSelected: false,
              measureId: this.templateDomains
                ? this.templateDomains
                    .find((d: IBaseTemplateDomain) =>
                      d.measures.some(
                        (m: ITemplateMeasure) => m.code === measure.measureCode
                      )
                    )
                    ?.measures.find(
                      (m: ITemplateMeasure) => m.code === measure.measureCode
                    )?.id
                : null,
            }));
            if (selectedMeasures) {
              addPortfolioModel.evidences[i].measures.push(...selectedMeasures);
            }
          }
        }
      }
    }
  }

  onSaveEvidence() {
    if (this.formStep === this.step.StepOne) {
      if (!this.params || !this.formOneValidation()) {
        return;
      }

      this.updateAddPortfolioModel();

      if (this.selectedPhotoEvidence) {
        this.uploadFileProcess(this.blobFiles);
      } else {
        if (this.isFutureRatingPeriod) {
          this.processFutureEvidences(
            this.addPortfolioModel,
            this.masterDomains!
          );
          this.checkSelectedDomainMeasure();
        } else {
          this.processEvidences(this.addPortfolioModel, this.templateDomains);
          this.checkSelectedDomainMeasure();
        }
      }
    } else {
      if (!this.formTwoValidation()) {
        return;
      }

      this.formTwoSave();
    }
  }

  checkSelectedDomainMeasure(): void {
    if (
      (this.isAddformHasSelectedDomain() &&
        !this.isAddFormHasSelectedMeasure()) ||
      (this.isAddFormHasSelectedFutureDomain() &&
        !this.isAddFormHasSelectedFutureMeasure())
    ) {
      this.formTwoSave();
    } else {
      this.formStep = this.step.StepTwo;
    }
  }

  formTwoSave() {
    if (this.isFutureRatingPeriod) {
      this.futureEvidenceSave();
    } else {
      var payload: IPortfolioAddModel[] = [];
      if (!this.selectedPhotoEvidence) payload.push(this.addPortfolioModel);
      else payload = this.addPortfolioModels;
      this.disableSave = true;
      this.portfolioService
        .saveEvidence(payload)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            this.toast.success($localize `Evidence added successfully.`);
            this.router.navigateByUrl(this.returnUrl);
          } else this.toast.success($localize `Failed to add evidence.`);
          this.disableSave = false;
          this.isFutureRatingPeriod = false;
        });
    }
  }

  futureEvidenceSave() {
    var payload: IPortfolioAddModel[] = [];
    if (!this.selectedPhotoEvidence) payload.push(this.addPortfolioModel);
    else payload = this.addPortfolioModels;
    this.disableSave = true;
    this.portfolioService
      .saveFutureEvidence(payload)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.toast.success($localize `Evidence added successfully.`);
          this.router.navigateByUrl(this.returnUrl);
          this.confirmFutureEvidenceSave();
        } else this.toast.success($localize `Failed to add evidence.`);
        this.disableSave = false;
        this.isFutureRatingPeriod = false;
      });
  }

  translateOk(ok: any): string {
    switch (ok) {
      case Modal.OK: 
        return $localize `Ok`;
      default:
        return '';
    }
  }

  confirmFutureEvidenceSave() {
    let event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: $localize `Evidence Saved`,
      message: $localize `Evidence can be found in the Review Evidence Library.`,
      primaryBtnClass: 'blue',
      primaryBtnText: this.translateOk(Modal.OK),
    };
    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe(() => {});
  }

  cancel() {
    this.formStep = this.step.StepOne;
  }

  back() {
    this.confirmLeavingWithoutSavingStepTwo().subscribe(
      (result: boolean | '') => {
        if (result) {
          this.router.navigateByUrl(this.returnUrl);
        }
      }
    );
  }

  confirmLeavingWithoutSavingStepTwo(): Observable<boolean | ''> {
    if (this.formStep == this.step.StepTwo) {
      const modal = {
        data: {
          modalInfo: {
            title: 'You have unsaved changes',
            message:
              'Are you sure you want to navigate away from this page? Your evidence has not been saved.',
            primaryBtnClass: 'blue',
            primaryBtnText: 'Continue',
            cancelText: 'Cancel',
            showCancel: true,
          },
        },
      };

      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        data: modal,
      });

      return dialogRef.afterClosed();
    } else {
      return of(true);
    }
  }

  openInputRatingModal(measure: any) {
    if (this.params) {
      const measureId = this.isFutureRatingPeriod
        ? measure.measureId
        : measure?.id;
      this.portfolioService
        .getRatingMeasures(this.params.ageGradeInstrumentId, measureId)
        .pipe(take(1))
        .subscribe((result) => {
          if (result) {
            var data = {
              options: result.levels,
              selectedLevel: measure.selectedLevel,
              selectedMeasureValue: measure.measureValue,
              measureCode: measure.code,
            };
            const modalRef = this.modal.open(
              PortfolioInputRatingModalComponent,
              {
                data: data,
              }
            );
            modalRef
              .afterClosed()
              .pipe(take(1))
              .subscribe((result) => {
                if (result && result.success) {
                  measure.measureValue = result.data.selectedId;
                  measure.selectedLevel = result.data.selectedLevel;
                  measure.selectedLevelName = result.data.selectedName;
                  measure.selectedLevelIcon = result.data.selectedIcon;
                }
              });
          }
        });
    }
  }
}
