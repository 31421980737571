<ng-container class="contents">
  <div class="col-span-1 z-50">
    <drdp-select-state [initialStateId]="initStateId" (state)="handleState($event)" [required]="true"
      [clear]="clearState" [disabled]="!isSuperUser">
    </drdp-select-state>
  </div>
  <div class="col-span-1 z-50">
    <drdp-select-agency (agency)="handleChosenAgency($event)" [stateId]="stateId.value" [initialAgencyId]="initAgencyId"
      [required]="true" [clear]="clearAgency" [disabled]="!isSuperUser">
    </drdp-select-agency>
  </div>
  <div class="col-span-1 z-50">
    <drdp-select-rating-period [initialRatingPeriodId]="ratingPeriodId.value" (ratingPeriod)="handleChosenRatingPeriod($event)" [agencyId]="agencyId.value"
                               [required]="isDropdownRequired.ratingPeriod" [omitFutureRps]="omitFutureRps">
    </drdp-select-rating-period>
  </div>
  <div class="col-span-1 z-50">
    <drdp-select-site (site)="handleChosenSite($event)" [agencyId]="agencyId.value" [multiSelect]="false" [required]="isDropdownRequired.site"
      [multiSelectInitVals]="false" [clear]="clearSite">
    </drdp-select-site>
  </div>

  <div class="col-span-1 z-40">
    <drdp-select-class (classroom)="handleChosenClassroom($event)" [siteIds]="siteId.value" [required]="isDropdownRequired.classroom"
              [ratingPeriodId]="ratingPeriodId.value" [multiSelect]="false">
    </drdp-select-class>
  </div>
  <div *ngIf="search" class="col-span-1 mt-10 flex items-center">
    <drdp-btn type="submit" (click)="submit()" class="mr-2" i18n>Search</drdp-btn>
    <drdp-btn type="button" (click)="clear()" color="outline" i18n>Clear</drdp-btn>
  </div>
</ng-container>
