import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClassService } from '@core/services/class.service';
import { AuthService } from '@core/services/auth.service';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { SelectType } from '@core/enums/select';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-classroom-rating-period-params',
  templateUrl: './classroom-rating-period-params.component.html',
  styleUrls: ['./classroom-rating-period-params.component.scss'],
})
export class ClassroomRatingPeriodParamsComponent implements OnInit {
  @Input() search: boolean = false;
  @Input() omitFutureRps: boolean = false;
  @Input() isDropdownRequired = {
    ratingPeriod: false,
    site: false,
    classroom: false
  };
  @Output() formValues = new EventEmitter();
  @Output() searchValues = new EventEmitter();

  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;

  user = this.auth.getCurrentUser();
  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  selectForm: FormGroup | any;
  classroomList: IClassroomAssignment[] = [];
  ratingPeriodList: IRatingPeriod[] = [];
  select = SelectType;
  clearState = false;
  clearAgency = false;
  clearSite = false;
  isSuperUser: boolean = this.auth.isSuperUser();

  get stateId() {
    return this.selectForm.get('stateId');
  }

  get agencyId() {
    return this.selectForm.get('agencyId');
  }
  get siteId() {
    return this.selectForm.get('siteId');
  }
  get ratingPeriodId() {
    return this.selectForm.get('ratingPeriodId');
  }
  get classroomId() {
    return this.selectForm.get('classroomId');
  }
  get ratingPeriodMinDate() {
    return this.selectForm.get('ratingPeriodMinDate');
  }
  get ratingPeriodMaxDate() {
     return this.selectForm.get('ratingPeriodMaxDate');
  }

  constructor(
    private auth: AuthService,
    private ratingPeriodService: RatingPeriodService,
    private classService: ClassService,
    private toastService: ToastService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.selectForm = this.fb.group({
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null, [Validators.required]],
      ratingPeriodId: [null],
      siteId: [null],
      classroomId: [null],
      ratingPeriodMinDate: [null],
      ratingPeriodMaxDate: [null]
    });
  }

  handleState(state: any) {
    this.stateId.setValue(state.id);
    this.agencyId.setValue(null);
    this.formValues.emit(this.selectForm.value);
  }

  handleChosenAgency(agency: any): void {
    this.agencyId.setValue(agency?.id);
    this.formValues.emit(this.selectForm.value);
  }

  handleChosenSite(site: any): void {
    this.siteId.setValue(site?.id);
    if (this.classroomId.value) this.classroomId.setValue(null);
    this.formValues.emit(this.selectForm.value);
  }

  handleChosenClassroom(classroom: any): void {
    this.classroomId.setValue(classroom?.id);
    this.formValues.emit(this.selectForm.value);
  }

  handleChosenRatingPeriod(ratingPeriod: IRatingPeriod): void {
    this.ratingPeriodId.setValue(ratingPeriod?.id);
    this.ratingPeriodMinDate.setValue(new Date(ratingPeriod.minDate));
    this.ratingPeriodMaxDate.setValue(new Date(ratingPeriod.maxDate));
    if (this.classroomId.value) this.classroomId.setValue(null);
    this.formValues.emit(this.selectForm.value);
  }


  clear(): void {
    this.sitesSelect?.clear();
    this.classroomSelect?.clear();
    this.ratingPeriodSelect?.clear();
    this.clearSite = true;

    if (this.isSuperUser) {
      this.agencySelect?.clear();
      this.stateSelect?.clear();
      this.clearState = true;
      this.clearAgency = true;
    }
  }

  submit(): void {
    if (this.selectForm.invalid) {
      this.toastService.error($localize `Please enter all required fields. `);
      return;
    }
    this.searchValues.emit(this.selectForm.value);
  }
}
