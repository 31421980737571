<div class="p-5">
  <div class="flex flex-row">
    <drdp-title title="Total Locked Children Summary" i18n-title></drdp-title>
  </div>
  <drdp-container additionClasses="w-full">
    <drdp-container color="lightBlue">
        <form [formGroup]="lockedForm">
      <div class="p-3 flex grid md:grid-cols-5 gap-2 flex-row items-end">
        <drdp-select-state
          #stateSelect
          [stateId]="stateId.value"
          [initialStateId]="initStateId"
          (state)="handleState($event)"
          [required]="true"
          [clear]="clearState"
          [disabled]="!hasAccessToAll"
          [invalid]="true">
      </drdp-select-state>

      <div class="col-span-1 self-end">
        <drdp-select-agency
          #agencySelect
          (agency)="handleAgency($event)"
          [stateId]="stateId.value"
          [initialAgencyId]="initAgencyId"
          [required]="true"
          [clear]="clearAgency"
          [disabled]="!accessAssignedStateAgencies">
        </drdp-select-agency>
      </div>

      <div class="col-span-1 self-end">
        <drdp-select-rating-period
            #ratingPeriodSelect
            [displayCurrentRpsByStartEnd]="true"
            [initialRatingPeriodId]="ratingPeriodId.value"
            (ratingPeriod)="handleRatingPeriod($event)"
            [agencyId]="agencyId.value">
      </drdp-select-rating-period>
        </div>

        <div class="col-span-1 self-end">
            <drdp-select-site
                #sitesSelect
                (site)="handleSite($event)"
                [agencyId]="agencyId.value"
                [multiSelect]="false"
                [required]="false"
                [multiSelectInitVals]="false"
                [clear]="clearDropdown">
            </drdp-select-site>
          </div>

          <div class="col-span-1 self-end">
            <drdp-select-class
            (classroom)="handleChosenClassroom($event)"
            [siteIds]="siteId.value"
            [required]="false"
            [ratingPeriodId]="ratingPeriodId.value"
            [clear]="clearDropdown"
            [multiSelect]="false"
            [showPilot]="true">
          </drdp-select-class>
          </div>

          <div class="col-span-1 self-end">
            <drdp-select-age-grade
                #ageGroupInstrumentSelect
                label="Age Group/Instrument"
                [required]="false"
                [isPilot]="isPilot"
                [initialageGradeId]="ageGradeInstrumentId.value"
                (ageGrade)="handleAgeGrade($event)"
                [multiSelect]="false">
            </drdp-select-age-grade>
          </div>

          <div class="flex flex-row w-ful">
              <drdp-btn i18n class="self-end" color="outline" (click)="clear()"> Clear </drdp-btn>
              <drdp-btn class="flex-grow self-end pl-3" type="submit">
                <span i18n class="px-2" (click)="search()">Search</span>
              </drdp-btn>
          </div>
      </div>
      </form>
    </drdp-container>
    <div>
        <drdp-add-edit-table
          class="whitespace-pre-wrap"
          [dataSource]="dataSource"
          [tableData]="tableData"
          [columns]="tableColumns"
          (actionIconClicked)="onIconClick($event)">
        </drdp-add-edit-table>
      </div>
  </drdp-container>
  <mat-paginator
    class="mt-5 pb-3"
    [length]="totalData"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
  </mat-paginator>
</div>
