<div class="w-full p-5">

    <drdp-title title="Upload Class" i18n-title></drdp-title>

    <drdp-container [additionClasses]="'min-h-[44rem]'">
        <div class="w-full ">
            <mat-card [ngClass]="cardColor">
                <mat-card-content>
                    <div class="flex justify-between">
                        <p class="pt-2" [innerHTML]="cardText"></p>
                        <drdp-btn i18n *ngIf="!isFormValidated && !isFormValid && isHeaderValid" (click)="checkFile()">Check
                            File</drdp-btn>
                        <drdp-btn i18n *ngIf="isFormValidated && isFormValid" (click)="save()">Submit</drdp-btn>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="w-full">
                <drdp-btn class="float-right pt-10" (click)="uploadNewFile()" color="cancel"><i
                        class="uil uil-plus text-lg"></i><span i18n>Upload New File</span></drdp-btn>
            </div>
            <drdp-upload-table (onDataChange)="onDataChange($event)" [dataSource]="dataSource" [columns]="headerData">
            </drdp-upload-table>
        </div>
    </drdp-container>
    <div class="uploads-pagination">
        <mat-paginator [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
    </div>
</div>