import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { AgeGradeTemplateService } from '@core/services/age-grade-template.service';
import { ToastService } from '@core/services/toast.service';
import { take, tap, combineLatest, Observable, Subscription } from 'rxjs';
import { LookupService } from '@core/services/lookup.service';
import { AgeGradeTemplateEnrollmentService } from '@core/services/age-grade-template-enrollment.service.ts';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { IRatingBaseTemplate } from '../interfaces/irating-base-template';
import { IBaseTemplateDomain } from '../interfaces/ibase-template-domain';
import { ICustomRatingTemplateForm } from '../interfaces/icustom-rating-template-form';
import { ITemplateMeasure } from '../interfaces/itemplate-measure';

@Component({
  selector: 'drdp-base-template',
  templateUrl: './base-template.component.html',
  styleUrls: ['./base-template.component.scss'],
})
export class BaseTemplateComponent implements OnInit {
  pageTitle = $localize `Base Template View`;
  isView = false;
  customViewForm: FormGroup | any;
  ageGradeInstruments: IAgeGradeInstrument[] = [];
  baseTemplates: IRatingBaseTemplate[] = [];
  ageGradeBaseTemplates: IRatingBaseTemplate[] = [];
  templateDomains: IBaseTemplateDomain[] = [];
  selectedAgencyId = 0;
  customTemplate?: ICustomRatingTemplateForm;
  lookups$?: Observable<{
    ageGradeInstruments: IAgeGradeInstrument[];
    baseTemplates: IRatingBaseTemplate[];
  }>;
  subscriptions = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastService,
    private lookupService: LookupService,
    private templateService: AgeGradeTemplateService,
    private router: Router
  ) {
    this.selectedAgencyId =
      this.router.getCurrentNavigation()?.extras?.state?.['agencyId'] || 0;
  }

  get title() {
    return this.customViewForm.get('title');
  }
  get description() {
    return this.customViewForm.get('description');
  }
  get ageGradeInstrumentId() {
    return this.customViewForm.get('ageGradeInstrumentId');
  }
  get ageGradeTemplateId() {
    return this.customViewForm.get('ageGradeTemplateId');
  }
  get displayMeasures() {
    return this.templateDomains.length > 0;
  }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  initForm() {
    this.customViewForm = this.formBuilder.group({
      title: [null, [Validators.required, Validators.maxLength(250)]],
      description: [null, [Validators.required, Validators.maxLength(500)]],
      ageGradeInstrumentId: [null, Validators.required],
      ageGradeTemplateId: [null, Validators.required],
    });

    this.subscriptions.add(
      this.ageGradeInstrumentId.valueChanges.subscribe((val: number) => {
        if (!val) {
          return;
        }

        this.templateService
          .getBaseMeasuresByInstrument(val)
          .pipe(
            take(1),
            tap((results) => {
              this.templateDomains = results;
            })
          )
          .subscribe();
      })
    );
  }

  initData() {
    this.lookups$ = combineLatest({
      ageGradeInstruments: this.lookupService.getAgeGradeInstruments(0),
      baseTemplates: this.templateService.getAvailBaseTemplates(),
    });

    this.subscriptions.add(
      this.lookups$.subscribe((results) => {
        this.ageGradeInstruments = results['ageGradeInstruments'];
        this.baseTemplates = results['baseTemplates'];
        this.ageGradeBaseTemplates = this.baseTemplates;
      })
    );
  }

  submitForm() {
    if (this.customViewForm.invalid) {
      this.toast.error($localize `Please enter all required fields.`);
      return;
    }

    let selectedMeasures: ITemplateMeasure[] = [];
    this.templateDomains.forEach((domain) => {
      domain.measures.forEach((m) => {
        if (m.isSelected) {
          selectedMeasures.push(m);
        }
      });
    });

    if (selectedMeasures.length == 0) {
      this.toast.error($localize `There's no measures selected. `);
      return;
    }

    const payload = {
      ...this.customViewForm.value,
      agencyId: this.selectedAgencyId,
      selectedMeasures: selectedMeasures,
    };

    this.templateService
      .saveBaseTemplate(payload)
      .pipe(
        take(1),
        tap((result: number) => {
          this.router.navigateByUrl('/config/rating-views', {
            state: { agencyId: this.selectedAgencyId },
          });
        })
      )
      .subscribe();
  }

  checkDomainSelected(domain: IBaseTemplateDomain) {
    let nonRequired = domain.measures.filter((d) => !d.isRequired);
    nonRequired.forEach((n) => (n.isSelected = domain.isAllSelected));
  }

  checkDomainAllSelected(domain: IBaseTemplateDomain) {
    domain.isAllSelected =
      domain.measures.filter((d) => !d.isSelected).length == 0;
  }

  ngOnDestroy() {
    if (this.subscriptions) this.subscriptions?.unsubscribe();
  }
}
