<div class="w-max">
  <div class="flex justify-between border-b">
      <h1 i18n class="text-[25px] font-bold">Assign Enrollment</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <div class="h-full flex flex-col justify-between">
      <drdp-filter-select #ageGradeSelect label="Active Enrollment" i18n-label [options]="availableOptions"
        [selectType]="select.ActiveEnrollmentClass" [required]="true"
        [tooltipActive]="true"
        (onSelected)="onSelect($event)">
      </drdp-filter-select>
    </div>

  </div>
  <div mat-dialog-actions class="flex justify-end">
      <drdp-btn i18n type="button" mat-dialog-close class="mr-2">Cancel</drdp-btn>
      <drdp-btn i18n type="submit" (click)="assignEvidence()">Assign</drdp-btn>
  </div>
</div>
