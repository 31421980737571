import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { Subscription, take } from 'rxjs';
import { matchPassword } from '@core/validators/auth.validator'
import { EncryptionService } from '@core/services/encryption.service';
@Component({
  selector: 'drdp-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private subscriptions = new Subscription();

  createPassword: boolean = false;
  modalHeader: string = $localize `Reset Password?`;
  resetPwForm: FormGroup | any;
  hidden: boolean = true;
  resetFailed = false;
  confirmHidden: boolean = true;
  passwordRequirements: string[] = [$localize `- 8 characters in length`,
                                    $localize `- Contain at least one capital letter`,
                                    $localize `- Contain at least one lowercase letter`,
                                    $localize `- Contain at least one number`,
                                    $localize `- Contain at least 1 special character (@,$,%,*,!)`];

  get newPassword() { return this.resetPwForm.get('newPassword'); }
  get confirmPassword() { return this.resetPwForm.get('confirmPassword'); }
  get inputType() { return this.hidden ? "password" : "text"; }
  get confirmInputType() { return this.confirmHidden ? "password" : "text"; }


  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private toast: ToastService,
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public userId: number,
    private encryptionService: EncryptionService) {
      dialogRef.disableClose = true;
    }

  ngOnInit(): void {
    this.resetPwForm = this.fb.group({
      newPassword: ['', [
          Validators.required,
          Validators.minLength(8),
          this.patternValidator(/\d/, { hasNumber: false }),
          this.patternValidator(/[A-Z]/, { hasCapitalCase: false }),
          this.patternValidator(/[a-z]/, { hasSmallCase: false}),
          this.patternValidator(/[!@#\$%\^&\*`~]/, { hasSpecialCharacters: false }),
        ]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: matchPassword('newPassword', 'confirmPassword') });
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  resetConfirm(): void {
    this.subscriptions.add(this.auth.adminResetPassword(this.userId).subscribe(success => {
      if (!success) {
        this.toast.error($localize `Could not reset password`)
      } else {
        this.toast.success($localize `Password has been reset`)
      }
      this.dialogRef.close(success);
    }));
  }

  resetCreate(): void {
    this.createPassword = true;
    this.modalHeader = $localize `Change Password`
  }

  updatePassword(): void {
    const payload = {
      userId: this.userId,
      oldPassword: this.encryptionService.encryptPassword(`Reset`),
      newPassword: this.encryptionService.encryptPassword(this.newPassword.value),
      isAdminUpdate: true,
    }

    this.auth.updatePassword(payload, $localize `Password has been updated.`).pipe(take(1)).subscribe((success) => {

    });
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
