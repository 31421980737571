import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { SelectType } from '@core/enums/select';
import { ISelectable } from '@core/interfaces/iselectable';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'drdp-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  private eventsSubscription?: Subscription;
  @ViewChild('select', { read: ElementRef }) selectElement:
    | ElementRef
    | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() label = '';
  @Input() tooltip: string | undefined;
  @Input() multiSelect: boolean = false;
  @Input() options: any;
  @Input() selectType: SelectType | undefined;
  @Input() search?: Observable<boolean>;
  @Input() initialValues?: any = this.multiSelect ? [] : null; //should be used for both single and multi
  @Input() required = false;
  @Input() disabled = false;
  @Input() isValid = true;
  @Input() additionalClasses = '';
  @Input() tooltipActive = false;
  @Output() onSelected = new EventEmitter();
  @Input() overrideLabel: string | null = null;
  @Input() showPilot?: boolean = false;

  selected: any = [];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData: number = 0;
  itemsInPage: any[] = [];
  selectable: ISelectable[] = [];
  filterText: string = '';
  selectFormGroup: FormGroup | any;

  get selectedOptionControl() {
    return this.selectFormGroup.get('selectedOptionControl');
  }

  public get selectLabel() {
    if (this.options?.length < 1) {
      let newLabel = this.label;
      let pluralSuffix = this.label.endsWith('s') ? '' : 's';
      if (this.label.endsWith('y')) {
        pluralSuffix = 'ies';
        newLabel = this.label.slice(0, -1);
      }
      return $localize `No ${newLabel}${pluralSuffix} available`;
    } else if (this.multiSelect && this.initialValues?.length > 0) {
      return $localize `Selected ` + `${ this.label }: ${this.initialValues?.length}`;
    } else {
      return this.overrideLabel ? $localize `Select ` + `${this.overrideLabel}` : $localize `Select ` + `${this.label}`;
    }
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.eventsSubscription = this.search?.subscribe((clear) =>
      this.clearSearch(clear)
    );
    this.selectFormGroup = this.fb.group({
      selectedOptionControl: new FormControl({ value: this.initialValues, disabled: this.disabled })
    });
  }

  clearSelected() {
    this.selected = this.multiSelect ? [] : null;
    this.filterText = '';
    this.filterOptions(this.filterText);
    this.selectedOptionControl.setValue(this.selected);
    this.onSelected.emit(this.selected);
  }

  clear(): void {
    this.selected = [];
    this.filterText = '';
    this.filterOptions(this.filterText);
  }

  clearAll(): void {
    this.selected = this.multiSelect ? [] : null;
    this.filterText = '';
    this.filterOptions(this.filterText);
  }

  clearSearch(clearSearch: boolean): void {
    if (clearSearch) {
      this.clear();
      this.clearAll();
    }
  }

  clearFilter(): void {
    this.filterText = '';
    this.filterOptions(this.filterText);
  }

  ngOnChanges(): void {
    if (this.options) {
      this.toSelectable();
      this.totalData = this.selectable.length;
      this.filterOptions(this.filterText);

      if ((this.initialValues === 0 || this.initialValues) && this.selectable.length > 0) {
        if (this.multiSelect) {
          if (Array.isArray(this.initialValues) && this.selected.length > 0) {
            const idsExistInObjects = this.initialValues.some((id) =>
              this.selectable.some((obj) => obj.id === id)
            );
            if (idsExistInObjects)
              this.goToDefaultItemPage(this.initialValues[0]);
          }

          this.selected = this.selectable.filter((x) =>
            this.initialValues.includes(x.id)
          );
          this.selectedOptionControl.setValue(this.selected);
        } else {
          let index = this.selectable.findIndex(
            (s) => s.id === this.initialValues
          );
          if (index > 0) this.goToDefaultItemPage(this.initialValues);
          this.selected = this.selectable.filter(
            (x) => x.id == this.initialValues
          )[0];
          this.selectedOptionControl.setValue(this.selected);
        }
      }
    }
  }

  goToDefaultItemPage(itemId: number): void {
    var itemPosition = this.selectable.findIndex((s) => s.id === itemId) + 1;
    var pageNumber = Math.ceil(itemPosition / this.pageSize);
    this.pageIndex = pageNumber - 1;
  }

  toSelectable(): void {
    let selectName = '';

    if (this.selectType) {
      selectName = this.selectType;

      this.selectable = this.options.map((item: any) => {
        return <ISelectable>{
          id: item.id,
          name: item[selectName],
          type: this.selectType,
          ...(this.showPilot && { isPilot: item.isPilot ?? null })
        };
      });
    }
  }

  onFilterChange(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    this.filterOptions(inputValue);
  }

  filterOptions(value: string) {
    const keyword = value.toUpperCase();
    const filteredOptions = this.selectable.filter((x: any) =>
        x.name && x.name.toUpperCase().includes(keyword)
    );

    this.pageIndex = 0;
    this.totalData = filteredOptions.length;
    this.itemsInPage = this.paginate(filteredOptions);

    // if (this.selected && this.selected.length > 0) {}
    //   this.selected = this.selectable.filter((x) =>
    //     this.selected.map((x: { id: number }) => x.id).includes(x.id)
    //   );
  }

  toggleOption(selected: boolean) {
    if (selected) {
      this.selected = [...this.selectable];
      this.selectedOptionControl.setValue(this.selected);
    } else {
      this.selected = [];
      this.selectedOptionControl.setValue(this.selected);
    }
    this.onSelected.emit(this.selected);
  }

  compareWith(a: unknown, b: unknown): boolean {
    if (!a || !b) return false;
    return JSON.stringify(a) === JSON.stringify(b);
  }

  paginate(selectables: ISelectable[]) {
    return selectables.reduce(
      (arr: any, items: any, i: number) =>
        (i % this.pageSize
          ? arr[arr.length - 1].push(items)
          : arr.push([items])) && arr,
      []
    );
  }

  page(): void {
    this.pageIndex = this.paginator.pageIndex;

    // if (this.selected && this.selected.length > 0)
    //   this.selected = this.selectable.filter((x) =>
    //     this.selected.map((x: { id: number }) => x.id).includes(x.id)
    //   );
  }

  handleOption(item: any) {
    let selectedOption = this.selectable.find((s) => s.id == item.id);
    if (this.multiSelect) {
      const itemIndex = this.selected.indexOf(selectedOption);
      if (itemIndex !== -1) {
        this.selected.splice(itemIndex, 1);
      } else {
        this.selected.push(selectedOption);
      }
      this.selectedOptionControl.setValue(this.selected);
    } else {
      if (this.selected && item.id === this.selected.id) {
        this.selected = null;
        this.selectedOptionControl.setValue(this.selected);
        this.clearFilter();
      } else {
        this.selected = selectedOption;
        this.selectedOptionControl.setValue(this.selected);
      }
    }

    this.onSelected.emit(this.selected);
  }

  onClose() {
    this.filterText = '';
    this.filterOptions(this.filterText);
    if (this.multiSelect) {
      if (
        this.selected &&
        Array.isArray(this.selected) &&
        this.selected.length > 0
      ) {
        this.goToDefaultItemPage(this.selected[0].id);
      }
    } else {
      if (this.selected) {
        let id =
          Array.isArray(this.selected) && this.selected.length > 0
            ? this.selected[0].id
            : this.selected.id;

        if (id) {
          this.goToDefaultItemPage(id);
        }
      }
    }
  }

  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
  }
}
