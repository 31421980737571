import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ClassReleaseReason, StudentRequestReason } from "@core/enums/student-request-reason";
import { LookupService } from "@core/services/lookup.service";
import { StudentRequestService } from "@core/services/student-request.service";
import { ToastService } from "@core/services/toast.service";
import { Observable, take } from "rxjs";


@Component({
    selector: 'drdp-release-student',
    templateUrl: './request-release-student.component.html',
    styleUrls: ['./request-release-student.component.scss'],
})
export class ReleaseStudentComponent implements OnInit {
    child: any;
    releaseStudentForm: FormGroup | any;
    releaseReasons$?: Observable<any>;
    isOther: boolean = false;

    get classReleaseReasonId() {
        return this.releaseStudentForm.get('classReleaseReasonId');
    }
    get comment() {
        return this.releaseStudentForm.get('comment');
    }

    constructor(
        public dialogRef: MatDialogRef<ReleaseStudentComponent>,
        private lookup: LookupService,
        private toastService: ToastService,
        private studentRequestService: StudentRequestService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.initializeForm();
        this.getReleaseReasons();
    }

    initializeForm(): void {
        this.releaseStudentForm = this.fb.group({
            classReleaseReasonId: [null, Validators.required],
            comment: [null],
        });

        this.child = this.data.data;
    }

    getReleaseReasons() {
        this.releaseReasons$ = this.lookup.getClassReleaseReasons();
    }

    getSelectedReleaseReason(event: any) {
        if (event.id === ClassReleaseReason.Other) {
            this.isOther = true;
        }
        else {
            this.isOther = false;
            this.comment.setValue(null);
        }
    }

    onSubmit() {
        if (this.releaseStudentForm.invalid) {
            this.toastService.error($localize `:requestReleaseStudentDialog:Please enter all required fields.`);
            return;
        }

        let payload: any = this.releaseStudentForm.value;
        payload.studentId = this.child.id;
        payload.fromClassId = this.child.classId;
        payload.requestReasonId = StudentRequestReason.Release;
        payload.siteId = this.child.siteId;

        const successMessage = $localize `:requestReleaseStudentDialog:Release request has been sent.`;

        this.studentRequestService
          .addStudentRequest(payload, successMessage)
          .pipe(take(1))
          .subscribe((r) => {
            this.dialogRef.close({ success: true });
          });
    }

}
