import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISchoolYear } from '@core/interfaces/iratingperiod';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-add-schoolyear',
  templateUrl: './add-schoolyear.component.html',
  styleUrls: ['./add-schoolyear.component.scss'],
})
export class AddSchoolyearComponent implements OnInit {
  schoolYearForm: FormGroup | any;
  latestSchoolYear!: number;

  get schoolYearName() {
    return this.schoolYearForm.get('schoolYearName');
  }
  get schoolYearDescription() {
    return this.schoolYearForm.get('schoolYearDescription');
  }
  get isCurrent() {
    return this.schoolYearForm.get('isCurrent');
  }

  constructor(
    public dialogRef: MatDialogRef<AddSchoolyearComponent>,
    private fb: FormBuilder,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.latestSchoolYear = parseInt(this.data.schoolYearDescription);
    this.initializeForms();
  }

  initializeForms(): void {
    this.schoolYearForm = this.fb.group({
      schoolYearName: [
        null,
        [Validators.required, Validators.pattern('\\d{4}-\\d{2}')],
      ],
      schoolYearDescription: [null],
      isCurrent: [false],
    });
  }

  onSubmit(): any {
    if (this.schoolYearForm.invalid) {
      this.toast.error($localize `Inputted year is not valid.`);
      return;
    }
    var inputYear = parseInt(this.schoolYearName.value?.substring(0, 4));
    var beginRange = parseInt(this.schoolYearName.value?.substring(2, 4));
    var endRange = parseInt(this.schoolYearName.value?.substring(5, 7));
    var rangeDifference = endRange - beginRange;

    if (inputYear != this.latestSchoolYear || rangeDifference > 1) {
      this.toast.error($localize `Inputted year is not valid.`);
    } else {
      this.schoolYearDescription.setValue(
        this.schoolYearName.value.substring(0, 2) +
          this.schoolYearName.value.substring(5, 7)
      );

      let payload: ISchoolYear = this.schoolYearForm.value;
      this.dialogRef.close({ formValid: true, payload: payload });
    }
  }
}
