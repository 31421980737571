import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IStudent,
  IStudentClassEnrollmentDetails,
  IStudentDetails,
} from '@core/interfaces/istudent';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { PortfolioService } from '@core/services/portfolio.service';
import { filter, take, tap, switchMap, forkJoin } from 'rxjs';
import {
  IPortfolioClassResponse,
  IPortfolioEvidenceResponse,
} from '@core/interfaces/iportfolio';
import { AgeGradeInstrument } from '@core/enums/age-grade-instruments';
import { PortfolioMeasureType } from '@core/enums/portfolio';
import { MatDialog } from '@angular/material/dialog';
import { addHTTPS } from '@core/services/helper.service';
import { PortfolioMessageBoxComponent } from '../portfolio-message-box/portfolio-message-box.component';
import { LookupService } from '@core/services/lookup.service';
import { IBaseTemplateDomain } from '@views/user/configuration/rating-view-setup/interfaces/ibase-template-domain';
import { IStudentActiveEnrollmentInfo } from '@views/user/input-rating/interfaces/istudent-enrollment-info';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-portfolio-review-evidence',
  templateUrl: './portfolio-review-evidence.component.html',
  styleUrls: ['./portfolio-review-evidence.component.scss'],
})
export class PortfolioReviewEvidenceComponent implements OnInit {
  domainBg = 'black';
  measuresBg = 'white';
  showDomain = true;
  classEnrollmentDetails?: IStudentClassEnrollmentDetails;
  studentDetails?: IStudentDetails;
  studentActiveEnrollments?: IStudentActiveEnrollmentInfo[];
  studentId?: number;
  classDetails?: IPortfolioClassResponse;
  ageGroupInstrumentName?: string;
  measures?: any;
  show = false;
  selectedDomain = '';
  selectedMeasure = '';
  domainEvidence?: IPortfolioEvidenceResponse[];
  measureEvidence?: IPortfolioEvidenceResponse[];
  measurePrelimRatings?: IPortfolioEvidenceResponse[] = [];
  classId?: number;
  studentClassEnrollmentId?: number;
  ageGradeId?: number;
  previousPage = '';
  portfolioId!: number;
  isFutureEvidence: boolean = false;
  futureEvidenceId?: number | null;
  globalRatingPeriodName: string = '';
  isPilot: boolean = false;
  assignmentPayload = {
    studentId: 0,
    ageGradeInstrumentId: 0,
    futureEvidenceId: 0
  };

  public get measureType() {
    return PortfolioMeasureType;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private classEnrollmentService: StudentClassEnrollmentService,
    private portfolioService: PortfolioService,
    public modal: MatDialog,
    private lookup: LookupService,
    private toast: ToastService
  ) {}

  ngOnInit(): void {
    this. futureEvidenceId = Number(this.route.snapshot.paramMap.get('futureEvidenceId')) || null;
    let classId = this.route.snapshot.paramMap.get('classId');
    let ageGradeId = this.route.snapshot.paramMap.get('ageGradeId');
    this.previousPage = `portfolio/class/${classId}/ageGradeInstrument/${ageGradeId}`;
    this.assignmentPayload.ageGradeInstrumentId = Number(ageGradeId);
    if (this.futureEvidenceId) {
      this.classId = classId ? +classId : undefined;
      this.ageGradeId = ageGradeId ? +ageGradeId : undefined;
      this.loadFutureEvidenceData();
    }
    else {
      let studentClassEnrollmentId = this.route.snapshot.paramMap.get(
        'studentClassEnrollmentId'
      );
      this.route.queryParams.subscribe((params) => {
        this.portfolioId = params['portfolioId'];
      });

      if (studentClassEnrollmentId) {
        this.studentClassEnrollmentId = +studentClassEnrollmentId;
        this.getStudentDetails(this.studentClassEnrollmentId);
      }

      if (classId && ageGradeId) {
        this.classId = +classId;
        this.ageGradeId = +ageGradeId;
        this.portfolioService
          .getPortfolioClass(this.classId, this.ageGradeId)
          .pipe(take(1))
          .subscribe((res: IPortfolioClassResponse) => {
            if (res) {
              this.classDetails = res;
              this.ageGroupInstrumentName = res.ageGradeInstrumentName;
            }
          });
      }

      if (this.studentClassEnrollmentId && this.ageGradeId) {
        this.getDomainEvidence(this.studentClassEnrollmentId, this.ageGradeId);
        this.getMeasureEvidence(this.studentClassEnrollmentId, this.ageGradeId);
      }
    }
  }

  loadFutureEvidenceData(): void {
    this.isFutureEvidence = true;
    const studentId = this.route.snapshot.paramMap.get('studentId');
    const futureEvidenceId = this.route.snapshot.paramMap.get('futureEvidenceId');

    if (studentId && futureEvidenceId) {
      this.studentId = +studentId;
      this.futureEvidenceId = +futureEvidenceId;
      this.assignmentPayload.studentId = this.studentId;
      this.assignmentPayload.futureEvidenceId = this.futureEvidenceId;
      forkJoin([
        this.portfolioService.getFutureEvidencesById(this.futureEvidenceId),
        this.portfolioService.getPortfolioStudent(this.studentId),
        this.classEnrollmentService.getStudentActiveActiveEnrollments(this.studentId)
      ]).pipe(take(1))
        .subscribe(([evidences, studentDetails, activeEnrollments]) => {
          this.domainEvidence = evidences.domainEvidences;
          this.measureEvidence = evidences.measureEvidences;
          if (evidences.domainEvidences.length == 0 && evidences.measureEvidences.length == 0) {
            this.toast.error('No Future Evidence available.');
            if (this.classId && this.ageGradeId) {
              this.router.navigate([`portfolio/class/${this.classId}/ageGradeInstrument/${this.ageGradeId}`]);
            }
          }

          this.studentDetails = studentDetails as any;
          this.studentActiveEnrollments = activeEnrollments;

          this.domainEvidence?.forEach(evidence =>
            evidence.url ? evidence.url = addHTTPS(evidence.url) : '');
          this.measureEvidence?.forEach(evidence =>
            evidence.url ? evidence.url = addHTTPS(evidence.url) : '');
        });
    }

    this.lookup.getBaseMasterMeasures()
      .pipe(take(1))
      .subscribe((res: IBaseTemplateDomain[]) => { 
        if (this.isFutureEvidence) {
          this.measures = res.filter(measure => !measure.onlyPilot);
        } else {
          this.measures = res;
        }
      });
  }

  getDomainEvidence(
    studentClassEnrollmentId: number,
    ageGradeId: number
  ): void {
    this.portfolioService
      .getDomainEvidences(studentClassEnrollmentId, ageGradeId)
      .pipe(
        take(1),
        tap((res: IPortfolioEvidenceResponse[]) => (this.domainEvidence = res)),
        tap((_) =>
          this.domainEvidence?.forEach((evidence: IPortfolioEvidenceResponse) =>
            evidence.url ? (evidence.url = addHTTPS(evidence.url)) : ''
          )
        )
      )
      .subscribe();
  }

  getMeasureEvidence(
    studentClassEnrollmentId: number,
    ageGradeId: number
  ): void {
    this.measureEvidence = [];
    this.portfolioService
      .getMeasureEvidences(studentClassEnrollmentId, ageGradeId)
      .pipe(
        take(1),
        tap(
          (res: IPortfolioEvidenceResponse[]) => (this.measureEvidence = res)
        ),
        tap((_) =>
          this.measureEvidence?.forEach(
            (evidence: IPortfolioEvidenceResponse) =>
              evidence.url ? (evidence.url = addHTTPS(evidence.url)) : ''
          )
        ),
        tap((_) => this.getMeasurePrelimRating())
      )
      .subscribe();
  }

  updateFutureEvidence() {
    this.portfolioService.getFutureEvidencesById(this.assignmentPayload.futureEvidenceId!)
    .pipe(take(1))
    .subscribe(evidences=> {
      this.domainEvidence = evidences.domainEvidences;
      this.measureEvidence = evidences.measureEvidences;
    });
  }

  updatePortfolioEvidence() {
    if (this.studentClassEnrollmentId && this.ageGradeId) {
      this.getDomainEvidence(
        this.studentClassEnrollmentId,
        this.ageGradeId
      );
      this.getMeasureEvidence(
        this.studentClassEnrollmentId,
        this.ageGradeId
      );
    }
  }
  updateEvidence(): void {
      if (this.isFutureEvidence) {
        this.updateFutureEvidence();
      } else {
        this.updatePortfolioEvidence();
      }
  }

  getEvidenceCount(domainMeasure: any, type: number): string {
    let evidenceItems: IPortfolioEvidenceResponse[] | undefined;
    switch (type) {
      case PortfolioMeasureType.Domain:
        evidenceItems = this.domainEvidence?.filter(
          (evidence) => evidence.domainId === domainMeasure.id
        );
        break;
      case PortfolioMeasureType.Measure:
        evidenceItems = this.measureEvidence?.filter(
          (evidence) => evidence.measureId === domainMeasure.id
        );
        break;
    }

    const count = evidenceItems?.length || 0;
    const pluralSuffix = count === 1 ? '' : 's';
    return `${count} ` + $localize `Evidence Item` + `${pluralSuffix}`;
  }

  handleDomain(): void {
    this.showDomain = true;
    this.show = false;
    this.domainBg = 'black';
    this.measuresBg = 'white';

    if (this.studentClassEnrollmentId && this.ageGradeId) {
      this.getDomainEvidence(this.studentClassEnrollmentId, this.ageGradeId);
    }
  }

  handleMeasures(): void {
    this.showDomain = false;
    this.show = false;
    this.domainBg = 'white';
    this.measuresBg = 'black';

    if (this.studentClassEnrollmentId && this.ageGradeId) {
      this.getMeasureEvidence(this.studentClassEnrollmentId, this.ageGradeId);
    }
  }

  changeDomainShow(domainCode: string): void {
    this.show = !this.show;
    this.selectedDomain = domainCode;
  }

  changeMeasureShow(measureCode: string): void {
    this.show = !this.show;
    this.selectedMeasure = measureCode;
  }

  getStudentDetails(studentClassEnrollmentId: number): void {
    this.classEnrollmentService
      .getStudentClassEnrollment(studentClassEnrollmentId)
      .pipe(
        take(1),
        tap((res: any) => {
          this.classEnrollmentDetails = res;
          this.studentId = this.classEnrollmentDetails?.studentId;
        }),

        filter((_) => !!this.studentId), // check if studentId exists, if not stop observable stream
        switchMap((_) =>
          this.portfolioService.getPortfolioStudent(this.studentId!)
        ),
        tap((res: any) => {
          this.studentDetails = res;
          this.isPilot = this.studentDetails?.isPilotTest ?? false;
        }),

        filter((_) => !!this.studentDetails), // check if studentDetails exists, if not stop observable stream
        switchMap((_) =>
          this.portfolioService.getBaseMeasures(
            this.ageGradeId!
          )
        ),
        tap((res: any) => {
          this.measures = res;
        })
      )
      .subscribe();
  }

  getInstrumentName(instrumentId: number): string {
    switch (instrumentId) {
      case AgeGradeInstrument.infantToddler:
        return 'Infant Toddler';
      case AgeGradeInstrument.kindergarten:
        return 'Kindergarten';
      case AgeGradeInstrument.preschool:
        return 'Preschool';
      case AgeGradeInstrument.schoolAge:
        return 'School Age';
      case AgeGradeInstrument.tnKindergarten:
        return 'TN Kindergarten';
      default:
        return '';
    }
  }

  getDescription(measureId: number, isDomain: boolean): string {
    if (!isDomain) {
      const currTab = this.measureEvidence?.find(
        (evidence: IPortfolioEvidenceResponse) =>
          evidence.measureId === measureId
      );
      const description = currTab ? currTab.description : '';
      return description;
    }
    return '';
  }

  getMeasurePrelimRating(): void {
    this.measurePrelimRatings = [];
    const measureIds = Array.from(
      new Set(this.measureEvidence?.map((measure) => measure.measureId))
    );

    measureIds.forEach((id) => {
      const measureEvidenceGroup = this.measureEvidence?.filter(
        (measure: IPortfolioEvidenceResponse) =>
          measure.measureId === id && (measure.createdOn || measure.updatedOn)
      );
      if (measureEvidenceGroup) {
        const latestEvidence = [...measureEvidenceGroup].sort((a, b) => {
          const aDate = new Date(a.updatedOn || a.createdOn);
          const bDate = new Date(b.updatedOn || b.createdOn);
          return bDate.getTime() - aDate.getTime();
        })[0]; // Return evidence with the most recent updatedDate or createdDate

        if (latestEvidence && latestEvidence.selectedLevel) {
          this.measurePrelimRatings?.push(latestEvidence);
        }
      }
    });
  }

  updateEvidencePrelimRating(event: any): void {
    this.portfolioService
      .getMeasureEvidences(this.studentClassEnrollmentId!, this.ageGradeId!)
      .pipe(
        take(1),
        tap((res: IPortfolioEvidenceResponse[]) => {
          const index = res.findIndex(
            (evidence: IPortfolioEvidenceResponse) => evidence.id === event.id
          );
          if (this.measureEvidence) {
            this.measureEvidence[index] = res[index];
            this.getMeasurePrelimRating();
          }
        })
      )
      .subscribe();
  }

  goBack() {
    this.router.navigateByUrl(this.previousPage);
  }

  onDownloadPdf() {
    if (this.showDomain) {
      this.portfolioService
        .generateDomainPdf(this.studentClassEnrollmentId!, this.ageGradeId!)
        .pipe(take(1))
        .subscribe((res) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileName = `${this.studentDetails?.firstName}_${this.studentDetails?.lastName}_domain_evidences`;
          link.download = `${fileName.replace(/ /g, '_')}.pdf`;
          link.click();
        });
    } else {
      this.portfolioService
        .generateMeasurePdf(this.studentClassEnrollmentId!, this.ageGradeId!)
        .pipe(take(1))
        .subscribe((res) => {
          const file = new Blob([res], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileUrl;
          let fileName = `${this.studentDetails?.firstName}_${this.studentDetails?.lastName}_measure_evidences`;
          link.download = `${fileName.replace(/ /g, '_')}.pdf`;
          link.click();
        });
    }
  }

  addToMessageBoard() {
    this.modal.open(PortfolioMessageBoxComponent, {
      data: {
        enrollmentId: this.studentClassEnrollmentId,
        studentName: this.studentDetails?.firstName + ' ' + this.studentDetails?.lastName,
        portfolioId: this.portfolioId,
      }
    });
  }
}
