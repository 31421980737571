import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IModalEmitData } from '@core/interfaces/imodal';
import { ISearchInput } from '@core/interfaces/isearch-input';
import { ISites } from '@core/interfaces/isites';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { SiteService } from '@core/services/site.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { Observable, Subscription, take } from 'rxjs';
import { AddEditSitesComponent } from './add-edit-sites/add-edit-sites.component';
import { Permission } from '@core/enums/permissions';
import { PermissionService } from '@core/services/permission.service';
import { Modal } from '@core/enums/modal';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
})
export class SitesComponent implements OnInit, OnDestroy {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  pageSizeOptions = [5, 10, 25];
  pageIndex = 1;
  pageSize = 10;
  totalData = 0;
  tableData?: any;
  searchObj?: any;
  siteSearchName?: string = '';
  siteType = 0;
  siteId = 0;

  hasAccessToAll = this.permissionService.checkPermission(Permission.AccessAllAgencies);
  hasAccessToAssigned = this.permissionService.checkPermission(Permission.AccessAssignedStateAgencies);
  isToggleActive = this.permissionService.checkPermission(Permission.DeactivateSites);
  isEditable: boolean = false;
  isDeletable: boolean = false;
  reload = false;

  data?: Observable<any>;
  num: number = 1;

  siteTypeInfo: any = [];

  user = this.authService.getCurrentUser();

  payload: any;
  initialPayload: any = {
    itemsPerPage: this.pageSize,
    pageIndex: this.pageIndex,
    agencyId: this.user.agencyId,
    stateId: this.user.stateId
  };

  tableColumns = [
    { columnDef: 'siteName', header: $localize `Site Name`, type: 'text' },
    { columnDef: 'cdsCode', header: $localize `Site Code`, type: 'text' },
    {
      columnDef: 'siteCodeTypeName',
      header: $localize `Site Code Type`,
      type: 'text',
    },
    { columnDef: 'agencyName', header: $localize `Agency`, type: 'text' },
    { columnDef: 'siteTypeName', header: $localize `Type`, type: 'text' },
    {
      columnDef: 'isActive',
      header: $localize `Status`,
      type: 'toggleSwitch',
      readOnly: !this.isToggleActive,
    },
  ];

  searchInput: ISearchInput = {
    searchName: true,
    searchSite: true,
    searchMultiSite: true,
    searchState: true,
    searchAgency: true,
    searchSiteType: true,
  };

  constructor(
    private site: SiteService,
    public modal: MatDialog,
    public lookup: LookupService,
    public authService: AuthService,
    public permissionService: PermissionService,
    private toastService: ToastService
  ) { }

  public get Permission() {
    return Permission;
  }
  private subscriptions = new Subscription();

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  ngOnInit(): void {
    this.isEditable = this.permissionService.checkPermission(
      Permission.EditSites
    );
    if (this.isEditable) {
      this.tableColumns.push({
        columnDef: 'edit',
        header:  $localize `Edit`,
        type: 'edit',
      });
    }
    this.isDeletable = this.permissionService.checkPermission(
      Permission.DeleteSites
    );
    if (this.isDeletable) {
      this.tableColumns.push({
        columnDef: 'delete',
        header: $localize `Delete`,
        type: 'delete',
      });
    }

    this.loadInitialList();
  }

  loadInitialList() {
    this.payload = {
      searchName: '',
      typeId: null,
      agencyId: this.user.agencyId,
      siteIds: [],
      stateId: this.user.stateId,
      itemsPerPage: 10,
      pageIndex: 1,
    };

    this.paginate(true);
  }

  getInitialAgencyId(): number | null {
    return this.hasAccessToAll ? null : this.user.agencyId;
  }

  openModal(editData?: IModalEmitData): void {
    this.reload = false;
    if (editData) {
      const { isActive } = editData.data;
      if (!isActive) {
        this.toastService.error($localize `Unable to edit while site is inactive.`);
        return;
      }
    }
    const modalRef = this.modal.open(AddEditSitesComponent, {
      data: editData ?? undefined,
    });
    this.subscriptions.add(
      modalRef.afterClosed().subscribe((result) => {
        if (result.success) {
          if (!editData) this.totalData++;
          this.reload = true;
          this.getSites(this.payload);
        }
      })
    );
  }

  translateEnum(enumValue: any): string {
    return $localize `${enumValue}`
  }

  deleteModal(event: IModalEmitData): void {
    event.data.modalInfo = {
      title: `${$localize `Delete`} ${event.data.siteName}`,
      message: $localize `Are you sure you want to want to delete <span class="font-bold">${event.data.siteName}</span>?`,
      name: event.data.siteName,
      primaryBtnClass: 'red',
      primaryBtnText: this.translateEnum(Modal.Delete),
      showCancel: true,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.removeSite(res.id, $localize `Site Deleted`);
      }
    });
  }

  removeSite(siteId: number, message?: any) {
    this.reload = false;
    this.subscriptions.add(
      this.site.deleteSite(siteId, message).subscribe((res: boolean) => {
        if (res) {
          this.totalData--;
          this.reload = true;
          this.getSites(this.payload);
        }
      })
    );
  }

  updateSite(site: any): void {
    this.reload = false;
    this.subscriptions.add(
      this.site
        .updateSite(site.payload, $localize `Updated Site`)
        .subscribe((res: boolean) => {
          if (res) {
            this.reload = true;
            this.getSites(this.payload);
          }
        })
    );
  }

  changeActiveStatus(event: any) {
    this.reload = false;
    if (event) {
      const siteInfo: ISites = {
        id: event.data.id,
        isActive: (event.data.isActive = !event.data.isActive),
        siteName: event.data.siteName,
        agencyId: event.data.agencyId,
        cdsCode: event.data.cdsCode,
        countyID: event.data.countyID,
        regionId: event.data.regionId,
        siteAddress: event.data.siteAddress,
        siteCity: event.data.siteCity,
        siteCodeTypeId: event.data.siteCodeTypeId,
        siteTypeId: event.data.siteTypeId,
        siteZipCode: event.data.siteZipCode,
      };
      this.site.updateSite(siteInfo).pipe(take(1)).subscribe(res => {
        if (res) {
          this.reload = true;
          this.getSites(this.payload);
        }
      });
    }
  }

  // Initialize Table data
  initializeTableData(res: any): void {
    this.dataSource = new MatTableDataSource(res.sites);
    this.totalData = res.totalData;
    this.tableData = res.sites;
  }

  searchSite(search: any): void {
    this.payload = {
      siteName: search.SearchName,
      stateId: search.StateId,
      agencyId: search.AgencyId,
      siteIds: search.SiteIds.split(',').map((id: string) => Number(id.trim())).filter((id: number) => !isNaN(id) && id !== 0),
      typeId: search.Type === 0 ? null : search.Type,
      itemsPerPage: this.paginator.pageSize = 10,
      pageIndex: this.paginator.pageIndex = 0
    };

    this.siteType = search.searchSiteType;
    this.siteSearchName = search.SearchName;

    this.paginate(true);
  }

  getSites(payload?: ISites): void {
    this.subscriptions.add(
      this.site.getSitesWithParam(payload).subscribe((res: any) => {
        if (res) {
          this.initializeTableData(res);
        }
      })
    );
  }

  paginate(resetPage?: boolean): void {
    if (this.paginator) {
      this.payload.pageIndex = resetPage ? 1 : this.paginator.pageIndex + 1;
      this.payload.itemsPerPage = this.paginator.pageSize;

      if (resetPage) {
        this.paginator.pageSize = this.payload.itemsPerPage;
      }
    }

    this.getSites(this.payload);
  }

  onClear(): void {
    this.siteSearchName = '';
    this.payload = {
      searchName: '',
      typeId: null,
      agencyId: this.getInitialAgencyId(),
      siteIds: [],
      stateId: this.user.stateId,
      itemsPerPage: 10,
      pageIndex: 1,
    };
  
    this.paginate(true);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
