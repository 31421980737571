<div class="p-5">
    <drdp-title title="Rating Periods" i18n-title></drdp-title>

    <drdp-container>
      <div class="w-full">
        <drdp-search (search)="search($event)" (clear)="onClear()" [searchInput]="searchInput" textInputPlaceholder="Rating Period" i18n-textInputPlaceholder></drdp-search>
        <drdp-add-edit-table 
          [dataSource]="dataSource"
          [tableData]="tableData" 
          [columns]="tableColumns" 
          (toggleSwitchActionItem)="toggleStatus($event)"
          (editActionItem)="openModal($event)"
          toggleMsg="Are you sure you want to deactivate this rating period?" i18n-toggleMsg>
        </drdp-add-edit-table>
      </div>
      </drdp-container>

      <mat-paginator *ngIf="isAdmin"
                     [pageSizeOptions]="pageSizeOptions" 
                     [pageSize]="pageSize" 
                     [length]="totalData" 
                     [pageIndex]="pageIndex" 
                     (page)="getRatingPeriods()">
      </mat-paginator>
</div>