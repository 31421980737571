import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  @ViewChild('canvas') canvas?: ElementRef;
  public chart: any;
  chartId: string = '';
  constructor() { }

  ngOnInit() {
    this.chartId = 'line_' + Math.random().toString(36).substr(2, 9); // Generate a unique ID
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart() {
    if(this.canvas === undefined) return;
    const ctx: CanvasRenderingContext2D = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line', //this denotes tha type of chart
      data: {// values on X-Axis
        labels: ['2021', '2022', '2023', '2024', '2025'],
	       datasets: [
          {
            label: "Result",
            data: ['50','60', '70', '80', '90'],
            backgroundColor: 'orange'
          },
          {
            label: "State Target",
            data: ['45', '65', '75', '85', '100'],
            backgroundColor: 'blue'
          }
        ]
      },
      options: {
        maintainAspectRatio: false, // Set to false to allow the chart to take up the full height of its container
        responsive: true
      }
    });
  }

}
