import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICounty } from '@core/interfaces/icounty';
import { ISiteCodeType, ISiteType } from '@core/interfaces/isites';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { SiteService } from '@core/services/site.service';
import { SelectType } from '@core/enums/select';
import { take } from 'rxjs';
import { ToastService } from '@core/services/toast.service';
import { States } from '@core/enums/states';

@Component({
  selector: 'drdp-add-edit-sites',
  templateUrl: './add-edit-sites.component.html',
  styleUrls: ['./add-edit-sites.component.scss'],
})
export class AddEditSitesComponent implements OnInit {
  isSuperAdmin: boolean = false;
  initStateId: number = 0;
  initAgencyId: number = 0;
  clearDropdowns = false;

  siteForm: FormGroup | any;

  selectedCodeType?: ISiteCodeType;
  selectedType?: ISiteType;
  selectedCounty?: ICounty;
  selectedState?: any;
  selectedStateId?: number;
  headingText!: string;
  user = this.auth.getCurrentUser();
  hasFullOptions: boolean =
    this.auth.isSuperAdmin() || this.auth.isCustomerService();
  viewCountyDropdown: boolean = true;

  get siteName() {
    return this.siteForm.get('siteName');
  }
  get siteCode() {
    return this.siteForm.get('siteCode');
  }
  get siteCodeType() {
    return this.siteForm.get('siteCodeType');
  }
  get siteType() {
    return this.siteForm.get('siteType');
  }
  get adminStateDropdown() {
    return this.siteForm.get('adminStateDropdown');
  }
  get state() {
    return this.siteForm.get('state');
  }
  get adminAgencyDropdown() {
    return this.siteForm.get('adminAgencyDropdown');
  }
  get siteAddress() {
    return this.siteForm.get('siteAddress');
  }
  get siteCity() {
    return this.siteForm.get('siteCity');
  }
  get siteZipCode() {
    return this.siteForm.get('siteZip');
  }
  get siteCounty() {
    return this.siteForm.get('siteCounty');
  }
  get isDualEnrollment() {
    return this.siteForm.get('isDualEnrollment');
  }

  public get select() {
    return SelectType;
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditSitesComponent>,
    private siteService: SiteService,
    public lookup: LookupService,
    private auth: AuthService,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    this.updateHeadingText();
    (dialogRef.disableClose = true), dialogRef.updateSize('600px');
  }

  ngOnInit(): void {
    this.initializeForms();
  }

  private updateHeadingText(): void {
    this.headingText = !this.editData ? $localize `Add New Site` : $localize `Edit Site`;
  }

  initializeForms(): void {
    this.siteForm = this.fb.group({
      siteName: [null, [Validators.required, Validators.maxLength(100)]],
      siteCode: [null, [Validators.maxLength(25)]],
      siteCodeType: [null],
      siteType: [null, [Validators.required]],
      siteAddress: [null, [Validators.required, Validators.maxLength(100)]],
      siteCity: [null, [Validators.required, Validators.maxLength(25)]],
      siteCounty: [null],
      state: [this.user.stateId, [Validators.required]],
      siteZip: [null, [Validators.required, Validators.maxLength(10)]],
      adminAgencyDropdown: [this.user.agencyId > 0 ? this.user.agencyId : 0],
      adminStateDropdown: [0],
      isDualEnrollment: [0]
    });

    if (this.editData) {
      const edit = this.editData.data;
      this.siteName.setValue(edit.siteName);
      this.siteCode.setValue(edit.cdsCode);
      this.state.setValue(edit.stateId);
      this.siteCodeType.setValue(edit.siteCodeTypeId);
      this.siteType.setValue(edit.siteTypeId);
      this.siteAddress.setValue(edit.siteAddress);
      this.siteCity.setValue(edit.siteCity);
      this.siteCounty.setValue(edit.countyID);
      this.siteZipCode.setValue(edit.siteZipCode);

      this.adminAgencyDropdown.setValue(edit.agencyId);
      this.adminStateDropdown.setValue(edit.agencyId);
      this.isDualEnrollment.setValue(edit.isDualEnrollment);
      this.initStateId = edit.stateId;
      this.initAgencyId = edit.agencyId;
      this.selectedCodeType = edit.siteCodeType;
      this.selectedType = edit.siteType;
      this.selectedCounty = edit.county;
    }
  }

  handleView(event: boolean) {
    this.viewCountyDropdown = event;
  }

  onDropDownSelect(event: any, selectType: string) {
    this.clearDropdowns = false;
    switch (selectType) {
      case this.select.States:
        if (event?.id == States.california || event?.id == States.newHampshire) {
          this.viewCountyDropdown = true;
        }
        else {
          this.viewCountyDropdown = false;
        }
        this.siteCounty.reset();
        this.state.setValue(event?.id);
        this.adminStateDropdown.setValue(event?.id);
        break;
      case this.select.Agencies:
        this.adminAgencyDropdown.setValue(event?.id);
        break;
      case this.select.CodeType:
        this.siteCodeType.setValue(event?.id);
        this.siteCodeType.markAsTouched();
        this.selectedCodeType = event;
        break;
      case this.select.SiteType:
        this.siteType.setValue(event?.id);
        this.siteType.markAsTouched();
        this.selectedType = event;
        break;
      case SelectType.County:
        this.selectedCounty = event;
        this.siteCounty.setValue(event?.id);
        this.siteCounty.markAsTouched();
        break;
      default:
        break;
    }
  }

  // Sends Payload obj to parent
  onSubmit() {
    if (this.siteForm.invalid) {
      this.toast.error($localize `Please enter all required fields.`);
      return;
    }

    let payload = {
      id: this.editData ? this.editData.data.id : 0,
      RegionId: 1,
      agencyId: this.adminAgencyDropdown.value,
      cdsCode: this.siteCode.value,
      countyId: this.siteCounty.value,
      isActive: true,
      isDualEnrollment: this.isDualEnrollment.value,
      siteAddress: this.siteAddress.value,
      siteCity: this.siteCity.value,
      siteName: this.siteName.value,
      siteTypeId: this.siteType.value,
      siteCodeTypeId: this.siteCodeType.value,
      siteZipCode: this.siteZipCode.value
    };

    let updateTableView = {
      id: this.editData ? this.editData.data.id : 0,
      siteName: this.siteName.value,
      cdsCode: this.siteCode.value,
      siteType: this.siteType.value,
      siteCodeType: this.siteCodeType.value,
      siteAddress: this.siteAddress.value,
      siteCity: this.siteCity.value,
      siteZipCode: this.siteZipCode.value,
      countyId: this.siteCounty.value,
      states: this.state.value
    };

    if (this.editData) {
      this.siteService
        .updateSite(payload, $localize `Site has been succesfully updated.`)
        .pipe(take(1))
        .subscribe((success: boolean) => {
          if (success) {
            this.dialogRef.close({
              success: true,
              updateTableView: updateTableView,
            });
          }
        });
    } else {
      this.siteService
        .addSite(payload, $localize `Site has been successfully added.`)
        .pipe(take(1))
        .subscribe((result: any) => {
          if (result && result.id > 0) {
            this.dialogRef.close({
              success: true,
              updateTableView: updateTableView,
            });
          }
        });
    }
  }
}
