import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TableColumnType } from '@core/enums/table';
import { IFutureEvidence } from '@core/interfaces/ifuture-evidence';
import { PortfolioService } from '@core/services/portfolio.service';
import { ToastService } from '@core/services/toast.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-view-child-evidence',
  templateUrl: './view-child-evidence.component.html',
  styleUrls: ['./view-child-evidence.component.scss']
})
export class ViewChildEvidenceComponent implements OnInit {
  dataSource: MatTableDataSource<IFutureEvidence> = new MatTableDataSource();
  tableData?: any;
  studentId: number = 0;
  constructor(
    private portfolioService: PortfolioService,
    private router: Router,
    private toastService: ToastService,
    private route: ActivatedRoute,
    public modal: MatDialog) { }

  tableColumns = [
    {
      columnDef: 'globalRatingPeriodName',
      header: 'Rating Period',
      type: TableColumnType.text
    },
    {
      columnDef: 'Evidence Library',
      header: 'Evidence Library',
      type: 'view'
    }
  ];

  ngOnInit(): void {
    const studentId = this.route.snapshot.paramMap.get('id');

    if (!studentId) {
      this.toastService.error('StudentId parameter is missing.');
      return;
    }

    this.studentId = +studentId;

    this.getFutureEvidenceByStudent();
  }

  getFutureEvidenceByStudent() {
    this.portfolioService
      .getFutureEvidenceByStudent(this.studentId)
      .pipe(take(1))
      .subscribe((res: IFutureEvidence[]) => {
        this.dataSource = new MatTableDataSource(res);
        this.tableData = res;
      });
  }

  viewEvidence(event: any): void {
    const data = event.data;
    this.router.navigate([
      `portfolio/review-evidence/futureEvidence/${data.id}/student/${data.studentId}`
    ]);
  }
}
