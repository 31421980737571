export const environment = {
  production: false,
  apiUrl: 'https://drdp-staging.codestack-wedprojects.org/',
  url: 'https://drdpv2.codestack-wedprojects.org/',
  authToken: 'drdp-token',
  storageName: 'drdpdevstoragewst2',
  storageUrl: 'https://drdpdevstoragewst2.blob.core.windows.net/',
  encryptionKey: 'hWmZq4t7w!z$C&F)J@NcRfUjXn2r5u8x',
  recaptcha: '6LfaYkcpAAAAAJDQ4qqzTolh5XdktqOOZsC2iY4u',
  chatboxKey: '401e2f55-65a4-4b33-be6c-c3bdd87d8984',
};
