import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputRatingSelectedLevel } from '@core/enums/portfolio';
import { ToastService } from '@core/services/toast.service';
import { IMeasureLevel } from '@views/user/input-rating/interfaces/imeasure-level';

@Component({
  selector: 'drdp-portfolio-input-rating-modal',
  templateUrl: './portfolio-input-rating-modal.component.html',
  styleUrls: ['./portfolio-input-rating-modal.component.scss']
})
export class PortfolioInputRatingModalComponent implements OnInit {
  measureCode: string = '';
  title: string = $localize `Preliminary Rating`;
  options: IMeasureLevel[] = [];
  selectedLevel: number = 1;
  selectedId: number = 0;
  selectedName: string = '';
  selectedIcon: string = '';

  constructor(public dialogRef: MatDialogRef<PortfolioInputRatingModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef, private toast: ToastService) {

  }

  ngOnInit(): void {
    this.options = this.data.options;
    this.selectedLevel = this.data.selectedLevel ? this.data.selectedLevel : InputRatingSelectedLevel.Unshaded;
    this.selectedId = this.data.selectedMeasureValue ? this.data.selectedMeasureValue : 0;
    this.measureCode = this.data.measureCode;
    this.cdr.detectChanges();
  }

  onOptionClick(id: number, isMultiLevel: boolean | null | undefined, name: string, icon: string) {
    if (this.selectedId !== id) {
      this.selectedLevel = InputRatingSelectedLevel.Unshaded;
      this.selectedId = id;
      this.selectedName = name;
      this.selectedIcon = icon;
      this.toggleSelectedLevel(isMultiLevel);
    } else {
      this.selectedLevel = InputRatingSelectedLevel.Unshaded;
      this.selectedId = 0;
      this.selectedName = '';
      this.selectedIcon = '';
      this.toggleSelectedLevel(isMultiLevel);
    }
  }

  toggleSelectedLevel(isMultiLevel: boolean | null | undefined) {
    if (isMultiLevel) {
      this.selectedLevel = this.selectedLevel !== InputRatingSelectedLevel.FullShaded ? this.selectedLevel + 1 : InputRatingSelectedLevel.Unshaded;
    } else {
      this.selectedLevel = this.selectedLevel === InputRatingSelectedLevel.Unshaded ? InputRatingSelectedLevel.FullShadedOrHalfShaded : InputRatingSelectedLevel.Unshaded;
    }
  }

  onSubmit() {
    if (this.selectedId === 0){
      this.toast.error($localize `Please select a measure level.`);
      return;
    }

    this.dialogRef.close({
      success: true,
      data: {
        selectedId: this.selectedId,
        selectedLevel: this.selectedLevel,
        selectedName: this.selectedName,
        selectedIcon: this.selectedIcon
      }
    });
  }

}
