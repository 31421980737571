import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { ReportService } from '@core/services/report.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { SelectType } from '@core/enums/select';
import { MatTableDataSource } from '@angular/material/table';
import { setIconDisplayObject } from '@core/services/helper.service';
import { ViewChildrenModalComponent } from '../view-children-modal/view-children-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { IRatingCompletionReportDto, IRatingCompletionSearch } from '../interfaces/irating-completion-search';
import { TableColumnType } from '@core/enums/table';
import { IPaginatedResponse } from '@core/interfaces/ipaginated-response';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
@Component({
  selector: 'drdp-rating-completion',
  templateUrl: './rating-completion.component.html',
  styleUrls: ['./rating-completion.component.scss']
})

export class RatingCompletionComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  user = this.authService.getCurrentUser();
  ratingCompletionSearch: FormGroup | any;
  clearDropdowns: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  modal = inject(MatDialog);
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  tableData?: any;
  payload: IRatingCompletionSearch = {
    itemsPerPage: this.pageSize,
    pageIndex: this.pageIndex
  }
  hasAccessToPilot = this.permissionService.checkPermission(
    Permission.All2025Pilot
  );
  tableColumns = [
    { columnDef: 'agencyName', header: $localize `Agency`, type: TableColumnType.text },
    { columnDef: 'siteName', header: $localize `Site`, type: TableColumnType.text },
    { columnDef: 'classroomName', header: $localize `Class`, type: TableColumnType.text },
    { columnDef: 'children', header: $localize `Total Children`, type: TableColumnType.text },
    { columnDef: 'avgPctMeasuresCompleted', header: $localize `Avg % of measures completed by child`, type: TableColumnType.text },
    { columnDef: 'pctChildrenCompleted', header: $localize `% of children completed`, type: TableColumnType.text },
    { columnDef: 'pctChildrenLocked', header: $localize `% children locked`, type: TableColumnType.text },
    {
      columnDef: 'preview',
      header: $localize `View`,
      icon: 'eye',
      type: TableColumnType.icon,
      colorClass: 'text-drdpblue-300',
      cursorClass: 'cursor-pointer' }
  ];
  get globalRatingPeriodId() { return this.ratingCompletionSearch.get('globalRatingPeriodId'); }
  get classroomIds() { return this.ratingCompletionSearch.get('classroomIds'); }
  get siteIds() { return this.ratingCompletionSearch.get('siteIds'); }
  get stateId() { return this.ratingCompletionSearch.get('stateId'); }
  get agencyId() { return this.ratingCompletionSearch.get('agencyId'); }
  get isAllPilot() { return this.ratingCompletionSearch.get('isAllPilot'); }
  public get selectType() { return SelectType; }

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private reportService: ReportService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  onIconClick(event: any): void {
    event.data.globalRatingPeriodId = this.globalRatingPeriodId.value;
    const modalRef = this.modal.open(ViewChildrenModalComponent, {
      data: event.data ?? undefined,
      autoFocus: false
    });
    modalRef.afterClosed().subscribe();
  }

  handleSearch(): void {
    this.payload = this.ratingCompletionSearch.value;
    if (!this.isAllPilot.value) {
      this.payload.siteIds = this.siteIds.value.toString();
      this.payload.classroomIds = this.classroomIds.value.toString();
    }
    this.payload.itemsPerPage = this.pageSize;
    this.payload.pageIndex = this.pageIndex;
    this.paginateTable(true);
  }

  paginateTable(resetPage?: boolean): void {
    this.payload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    this.payload.itemsPerPage = resetPage ? 10 : this.paginator.pageSize;
    this.reportService.generateRatingCompletionReport(this.payload).pipe(take(1)).subscribe((res: IPaginatedResponse) => {
      res.items = res.items.map((data: IRatingCompletionReportDto[]) => ({
        ...data,
        displayIcons: setIconDisplayObject(this.tableColumns, data)
      }))
	  this.setData(res);
	})
  }

  setData(res: any): void {
    this.dataSource = new MatTableDataSource(res.items);
	  this.tableData = res.items;
    this.totalData = res.totalData;
  }

  initializeForm(): void {
    this.ratingCompletionSearch = this.fb.group({
      stateId: [null, Validators.required],
      agencyId: [null, Validators.required],
      siteIds: [null, Validators.required],
      classroomIds: [null, Validators.required],
      globalRatingPeriodId: [null, Validators.required],
      isAllPilot: [false, Validators.required]
    });

    this.ratingCompletionSearch.patchValue({
        stateId: this.authService.getUserStateId(),
        agencyId: this.authService.getCurrentUser().agencyId ?? null,
    });
  }

  handleDropdown(event: any, type: string): void {
    switch (type) {
      case SelectType.States:
        this.stateId.setValue(event?.id);
        this.agencyId.setValue(null);
        this.siteIds.setValue(null);
        this.globalRatingPeriodId.setValue(null);
        this.classroomIds.setValue(null);
        break;
      case SelectType.Agencies:
        this.agencyId.setValue(event?.id);
        this.siteIds.setValue(null);
        break;
      case SelectType.Sites:
        const siteIds = event?.map((sites: any) => sites.id);
        this.siteIds.setValue(siteIds);
        this.classroomIds.setValue(null);
        break;
      case SelectType.RatingPeriods:
        this.globalRatingPeriodId.setValue(event?.id);
        this.classroomIds.setValue(null);
        break;
      case SelectType.Class:
        const classroomIds = event?.map((classroom: any) => classroom.id);
        this.classroomIds.setValue(classroomIds);
        break;
    }
  }

  onClear(): void {
    this.clearDropdowns = true;
    this.ratingCompletionSearch.reset();
  }

  handleGlobal(): void {
    if (this.isAllPilot.value) {
      this.resetFieldsAndValidators();
    } else {
      this.agencyId.setValue(this.authService.getCurrentUser().agencyId);
      this.setRequiredValidators();
    }
    this.updateFieldValidity();
  }

  resetFieldsAndValidators() {
    this.globalRatingPeriodId.setValue(null);
    this.agencyId.setValue(null);
    this.siteIds.setValue(null);
    this.classroomIds.setValue(null);
    this.agencyId.setValidators([]);
    this.siteIds.setValidators([]);
    this.classroomIds.setValidators([]);
  }
  
  setRequiredValidators() {
    this.agencyId.setValidators([Validators.required]);
    this.siteIds.setValidators([Validators.required]);
    this.classroomIds.setValidators([Validators.required]);
  }
  
  updateFieldValidity() {
    this.agencyId.updateValueAndValidity();
    this.siteIds.updateValueAndValidity();
    this.classroomIds.updateValueAndValidity();
  }
}
