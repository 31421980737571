import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectType } from '@core/enums/select';
import { IClass, IUserClassSearchRequestDto } from '@core/interfaces/iclasses';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ISites } from '@core/interfaces/isites';
import { AgeGradeTemplateEnrollmentService } from '@core/services/age-grade-template-enrollment.service.ts';
import { ClassService } from '@core/services/class.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';
import {
  IClassroomDetail,
  IRatingViewTemplateOption,
  IStudentOption,
} from '../../interfaces/irating-view-template-option';
import { Modal } from '@core/enums/modal';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'drdp-custom-rating-view-assignment',
  templateUrl: './custom-rating-view-assignment.component.html',
  styleUrls: ['./custom-rating-view-assignment.component.scss'],
})
export class CustomRatingViewAssignmentComponent implements OnInit {
  payload: IUserClassSearchRequestDto = {};
  agencyId = 0;
  templateId = 0;
  selectedSiteId?: any;
  selectedRatingPeriodId?: number | null;
  siteList: ISites[] | undefined = [];
  ratingPeriodList: IRatingPeriod[] | undefined = [];
  classroomList: IClass[] = [];
  results: IRatingViewTemplateOption[] = [];
  clearDropdowns = false;

  get selectType() {
    return SelectType;
  }
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;

  constructor(
    private ratingPeriodService: RatingPeriodService,
    private classService: ClassService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private studentClassEnrollmentService: StudentClassEnrollmentService,
    private enrollmentService: AgeGradeTemplateEnrollmentService,
    public modal: MatDialog
  ) {
    this.agencyId =
      this.router.getCurrentNavigation()?.extras?.state?.['agencyId'] || 0;

    if (this.agencyId == 0) {
      this.toast.error($localize `Choose a custom view template to edit.`);
      this.router.navigateByUrl('/config/rating-views');
    }
  }

  ngOnInit(): void {
    this.templateId = Number(this.route.snapshot.paramMap.get('id'));
    this.initializeDropdowns();
  }

  initializeDropdowns(): void {
    this.ratingPeriodService
      .getByAgency(this.agencyId)
      .pipe(take(1))
      .subscribe((req) => {
        this.ratingPeriodList = req;
      });
  }

  handleSites(site: ISites): void {
    if (!site) {
      this.payload.siteId = null;
      this.payload.ratingPeriodId = null;
      this.payload.classroomId = null;
      this.ratingPeriodSelect?.clear();
      this.classroomList = [];
      return;
    }

    this.payload.siteId = site.id;
    this.selectedSiteId = site.id;
    this.updateClassroomDropdown();
  }

  handleRatingPeriod(ratingPeriod: IRatingPeriod): void {
    if (!ratingPeriod) {
      this.payload.ratingPeriodId = null;
      this.payload.classroomId = null;
      this.classroomList = [];
      return;
    }

    this.payload.ratingPeriodId = ratingPeriod.id;
    this.selectedRatingPeriodId = ratingPeriod.id;
    this.updateClassroomDropdown();
  }

  handleClassroom(classroom: any): void {
    if (!classroom) {
      this.payload.classroomId = null;
      return;
    }

    this.payload.classroomId = classroom.id;
  }

  updateClassroomDropdown(): void {
    const payload: any = {};
    payload.siteIds = this.selectedSiteId ? [this.selectedSiteId] : null;
    payload.ratingPeriodId = this.selectedRatingPeriodId ?? '';
    payload.itemsPerPage = 10;

    if (payload.siteIds?.length > 0 && payload.ratingPeriodId > 0) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomList = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id)
          );
        });
    }
  }

  onClear(): void {
    this.clearDropdowns = true;
    this.ratingPeriodSelect?.clear();
    this.classroomSelect?.clear();
    this.selectedRatingPeriodId = null;
    this.selectedSiteId = null;
    this.classroomList = [];

    this.payload = {};
    this.payload.itemsPerPage = 10;
    this.results = [];
  }

  search(): void {
    if (!this.payload.ratingPeriodId) {
      this.toast.error($localize `Rating Period is required`);
      return;
    }

    this.studentClassEnrollmentService
      .getRatingPeriodTemplateOptions(
        this.payload.ratingPeriodId,
        this.payload.siteId,
        this.payload.classroomId
      )
      .pipe(take(1))
      .subscribe((result: IRatingViewTemplateOption[]) => {
        this.results = result;
      });
  }

  toggleSite(site: IRatingViewTemplateOption) {
    site.classroomDetails.forEach((c) => {
      c.isSelected = site.isSelected;
      c.students.forEach((student) => {
        student.isSelected = site.isSelected;
      });
    });
  }

  toggleClassroom(
    site: IRatingViewTemplateOption,
    classroom: IClassroomDetail
  ) {
    classroom.students.forEach((student) => {
      student.isSelected = classroom.isSelected;
    });

    if (!classroom.isSelected) this.checkIfSiteSelected(site);
  }

  checkIsClassroomSelected(
    site: IRatingViewTemplateOption,
    classroom: IClassroomDetail,
    student: IStudentOption
  ) {
    if (!student.isSelected) {
      classroom.isSelected =
        classroom.students.filter((d) => !d.isSelected).length == 0;
    }

    if (!classroom.isSelected) this.checkIfSiteSelected(site);
  }

  checkIfSiteSelected(site: IRatingViewTemplateOption) {
    site.isSelected =
      site.classroomDetails.filter((d) => !d.isSelected).length == 0;
  }

  translateOk(ok: any): string {
    switch (ok) {
      case Modal.OK: 
        return $localize `Ok`;
      default:
        return '';
    }
  }

  submit() {
    if (
      !this.results.some((d) => d.isSelected) &&
      !this.results.some((d) => d.classroomDetails.some((c) => c.isSelected)) &&
      !this.results.some((d) =>
        d.classroomDetails.some((c) => c.students.some((s) => s.isSelected))
      )
    ) {
      this.toast.error($localize `Please select a site, classroom or student.`);
      return;
    }

    const request = {
      agencyId: this.agencyId,
      ageGradeTemplateId: this.templateId,
      ratingPeriodId: this.selectedRatingPeriodId,
      options: this.results,
    };
    this.enrollmentService
      .saveTemplateAssignments(request)
      .pipe(take(1))
      .subscribe((s) => {  
        if (s.pilotStudents) {
          this.openErrorModal(s.pilotStudents.join('<br><br>'));
        } else if (s.lockedStudents) {
          this.openErrorModal(s.lockedStudents.join('<br><br>'));
        } else if (s.iepStudents) {
          let errorMsg = $localize `PS Children with an IEP cannot be assigned an alternate rating view that has less domain and measure requirements than the PS Fundamental View. IT Children with an IFSP cannot be assigned an alternate rating view that has less domain and measure requirements than the IT Comprehensive View.<br><br>" + 
          "<b>IEP Student(s)</b><br>` + 
          s.iepStudents.join('<br>');
          this.openErrorModal(errorMsg);
        } else if (s.duplicates) {
          this.openErrorModal(s.duplicates.join('<br><br>'));
        }
        else {
          this.toast.success($localize `Assignments successfully saved!`);
          this.router.navigateByUrl('/config/rating-views');
        }
      });
  }

  openErrorModal(message: any) {
    let modal = { data: { modalInfo: {
      title: 'Error',
      message: message,
      primaryBtnText: Modal.OK,
      wideContent: true
    }}};
    this.modal.open(ConfirmationModalComponent, { data: modal });
  }

  cancel() {
    this.router.navigateByUrl(`/config/custom-rating-view/${this.templateId}`, {
      state: { agencyId: this.agencyId },
    });
  }
}
