<div class="p-5">
    <div class="flex justify-between">
      <drdp-title title="Integration Management" i18n-title></drdp-title>
      <span>
        <drdp-btn *drdpCheckPermission="Permission.IntegrationManagement" (click)="addVendor()"><i class="uil uil-plus"></i> 
          <span i18n>Add New Vendor</span></drdp-btn>
      </span>
    </div>
  
    <drdp-container [additionClasses]="'min-h-[44rem]'">
      <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
        [isAdmin]="hasFullAccess" (editActionItem)="editVendor($event)">
      </drdp-add-edit-table>
    </drdp-container>
  </div>
