import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRoleGroup, IRoles } from '@core/interfaces/iroles';
import { RoleService } from '@core/services/role.service';
import { ToastService } from '@core/services/toast.service';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'drdp-add-edit-roles',
  templateUrl: './add-edit-roles.component.html',
  styleUrls: ['./add-edit-roles.component.scss'],
})
export class AddEditRolesComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  isSuperAdmin: boolean = false;

  roleForm: FormGroup | any;
  roleEdit: any;
  roleGroup$?: Observable<IRoleGroup[]>;

  get RoleName() {
    return this.roleForm.get('RoleName');
  }
  get RoleDescription() {
    return this.roleForm.get('RoleDescription');
  }
  get RoleGroupId() {
    return this.roleForm.get('RoleGroupId');
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditRolesComponent>,
    private roleService: RoleService,
    private toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
    this.initializeForms();
    this.roleGroup$ = this.roleService.getRoleGroups();
  }

  initializeForms(): void {
    this.roleForm = this.fb.group({
      RoleName: [null, [Validators.required, Validators.maxLength(25)]],
      RoleDescription: [null, [Validators.required, Validators.maxLength(50)]],
      RoleGroupId: [null, Validators.required],
    });

    if (this.editData) {
      const edit = this.editData.data;
      this.RoleName.setValue(edit.roleName);
      this.RoleDescription.setValue(edit.roleDescription);
      this.RoleGroupId.setValue(edit.roleGroupId);
    }
  }

  onSubmit() {
    if (this.roleForm.invalid) {
      this.toast.error($localize `Please fix form errors.`);
      return;
    }
    let payload: IRoles = this.roleForm.value;
    if (this.editData) {
      payload.id = this.editData.data.id;
    }

    this.roleService
      .addRole(payload, $localize `Added Role`)
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.dialogRef.close({ success: true });
        }
      });
  }
}
