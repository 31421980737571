import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import { TableColumnType } from '@core/enums/table';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { ISites } from '@core/interfaces/isites';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { StudentClassEnrollmentService } from '@core/services/student-class-enrollment.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-total-locked-children',
  templateUrl: './total-locked-children.component.html',
  styleUrls: ['./total-locked-children.component.scss']
})
export class TotalLockedChildrenComponent implements OnInit {
  @Output() formValues = new EventEmitter();
  lockedForm: FormGroup | any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageSize: number = 10;
  pageIndex: number = 1;
  tableData?: any;
  tableColumns: any[] = [];
  totalData: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild('ageGroupInstrumentSelect') ageGroupInstrumentSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;

  user = this.auth.getCurrentUser();
  hasAccessToAll: boolean = this.permissionService.checkPermission(Permission.AccessAllAgencies);
  accessAssignedStateAgencies!: boolean;

  initStateId = this.user.stateId;
  initAgencyId = this.user.agencyId;

  clearDropdown = false;
  clearState = false;
  clearAgency = false;
  searchPayload: any;
  ageGradeInstruments?: IAgeGradeInstrument[] = [];
  sites!: ISites[];
  isPilot: boolean = false;
  previousIsPilot: boolean = false;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private permissionService: PermissionService,
    private toastService: ToastService,
    private router: Router,
    private studentClassEnrollmentService: StudentClassEnrollmentService
  ) {
    this.user = this.auth.getCurrentUser();
    this.hasAccessToAll = this.permissionService.checkPermission(Permission.AccessAllAgencies);
    this.accessAssignedStateAgencies = this.permissionService.checkPermission(Permission.AccessAssignedStateAgencies);
  }

  get stateId() {
    return this.lockedForm.get('stateId');
  }
  get agencyId() {
    return this.lockedForm.get('agencyId');
  }
  get ageGradeInstrumentId() {
    return this.lockedForm.get('ageGradeInstrumentId');
  }
  get classId() {
    return this.lockedForm.get('classId');
  }
  get classroomId() {
    return this.lockedForm.get('classroomId');
  }
  get ratingPeriodId() {
    return this.lockedForm.get('ratingPeriodId');
  }
  get siteId() {
    return this.lockedForm.get('siteId');
  }

  ngOnInit(): void {
    this.initializeForm();

    this.paginateTable(true);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  initializeForm(): void {
    this.lockedForm = this.fb.group({
      userId: [this.user.userId ?? null],
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null, [Validators.required]],
      ratingPeriodId: [null],
      siteId: [null],
      classId: [null],
      ageGradeInstrumentId: [null],
    });
  }

  handleState(state: any) {
    this.stateId.setValue(state?.id);
    this.agencyId.setValue(null);
  }

  handleAgency(agency: any): void {
    this.agencyId.setValue(agency?.id);
    this.classId.setValue(null);
    this.ratingPeriodId.setValue(null);
  }

  handleRatingPeriod(ratingPeriod: any): void {
    this.ratingPeriodId.setValue(ratingPeriod?.id);
  }

  handleSite(site: any): void {
    this.siteId.setValue(site?.id);
  }

  handleChosenClassroom(classroom: any): void {
    this.isPilot = classroom?.isPilot ?? false;
    if (this.previousIsPilot !== this.isPilot) {
      this.ageGradeInstrumentId.setValue(null);
      this.ageGroupInstrumentSelect?.clearSelected();
    }
    this.previousIsPilot = this.isPilot;
    this.classId.setValue(classroom?.id);
  }

  handleAgeGrade(event: IAgeGradeInstrument): void {
    this.ageGradeInstrumentId.setValue(event?.id);
  }

  search() {
    if (this.lockedForm.invalid) {
      this.toastService.error($localize `Please enter all required fields.`);
      return;
    }

    this.paginateTable(true);
  }

  paginateTable(resetPage?: boolean): void {
    const searchPayload = this.createSearchPayload(resetPage);
    this.getAgencyCurrentStudentEnrollments(searchPayload);
  }

  createSearchPayload(resetPage?: boolean): void {
    const payload = this.lockedForm.value;

    payload.userId = this.user.userId ?? null;
    payload.stateId = this.stateId.value ?? null;
    payload.agencyId = this.agencyId.value ?? null;
    payload.ratingPeriodId = this.ratingPeriodId.value ?? null;
    payload.siteId = this.siteId.value ?? null;
    payload.classId = this.classId.value ?? null;
    payload.ageGradeInstrumentId = this.ageGradeInstrumentId.value ?? null;
    payload.pageIndex = resetPage ? 1 : this.paginator.pageIndex + 1;
    payload.itemsPerPage = resetPage ? this.pageSize : this.paginator.pageSize;

    if (resetPage && this.paginator) {
      this.paginator.pageSize = payload.itemsPerPage;
    }

    return payload;
  }

  getAgencyCurrentStudentEnrollments(params: any): void {
    this.studentClassEnrollmentService
      .getAgencyCurrentStudentEnrollments(params)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.tableData = res.items.map((item: any) => ({
            ...item,
            userId: item.userId ?? null,
            stateId: item.stateId ?? null,
            agencyId: item.agencyId ?? null,
            ratingPeriodId: item.ratingPeriodId ?? null,
            siteId: item.siteId ?? null,
            classId: item.classId ?? null,
            ageGradeInstrumentId: item.ageGradeInstrumentId ?? null,
          }));

          this.dataSource = new MatTableDataSource<any>(this.tableData);
          this.initializeTableData(res);
        }
      );
  }

  initializeTableData(res: any): void {
    this.initializeTableColumns(res.items);
    this.dataSource = new MatTableDataSource(res.items);
    this.totalData = res.totalData;
    this.tableData = res.items;
  }

  initializeTableColumns(_items: any[]): void {
    this.tableColumns = [
      {
        columnDef: 'studentClassEnrollmentId',
        header: $localize `Ratings`,
        icon: 'pen',
        type: TableColumnType.actionIcon,
        action: 'view-rating',
        colorClass: 'text-drdpblue-300',
        cursorClass: 'cursor-pointer',
      },
      {
        columnDef: 'studentName',
        header: $localize `Student Name`,
        type: TableColumnType.text,
        isSortable: true,
      },
      {
        columnDef: 'ratingPeriodName',
        header: $localize `Rating Period`,
        type: TableColumnType.text,
        isSortable: true,
      },
      {
        columnDef: 'classroomName',
        header: $localize `Classroom`,
        type: TableColumnType.text,
        isSortable: true,
      },
      {
        columnDef: 'siteName',
        header: $localize `Site`,
        type: TableColumnType.text,
        isSortable: true,
      },
      {
        columnDef: 'assessmentDate',
        header: $localize `Assessment Date`,
        type: TableColumnType.date,
        isSortable: true,
      },
      {
        columnDef: 'lockingDate',
        header: $localize `Lock Date`,
        type: TableColumnType.date,
        isSortable: true,
      },
      {
        columnDef: 'isLocked',
        header: $localize `Locking Status`,
        type: TableColumnType.boolIcon,
        truthyClass: 'text-drdpGreen-300',
        truthyIconUrl: 'assets/images/misc/lock-alt.svg',
        falsyClass: 'text-drdpgrey-200',
        falsyIconUrl: 'assets/images/misc/padlock.svg',
        isSortable: true,
      },
    ];
  }

  onIconClick(event: any): void {
    if (event.action == 'view-rating') {
      this.router.navigate([`input-ratings/${event.data.studentClassEnrollmentId}`]);
    }
  }

  clearTable(): void {
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
  }

  clear(): void {
    this.sitesSelect?.clearSelected();
    this.ageGroupInstrumentSelect?.clearSelected();
    this.clearDropdown = true;
    this.ratingPeriodSelect?.clearSelected();
    this.ratingPeriodId.setValue(null);
    this.clearTable();

    if (!this.stateSelect?.disabled) {
      this.initStateId = 0;
      this.stateId.setValue(null);
      this.clearState = true;
    }

    if (!this.agencySelect?.disabled) {
      this.initAgencyId = 0;
      this.agencyId.setValue(null);
      this.clearAgency = true;
    }
  }
}
