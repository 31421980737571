import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SelectType } from "@core/enums/select";
import { ClassDeclineReleaseReason, StudentRequestReason } from "@core/enums/student-request-reason";
import { IAgency } from "@core/interfaces/iagency";
import { IClassExitReason } from "@core/interfaces/iclasses";
import { ISites } from "@core/interfaces/isites";
import { IState } from "@core/interfaces/istate";
import { IStudentRequest, IStudentRequestSearch } from "@core/interfaces/istudent-request";
import { AuthService } from "@core/services/auth.service";
import { ClassroomService } from "@core/services/classroom.service";
import { mapClassResToDRDPSelect } from "@core/services/helper.service";
import { SiteService } from "@core/services/site.service";
import { StudentRequestService } from "@core/services/student-request.service";
import { ToastService } from "@core/services/toast.service";
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { combineLatest, Observable, take } from "rxjs";
import { DeclineReleaseStudentComponent } from "./decline-release-student/decline-release-student.component";
import { Permission } from "@core/enums/permissions";
import { SelectAgencyComponent } from "@shared/components/dropdowns/select-agency/select-agency.component";
import { SelectStateComponent } from "@shared/components/dropdowns/select-state/select-state.component";

@Component({
    selector: 'drdp-pending-releases',
    templateUrl: './pending-releases.component.html',
    styleUrls: ['./pending-releases.component.scss'],
})
export class PendingReleasesComponent implements OnInit {
    @ViewChildren(FilterSelectComponent) selectComponents:
        | QueryList<FilterSelectComponent>
        | undefined;
    @ViewChild('agencySelect') agencySelect: SelectAgencyComponent | undefined;
    @ViewChild('stateSelect') stateSelect: SelectStateComponent | undefined;
    @ViewChild('siteSelect') siteSelect: SelectStateComponent | undefined;

    searchPayload!: IStudentRequestSearch;

    user = this.authService.getCurrentUser();
    canViewMultipleAgencies!: boolean;

    searchDropdownOptions$!: Observable<any>;
    selectSites = SelectType.Sites;
    selectClass = SelectType.Class;
    exitReasons!: IClassExitReason[];
    agencies!: IAgency[];
    sites!: ISites[];
    classrooms!: any[];
    Permission = Permission;
    initialAgencyId?: number | undefined = !this.canViewMultipleAgencies
        ? this.user.agencyId
        : undefined;
    stateSearchId?= this.user.stateId;
    clearDropdowns = false;

    studentRequestPayload: IStudentRequest[] = [];
    originalData: IStudentRequest[] = [];
    existingData?: any;
    tableData?: any;

    releaseRequestSearchForm: FormGroup | any;
    get firstName() {
        return this.releaseRequestSearchForm.get('firstName');
    }
    get lastName() {
        return this.releaseRequestSearchForm.get('lastName');
    }
    get stateId() {
        return this.releaseRequestSearchForm.get('stateId');
    }
    get agencyId() {
        return this.releaseRequestSearchForm.get('agencyId');
    }
    get classroomId() {
        return this.releaseRequestSearchForm.get('classroomId');
    }
    get fromSiteId() {
        return this.releaseRequestSearchForm.get('fromSiteId');
    }

    constructor(
        private studentRequestsService: StudentRequestService,
        public modal: MatDialog,
        private authService: AuthService,
        private toastService: ToastService,
        public fb: FormBuilder,
        private router: Router,
        private siteService: SiteService,
        private classroomService: ClassroomService) { }

    ngOnInit(): void {
        this.setDefaultPayload();
        this.initializeSearchForm();

        if (this.user.stateId) {
            this.searchPayload.stateId = this.user.stateId;
        }
        if (this.user.agencyId) {
            this.searchPayload.agencyId = this.user.agencyId;
            this.siteService.getSitesByAgencyId(this.user.agencyId).pipe(take(1)).subscribe(result=> {
              this.sites = result;
            })

        }
        this.canViewMultipleAgencies =
            this.authService.isSuperAdmin() || this.authService.isCustomerService();

        this.getPendingReleases();
        this.stateId.setValue(this.stateSearchId);
        this.agencyId.setValue(this.initialAgencyId);
    }

    setDefaultPayload(): void {
        this.searchPayload = {
            requestReasonId: StudentRequestReason.Release,
        };
    }

    initializeSearchForm(): void {
        this.releaseRequestSearchForm = this.fb.group({
            stateId: [null],
            agencyId: [null],
            classroomId: [null],
            fromSiteId: [null],
            firstName: [null, [Validators.maxLength(100)]],
            lastName: [null, [Validators.maxLength(100)]],
        });
    }

    onDeclineChange(event: any, index: number) {
        const data = this.tableData[index];

        if (event.target.checked)
            this.openDeclineReleaseRequestModal(data);
        else {
            data.classDeclineReleaseReasonId = null;
            data.declineReleaseReasonName = null;
            data.adminComment = null;
            data.decline = false;
            if (!data.accept) {
                data.accept = null;
            }
        }
    }

    onAcceptChange(event: any, index: number) {
        const data = this.tableData[index];
        data.decline = null;
        data.accept = !data.accept ? true : null;
        if (!data.accept) {
            data.decline = false;
        }

        if (event.target.checked) {

            data.classDeclineReleaseReasonId = null;
            data.declineReleaseReasonName = null;
            data.adminComment = null;
        }
    }

    openDeclineReleaseRequestModal(event: any) {
        if (event) {
            const modalRef = this.modal.open(DeclineReleaseStudentComponent, {
                data: event ? event : undefined,
            });

            modalRef.afterClosed().subscribe((res) => {
                if (res.success) {
                    event.classDeclineReleaseReasonId = res?.data?.classDeclineReleaseReasonId;
                    event.declineReleaseReasonName = event.classDeclineReleaseReasonId === ClassDeclineReleaseReason.Other ? res?.data?.declineReleaseReasonName + ': ' + res?.data?.comment : res?.data?.declineReleaseReasonName;
                    event.adminComment = res?.data?.comment;
                    event.accept = null;
                    event.decline = true;
                    if (event.decline) {
                        event.accept = false;
                    }
                } else {
                    event.decline = null;
                }
            });
        } else {
            this.toastService.error($localize `No Student Data Present`);
        }
    }

    getPendingReleases(): void {
        this.studentRequestsService
            .getByReasonAndAgency(this.searchPayload)
            .subscribe((res: any) => {
                if (res) {
                    this.tableData = res;
                }
            });
    }

    handleState(state: IState) {
        if (!state) {
            this.stateId.setValue(null);
            this.agencyId.setValue(null);
            this.clearDropdownOptions();
        } else if (state.id == this.stateId.value) {
            return;
        } else {
            this.stateId.setValue(state.id);
        }
    }

    handleAgency(agency: IAgency) {
        if (!agency) {
            this.agencyId.setValue(null);
            this.clearDropdownOptions();
        } else if (agency.id == this.agencyId.value) {
            return;
        } else {
            this.agencyId.setValue(agency.id);
            this.searchDropdownOptions$ = combineLatest({
                sites: this.siteService.getSitesByAgencyId(agency.id),
                classrooms: this.classroomService.getClassroomsByAgency(agency.id),
            });
            this.searchDropdownOptions$.pipe(take(1)).subscribe((res: any) => {
                this.sites = res['sites'];
                this.classrooms = mapClassResToDRDPSelect(res['classrooms']);
            });
        }
    }

    handleSites(site: any): void {
        if (!site || site == undefined) {
            this.classrooms = [];
            this.fromSiteId.setValue(null);
            this.classroomId.setValue(null);

            return;
        }

        this.fromSiteId.setValue(site.id);
        this.classroomService
            .getClassroomsBySite(site.id)
            .pipe(take(1))
            .subscribe((res: any) => {
                this.classrooms = mapClassResToDRDPSelect(res, true);
            });
    }

    handleClassroom(classroom: any): void {
        this.classroomId.setValue(null);
        if (!classroom) {
            return;
        }
        this.classroomId.setValue(classroom.id);
    }

    clearDropdownOptions(): void {
        this.sites = [];
        this.fromSiteId?.setValue(null);
        this.classrooms = [];
        this.classroomId?.setValue(null);
    }

    search() {
        this.searchPayload.firstName = this.firstName?.value;
        this.searchPayload.lastName = this.lastName?.value;
        this.searchPayload.agencyId = this.canViewMultipleAgencies
            ? this.agencyId?.value
            : this.user.agencyId;
        this.searchPayload.stateId = this.stateId?.value;
        this.searchPayload.fromSiteId = this.fromSiteId?.value;
        this.searchPayload.classroomId = this.classroomId?.value;
        this.getPendingReleases();
    }

    onClear() {
        this.clearDropdownOptions();
        this.clearDropdowns = !this.clearDropdowns;
        this.agencySelect?.clearSelected();
        this.stateSelect?.clearSelected();
        this.siteSelect?.clearSelected();
        this.firstName?.setValue(null);
        this.lastName?.setValue(null);

        this.stateSearchId = undefined;
        this.initialAgencyId = undefined;

        this.setDefaultPayload();
        this.getPendingReleases();
    }

    onCancel() {
      this.router.navigateByUrl('/dashboard');
    }

    onSubmit() {
        // Filter out items without data to update
        const dataToUpdate = this.tableData.filter((data: any) => data.accept !== undefined && data.accept !== null);

        if (dataToUpdate.length == 0) {
            this.toastService.error($localize `No request to be updated.`);
            return;
        }

        dataToUpdate.forEach((d: any)=> d.agencyId = this.searchPayload.agencyId);

        this.studentRequestsService
            .processBulkReleaseUpdates(
                dataToUpdate,
                $localize `Release requests have been updated.`
            )
            .subscribe((res: any) => {
                if (res) {
                    this.existingData = null;
                    this.getPendingReleases();
                }
            });
    }
}
