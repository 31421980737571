import { Component, OnInit, inject } from '@angular/core'
import { ToastService } from '@core/services/toast.service';
import { environment } from '@env/environment';

@Component({
  selector: 'drdp-permission-and-roles',
  templateUrl: './permission-and-roles.component.html',
  styleUrls: ['./permission-and-roles.component.scss'],
})
export class PermissionAndRolesComponent implements OnInit {
  private storageUrl = environment.storageUrl;
  toastService = inject(ToastService);
  downloadFile = [
    {
      label: $localize `Roles & Permissions`,
      icon: 'uil uil-file-download',
      description: $localize `Download`,
    },
  ]

  constructor() {}

  ngOnInit(): void {}

  downloadReport(): void {
    const fileUrl = `${this.storageUrl}drdp-rebuild-resources/DRDP Online 2023 Roles and Permissions.pdf`;
    this.fetchAndDownloadFile(fileUrl, 'DRDP Roles and Permissions');
  }

  private fetchAndDownloadFile(fileUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.style.display = 'none';

    fetch(fileUrl)
      .then(response => {
        if (!response.ok) {
          this.toastService.error($localize `Failed to download file.`);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        this.toastService.error($localize `Failed to download file.`);
      })
      .finally(() => {
        document.body.removeChild(link);
      });

    document.body.appendChild(link);
  }

}
