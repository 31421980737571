import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectType } from '@core/enums/select';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { LookupService } from '@core/services/lookup.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';
@Component({
  selector: 'drdp-download-search',
  templateUrl: './download-search.component.html',
  styleUrls: ['./download-search.component.scss'],
})
export class DownloadSearchComponent implements OnInit {
  @Output() searchClicked = new EventEmitter();
  @Input() set disableDropdown(value: boolean) {
    this._disableDropdown = value;
    if (this.searchForm) {
      this.updateFormValidation();
    }
  }
  
  get disableDropdown(): boolean {
    return this._disableDropdown;
  }
  
  private _disableDropdown = false;

  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @ViewChild('ageGroupInstrumentSelect') ageGroupInstrumentSelect:
    | FilterSelectComponent
    | undefined;
  @Input() isPilot = false;

  get stateId() {
    return this.searchForm.get('stateId');
  }
  get agencyId() {
    return this.searchForm.get('agencyId');
  }
  get agencyIds() {
    return this.searchForm.get('agencyIds');
  }
  get siteIds() {
    return this.searchForm.get('siteIds');
  }
  get ratingPeriodId() {
    return this.searchForm.get('ratingPeriodId');
  }
  get classIds() {
    return this.searchForm.get('classIds');
  }
  get ageGradeInstrumentId() {
    return this.searchForm.get('ageGradeInstrumentId');
  }
  get isAllPilot() {
    return this.searchForm.get('isAllPilot');
  }

  auth = inject(AuthService);
  fb = inject(FormBuilder);
  ratingPeriodService = inject(RatingPeriodService);
  lookup = inject(LookupService);
  classService = inject(ClassService);
  toastService = inject(ToastService);

  user = this.auth.getCurrentUser();
  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  searchForm: FormGroup | any;
  classroomList: IClassroomAssignment[] = [];
  clearDropdown = false;
  select = SelectType;

  constructor() {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null],
      agencyIds: [null],
      ratingPeriodId: [null, [Validators.required]],
      siteIds: [null],
      classIds: [null],
      ageGradeInstrumentId: [null, [Validators.required]],
      isAllPilot: [false],
    });
  
    // Add listener to isAllPilot control
    this.searchForm.get('isAllPilot')?.valueChanges.subscribe((isAllPilot: boolean) => {
      this.updateValidators(isAllPilot);
    });
  }
  
  updateValidators(isAllPilot: boolean): void {
    const controlsToUpdate = ['agencyId', 'agencyIds', 'siteIds', 'classIds', 'ageGradeInstrumentId'];
    
    controlsToUpdate.forEach(controlName => {
      const control = this.searchForm.get(controlName);
      if (control) {
        if (this.isPilot && isAllPilot) {
          control.clearValidators();
        } else {
          this.agencyId.setValue(this.user.agencyId);
          this.updateFormValidation();
        }
        control.updateValueAndValidity();
      }
    });
  }
  
  updateFormValidation(): void {
    if (this.disableDropdown) {
      this.searchForm.get('agencyId').clearValidators();
      this.searchForm.get('siteIds').clearValidators();
      this.searchForm.get('classIds').clearValidators();
      this.searchForm.get('agencyIds').setValidators([Validators.required]);
    } else {
      this.searchForm.get('agencyId').setValidators([Validators.required]);
      this.searchForm.get('siteIds').setValidators([Validators.required]);
      this.searchForm.get('classIds').setValidators([Validators.required]);
      this.searchForm.get('agencyIds').clearValidators();
    }
  
    this.searchForm.get('agencyId').updateValueAndValidity();
    this.searchForm.get('siteIds').updateValueAndValidity();
    this.searchForm.get('classIds').updateValueAndValidity();
    this.searchForm.get('agencyIds').updateValueAndValidity();
  }

  handleState(state: any) {
    this.stateId.setValue(state?.id);
    this.agencyId.setValue(null);
    this.ageGradeInstrumentId.setValue(null);
  }

  handleChosenAgency(agency: any): void {
    this.agencyId.setValue(agency?.id);
    this.classIds.setValue(null);
    this.classroomList = [];
  }

  handleChosenAgencies(agency: any): void {
    const selectedAgencyIds =
      agency?.length > 0 ? agency.map((c: { id: number }) => c.id) : null;
    this.agencyIds.setValue(selectedAgencyIds);
    this.agencyId.setValue(null);
  }

  handleChosenSite(sites: any): void {
    const selectedSiteIds =
      sites.length > 0 ? sites.map((c: { id: number }) => c.id) : null;
    this.siteIds.setValue(selectedSiteIds);
    this.updateClassroomDropdown();
  }

  handleChosenClassroom(classrooms: any): void {
    const selectedClassIds =
      classrooms.length > 0
        ? classrooms.map((c: { id: number }) => c.id)
        : null;
    this.classIds.setValue(selectedClassIds);
  }

  handleAgeGroupInstrument(ageGroupInstrument: any): void {
    this.ageGradeInstrumentId.setValue(ageGroupInstrument?.id);
  }

  handleChosenRatingPeriod(ratingPeriod: any): void {
    if (ratingPeriod) {
      this.ratingPeriodId.setValue(ratingPeriod?.id);
    }
    
    this.updateClassroomDropdown();
  }

  updateClassroomDropdown(): void {
    this.classroomList = [];
    const payload: any = {};
    payload.siteIds = this.siteIds.value ?? null;
    payload.ratingPeriodId = this.ratingPeriodId.value ?? '';
    payload.useClassId = true;

    if (payload.siteIds?.length > 0 && payload.ratingPeriodId > 0) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomList = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id && (value.isPilot ==  this.isPilot))
          );
        });
    }
  }

  clear(): void {
    this.ageGradeInstrumentId.setValue(null);
    this.ratingPeriodId.setValue(null);
    this.classIds.setValue(null);
    this.siteIds.setValue(null);
    this.classroomSelect?.clear();
    this.clearDropdown = true;
    this.classroomList = [];

    if (!this.stateSelect?.disabled) {
      this.initStateId = 0;
      this.stateId.setValue(null);
    }

    if (!this.agencySelect?.disabled) {
      this.agencyId.setValue(null);
    }
  }

  search() {
    if (this.searchForm.invalid) {
      this.toastService.error($localize `Please enter all required fields.`);
      return;
    }

    if (this.isAllPilot.value) {
      const payload = {
        stateId: this.stateId.value,
        isAllPilot: true
      };
      this.searchClicked.emit(payload);
    } else {
      this.searchClicked.emit(this.searchForm.value);
    }
  }
}
