<div class="fix-action-row modal-body">
    <div class="flex justify-between border-b">
      <h1 class="text-[25px] font-bold" i18n>Request Release from Current Agency</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>

    <div mat-dialog-content>
      <form class="grid grid-cols-2 gap-x-10" [formGroup]="releaseStudentForm">

        <div class="col-span-full md:col-span-1 pt-3">
          <label class="font-normal" for="Name" i18n>Child's Name</label>
          <p>{{child.firstName + ' ' + child.lastName}}</p>
        </div>

        <div class="col-span-full md:col-span-1 pt-4">
          <mat-form-field class="example-full-width no-underline" appearance="fill">
            <label class="font-normal" i18n>Release Reason<span class="required"></span></label>
            <mat-select formControlName="classReleaseReasonId" placeholder='Reason' i18n-placeholder>
              <mat-option *ngFor="let reason of releaseReasons$ | async" [value]="reason.id"
                (click)="getSelectedReleaseReason(reason)"> {{ reason.releaseReason }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="isOther" class="col-span-full">
          <mat-form-field class="bg-white" appearance="fill">
            <label class="font-normal" for="comment" i18n>Comment</label>
            <input matInput placeholder="Comments" i18n-placeholder autocomplete="off" type="text" formControlName="comment">
          </mat-form-field>
        </div>

      </form>

    </div>
  </div>
  <div mat-dialog-actions class="flex justify-end pt-4">
    <drdp-btn color="cancel" mat-button mat-dialog-close i18n>Cancel</drdp-btn>
    <drdp-btn class="ml-3" mat-button [disabled]="releaseStudentForm.invalid" cdkFocusInitial (click)="onSubmit()" i18n="requestReleaseStudentDialog">Request Release</drdp-btn>
  </div>
