import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import {
  MeasureCondition,
  MeasureConditionName,
} from '@core/enums/measure-condition';
import { IPortfolioRatingSheet } from '@core/interfaces/iportfolio';
import { IMeasureLevel } from '@views/user/input-rating/interfaces/imeasure-level';
import { IRatingMeasureDto } from '@views/user/input-rating/interfaces/irating-measure';

@Component({
  selector: 'drdp-rating-sheet',
  templateUrl: './rating-sheet.component.html',
  styleUrls: ['./rating-sheet.component.scss'],
})
export class RatingSheetComponent implements OnInit, AfterViewInit {
  @ViewChildren('levelRadioButtons') levelRadioButtons?: QueryList<ElementRef>;
  @Output() rating = new EventEmitter();
  @Input() ratingSheet!: IPortfolioRatingSheet;
  @Input() dialogData!: IRatingMeasureDto;
  @Input() isPortfolio = false;
  otherText = $localize `Other`;
  public get measureCondition() {
    return MeasureCondition;
  }

  public get measureConditionName() {
    return MeasureConditionName;
  }

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.dialogData.isPilotEnrollment) {
      this.scrollToRadioButton();
    }
  }

  selectCondition(level: IMeasureLevel | any) {
    if (level.id == MeasureCondition.Emerging) {
      if (!this.ratingSheet.canEmerging) return;
      else this.ratingSheet.measureConditionId = level.id;
    } else {
      this.ratingSheet.measureConditionId = level.id;
      this.ratingSheet.canEmerging = false;
      this.ratingSheet.selectedValue = null;
    }
    this.ratingSheet.measureConditionName = level.name;
    this.rating.emit(this.ratingSheet);
  }

  selectLevel(level: IMeasureLevel) {
    if (level.isDisabled) return;
    this.ratingSheet.canEmerging =
      level.id != (this.ratingSheet.measureRatingLevel?.maxLevel ?? 0);
    this.ratingSheet.selectedValue = level.id;
    if (
      this.ratingSheet.canEmerging &&
      this.ratingSheet.measureConditionId == MeasureCondition.Emerging
    )
      return;
    this.ratingSheet.measureConditionId = null;
    this.ratingSheet.measureConditionName = null;
    this.rating.emit(this.ratingSheet);
  }

  selectReason(reason: any) {
    this.ratingSheet.reasonId = reason.id;
    this.ratingSheet.showComment = reason.reasonComment == this.otherText;
    if (!this.ratingSheet.showComment) this.ratingSheet.comment = null;
    this.rating.emit(this.ratingSheet);
  }

  scrollToRadioButton() {
    const levelCode = this.getLevelCode();

    if (levelCode) {
      setTimeout(() => this.scrollToElementById(levelCode), 1000);
    }
  }

  private getLevelCode(): string {
    const ageGradeMap: { [key: string]: { [age: number]: string } } = {
      PTK: {
        3: 'BL',
        4: 'IM',
        5: 'IL',
        6: 'IL',
      },
      'PTK-3': {
        6: 'IL',
      },
    };

    const instrumentCode = this.dialogData.ageGradeInstrumentCode;
    const age = this.dialogData.age ?? 0;
    if (ageGradeMap[instrumentCode] && ageGradeMap[instrumentCode][age]) {
      return ageGradeMap[instrumentCode][age];
    }

    return '';
  }

  private scrollToElementById(levelCode: string) {
    if (this.levelRadioButtons == null) {
      return;
    }

    const radioButton = this.levelRadioButtons.find((radio) => radio.nativeElement.id === levelCode);

    if (radioButton) {
      const parentElement = radioButton.nativeElement.parentElement?.parentElement;
      const elementToScroll = parentElement || radioButton.nativeElement;
      elementToScroll.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }
}
