import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastService } from '@core/services/toast.service';
import { createLogicalAnd } from 'typescript';

export interface IPaginate {
  totalItems: number,
  itemsPerPage: number,
  numOfPages: number,
  currentPage: number,
  startIndex: number,
  lastIndex: number
}

@Component({
  selector: 'drdp-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() itemsPerPageValue: number = 10
  @Input() dataLength: number=0
  @Output() paginationChange = new EventEmitter<IPaginate>()

  pagination: IPaginate = {
    totalItems: this.dataLength,
    itemsPerPage: this.itemsPerPageValue,
    numOfPages: Math.ceil(this.dataLength / this.itemsPerPageValue),
    currentPage: 1,
    startIndex: 0,
    lastIndex: 9
  }


  constructor(public toast: ToastService) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pagination = {
      totalItems: this.dataLength,
      itemsPerPage: this.itemsPerPageValue,
      numOfPages: Math.ceil(this.dataLength / this.itemsPerPageValue),
      currentPage: 1,
      startIndex: 0,
      lastIndex: this.itemsPerPageValue - 1
    }
  }

  paginationFilter(page: string) {
    switch (page) {

      case 'beforePrevious':
          this.pagination.currentPage = this.pagination.currentPage - 2;
          this.pagination.startIndex = this.pagination.startIndex - (this.pagination.itemsPerPage * 2)
          this.pagination.lastIndex = this.pagination.startIndex + this.pagination.itemsPerPage -1
          this.paginationChange.emit(this.pagination)
        break;

      case 'previous':
          this.pagination.currentPage--;
          this.pagination.startIndex = this.pagination.startIndex - this.pagination.itemsPerPage
          this.pagination.lastIndex = this.pagination.startIndex + this.pagination.itemsPerPage - 1
          this.paginationChange.emit(this.pagination)
        break;

      case 'first':
        this.pagination.currentPage = 1;
        this.pagination.startIndex = 0;
        this.pagination.lastIndex = this.pagination.itemsPerPage - 1;
        this.paginationChange.emit(this.pagination)
        break;

      case 'next':
        this.pagination.currentPage++;
        this.pagination.lastIndex = this.pagination.lastIndex + this.pagination.itemsPerPage
        this.pagination.startIndex = this.pagination.startIndex + this.pagination.itemsPerPage
        if (this.pagination.lastIndex>this.pagination.totalItems) this.pagination.lastIndex =this.pagination.totalItems
        this.paginationChange.emit(this.pagination)
        break;

      case 'afterNext':
        this.pagination.currentPage = this.pagination.currentPage + 2;
        this.pagination.lastIndex = this.pagination.lastIndex + (this.pagination.itemsPerPage * 2)
        this.pagination.startIndex = this.pagination.startIndex + (this.pagination.itemsPerPage * 2)
        this.paginationChange.emit(this.pagination)
        break;

      case 'last':
        this.pagination.currentPage = this.pagination.numOfPages
        this.pagination.startIndex = this.pagination.totalItems - (this.pagination.totalItems % this.pagination.itemsPerPage);
        this.pagination.lastIndex = this.pagination.totalItems;
        this.paginationChange.emit(this.pagination)
        break;

      default:
      break;
    }
  }

}
