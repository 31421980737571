<div class="rounded-lg fixActionRow" id="addFeatureDialog">
    <div class="flex justify-between border-b">
      <h1 class="text-[25px] font-bold">{{headerName}}</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>

    <div mat-dialog-content>
      <form class="grid grid-cols-1 gap-10 py-5 px-2" [formGroup]="featureForm">
          <div class="col-span-1">
            <label i18n class="font-normal" for="FeatureName">Feature Name</label>
            <mat-form-field class="bg-white" appearance="fill">
              <input matInput
                     placeholder="Feature Name*"
                     i18n-placeholder
                     autocomplete="off"
                     id="featureName"
                     type="text"
                     formControlName="permissionName"
                     maxlength="100"
                     required>
            </mat-form-field>
          </div>

          <div class="col-span-1">
            <label i18n class="font-normal" for="featureDescription">Feature Description</label>
            <mat-form-field class="bg-white" appearance="fill">
              <input matInput
                     placeholder="Feature Description*"
                     i18n-placeholder
                     autocomplete="off"
                     id="featureDescription"
                     type="text"
                     formControlName="permissionDescription"
                     maxlength="100"
                     required>
            </mat-form-field>
          </div>

      </form>

    </div>
    <div mat-dialog-actions class="flex justify-end align-bottom">
      <button i18n mat-button mat-dialog-close>Cancel</button>
      <drdp-btn i18n *ngIf="featureForm.valid" mat-button [mat-dialog-close]="onSubmit()" [disabled]="false" cdkFocusInitial>Save</drdp-btn>
      <drdp-btn i18n *ngIf="!featureForm.valid" mat-button [disabled]="true" cdkFocusInitial>Save</drdp-btn>
    </div>
  </div>
