import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';
import { IRatingDomainDto } from '../../interfaces/irating-domain';
import { IStudentEnrollmentInfo } from '../../interfaces/istudent-enrollment-info';
import { IRatingMeasureDto } from '../../interfaces/irating-measure';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { ITeacherSurvey, ITeacherSurveyRequest } from '../../interfaces/iteacher-survey';
@Component({
  selector: 'drdp-rating-card',
  templateUrl: './rating-card.component.html',
  styleUrls: ['./rating-card.component.scss'],
})
export class RatingCardComponent implements OnInit {
  @Input() studentDomains: IRatingDomainDto[] = [];
  @Input() studentInfo?: IStudentEnrollmentInfo;
  @Output() actionClicked = new EventEmitter();
  @Output() editClicked = new EventEmitter();
  @Output() viewClicked = new EventEmitter();
  private permissionService = inject(PermissionService);
  canCreateRating: boolean = this.permissionService.checkPermission(
    Permission.CreateRating
  );

  constructor() {}

  noneSelected: string = $localize`None Selected`;
  ngOnInit(): void {}

  selectRating(domain: IRatingDomainDto, measure: IRatingMeasureDto): void {
    if (!measure.ratingValue && !measure.measureConditionId && this.studentInfo?.isPilotEnrollment) {
      const teacherSurveyReq: ITeacherSurveyRequest = {
        cardId: this.studentInfo?.carId,
        measureId: measure.id,
        isStructuredPrompt: measure.isStructuredPrompt
      };
      this.actionClicked.emit({ domain, measure, teacherSurveyReq });
    } else {
      this.actionClicked.emit({ domain, measure });
    }
  }

  editTeacherSurvey(measure: IRatingMeasureDto): void {
    const teacherSurveyReq: ITeacherSurveyRequest = {
      cardId: this.studentInfo?.carId,
      measureId: measure.id,
      isStructuredPrompt: measure.isStructuredPrompt
    };
    this.editClicked.emit({ teacherSurveyReq });
  }

  viewTeacherSurvey(measure: IRatingMeasureDto): void {
    const teacherSurveyReq: ITeacherSurveyRequest = {
      cardId: this.studentInfo?.carId,
      measureId: measure.id,
      isStructuredPrompt: measure.isStructuredPrompt
    };
    this.viewClicked.emit({ teacherSurveyReq });
  }
}
