import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TableColumnType } from '@core/enums/table';
import { RatingService } from '@core/services/rating.service';
import { StudentService } from '@core/services/student.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-view-child-assessments',
  templateUrl: './view-child-assessments.component.html',
  styleUrls: ['./view-child-assessments.component.scss'],
})
export class ViewChildAssessmentsComponent implements OnInit {
  studentId: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;

  tableColumns = [
    {
      columnDef: 'ratingPeriodName',
      header: $localize `Rating Period`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'classroomName',
      header: $localize `Classroom Name`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'hasRating',
      header: $localize `Assessment`,
      type: TableColumnType.boolIcon,
      truthyClass: 'file-download text-drdpblue-300',
      falsyIcon: '',
      action: 'download',
      cursorClass: 'cursor-pointer',
    },
    {
      columnDef: 'agencyName',
      header: $localize `Agency Name`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'siteName',
      header: $localize `Site Name`,
      type: TableColumnType.text,
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private studentService: StudentService,
    private ratingService: RatingService
  ) {}

  ngOnInit(): void {
    this.studentId = Number(this.route.snapshot.paramMap.get('id'));
    this.studentService
      .getStudentAssessments(this.studentId)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.dataSource = new MatTableDataSource(res);
        this.tableData = res;
      });
  }

  onIconClick(event: any): void {
    if (event.action == 'download') this.downloadUrl(event.data.carId);
  }

  downloadUrl(id: number) {
    this.ratingService
      .getRatingDownloadUrl(id)
      .pipe(take(1))
      .subscribe((res: any) => {
        const link = document.createElement('a');
        link.href = res.url;
        link.click();
      });
  }
}
