import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectType } from '@core/enums/select';
import { ISites } from '@core/interfaces/isites';
import { take } from 'rxjs';
import { SiteService } from '@core/services/site.service';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-select-site',
  templateUrl: './select-site.component.html',
  styleUrls: ['./select-site.component.scss'],
})
export class SelectSiteComponent implements OnInit {
  @ViewChild('siteSelect') siteSelect: FilterSelectComponent | undefined;
  @Output() site = new EventEmitter<any>();
  @Input() initialSiteId?: number | null;
  @Input() initialSiteIds?: number[] | null;
  @Input() agencyId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() isValid = true;
  @Input() required = false;
  @Input() label = $localize `Site`;
  @Input() reloadSites = false;
  @Input() isDualEnrollments = false;
  @Input() isPilot: boolean = false;
  siteOptions?: ISites[] = [];
  selectedState?: number;
  selectedAgency?: number;
  public get select() {
    return SelectType;
  }

  constructor(
    private siteService: SiteService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    //commenting this out for now since this should have been done by the ngOnChanges
    // if (this.agencyId) this.getSites(this.agencyId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      if (this.multiSelect) {
        this.siteSelect?.clearAll();
      } else {
        this.siteSelect?.clearSearch(true);
      }
    }

    if (this.reloadSites) {
      if (this.agencyId) {
        this.getSites(this.agencyId);
      }
    }

    if (changes['agencyId']) {
      if (this.agencyId) {
        this.siteSelect?.clearSearch(true);
        this.getSites(this.agencyId);
      }
      else
        this.siteOptions = [];

    }

    if (changes['initialSiteId'] && this.initialSiteId) {
      this.initialSiteIds = [];
      this.initialSiteIds.push(this.initialSiteId);
    }
  }

  clearSelected() {
    this.siteSelect?.clearSearch(true);
  }

  getSites(agencyId: number | number[]): void {
    if (!Array.isArray(agencyId)) agencyId = [agencyId];

    this.siteService.getSitesByPermission(agencyId, this.isPilot).pipe(take(1)).subscribe((sites: ISites[]) => {
      this.siteOptions = sites.filter((site: ISites) => site.isActive);
      
      if (this.isDualEnrollments) {
        this.siteOptions = this.siteOptions.filter((site: ISites) => site.isDualEnrollment);
      }

      if (this.initialSiteIds) {
        const selected = this.siteOptions!.filter((site: ISites) =>
          this.initialSiteIds?.includes(site.id)
        );
        this.site.emit(selected);
      }
    });
  }

  onSiteSelect(event: ISites): void {
    this.site.emit(event);
  }
}
