<div class="rounded-lg  modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">
      <span i18n>Viewing Students in </span>
      {{ classroomName || '(Classroom Name Not Found)' }}
    </h1>
    <i
      mat-button
      mat-dialog-close
      class="text-drdpblue-300 cursor-pointer uil uil-times-circle"
    ></i>
  </div>
  <div class="w-full">
    <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData"
      [columns]="tableColumns" (iconActionItem)="onIconClick($event)">
    </drdp-add-edit-table>
  </div>
  <mat-paginator class="mb-12" [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginate(false)">
  </mat-paginator>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <div class="flex justify-end align-bottom">
      <drdp-btn i18n color="cancel" class="mr-2" mat-dialog-close>Cancel</drdp-btn>
    </div>
  </div>
</div>
