import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { combineLatest, Observable, Subscription, take } from 'rxjs';
import { LookupService } from '@core/services/lookup.service';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';
import { IInitialAssessmentToolType, IOutcome, ISelectBoolean } from '@core/interfaces/iselectable';
import {
  IAgeGradeInstrument,
  IAgeGradeEnrollmentProgramFilter,
} from '@core/interfaces/iage-grade-instrument';
import { ILanguage } from '@core/interfaces/ilanguage';
import { IDistrictOfLiability } from '@core/interfaces/idistrict-of-liability';
import { IAdaptationType } from '@core/interfaces/iadaptation-type';
import { IEthnicity } from '@core/interfaces/iethnicity';
import { ICounty } from '@core/interfaces/icounty';
import { AuthService } from '@core/services/auth.service';
import { IDisability } from '@core/interfaces/idisability';
import { MatDialog } from '@angular/material/dialog';
import { AssignChildComponent } from './assign-child/assign-child.component';
import { IGender } from '@core/interfaces/igender';
import { ITuitionFee } from '@core/interfaces/ituition-fee';
import { IIepPlan } from '@core/interfaces/iiep-plan';
import { AgeGradeInstrument } from '@core/enums/age-grade-instruments';
import { EnrollmentProgram } from '@core/enums/enrollment-programs';
import { StateService } from '@core/services/state.service';
import {
  StudentDemographic,
  StudentDemographicEthnicity,
} from '@core/enums/student-demographic-form';
import { DemographicSections } from '@core/enums/demographic-sections';
import { DataType, FieldType } from '@core/enums/data-type';
import { ICustomFieldValue } from '@core/interfaces/icustom-field-value';
import { IStudentDuplicateValidationDto } from '@core/interfaces/istudent';
import { IDroppedChild } from '@core/interfaces/idropped-children';
import { Router } from '@angular/router';
import { formatDatesToString, replaceWhitespace } from '@core/services/helper.service';
import { States } from '@core/enums/states';
import { IepPlan } from '@core/enums/iep-plan';

@Component({
  selector: 'drdp-add-new-child',
  templateUrl: './add-new-child.component.html',
  styleUrls: [
    './add-new-child.component.scss',
    './../../../shared/components/drdp-tables/add-edit/add-edit.component.scss',
  ],
})
export class AddNewChildComponent implements OnInit {
  private subscriptions = new Subscription();

  get firstName() {
    return this.addChildForm.get('firstName');
  }
  get lastName() {
    return this.addChildForm.get('lastName');
  }
  get middleInitial() {
    return this.addChildForm.get('middleInitial');
  }
  get SSID() {
    return this.addChildForm.get('SSID');
  }
  get dob() {
    return this.addChildForm.get('dob');
  }
  get genderID() {
    return this.addChildForm.get('genderID');
  }
  get languages() {
    return this.addChildForm.get('languages');
  }
  get eld() {
    return this.addChildForm.get('eld');
  }
  get iepPlanId() {
    return this.addChildForm.get('iepPlanId');
  }
  get localID() {
    return this.addChildForm.get('localID');
  }
  get drdpId() {
    return this.addChildForm.get('drdpId');
  }
  get ageGradeInstrumentId() {
    return this.addChildForm.get('ageGradeInstrumentId');
  }
  get enrollmentPrograms() {
    return this.addChildForm.get('enrollmentPrograms');
  }
  get enrollmentStartDate() {
    return this.addChildForm.get('enrollmentStartDate');
  }
  get enrollmentEndDate() {
    return this.addChildForm.get('enrollmentEndDate');
  }
  get seEntryDate() {
    return this.addChildForm.get('seEntryDate');
  }
  get seAnticipateExitDate() {
    return this.addChildForm.get('seAnticipateExitDate');
  }
  get seExitDate() {
    return this.addChildForm.get('seExitDate');
  }
  get ethnicity() {
    return this.addChildForm.get('ethnicities');
  }
  get latino() {
    return this.addChildForm.get('latino');
  }
  get other() {
    return this.addChildForm.get('other');
  }
  get selpaId() {
    return this.addChildForm.get('selpaId');
  }
  get initialAssessmentToolId() {
    return this.addChildForm.get('initialAssessmentToolId');
  }
  get outcome1Id() {
    return this.addChildForm.get('outcome1Id');
  }
  get outcome2Id() {
    return this.addChildForm.get('outcome2Id');
  }
  get outcome3Id() {
    return this.addChildForm.get('outcome3Id');
  }
  get countyId() {
    return this.addChildForm.get('countyId');
  }
  get countyOfResidenceId() {
    return this.addChildForm.get('countyOfResidenceId');
  }
  get tuitionFeeId() {
    return this.addChildForm.get('tuitionFeeId');
  }
  get districtOfLiabilityId() {
    return this.addChildForm.get('districtOfLiabilityId');
  }
  get districtId() {
    return this.districtOfLiabilityId.value !== null
      ? this.districtsOfLiability.find(
        (dol: IDistrictOfLiability) => dol.id === this.districtOfLiabilityId.value)?.districtId || ''
      : '';
  }
  get sauId() {
    return this.districtOfLiabilityId.value !== null
      ? this.districtsOfLiability.find(
        (dol: IDistrictOfLiability) => dol.id === this.districtOfLiabilityId.value)?.sauId || ''
      : '';
  }

  iepPlan = IepPlan;
  currentSSID!: string;
  currentLocalID!: string;

  enrollmentProgramPlaceholder: string = $localize `Select Child Age/Grade Instrument`;

  getFormSelects$: Observable<any> | undefined;
  updateProgramTypesSelect$: Observable<any> | undefined;

  yesOrNo: ISelectBoolean[] = [];
  ethnicities: IEthnicity[] = [];
  languagesList: ILanguage[] = [];
  counties: ICounty[] = [];
  districtsOfLiability: IDistrictOfLiability[] = [];
  iepPlans: IIepPlan[] = [];
  adaptations: IAdaptationType[] = [];
  enrollmentProgramTypes!: IAgeGradeEnrollmentProgramFilter[];
  enrollmentProgramTypesFiltered!: IAgeGradeEnrollmentProgramFilter[];
  ageGradeInstruments: IAgeGradeInstrument[] = [];
  disabilities: IDisability[] = [];
  genders: IGender[] = [];
  tuitionFee: ITuitionFee[] = [];
  stateConfig: IStateConfiguration[] | null = null;
  customStateConfig: IStateConfiguration[] | null = null;
  droppedChildren: IDroppedChild[] = [];

  searchValues: any | null = {};

  addChildForm: FormGroup | any;
  userStateId: number = 0;
  otherRequired: boolean = false;
  EnrollmentProgram = EnrollmentProgram;
  AgeGradeInstrument = AgeGradeInstrument;
  ssidLabel = $localize `Statewide Student Identifier (10-Digit SSID)`;
  ssidmaxLength = 10;
  agencySidLabel = $localize `Agency/District Student Identifier (Agency/District)`;
  specialEdInfo = {
    isPrimaryDisabilityEnabled: false,
    isDistrictLiabilityEnabled: false,
    isSpecialEdEntryEnabled: false,
    isSpecialEdExitEnabled: false,
    isAnticipatedDateEnabled: false,
    isSelpaEnabled: false,
    isInitialAssessmentToolEnabled: false,
    isOutcome1Enabled: false,
    isOutcome2Enabled: false,
    isOutcome3Enabled: false,
  };
  customFieldMap = new Map();
  customFieldAnsCollection?: ICustomFieldValue[] = [];

  duplicateStudentValidationPayload!: IStudentDuplicateValidationDto;
  eventData!: any;
  chosenCountyOfProgram: string = '';
  initialCountyId: number[] = [];
  isEthnicityDisabled = false;
  isNewHampshire = false;
  ssidErrorMsg = $localize `Duplicate SSID found.`;
  ssidPatternErrorMsg: string = $localize `SSID must be numeric 10 digits.`;
  ssidLocalIdReqErrorMsg: string = $localize `Either the SSID or the Agency/District ID need to be filled out.`
  required: string = $localize `Field required.`;
  withdrawlInfo = $localize `In order to set the Agency Withdrawal date for the child, please withdraw the child from your agency in the Manage Enrollment page. The date will populate once the child is withdrawn.`;
  agencyEnrollmentInfo = $localize `The day the child was enrolled with your agency.`;
  specialEdExitInfo: string = $localize `In order to set the Special Education Exit Date for child, please exit the child from the Manage Enrollment > Classrooms ` +
  $localize `page. The date will populate once the child's exit date is saved.`;

  public get studentDemographics() {
    return StudentDemographic;
  }
  public get demographicSections() {
    return DemographicSections;
  }
  public get fieldType() {
    return FieldType;
  }
  public get dataType() {
    return DataType;
  }
  public get ethnicityvalues() {
    return StudentDemographicEthnicity;
  }


  constructor(
    private fb: FormBuilder,
    public toastService: ToastService,
    private lookupService: LookupService,
    public studentService: StudentService,
    public authService: AuthService,
    private stateService: StateService,
    public modal: MatDialog,
    private router: Router
  ) {
    this.searchValues =
      this.router.getCurrentNavigation()?.extras?.state?.['searchValues'] ||
      null;
  }

  ngOnInit(): void {
    this.userStateId = this.authService.getUserStateId();
    this.isNewHampshire = this.userStateId == States.newHampshire;
    this.initializeForm();
    this.onFormChanges();

    this.getFormSelects$ = combineLatest({
      ethnicities: this.lookupService.getEthnicities(this.userStateId),
      languagesList: this.lookupService.getLanguages(),
      counties: this.lookupService.getCountyList(),
      districtsOfLiability: this.lookupService.getDistrictsOfLiability(),
      iepPlans: this.lookupService.getIepPlans(),
      adaptations: this.lookupService.getAdaptationTypes(),
      ageGradeInstruments: this.lookupService.getAgeGradeInstruments(
        this.userStateId
      ),
      disabilities: this.lookupService.getDisabilities(),
      genders: this.lookupService.getGenders(this.userStateId), // needs to filter based on state config
      tuitionFeeId: this.lookupService.getTuitionFees(),
      yesOrNo: this.lookupService.getBasicSelects(),
      enrollmentProgramTypes: this.lookupService.getAgeGradeEnrollmentFilters(),
      stateConfig: this.stateService.getStateConfigurations(this.userStateId),
      customConfigs: this.stateService.getCustomStateConfigurations(
        this.userStateId
      ),
    });

    this.subscriptions.add(
      this.getFormSelects$.subscribe((res: any) => {
        this.ethnicities = res['ethnicities'];
        this.languagesList = res['languagesList'];
        this.counties = res['counties'];
        this.districtsOfLiability = res['districtsOfLiability'];
        this.iepPlans = res['iepPlans'];
        this.adaptations = res['adaptations'];
        this.ageGradeInstruments = res['ageGradeInstruments'];
        this.disabilities = res['disabilities'];
        this.genders = res['genders'];
        this.tuitionFee = res['tuitionFeeId'];
        this.yesOrNo = res['yesOrNo'];
        this.enrollmentProgramTypes = res['enrollmentProgramTypes'];
        this.stateConfig = res['stateConfig'];
        this.customStateConfig = res['customConfigs'];

        this.checkSpecialEdInfo();
        this.buildCustomConfigFormControl();

        // set SSID & LocalId custom labels
        const ssid = this.stateConfig?.find(
          (x: any) => x.columnName === this.studentDemographics.SSID
        );
        this.ssidLabel = ssid?.label ? ssid?.label : this.ssidLabel;
        const localId = this.stateConfig?.find(
          (x: any) => x.columnName === this.studentDemographics.AgencySID
        );
        this.agencySidLabel = localId?.label
          ? localId?.label
          : this.agencySidLabel;

        this.ssidErrorMsg = this.isNewHampshire
          ? $localize `Duplicate SASID found.`
          : this.ssidErrorMsg;

        this.ssidmaxLength = ssid?.maxLength! ?? this.ssidmaxLength;

        this.ssidPatternErrorMsg = `${this.isNewHampshire ? 'SASID' : 'SSID'}` + $localize `must be` +`${this.ssidmaxLength}` + $localize `numeric digits.`;

        this.ssidLocalIdReqErrorMsg = $localize `Either the` + ` ${this.isNewHampshire ? 'SASID' : 'SSID'} ` + $localize `or the Agency/District ID need to be filled out.`

        // Get SSID && LocalId Max Length
        if (ssid?.maxLength) {
          this.ssidmaxLength = ssid.maxLength;
          this.SSID.setValidators([Validators.pattern(new RegExp(`^\\d{${this.ssidmaxLength}}$`))]);
          this.SSID.updateValueAndValidity();
        }
      })
    );
  }

  initializeForm(): void {
    this.addChildForm = this.fb.group({
      // ========== Child Information ==========
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      middleInitial: [null],
      lastName: [null, [Validators.required]],
      SSID: [
        null,
        {
          validators: [Validators.required, Validators.pattern(/^\d{10}$/)],
          updateOn: 'blur',
        }
      ],

      localID: [
        null,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      drdpId: [null],
      ageGradeInstrumentId: [null, [Validators.required]],
      dob: [null, [Validators.required]],
      genderID: [null, [Validators.required]],
      socialEconomic: [0],
      // ========== Race & Ethnicity ==========
      latino: [null, [Validators.required]],
      ethnicities: [null, [Validators.required]],
      // ========== Language ==========
      languages: [null, [Validators.required]],
      eld: [null, [Validators.required]],
      // ========== Program ==========
      enrollmentPrograms: [null, Validators.required],
      other: [null],
      enrollmentStartDate: [null, [Validators.required]],
      enrollmentEndDate: [null],
      countyId: [null],
      countyOfResidenceId: [null],
      tuitionFeeId: [null],
      // ========== Special Education ==========
      iepPlanId: [null, [Validators.required]],
      primaryDisabilityId: [null],
      districtOfLiabilityId: [null],
      adaptations: [null],
      seEntryDate: [null],
      seAnticipateExitDate: [null],
      seExitDate: [null],
      selpaId: [null],
      initialAssessmentToolId: [null],
      outcome1Id: [null],
      outcome2Id: [null],
      outcome3Id: [null],
    });

    if (this.searchValues) {
      this.addChildForm.patchValue({
        firstName: this.searchValues.firstName,
        lastName: this.searchValues.lastName,
        dob: this.searchValues.dob,
        SSID: this.searchValues.ssid,
      });

      if (this.SSID.value) {
        this.currentSSID = this.SSID.value;
        this.SSID.updateValueAndValidity();

        if (this.SSID.valid) {
          this.validateSSID();
        }
      }
      if (this.searchValues.countyOfProgramId) {
        this.initialCountyId.push(this.searchValues.countyOfProgramId);
      }
    }
  }

  validateFormData(event?: any): void {
    if (!this.addChildForm.valid) {
      this.addChildForm.updateValueAndValidity();

      for (const field in this.addChildForm.controls) {
        const currentField = this.addChildForm.get(field);
        if (!currentField.validator) continue;

        const validator = currentField.validator({} as AbstractControl);
        if (validator && validator.required) {
          currentField.markAsDirty();
          currentField.markAsTouched();
          currentField.setErrors();
          currentField.updateValueAndValidity();
        }
      }

      this.toastService.error($localize `Please complete the form before submitting.`);

      return;
    }

    this.validateStudent();
    this.eventData = event;
  }

  validateSSID() {
    if (this.currentSSID) {
      this.studentService
        .checkDuplicateStringSSID(this.currentSSID, this.userStateId)
        .pipe(take(1))
        .subscribe((isDuplicate: boolean) => {
          if (isDuplicate) {
            this.SSID.setErrors({ duplicateSSID: true });
            this.localID.setValidators([Validators.required]);
            this.SSID.markAsTouched();
          } else {
            this.localID.setValidators([]);
          }
          this.localID.updateValueAndValidity();
        });
    }
  }

  validateStudent(): void {
    if (this.searchValues.isDifferentChild) this.prepareForAssigment();
    else {
      this.duplicateStudentValidationPayload = {
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        dob: this.dob.value,
      };

      this.studentService
        .validateStudent(this.duplicateStudentValidationPayload)
        .subscribe((isDuplicate: boolean) => {
          if (isDuplicate) {
            this.toastService.error(
              $localize `The child you are trying to enter already exists in DRDPOnline.  If you need assistance, please contact WestEd at 1-800-7700-6339 or email DRDPOnline@wested.org.`
            );
            return;
          }

          this.prepareForAssigment();
        });
    }
  }

  prepareForAssigment(): void {
    this.buildCustomConfigCollection();
    this.firstName?.setValue(replaceWhitespace(this.firstName.value));
    this.lastName?.setValue(replaceWhitespace(this.lastName.value));
    const payload = this.addChildForm.value;
    payload.CustomFields = this.customFieldAnsCollection;

    const modalRef = this.modal.open(AssignChildComponent, {
      data: payload,
    });
    modalRef.afterClosed().subscribe((student: any) => {
      if (student) {
        this.toastService.success($localize `Student Successfully Saved and Enrolled`);
        this.router.navigateByUrl(`view-child/${student}`);
      }
    });
  }

  onFormChanges(): void {
    this.ageGradeInstrumentId.valueChanges.subscribe((val: any) => {
    this.enrollmentPrograms.reset();
    this.enrollmentProgramPlaceholder = $localize `Select all that apply`;
    this.enrollmentProgramTypesFiltered =
    this.enrollmentProgramTypes!.filter(
            (ept: IAgeGradeEnrollmentProgramFilter) => {
              return ept.ageGradeInstrumentId === val;
            }
          );
    });

    this.enrollmentPrograms.valueChanges.subscribe((val: any) => {
      if (
        val &&
        (val.includes(this.EnrollmentProgram.other) ||
          val.includes(EnrollmentProgram.otherNH))
      ) {
        this.other.setValidators([Validators.required]);
        this.other.updateValueAndValidity();
        this.otherRequired = true;
        return;
      }

      this.other.reset();
      this.other.setValidators([]);
      this.other.updateValueAndValidity();
      this.otherRequired = false;
    });

    this.SSID.valueChanges.subscribe((val: any) => {
      if (this.currentSSID == val) return;
      this.currentSSID = val;
      if (this.SSID.valid && this.SSID.value != "") {
        this.validateSSID();
      } else {
        this.localID.setValidators([Validators.required]);
        this.localID.updateValueAndValidity();
      }
    });

    this.localID.valueChanges.subscribe((val: any) => {
      var ssidPattern = new RegExp(`^\\d{${this.ssidmaxLength}}$`);
      if (this.currentLocalID == val) return;
      this.currentLocalID = val;
      if (this.localID.valid) {
        if (this.currentLocalID) {
          this.SSID.setValidators([Validators.pattern(ssidPattern)]);

          if (this.SSID.value) {
            this.validateSSID();
          }
        } else {
          this.SSID.setValidators([
            Validators.required,
            Validators.pattern(ssidPattern),
          ]);
        }
        this.SSID.updateValueAndValidity();
      } else {
        this.SSID.setValidators([
          Validators.required,
          Validators.minLength(this.ssidmaxLength),
          Validators.pattern(ssidPattern),
        ]);
        this.SSID.updateValueAndValidity();
      }
    });
  }

  resetAddChildForm(): void {
    this.addChildForm.reset();
    this.toastService.success($localize `The form has been reset.`);
  }

  checkSpecialEdInfo(): void {
    if (this.stateConfig) {
      this.stateConfig.forEach((config: IStateConfiguration) => {
        switch (config.columnName) {
          case this.studentDemographics.PrimaryDisability:
            this.specialEdInfo.isPrimaryDisabilityEnabled = config.isActive;
            break;
          case this.studentDemographics.DistrictLiability:
            this.specialEdInfo.isDistrictLiabilityEnabled = config.isActive;
            break;
          case this.studentDemographics.SpecialEdEntryDate:
            this.specialEdInfo.isSpecialEdEntryEnabled = config.isActive;
            break;
          case this.studentDemographics.AnticipatedDateExit:
            this.specialEdInfo.isAnticipatedDateEnabled = config.isActive;
            break;
          case this.studentDemographics.DateExit:
            this.specialEdInfo.isSpecialEdExitEnabled = config.isActive;
            break;
          case this.studentDemographics.Selpa:
            this.specialEdInfo.isSelpaEnabled = config.isActive;
            break;
          case this.studentDemographics.initialAssessmentToolId:
            this.specialEdInfo.isInitialAssessmentToolEnabled = config.isActive;
            break;
          case this.studentDemographics.outcome1Id:
            this.specialEdInfo.isOutcome1Enabled = config.isActive;
            break;
          case this.studentDemographics.outcome2Id:
            this.specialEdInfo.isOutcome2Enabled = config.isActive;
            break;
          case this.studentDemographics.outcome3Id:
            this.specialEdInfo.isOutcome3Enabled = config.isActive;
            break;
          default:
            return;
        }
      });
    }
  }

  buildCustomConfigFormControl(): void {
    if (this.customStateConfig) {
      this.customStateConfig.forEach((config: IStateConfiguration) => {
        if (config.isActive) {
          const isTextField = config.customFieldTypeId === FieldType.text;
          if (config.isRequired && isTextField) {
            this.addChildForm.addControl(
              config.label,
              new FormControl('', [
                Validators.required,
                Validators.maxLength(config.maxLength),
              ])
            );
          } else if (isTextField) {
            this.addChildForm.addControl(
              config.label,
              new FormControl('', Validators.maxLength(config.maxLength))
            );
          } else if (config.isRequired) {
            this.addChildForm.addControl(
              config.label,
              new FormControl('', [Validators.required])
            );
          } else {
            this.addChildForm.addControl(config.label, new FormControl(''));
          }
        }
      });
    }
  }

  buildCustomConfigObj(
    colName: string,
    configId: number,
    customFieldTypeId: number,
    event?: any
  ): void {
    if (event) {
      this.addChildForm.get(colName).setValue(event);
    }
    const ans = this.addChildForm.get(colName).value;
    const customField: ICustomFieldValue = {
      id: 0,
      studentId: 0,
      stateDemographicConfigurationId: configId,
      intValue: customFieldTypeId == FieldType.number ? ans : 0,
      smCharValue:
        customFieldTypeId === FieldType.dropdownSingle ||
        customFieldTypeId === FieldType.text
          ? ans
          : '',
      lgCharValue:
        customFieldTypeId === FieldType.dropdownMultiple ? ans.join('|') : '',
      dateValue: customFieldTypeId === FieldType.date ? ans : '',
    };
    this.customFieldMap.set(colName, customField);
  }

  buildCustomConfigCollection(): void {
    this.customFieldMap.forEach((config: ICustomFieldValue) => {
      this.customFieldAnsCollection?.push(config);
    });
  }

  handleChosenCountyOfProgram(event: any): void {
    this.chosenCountyOfProgram = event.countyName;
    this.countyId.setValue(event.id);
  }
  handleChosenCountyOfResidence(event: any): void {
    this.countyOfResidenceId.setValue(event.id);
  }

  handleChosenSelpa(event: any): void {
    this.selpaId.setValue(event.id);
  }

  handleChosenEthnicity(event: any): void {
    if (
      event.id === this.ethnicityvalues.Intentionally_left_blank &&
      !this.isEthnicityDisabled
    ) {
      this.isEthnicityDisabled = true;
      this.ethnicity.setValue([event.id]);
    } else if (
      event.id === this.ethnicityvalues.Intentionally_left_blank &&
      this.isEthnicityDisabled
    ) {
      this.isEthnicityDisabled = false;
    }
  }


  handleDate(event: any, controlName: string): void {
    if (event === '' || !event) {
      this.addChildForm.get(controlName).setValue(formatDatesToString(null));
    } else {
      this.addChildForm.get(controlName).setValue(formatDatesToString(event));
    }
  }

  handleEld(event: ISelectBoolean): void {
    this.eld.setValue(event?.id);
  }

  handleAgeGrade(event: IAgeGradeInstrument): void {
    this.ageGradeInstrumentId.setValue(event?.id);
  }

  handleHispanic(event: ISelectBoolean): void {
    this.latino.setValue(event?.id);
  }

  handleIep(event: IIepPlan): void {
    this.iepPlanId.setValue(event?.id);

    if (this.isNewHampshire && this.specialEdInfo.isDistrictLiabilityEnabled && event?.id == this.iepPlan.Yes) {
      this.districtOfLiabilityId.addValidators([Validators.required]);
      this.districtOfLiabilityId.updateValueAndValidity();
    }
    else {
      this.districtOfLiabilityId.clearValidators();
      this.districtOfLiabilityId.updateValueAndValidity();
    }

    if (this.specialEdInfo.isSpecialEdEntryEnabled) {
      if (event?.id == this.iepPlan.Yes) this.seEntryDate.setValidators([Validators.required]);
      else this.seEntryDate.clearValidators();
      this.seEntryDate.updateValueAndValidity();
    }
  }

  handleOutcome(event: IOutcome, controlName: string): void {
    if (!this.addChildForm.get(controlName)) return;
    this.addChildForm.get(controlName).setValue(event?.id);
  }

  handleInitAssessment(event: IInitialAssessmentToolType): void {
    this.initialAssessmentToolId.setValue(event?.id);
  }

  deselectCheck(id: number): void {
    const currentTuitionFeeId = this.tuitionFeeId?.value;
    if (currentTuitionFeeId === id) {
      this.tuitionFeeId?.setValue(null);
    } else {
      this.tuitionFeeId?.setValue(id);
    }
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
