import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Modal } from '@core/enums/modal';
import { Permission } from '@core/enums/permissions';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { IUserClassSearchRequestDto } from '@core/interfaces/iclasses';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ISites } from '@core/interfaces/isites';
import { IStudentSearchResponseDto } from '@core/interfaces/istudent';
import {
  IStudentRequest,
  IStudentRequestBatch,
} from '@core/interfaces/istudent-request';
import { PermissionService } from '@core/services/permission.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ToastService } from '@core/services/toast.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-transfer-child',
  templateUrl: './transfer-child.component.html',
  styleUrls: ['./transfer-child.component.scss'],
})
export class TransferChildComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  isAdmin = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  isProcessing = false;
  child: any;
  payload: IUserClassSearchRequestDto = {};
  initialRatingPeriodId: any | undefined;
  transferChildForm: FormGroup | any;
  submitBtnText = '';
  isBatchTransfer = false;
  students?: IStudentSearchResponseDto[];
  agencyId?: number;
  label = $localize `Transfer Child`;
  ratingStartDate: Date = new Date();
  ratingEndDate: Date = new Date();
  yesterday! : number;
  enrollmentDateInfo = $localize `The day the child will start in the new class enrollment.`;

  get site() {
    return this.transferChildForm.get('site');
  }
  get classroom() {
    return this.transferChildForm.get('classroom');
  }
  get ratingPeriod() {
    return this.transferChildForm.get('ratingPeriod');
  }
  get comment() {
    return this.transferChildForm.get('Comment');
  }
  get classEnrollmentDate() {
    return this.transferChildForm.get('classEnrollmentDate');
  }

  constructor(
    public toastService: ToastService,
    public permissionService: PermissionService,
    private fb: FormBuilder,
    private studentRequestService: StudentRequestService,
    public modal: MatDialog,
    public dialogRef: MatDialogRef<TransferChildComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.calculateYesterdayDate();
    this.initializeForms();
    this.isAdmin = this.permissionService.checkPermission(
      Permission.AdminEnrollmentByPass
    );
    this.submitBtnText = this.isAdmin
      ? $localize `Transfer Child`
      : $localize `Save and Send For Approval`;

    if (Array.isArray(this.editData.studentBatch)) {
      const { studentBatch, agencyId } = this.editData;
      this.students = studentBatch;
      this.isBatchTransfer = true;
      this.agencyId = agencyId;
      this.label = $localize `Batch Transfer Confirmation`;
    } else {
      this.child = this.editData.data;
      this.agencyId = this.child.agencyId;
    }
  }

  initializeForms(): void {
    this.transferChildForm = this.fb.group({
      site: [null, Validators.required],
      classroom: [null, Validators.required],
      ratingPeriod: [null, Validators.required],
      Comment: ['', [Validators.maxLength(150)]],
      classEnrollmentDate: [null, [Validators.required]],
    });
  }

  calculateYesterdayDate(): void {
    const currentDate = new Date();
    let yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.yesterday =  yesterday.getTime();
  }

  handleSite(site: ISites): void {
    this.site.setValue(site?.id);
    this.payload.siteId = site?.id;
    this.classroom.setValue(null);
  }

  handleClassroom(classroom: any): void {
    this.classroom.setValue(classroom?.id);
  }

  handleRatingPeriod(ratingPeriod: IRatingPeriod): void {
    this.ratingPeriod.setValue(ratingPeriod?.id);
    this.payload.ratingPeriodId = ratingPeriod?.id;
    this.classroom.setValue(null);
    this.ratingStartDate = new Date(ratingPeriod?.minDate);
    this.ratingEndDate = new Date(ratingPeriod?.maxDate);
    this.handleDate(this.classEnrollmentDate.value);
  }

  onSubmit() {
    this.handleDate(this.classEnrollmentDate.value);
    if (this.transferChildForm.invalid) {
      this.toastService.error($localize `Please enter all required fields.`);
      return;
    }

    const isTransferringToSameClass = this.students?.some(
      (student: IStudentSearchResponseDto) =>
        student.classId == this.classroom.value
    );

    if (isTransferringToSameClass) {
      let event = { data: { modalInfo: {} } };
      event.data.modalInfo = {
        title: '',
        message: $localize `One or more children selected are already enrolled in the same classroom and rating period you are transferring to.`,
        name: '',
        primaryBtnClass: 'blue',
        primaryBtnText: Modal.OK,
      };
      const modalRef = this.modal.open(ConfirmationModalComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe();
      return;
    }

    this.isProcessing = true;
    const successMessage = this.isAdmin
      ? $localize `Transfer successful.`
      : $localize `Transfer request has been sent.`;

    if (this.isBatchTransfer) {
      const payload: IStudentRequestBatch = {
        studentRequestDtos: [],
        siteId: this.site.value,
        requestReasonId: StudentRequestReason.Transfer,
      };

      if (this.students?.length) {
        const studentRequest: IStudentRequest[] = this.students.map(
          (student: IStudentSearchResponseDto) => ({
            fromClassId: student.classId,
            toClassId: this.classroom.value,
            studentId: student.id,
            requestReasonId: StudentRequestReason.Transfer,
            comment: this.comment.value,
            siteId: this.site.value,
            studentName: `${student.firstName} ${student.lastName}`,
            classEnrollmentDate: this.classEnrollmentDate.value,
            classExitDate: new Date(this.getStudentExitDate(student))
          })
        );
        payload.studentRequestDtos = studentRequest;
      }

      if (payload.studentRequestDtos) {

        this.studentRequestService.checkDuplicateBatch(payload)
        .pipe(take(1))
        .subscribe((duplicates) => {
          if(duplicates && duplicates.length > 0) {
            this.dialogRef.close({ success: false, duplicates: duplicates });
          }
          else {
            this.studentRequestService
            .processBatchRequest(payload)
            .pipe(take(1))
            .subscribe((_) => {
              this.isProcessing = false;
              this.dialogRef.close({ success: true });
            });
          }
        });
      }
    } else {
      let payload: IStudentRequest = {
        fromClassId: this.child.classId,
        toClassId: this.classroom.value,
        studentId: this.child.id,
        requestReasonId: StudentRequestReason.Transfer,
        comment: this.comment.value,
        siteId: this.site.value,
        classEnrollmentDate: this.classEnrollmentDate.value,
        classExitDate: new Date(this.getStudentExitDate(this.child))
      };

      this.studentRequestService
        .addStudentRequest(payload, successMessage)
        .pipe(take(1))
        .subscribe(() => {
          this.isProcessing = false;
          this.dialogRef.close({ success: true });
        });
    }
  }

  handleDate(event: any): void {
    this.classEnrollmentDate.setValue(event);

    const enrollmentDate = new Date(event);
    enrollmentDate.setHours(0, 0, 0, 0);
    const classEnrollmentDate = enrollmentDate.getTime();

    if (this.classEnrollmentDate.value && this.ratingPeriod.value) {
      if (classEnrollmentDate < this.ratingStartDate.getTime() ||
        classEnrollmentDate > this.ratingEndDate.getTime()) {
        this.transferChildForm.get('classEnrollmentDate').setErrors({dateRange: true});
      } else if (this.checkExitDate(classEnrollmentDate)) {
        this.transferChildForm.get('classEnrollmentDate').setErrors({exitDate: true});
      } else {
        this.classEnrollmentDate.setErrors(null);
      }
    }
  }

  checkExitDate(classEnrollmentDate: number): boolean {
    if (this.isBatchTransfer && this.students) {
      return this.students.some(student => student.ratingPeriodId != this.ratingPeriod.value &&
        this.invalidEnrollmentDate(student, classEnrollmentDate));
    } else if (this.child && (this.child.ratingPeriodId != this.ratingPeriod.value)) {
      return this.invalidEnrollmentDate(this.child, classEnrollmentDate);
    }

    return false;
  }

  invalidEnrollmentDate(student: IStudentSearchResponseDto, classEnrollmentDate: number): boolean {
    const exitDate = this.getStudentExitDate(student);
    return exitDate >= classEnrollmentDate;
  }

  getStudentExitDate(student: IStudentSearchResponseDto) {
    const currentRatingPeriodEndDate = new Date(student.ratingPeriodEndDate).getTime();
    const exitDate = currentRatingPeriodEndDate < this.yesterday ? currentRatingPeriodEndDate : this.yesterday;
    return exitDate;
  }
}
