<div class="rounded-lg fixActionRow" id="superFeatureDialog">
    <div class="flex justify-between border-b">
      <h1 class="text-[25px] font-bold">{{ modalTitle }}</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>
    
    <div mat-dialog-content>
      <form class="grid grid-cols-1 gap-10 py-5 px-2" [formGroup]="featureGroupForm">

        <div *ngIf="!hasChosenSuperFeature" class="col-span-1">
        <drdp-add-edit-table [dataSource]="uncategorizedDataSource"
                             [tableData]="uncategorizedTableData"
                             [columns]="addUncategorizedSubColumns"
                             (addActionItem)="addSuperFeature($event)">
        </drdp-add-edit-table>
        </div>

        <div *ngIf="hasChosenSuperFeature" class="col-span-1">
          <div>
            <h3 class="global-font-bold" i18n>Current Sub Features</h3>
            <drdp-add-edit-table [dataSource]="newSubDataSource"
                                 [tableData]="newSubTableData"
                                 [columns]="removeUncategorizedSubColumns"
                                 (deleteActionItem)="removeSubFeature($event)">
            </drdp-add-edit-table>
          </div>
          <div>
            <h3 class="global-font-bold" i18n>Add Sub Features</h3>
            <drdp-add-edit-table [dataSource]="uncategorizedDataSource"
                                 [tableData]="uncategorizedTableData"
                                 [columns]="addUncategorizedSubColumns"
                                 (addActionItem)="addSubFeature($event)">
            </drdp-add-edit-table>
          </div>
        </div>

      </form>

    </div>
    <div mat-dialog-actions class="flex justify-end align-bottom">
      <button i18n mat-button mat-dialog-close>Cancel</button>
      <drdp-btn i18n *ngIf="featureGroupForm.valid" mat-button [mat-dialog-close]="onSubmit()" [disabled]="false" cdkFocusInitial>Save</drdp-btn>
      <drdp-btn i18n *ngIf="!featureGroupForm.valid" mat-button [disabled]="true" cdkFocusInitial>Save</drdp-btn>
    </div>
  </div>

