<div class="p-5">
    <div class="flex items-center">
        <div>
          <span class="flex items-center">
            <drdp-title i18n-title title="Search for Child in DRDP Online System"></drdp-title>
            <i class="uil uil-info-circle text-xl ml-2 mb-2 cursor-pointer "
              i18n-matTooltip matTooltip="Please search for child that will be added to ensure a duplicate record is not created. The child may already be enrolled in a classroom/family child care home or the child may be available to transfer to your organization."
              matTooltipPosition="right" matTooltipClass="bg-black text-white custom-tooltip"></i>
          </span>
        </div>
      </div>

    <drdp-container>
        <drdp-container color="lightBlue">
            <form class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 p-2 gap-3 flex-row items-end"
                [formGroup]="searchForm">
                <div class="col-span-1">
                    <label i18n class="required">First Name</label>
                    <div class="basis-1/2 border-b border-black">
                        <input matInput i18n-placeholder placeholder="First Name" autocomplete="off" type="text"
                            formControlName="firstName" maxlength="50">
                    </div>
                </div>
                <div class="col-span-1">
                    <label i18n class="required">Last Name</label>
                    <div class="basis-1/2 border-b border-black">
                        <input matInput i18n-placeholder placeholder="Last Name" autocomplete="off" type="text"
                            formControlName="lastName" maxlength="50">
                    </div>
                </div>
                <div class="col-span-1">
                    <drdp-date-picker i18n-label label="DOB" [initialDate]="dob?.value" labelClasses="pb-1" [isRequired]="true"
                        (emitDate)="handleDate($event)">
                    </drdp-date-picker>
                </div>
                <div class="col-span-1">
                    <div><span class="font-bold" i18n>Additional Filters</span></div>
                    <mat-radio-group aria-label="Select an option" class="flex gap-3" formControlName="additionalFilter">
                      <mat-radio-button [value]="subFilter.None" color="primary" i18n>None</mat-radio-button>
                      <mat-radio-button [value]="subFilter.Subgroups" color="primary" i18n>Filters</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="additionalFilter.value == subFilter.Subgroups"
                    class="col-span-1 md:col-span-3 lg:col-span-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3">
                    <div class="col-span-1">
                        <label class="m-0 text-sm flex items-center" style="min-height: 2.5rem;">
                            {{ ssidLabel }}
                        </label>
                        <div class="basis-1/2 border-b border-black">
                            <input matInput [placeholder]="ssidPlaceholder" autocomplete="off" type="text"
                                formControlName="ssid" maxlength="50">
                        </div>
                    </div>
                    <div class="col-span-1">
                        <label>
                            DRDP ID
                        </label>
                        <div class="basis-1/2 border-b border-black">
                            <input matInput (keypress)="numberOnly($event)" placeholder="DRDP ID" autocomplete="off"
                                type="text" formControlName="drdpId" maxlength="50">
                        </div>
                    </div>
                    <div class="col-span-1">
                        <drdp-select-county #countySelect [initialCountyIds]="null" (county)="handleChosenCounty($event)"
                            [stateId]="userStateId" [multiSelect]="false">
                        </drdp-select-county>
                    </div>
                </div>
                <div class="col-span-1 md:col-start-5 self-end justify-center md:justify-self-end">
                    <drdp-btn i18n type="submit" (click)="search()" class="mr-2">Search</drdp-btn>
                    <drdp-btn i18n type="button" (click)="clear()" color="outline">Clear</drdp-btn>
                </div>
            </form>
        </drdp-container>
        <div class="p-5 w-full">
            <drdp-add-edit-table viewIcon="uil uil-file-alt text-drdpblue-300" (iconActionItem)="actionItem($event)" (actionIconClicked)="openEnrollmentModal($event)"
                [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns">
            </drdp-add-edit-table>
        </div>
    </drdp-container>
</div>
<mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
</mat-paginator>

<div *ngIf="showCheckbox" class="flex flex-wrap mx-2 my-2 justify-center items-center gap-3">
    <mat-checkbox i18n color="primary" (click)="handleCheckbox()">By checking this box, I am acknowledging that child is not listed above from search results.</mat-checkbox>
    <drdp-btn i18n [disabled]="disableAddChildBtn" type="button" color="filled" (click)="handleAddChildBtn()">Add New Child</drdp-btn>
</div>
