import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IClassTeacher } from '@core/interfaces/iclasses';
import { SelectComponent } from '../select/select.component';
import { DropdownService } from '@core/services/dropdown.service';
import { take } from 'rxjs';
import { IDropdownItemsDto } from '@core/interfaces/idropdown';
import { Role } from '@core/enums/roles';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-teacher',
  templateUrl: './select-teacher.component.html',
  styleUrls: ['./select-teacher.component.scss']
})
export class SelectTeacherComponent implements OnInit {
  @ViewChild('teacherSelect') teacherSelect: FilterSelectComponent | undefined;
  @Output() teacher = new EventEmitter<any>();
  @Output() teacherOfRecord = new EventEmitter<any>();
  @Input() initialTeacherId?: number | null;
  @Input() initialTeachersId?: number[] | null;
  @Input() agencyId?: number | null;
  @Input() siteId?: number | null;
  @Input() allTeachers?: boolean = false;
  @Input() teacherType?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() multiSelectInitVals: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() requireSite: boolean = false;
  teacherOptions?: IClassTeacher[] = [];
  label: string = $localize `Teacher`
  selectType = this.select.Teacher;
  isTooltipActive = false;

  public get select() {return SelectType }
  public get role() {return Role }
  constructor(private dropdownService: DropdownService) { }

  ngOnInit(): void {
    // if (this.agencyId && this.siteId) this.getTeachers(this.agencyId, this.siteId);
    // if (this.agencyId) this.getTeachers(this.agencyId);
    if (this.teacherType === this.role.TeacherOfRecord) {
      this.label = $localize `Teacher of Record`;
      this.selectType = this.select.UserRoleNames;
      this.isTooltipActive = true;
    }
  }

  clearSelected() {
    if (!this.disabled)
      this.teacherSelect?.clearSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.teacherSelect?.clearSearch(true);
    };

    if (this.requireSite && !this.siteId) {
      this.teacherOptions = [];
    } else if (this.agencyId) {
      this.getTeachers(this.agencyId, this.siteId ?? 0);
    } else {
      this.teacherOptions = [];
    }
  }

  getTeachers(agencyId: number, siteId?: number): void {
    this.dropdownService.getTeachers(agencyId, siteId)
      .pipe(take(1))
      .subscribe((dropdown: IDropdownItemsDto) => {
        if (this.allTeachers) this.teacherOptions = dropdown.allTeachers;
        else if (this.teacherType === Role.Teacher) this.teacherOptions = dropdown.teachers;
        else this.teacherOptions = dropdown.teachersOfRecord;
      });
  }

  onTeacherSelect(event: any): void {
    if (this.allTeachers || this.teacherType === Role.Teacher) {
      this.teacher.emit(event);
    } else {
      this.teacherOfRecord.emit(event);
    }
  }
}
