<div class="mt-2">
    <div class="flex justify-center">
        <button class="shadow-drdpblue-300 border-0 shadow-sm bg-white py-2 px-3 "
            (click)="paginationFilter('first')"><i class="uil uil-angle-double-left text-2xl "></i></button>
        <button *ngIf="pagination.currentPage==pagination.numOfPages && pagination.numOfPages>2"
            class="shadow-drdpblue-300 shadow-sm bg-white py-2 px-3 "
            (click)="paginationFilter('beforePrevious')">{{pagination.currentPage-2}}</button>
        <button *ngIf="pagination.currentPage>1 " class="shadow-drdpblue-300 shadow-sm bg-white py-2 px-3 "
            (click)="paginationFilter('previous')">{{pagination.currentPage-1}}</button>
        <button
            class="shadow-drdpblue-300 shadow-sm py-2 px-3 bg-drdpblue-300 text-white">{{pagination.currentPage}}</button>
        <button *ngIf="pagination.currentPage<pagination.numOfPages"
            class="shadow-drdpblue-300 shadow-sm bg-white py-2 px-3"
            (click)="paginationFilter('next')">{{pagination.currentPage+1}}</button>
        <button *ngIf="pagination.currentPage==1 && pagination.numOfPages>2" class="shadow-drdpblue-300 shadow-sm bg-white py-2 px-3"
            (click)="paginationFilter('afterNext')">{{pagination.currentPage+2}}</button>
        <button class="shadow-drdpblue-300 shadow-sm bg-white py-2 px-3 " (click)="paginationFilter('last')"><i
                class="uil uil-angle-double-right text-2xl "></i></button>

    </div>
</div>