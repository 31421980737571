import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EventAction } from '@core/enums/event-action';
import { Modal } from '@core/enums/modal';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { IClass } from '@core/interfaces/iclasses';
import { ILoginResponse } from '@core/interfaces/ilogin-response';
import { IRatingPeriod, ISchoolYear } from '@core/interfaces/iratingperiod';
import {
  IStudentRequest,
  IStudentRequestSearchResponse,
} from '@core/interfaces/istudent-request';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ToastService } from '@core/services/toast.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Observable, Subscription, concatMap, forkJoin, map } from 'rxjs';

@Component({
  selector: 'drdp-pending-child-re-enrollment',
  templateUrl: './pending-child-re-enrollment.component.html',
  styleUrls: ['./pending-child-re-enrollment.component.scss'],
})
export class PendingChildReEnrollmentComponent implements OnInit {
  private subscriptions = new Subscription();

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;
  pendingRequests: any[] = [];
  reEnrollRequests: any[] = [];
  ratingOptions: IRatingPeriod[] = [];
  existingClasses: IClass[] = [];
  currentDate = new Date();
  user: ILoginResponse;
  getRatingPeriods$: Observable<any> | undefined;
  getRequestData$: Observable<any> | undefined;
  getClassesByClassroom$: Observable<any> | undefined;
  getApprovedOrPendingReq$: Observable<any> | undefined;
  requests: any[] = [];
  router = inject(Router);

  tableColumns = [
    { columnDef: 'studentName', header: $localize `Child Name`, type: 'text' },
    {
      columnDef: 'ratingPeriodName',
      header: $localize `Rating Period`,
      type: 'textToSelect',
      action: EventAction.Select,
    },
    { columnDef: 'edit', header: $localize `Edit`, type: 'edit' },
    {
      columnDef: 'decline',
      header: $localize `Decline`,
      type: 'checkBox',
      checkClass: 'checkbox',
    },
    {
      columnDef: 'accept',
      header: $localize `Accept`,
      type: 'checkBox',
      checkClass: 'checkbox',
    },
  ];

  constructor(
    public toast: ToastService,
    public modal: MatDialog,
    private studentRequest: StudentRequestService,
    private authService: AuthService,
    private ratingPeriods: RatingPeriodService,
    private classes: ClassService
  ) {
    this.user = this.authService.getCurrentUser();
  }

  ngOnInit(): void {
    const pendingReqParams = {
      requestReasonId: StudentRequestReason.ReEnrollStudent,
      agencyId: this.user.agencyId,
    };

    this.getPendingRequests(pendingReqParams);
  }

  getPendingRequests(params: any): void {
    this.getRequestData$ = this.studentRequest
      .getByReasonAndAgency(params)
      .pipe(
        map((requests: IStudentRequestSearchResponse[]) => ({
          requests: requests,
        }))
      );

    this.getRatingPeriods$ = this.ratingPeriods
      .getActiveSchoolYear()
      .pipe(
        concatMap((year: ISchoolYear) =>
          this.ratingPeriods
            .getBySchoolYearAndAgency(year.id, this.user.agencyId)
            .pipe(map((ratingPeriods) => ratingPeriods))
        )
      );

    this.subscriptions.add(
      forkJoin({
        requestData: this.getRequestData$,
        ratings: this.getRatingPeriods$,
      }).subscribe(({ requestData, ratings }) => {
        ratings = ratings.map((rating: any) => ({
          ...rating,
          name: rating.ratingPeriodName,
        }));

        if (requestData) {
          let requests = requestData.requests.map((request: any) => ({
            ...request,
            decline: false,
            accept: false,
            isSelect: false,
            isEditing: false,
            items: this.getRatingPeriods(ratings),
          }));

          this.requests = requests;
          this.reEnrollRequests = [...requests];
          this.dataSource = new MatTableDataSource(requests);
          this.tableData = requests;
        }
      })
    );
  }

  handleSearch(event: any): void {
    const params = {
      stateId: event.stateId,
      agencyId: event.agencyId,
      fromSiteId: event.siteId,
      toClassId: event.classroomId,
      toRatingPeriodId: event.ratingPeriodId,
      requestReasonId: StudentRequestReason.ReEnrollStudent,
    };

    this.getPendingRequests(params);
  }

  getRatingPeriods(ratingPeriods: IRatingPeriod[]) {
    return ratingPeriods.filter(
      (r: IRatingPeriod) => new Date(r.startDate) > this.currentDate
    );
  }

  handleEditRequest(event: any): void {
    if (event.action == EventAction.Select) {
      this.handleChangeRequest(event);
    }

    if (!this.tableData[event.index].isEditing) {
      this.tableData[event.index].isSelect = this.tableData[
        event.index
      ].isEditing = true;
    } else {
      this.tableData[event.index].isSelect = this.tableData[
        event.index
      ].isEditing = false;
    }

    this.dataSource.data = this.tableData;
  }

  handleApprovalRequest(event: any): void {
    var i = event.index;
    var prop = event.property;

    this.requests[i][prop] = !this.requests[i][prop];
    if (prop == 'accept') this.requests[i].decline = false;
    else if (prop == 'decline') this.requests[i].accept = false;

    this.dataSource.data = this.requests;
  }

  handleChangeRequest(event: any): void {
    const params = {
      requestReasonId: StudentRequestReason.ReEnrollStudent,
      fromClassId: this.requests[event.index].fromClassId,
    };
    this.getClassesByClassroom$ = this.classes.getClassesByClassroom(
      this.requests[event.index].classroomId
    );
    this.getApprovedOrPendingReq$ =
      this.studentRequest.getApprovedOrPendingByReasonAndClass(params);

    this.subscriptions.add(
      forkJoin({
        classes: this.getClassesByClassroom$,
        approvedOrPendingReq: this.getApprovedOrPendingReq$,
      }).subscribe(({ classes, approvedOrPendingReq }) => {
        let toClassId = classes.filter(
          (c: IClass) => c.ratingPeriodId == event.data.id
        )[0]?.id;
        if (toClassId == null) {
          this.toast.error(
            $localize `Class assignment doesn't exist for this rating period.`
          );
          return;
        }

        if (
          approvedOrPendingReq.some(
            (req: any) =>
              req.toClassId === toClassId &&
              req.studentId === this.requests[event.index].studentId &&
              req.id !== this.requests[event.index].id
          )
        ){
          this.toast.error(
            $localize `An approved or pending re-enroll request already exists for this rating period.`
          );
          return;
        }

        this.requests[event.index].toClassId = toClassId;
        this.requests[event.index].ratingPeriodName =
          event.data.ratingPeriodName;
        this.dataSource.data = this.requests;
      })
    );
  }

  submit(): void {
    if (this.requests.length == 0) {
      this.toast.error($localize `No requests to process`);
      return;
    }

    var requestPayload: IStudentRequest[] = [];

    this.requests.forEach((req) => {
      if (!req.accept && !req.decline) return;

      var requestToPush = <IStudentRequest>{
        id: req.id,
        studentId: req.studentId,
        toClassId: req.toClassId,
        fromClassId: req.fromClassId,
        requestReasonId: StudentRequestReason.ReEnrollStudent,
        accept: req.accept,
      };

      requestPayload.push(requestToPush);
    });

    this.subscriptions.add(
      this.studentRequest
        .processReEnrollmentBatch(requestPayload, $localize `Request have been processed`)
        .subscribe((success: boolean) => {
          if (success) {
            var updatedView = this.requests.filter((req: any) => {
              !requestPayload.includes(req);
            });

            this.dataSource = new MatTableDataSource(updatedView);
            this.tableData = updatedView;
          }
        })
    );
  }

  handleCancel(): void {
    this.router.navigateByUrl('/dashboard');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
