<div class="flex flex-col justify-between">
  <label *ngIf="label.length > 0" class="font-bold" [ngClass]="{'required': required}">
    {{ label }}
  </label>
  <div class="flex flex-row cursor-pointer classRoomDropdown">
    <div class="px-2 py-[3px] rounded-lg shadow-md border bg-white hover:bg-drdpblue-300 hover:text-white w-full"
      [ngClass]="{'border-drdpblue-300': isValid, 'border-red-500': !isValid}">
      <div class="flex flex-row justify-between w-100 truncate" (click)="toggleDropdown($event)">
        <div *ngIf="!multiSelect" class="w-38">
          <p class="text-xs pt-1">
            {{ selectedItem ? selectedItem.name : "Select a " + label }}
          </p>
        </div>
        <div *ngIf="multiSelect" class="w-38">
          <p class="text-xs pt-1">
            {{ multiSelectLabel }}
          </p>
        </div>
        <div>
          <button class="uil uil-angle-down" type="button"></button>
        </div>
      </div>
    </div>
  </div>
</div>
<div #select class="translate-y-px z-50 relative">
  <div [ngStyle]="realign" *ngIf="dropdownOpen"
    class="w-max absolute rounded-lg shadow-md border-drdpblue-300 border bg-white">
    <div class="flex flex-row m-3 mb-5 px-2 rounded-lg border-drdpblue-300 border">
      <label i18n for="filterInput">Filter: </label>
      <input type="text" name="filterInput" [(ngModel)]="filterText" (ngModelChange)="filter($event)"
        class="focus:outline-none" />
    </div>
    <div *ngIf="multiSelect" class="m-3 mb-5 flex flex-row justify-between">
      <drdp-btn i18n color="defaultNoMargins" (click)="checkAll()" type="button">Check All</drdp-btn>
      <drdp-btn i18n color="defaultNoMargins" (click)="clearAll()" type="button">Clear All</drdp-btn>
    </div>
    <div>
      <ul class="list-none" *ngIf="!multiSelect">
        <li (click)="handleSelect(item)" (click)="toggleDropdown($event)" *ngFor="let item of itemsInPage[pageIndex]"
          class="cursor-pointer px-4 py-0.5 hover:bg-drdpblue-200 hover:text-black" [ngClass]="
              !multiSelect && selectedItem === item
                ? 'bg-drdpblue-300 text-white'
                : ''
            ">
          {{ item.name }}
        </li>
      </ul>
      <ul class="list-none" *ngIf="multiSelect">
        <li (click)="handleSelect(item)" *ngFor="let item of itemsInPage[pageIndex]"
          class="cursor-pointer px-4 hover:bg-drdpblue-200 hover:text-black" [ngClass]="
              (item.id
              | containsId : multiSelectedItems : multiSelectedItems.length)
                ? 'bg-drdpblue-300 text-white border-b border-white'
                : ''
            ">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="w-60">
      <mat-paginator [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex" [hidePageSize]="true"
        (page)="page()">
      </mat-paginator>
    </div>
  </div>
</div>