<div class="p-5">
  <drdp-title title="Assign Pilot Test Children" i18n-title></drdp-title>

  <drdp-container additionClasses="h-max">
    <div class="w-full flex flex-col">
      <drdp-container color="lightBlue" additionClasses=" min-h-[7rem] w-full">
        <form
          class="grid grid-cols-1 grid-rows-3 md:grid-cols-3 lg:grid-cols-5 p-2 gap-3 flex-row content-center"
          [formGroup]="childSearch"
        >
          <div class="col-span-1 self-end">
            <label i18n class="font-bold">First Name:</label>
            <div class="basis-1/2 border-b border-black">
              <input
                matInput
                placeholder="First Name"
                i18n-placeholder
                formControlName="firstName"
                class="w-full bg-drdpblue-200 border-b-2 border-black focus:outline-none focus:border-drdpblue-300"
              />
            </div>
          </div>
          <div class="col-span-1 self-end">
            <label i18n class="font-bold">Last Name:</label>
            <div class="basis-1/2 border-b border-black">
              <input
                matInput
                placeholder="Last Name"
                i18n-placeholder
                formControlName="lastName"
                class="w-full bg-drdpblue-200 border-b-2 border-black focus:outline-none focus:border-drdpblue-300"
              />
            </div>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-age-grade
              #ageGradeSelectSelect
              [initialageGradeId]="ageGradeInstrumentId.value"
              (ageGrade)="handleDropdown($event, selectType.AgeInstrument)"
              [stateId]="stateId.value"
              [multiSelect]="false"
              [clear]="clearDropdowns"
              label="Child Age/Grade Instrument"\
              i18n-label
            >
            </drdp-select-age-grade>
          </div>
          <div class="col-span-1 self-end">
            <label class="font-bold">DRDP ID:</label>
            <div class="basis-1/2 border-b border-black">
              <input
                matInput
                placeholder="DRDP ID"
                formControlName="drdpId"
                class="w-full bg-drdpblue-200 border-b-2 border-black focus:outline-none focus:border-drdpblue-300"
              />
            </div>
          </div>
          <div class="col-span-1 self-end">
            <label class="font-bold">{{ ssidLabel }}</label>
            <div class="basis-1/2 border-b border-black">
              <input
                matInput
                [placeholder]="ssidPlaceholder"
                i18n-placeholder
                formControlName="SSID"
                class="w-full bg-drdpblue-200 border-b-2 border-black focus:outline-none focus:border-drdpblue-300"
              />
            </div>
          </div>
          <div class="col-span-1 self-end">
            <label i18n class="font-bold">Agency ID:</label>
            <div class="basis-1/2 border-b border-black">
              <input
                matInput
                placeholder="Agency ID"
                i18n-placeholder
                formControlName="localId"
              />
            </div>
          </div>
          <div #dob class="col-span-1 self-end">
            <drdp-date-picker
              label="DOB"
              [isReadOnly]="false"
              (emitDate)="handleDate($event)"
            >
            </drdp-date-picker>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-state
              #stateSelect
              [initialStateId]="stateId?.value"
              (state)="handleDropdown($event, selectType.States)"
              [clear]="clearDropdowns"
            >
            </drdp-select-state>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-agency
              #agencySelect
              [initialAgencyId]="agencyId.value"
              (agency)="handleDropdown($event, selectType.Agencies)"
              [stateId]="stateId.value"
              [required]="false"
              [clear]="clearDropdowns"
              [disabled]="!isSuperUser"
            >
            </drdp-select-agency>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-site
              [initialSiteIds]="siteIds.value"
              (site)="handleDropdown($event, selectType.Sites)"
              [agencyId]="agencyId.value"
              [multiSelect]="true"
              [multiSelectInitVals]="true"
              [clear]="clearDropdowns"
              [required]="false"
            >
            </drdp-select-site>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-rating-period
              (ratingPeriod)="handleDropdown($event, selectType.RatingPeriods)"
              [initialRatingPeriodId]="ratingPeriodId.value"
              [agencyId]="agencyId.value"
              [required]="true"
              [clear]="clearDropdowns"
              [omitFutureRps]="true"
            >
            </drdp-select-rating-period>
          </div>
          <div class="col-span-1 self-end">
            <drdp-select-classroom
              (classroom)="handleDropdown($event, selectType.Class)"
              [initialClassroomIds]="classroomIds.value"
              [ratingPeriodId]="ratingPeriodId.value"
              [siteIds]="siteIds.value"
              [required]="false"
              [clear]="clearDropdowns"
              [useClassroomId]="true"
            >
            </drdp-select-classroom>
          </div>
          <div class="col-span-1 xl:col-span-2"></div>
          <div
            class="lg:col-span-2 xl:col-span-1 flex flex-row justify-evenly items-end xl:flex-row xl:justify-evenly xl:items-end"
          >
            <div class="col-span-1">
              <drdp-btn [disabled]="childSearch.invalid" (click)="search()">
                <span i18n class="px-2">Search</span>
              </drdp-btn>
            </div>
            <div class="col-span-1">
              <drdp-btn i18n color="outline" (click)="onClear()"> Clear </drdp-btn>
            </div>
          </div>
        </form>
      </drdp-container>
      <drdp-add-edit-table 
          [dataSource]="dataSource" 
          [tableData]="tableData" 
          [columns]="tableColumns"
          viewIcon="uil uil-file text-drdpblue-300" 
          (viewActionItem)="openViewChild($event)"
          (toggleSwitchActionItem)="assignRole($event)">
      </drdp-add-edit-table>
      <div class="flex justify-end align-bottom">
        <drdp-btn mat-button type="submit" (click)="submitPilotChildren()">Save</drdp-btn>
      </div>
    </div>
  </drdp-container>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateTable(false)">
  </mat-paginator>
</div>