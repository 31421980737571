import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ClassService } from '@core/services/class.service';
import { take } from 'rxjs';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { StudentRequestReason } from '@core/enums/student-request-reason';
import { IClass } from '@core/interfaces/iclasses';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-re-enroll-classroom',
  templateUrl: './re-enroll-classroom.component.html',
  styleUrls: ['./re-enroll-classroom.component.scss'],
})
export class ReEnrollClassroomComponent implements OnInit {
  user = this.authService.getCurrentUser();
  ratingOptions: IRatingPeriod[] = [];
  submitBtnText = '';
  isAdmin: boolean = false;
  reEnrollmentForm: FormGroup | any;
  existingClasses: IClass[] = [];
  displayResult = false;
  isProcessing = false;

  get ratingPeriodId() {
    return this.reEnrollmentForm.get('ratingPeriodId');
  }

  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
    private studentRequestService: StudentRequestService,
    private classService: ClassService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ReEnrollClassroomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.initializeForms();

    this.isAdmin = this.permissionService.checkPermission(
      Permission.AdminEnrollmentByPass
    );

    this.submitBtnText = this.isAdmin
      ? $localize `Save and Re-Enroll`
      : $localize `Save and Send For Approval`;

    this.classService
      .getClassesByClassroom(this.data.classroomId)
      .pipe(take(1))
      .subscribe((result) => {
        const ratingPeriods = result.flatMap((obj) => obj.ratingPeriod);

        const currentRatingPeriod = ratingPeriods.find(
          (r: any) => r.id == this.data.ratingPeriodId
        );

        if (currentRatingPeriod) {
          const currentDate = new Date();
          const dateWithoutTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
          this.ratingOptions = ratingPeriods.filter(
            (r: IRatingPeriod) =>
              new Date(r.startDate) > new Date(currentRatingPeriod.startDate) &&
              new Date(r.minDate) <= dateWithoutTime &&
              r.id != this.data.ratingPeriodId
          );
          this.existingClasses = result;
          this.displayResult = true;
        }
      });
  }

  initializeForms(): void {
    this.reEnrollmentForm = this.fb.group({
      ratingPeriodId: [null, Validators.required],
    });
  }

  submit(): void {
    const reEnrollClass = this.existingClasses.filter(
      (ec) => ec.ratingPeriodId == this.ratingPeriodId.value
    )[0];
    const payload: any = {
      fromClassId: this.data.id,
      toClassId: reEnrollClass.id,
      requestReasonId: StudentRequestReason.ReEnroll,
    };
    this.isProcessing = true;
    const successMessage = this.isAdmin
      ? $localize `Classroom successfully re-enrolled`
      : $localize `Re-enrollment request has been sent`;
    this.studentRequestService
      .addStudentRequest(payload, successMessage)
      .pipe(take(1))
      .subscribe((r) => {
        this.isProcessing = false;
        this.dialogRef.close({ success: true });
      });
  }
}
