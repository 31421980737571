import { Inject, Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { DOCUMENT, Location } from "@angular/common";

export class Locale {
  code: string;
  label: string;

  constructor(code: string, label: string) {
    this.code = code;
    this.label = label;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private readonly _currentLocale: Locale;
  private _locales: Locale[] = [
    new Locale('en', 'English'),
    new Locale('es', 'Espanol'),
    new Locale('ch', '中国人')
  ];
  private _availableLocales: Locale[];

  constructor(private _router: Router,
              private _location: Location,
              @Inject(DOCUMENT) private document: Document) {
    this._currentLocale = this.determineCurrentLocale(_location.prepareExternalUrl(_router.url));
    this._availableLocales = this._locales.filter((l: Locale) => l.code !== this._currentLocale.code);
  }

  getLocale(): Locale {
    return this._currentLocale;
  }

  getAvailableLocales(): Locale[] {
    return this._availableLocales;
  }

  changeLocale(locale: Locale): void {
    this.document.location.href = `${this.document.location.protocol}//${this.document.location.hostname}${this.document.location.port === '' ? '' : `:${this.document.location.port}`}/${locale.code}${this._router.url}`;
  }

  private determineCurrentLocale(currentUrl: string): Locale {
    const matches = currentUrl.match(/\/(?<locale>\w+)\//);
    if (!matches) {
      return this._locales[1];//default to spanish
    }

    const locale = matches.groups?.['locale'];
    const matchingLocales = this._locales.filter((l: Locale) => l.code === locale);
    if (matchingLocales.length < 1) {
      return this._locales[0];
    }

    return matchingLocales[0];
  }
}
