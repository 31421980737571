<div class="lg:hidden flex justify-between p-5 w-[100%]">
    <img class="header-image cursor-pointer" src="assets/images/logo/newDrdpLogo.svg" alt="DRDP Online Logo"
        routerLink="/dashboard">
    <i class="text-4xl uil uil-bars cursor-pointer" (click)="showMenu()"></i>
</div>
<div class="lg:block lg:static bg-white rounded-3xl lg:min-h-[96vh] p-4 justify-center items-center overflow-hidden drop-shadow-md sidenav"
    [ngClass]="showSideMenu?' z-[100] left-2 absolute w-[98%] min-h-fit':'-left-[100%] absolute w-[98%] z-[100] min-h-fit'">
    <div class="pb-3 border-drdpgrey-100 lg:block hidden">
        <img class="pt-3 header-image cursor-pointer" src="assets/images/logo/newDrdpLogo.svg" alt="DRDP Online Logo"
            routerLink="/dashboard">
    </div>
    <div class="lg:hidden">
        <drdp-drop-menu class="font-semibold" [agencyItems]="userDropMenu" [sites]="dropMenuItems" [show]="toggle(100)"
            [menuIndex]="100" (selectedItemEvent)="checkSelectedItem($event)"></drdp-drop-menu>
    </div>
    <div class="mt-3 border-b border-drdpgrey-100">
        <div class="lg:hidden border-t border-drdpgrey-100" *ngFor="let link of headerBtns">
            <ul>
                <li class="hover:bg-drdpblue-300 hover:rounded-xl">
                    <drdp-menu-link [icon]="link.icon" [label]="link.label" [link]="link.routeLink"
                        (click)="showMenu()"></drdp-menu-link>
                </li>
            </ul>
        </div>
        <div *ngFor="let item of menuItems, let i = index">
            <div *drdpCheckPermission="item.permissionId" class="border-t border-drdpgrey-100">
                <ul>
                    <li [ngClass]="{'hover:bg-drdpblue-300 hover:rounded-xl' : item.childItems.length == 0 }">
                        <drdp-menu-link *ngIf="item.childItems.length == 0; else dropdown" [icon]="item.icon ?? ''"
                            [label]="item.item" [link]="item.linkRoute ?? ''" (click)="showMenu()">
                        </drdp-menu-link>
                    </li>
                </ul>
            </div>
            <ng-template #dropdown>
                <ul>
                    <li>
                        <drdp-drop-menu [dropdownItems]="item" [show]="toggle(i)" [menuIndex]="i"
                            (selectedItemEvent)="checkSelectedItem($event)" (close)="showMenu()"></drdp-drop-menu>
                    </li>
                </ul>
            </ng-template>
        </div>
        <!-- Hide until ready -->
        <!-- <div class="lg:hidden border-t border-drdpgrey-100">
            <ul>
                <li class="hover:bg-drdpblue-300 hover:rounded-xl">
                    <drdp-menu-link icon="uil uil-comment" label="Chatbot" i18n-label (click)="openChat()">
                    </drdp-menu-link>
                </li>
            </ul>
        </div> -->
        <div class="lg:hidden border-t border-drdpgrey-100">
            <ul>
                <li class="hover:bg-drdpblue-300 hover:rounded-xl">
                    <drdp-menu-link icon="uil uil-signout" label="Logout" i18n-label (click)="logout()">
                    </drdp-menu-link>
                </li>
            </ul>
        </div>
    </div>
    <!-- Hide until ready -->
    <!-- <ul>
        <li class="hidden lg:block hover:bg-drdpblue-300 hover:rounded-xl">
            <drdp-menu-link icon="uil uil-comment" label="Chatbot" (click)="openChat()">
            </drdp-menu-link>
        </li>
    </ul> -->
</div>
