<div class="p-5">
  <div>
    <drdp-title title="Class Planning Report" i18n-title></drdp-title>
    <p i18n>
      This report allows users to select a rating period and classroom to generate a report for all the children in the classroom. 
      The report will include scaled scores and measure ratings for children, one rating period at a time.
    </p>
  </div>
  <drdp-report-search [multiSelectClassrooms]="false" 
                      [multiSelectSites]="false" 
                      [onlyLocked]="false" 
                      [onlyActive]="true" 
                      [showLocale]="false"
                      [showStudentSelect]="true" 
                      [canSelectAll]="true"
                      (showReportClicked)="showReport($event)" 
                      (downloadReportClicked)="downloadReport($event)"
                      (clearClicked)="handleClear()"
                      [includePilot]="true"></drdp-report-search>
  <div *ngIf="!!reportData" [innerHtml]="reportData! | safeHtml"></div>
</div>