<div *ngIf="newsItems" class="w-full flex flex-col">
  <div class="w-full bg-white rounded-[30px] shadow-md mt-5 flex flex-col relative" *ngFor="let news of newsItems">
    <div class="p-6 flex-grow">
      <div class="relative">
        <p class="absolute right-0">
          <drdp-pill *ngIf="news.newsItemType.newsItemTypeName" [pillClass]="news.newsItemType.newsItemTypeName">
            {{ news.newsItemType.newsItemTypeName }}
          </drdp-pill>
        </p>
        <p class="md:shrink-0 text-lg-300 text-[20px] font-semibold mr-16">
          {{ news.newsItemTitle }}
          <drdp-btn *ngIf="news.createdBy === user.userId" class="mx-2 text-sm" (click)="editNewsItem(news)"
            cdkFocusInitial>
            <i class="uil uil-pen cursor-pointer"></i> Edit News Item
          </drdp-btn>
        </p>
      </div>
      <div class="flex py-4">
          <div>
            <p class=" break-words mb-3">{{ news.newsItemBody }}</p>
          </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!newsItems">
  Nothing new to see here!
</div>
