import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { IGlobalRatingPeriod } from '@core/interfaces/iratingperiod';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@core/services/toast.service';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Modal } from '@core/enums/modal';
import { TableColumnType } from '@core/enums/table';
import { SchoolYear } from '@core/enums/school-year';

@Component({
  selector: 'drdp-add-global-rating-period',
  templateUrl: './add-global-rating-period.component.html',
  styleUrls: ['./add-global-rating-period.component.scss'],
})
export class AddGlobalRatingPeriodComponent implements OnChanges, OnDestroy {
  private subscription = new Subscription();

  @Input() currentYear: any;
  @Input() currentPeriods: IGlobalRatingPeriod[] = [];
  validForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  forms: FormGroup[] = [];
  globalFall: FormGroup | any;
  globalWinter: FormGroup | any;
  globalSpring: FormGroup | any;
  globalSummer1: FormGroup | any;
  globalSummer2: FormGroup | any;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  noSubmit: boolean = true;
  agenciesApplied: boolean = false;
  summerAssigned: boolean = false;
  isNewSchoolYear: boolean = false;
  formTableColumns = [
    { columnDef: 'schoolYearName', header: $localize `School Year`, type: TableColumnType.text },
    { columnDef: 'periodName', header: $localize `Term`, type: TableColumnType.text },
    {
      columnDef: 'defaultStartDate',
      header: $localize `Default Start Date`,
      type: TableColumnType.datepicker,
    },
    {
      columnDef: 'defaultEndDate',
      header: $localize `Default End Date`,
      type: TableColumnType.datepicker,
    },
    {
      columnDef: 'minStartDate',
      header: $localize `Minimum Start Date`,
      type: TableColumnType.datepicker,
    },
    { columnDef: 'maxEndDate', header: $localize `Maximum End Date`, type: TableColumnType.datepicker },
  ];

  constructor(
    private ratingPeriodService: RatingPeriodService,
    public toast: ToastService,
    public modal: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnChanges(): void {
    if (this.currentYear) {
      this.initializeForms();
    }
  }

  initializeForms(): void {
    const year = this.currentYear.schoolYearName;
    this.isNewSchoolYear = this.currentYear.id > SchoolYear.SchoolYear2023_24;
    if (this.forms.length < 4 && !this.isNewSchoolYear){
      this.forms.push((this.globalFall = this.generateFormGroup($localize `Fall`)));
      this.forms.push((this.globalWinter = this.generateFormGroup($localize `Winter`)));
      this.forms.push((this.globalSpring = this.generateFormGroup($localize `Spring`)));
      this.forms.push((this.globalSummer1 = this.generateFormGroup($localize `Summer`)));
    } else if (this.forms.length < 4 && this.isNewSchoolYear) {
      this.forms.push((this.globalSummer1 = this.generateFormGroup($localize `Summer`, true)));
      this.forms.push((this.globalFall = this.generateFormGroup($localize `Fall`)));
      this.forms.push((this.globalWinter = this.generateFormGroup($localize `Winter`)));
      this.forms.push((this.globalSpring = this.generateFormGroup($localize `Spring`)));
      this.forms.push((this.globalSummer2 = this.generateFormGroup($localize `Summer`)));
    }

    this.globalSummer1
      .get('globalRatingPeriodName')
      .setValue('Summer ' + year.substring(0, 4));
    this.globalFall
      .get('globalRatingPeriodName')
      .setValue($localize `Fall ` + year.substring(0, 4));
    this.globalWinter
      .get('globalRatingPeriodName')
      .setValue($localize `Winter ` + year);
    this.globalSpring
      .get('globalRatingPeriodName')
      .setValue($localize `Spring ` + year.substring(0, 2) + year.substring(5, 7));
    if (this.isNewSchoolYear) {
      this.globalSummer2
      .get('globalRatingPeriodName')
      .setValue($localize `Summer ` + year.substring(0, 2) + year.substring(5, 7));
    }


    this.globalFall.get('periodName').setValue($localize `Fall`);
    this.globalWinter.get('periodName').setValue($localize `Winter`);
    this.globalSpring.get('periodName').setValue($localize `Spring`);
    if (this.isNewSchoolYear) {
      this.globalSummer1.get('periodName').setValue($localize `Summer 1`);
      this.globalSummer2.get('periodName').setValue($localize `Summer 2`);
    } else {
      this.globalSummer1.get('periodName').setValue($localize `Summer`);
    }
    this.generateTableData();
  }

  generateFormGroup(season: string, isFirstSummer?: boolean): FormGroup {
    let schoolYearName = '';

    if (this.isNewSchoolYear && season === 'Summer' && isFirstSummer) {
      schoolYearName = this.currentYear.schoolYearName;
    } else if (season == 'Fall' && !this.isNewSchoolYear) {
      schoolYearName = this.currentYear.schoolYearName;
    }

    return this.fb.group({
      schoolYearName: [schoolYearName],
      schoolYearId: [this.currentYear.id],
      globalRatingPeriodName: [season],
      periodName: [null],
      minStartDate: [null, Validators.required],
      maxEndDate: [null, Validators.required],
      defaultStartDate: [null, Validators.required],
      defaultEndDate: [null, Validators.required],
      isSelect: [true]
    });
  }

  generateTableData(): void {
    if (this.currentPeriods.length) {
      this.noSubmit = false;
      this.formTableColumns.forEach((x) => {
        x.type = TableColumnType.text;
      });
      for (let per in this.currentPeriods) {
        switch (this.currentPeriods[per].periodName) {
          case $localize `Fall`: {
            this.populateFormValues(this.globalFall, this.currentPeriods[per]);
            break;
          }
          case $localize `Winter`: {
            this.populateFormValues(
              this.globalWinter,
              this.currentPeriods[per]
            );
            break;
          }
          case $localize `Spring`: {
            this.populateFormValues(
              this.globalSpring,
              this.currentPeriods[per]
            );
            break;
          }
          case 'Summer 2': {
            this.populateFormValues(this.globalSummer2, this.currentPeriods[per]);
            break;
          }
          default: {
            this.populateFormValues(
              this.globalSummer1,
              this.currentPeriods[per]
            );
            break;
          }
        }
      }
    }

    this.dataSource.data = [
      this.forms[0].value,
      this.forms[1].value,
      this.forms[2].value,
      this.forms[3].value,
    ];
    if (this.isNewSchoolYear) {
      this.dataSource.data.push(this.forms[4].value);
    }

    this.tableData = [
      this.forms[0].value,
      this.forms[1].value,
      this.forms[2].value,
      this.forms[3].value,
    ];
    if (this.isNewSchoolYear) {
      this.tableData.push(this.forms[4].value);
    }

    this.subscription.add(
      this.ratingPeriodService
        .globalRatingsApplied(this.currentYear.id)
        .subscribe((res) => {
          if (res) {
            this.agenciesApplied = res;
          }
        })
    );
  }

  populateFormValues(form: FormGroup, period: IGlobalRatingPeriod): void {
    form
      .get('defaultStartDate')
      ?.setValue(formatDate(period.defaultStartDate, 'M/d/yyyy', 'en-US'));
    form
      .get('defaultEndDate')
      ?.setValue(formatDate(period.defaultEndDate, 'M/d/yyyy', 'en-US'));
    form
      .get('minStartDate')
      ?.setValue(formatDate(period.minStartDate, 'M/d/yyyy', 'en-US'));
    form
      .get('maxEndDate')
      ?.setValue(formatDate(period.maxEndDate, 'M/d/yyyy', 'en-US'));
    form.get('periodName')?.setValue(period.periodName);
  }

  dateChange(change: any): void {
    let i = change.index;
    let property = change.property;
    let val = change.value;

    this.forms[i].get(property)?.setValue(formatDate(val, 'M/d/yyyy', 'en-US'));

    for (let form in this.forms) {
      if (!this.forms[form].valid) {
        this.validForm$.next(false);
        return;
      }
      this.validForm$.next(true);
    }
  }

  saveGlobalRatingPeriods() {
    let mappedPeriods = this.forms.map((form: any) => {
      let x = form.value;
      return <IGlobalRatingPeriod>{
        id: 0,
        schoolYearId: x.schoolYearId,
        globalRatingPeriodName: x.globalRatingPeriodName,
        minStartDate: x.minStartDate,
        maxEndDate: x.maxEndDate,
        defaultStartDate: x.defaultStartDate,
        defaultEndDate: x.defaultEndDate,
      };
    });

    this.subscription.add(
      this.ratingPeriodService
        .addGlobalRatingPeriod(mappedPeriods)
        .subscribe((success) => {
          if (success) {
            this.formTableColumns.forEach((x) => {
              x.type = TableColumnType.text;
            });
            this.currentPeriods = mappedPeriods;
            this.noSubmit = false;
            this.dataSource.data = [
              this.forms[0].value,
              this.forms[1].value,
              this.forms[2].value,
              this.forms[3].value,
              this.forms[4].value,
            ];
            this.tableData = [
              this.forms[0].value,
              this.forms[1].value,
              this.forms[2].value,
              this.forms[3].value,
              this.forms[4].value,
            ];
            this.toast.success($localize `Global Rating Periods have been saved.`);
          } else {
            this.toast.error($localize `Error! Could not add Global Rating Periods`);
          }
        })
    );
  }

  applyGlobalRatingPeriods(): void {
    const payload = {
      schoolYearId: this.currentYear.id,
      schoolYearName: this.currentYear.schoolYearName,
    };

    this.subscription.add(
      this.ratingPeriodService
        .applyGlobalRatingPeriodsExisting(payload)
        .subscribe((success) => {
          if (success) {
            this.agenciesApplied = true;
            this.toast.success(
              $localize `Rating Periods have been created for agencies.`
            );
          }
        })
    );
  }

  isValidDateOrder(form: FormGroup): boolean {
    return (
      new Date(form.get('minStartDate')?.value) <
        new Date(form.get('maxEndDate')?.value) &&
      new Date(form.get('defaultStartDate')?.value) <
        new Date(form.get('defaultEndDate')?.value)
    );
  }

  confirmSave(): void {
    if (!this.validForm$.getValue()) {
      this.toast.error($localize `Form not complete`);
      return;
    }

    for (var form in this.forms) {
      if (!this.isValidDateOrder(this.forms[form])) {
        this.toast.error($localize `Start dates cannot be after or equal to end dates`);
        return;
      }
    }

    let event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: $localize `Save Global Rating Periods`,
      message: $localize `Please confirm you want to save these dates.  They cannot be edited once saved.`,
      name: $localize `Global Rating Periods`,
      primaryBtnClass: 'blue',
      primaryBtnText: Modal.Confirm,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.saveGlobalRatingPeriods();
      }
    });
  }

  confirmSubmit(): void {
    let event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: $localize `Submit Global Rating Periods`,
      message: $localize `Submitting these dates will create the agency's rating periods for the school year.  Please confirm you want to submit.`,
      name: $localize `Global Rating Periods`,
      primaryBtnClass: 'blue',
      primaryBtnText: Modal.Confirm,
      showCancel: true,
    };

    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.applyGlobalRatingPeriods();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
