import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from '@core/services/toast.service';

@Component({
  selector: 'drdp-select-dropped-child',
  templateUrl: './select-dropped-child.component.html',
  styleUrls: ['./select-dropped-child.component.scss']
})
export class SelectDroppedChildComponent implements OnInit {

  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selectedChild: number | null = null;

  tableColumns = [
    { columnDef: 'firstName', header: 'First Name', type: 'text' },
    { columnDef: 'middleInitial', header: 'Middle Name', type: 'text' },
    { columnDef: 'lastName', header: 'Last Name', type: 'text' },
    { columnDef: 'SSID', header: 'SSID', type: 'text' },
    { columnDef: 'agencyId', header: 'Agency/District ID', type: 'text' },
    { columnDef: 'dob', header: 'DOB', type: 'date' },
    { columnDef: 'siteName', header: 'Previous Site', type: 'text' },
    { columnDef: 'classroomName', header: 'Previous Classroom', type: 'text' },
    { columnDef: 'selected', header: 'Select Child', type: 'checkBox',}
  ];
  
  constructor(
    public dialogRef: MatDialogRef<SelectDroppedChildComponent>,
    private router: Router,
    public toast: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
   }

  ngOnInit(): void {
    this.data = this.data.map((ch: any) => ({...ch, selected: false}))
    this.dataSource = new MatTableDataSource(this.data);
    this.tableData = this.data;
  }

  handleSelectedChild(event: any): void {
    if (this.selectedChild) {
      var currSelectedIndex = this.data.findIndex((ch: any) => ch.selected)
      var temp = this.data[currSelectedIndex]
      temp.selected = false;
      this.data[currSelectedIndex] = temp;
    } 

    var newSelectedIndex = this.data
      .findIndex((ch: any) => ch.drdpId == event.data.drdpId);
    
    if (currSelectedIndex != newSelectedIndex) {
      var newSelected =this.data[newSelectedIndex];
      newSelected.selected = true;
      this.data[newSelectedIndex] = newSelected;
  
      this.dataSource.data = this.data;
      this.tableData = this.data;
  
      this.selectedChild = event.data.drdpId;
    } else {
      this.selectedChild = null;
    }
  }

  submitSelectedChild(): void {
    if (this.selectedChild) {
      this.dialogRef.close();
      this.router.navigateByUrl(
        `view-child/${this.selectedChild}`, { 
          state: {
            isEdit: true,
            enrollDroppedChild: true
          } 
      });
    } else {
      this.toast.error('No child has been selected');
    }
  }
}
