import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StudentService } from "@core/services/student.service";
import { ToastService } from "@core/services/toast.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from "rxjs";
import { IAddStudentSharedProviderDto, IUserSpecialEdPowerDto } from "@core/interfaces/istudent";

@Component({
    selector: 'drdp-add-child-shared-provider',
    templateUrl: './add-child-shared-provider.component.html',
    styleUrls: ['./add-child-shared-provider.component.scss'],
})

export class AddChildSharedProviderComponent implements OnInit {
    childSharedProviderForm: FormGroup | any;
    options: any

    get teacherId() { return this.childSharedProviderForm.get('teacherId'); }
    get name() { return this.childSharedProviderForm.get('name'); }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddChildSharedProviderComponent>,
        private fb: FormBuilder,
        public toast: ToastService,
        private studentService: StudentService
    ) {
        dialogRef.disableClose = true
    }

    ngOnInit(): void {
        this.initializeForm();
    }

    initializeForm() {
        this.childSharedProviderForm = this.fb.group({
            teacherId: [null, Validators.required],
            name: [null, Validators.required]
        });
    }

    handleTeacher(event: any) {
        if (event && event.id && event.teacherName) {
            this.teacherId.setValue(event.id);
            this.name.setValue(event.teacherName);
        } else {
            this.teacherId.setValue(null);
            this.name.setValue(null);
        }
    }

    onSubmit() {
        if (this.childSharedProviderForm.invalid) {
            this.toast.error($localize `Please enter all required fields.`);
            return;
        }

        const payload: IAddStudentSharedProviderDto = {
            studentId: this.data?.studentId,
            userId: this.teacherId.value
        };

        this.studentService
            .addStudentSharedProvider(payload)
            .pipe(take(1))
            .subscribe((res: any) => {
                if (res) {
                    this.toast.success($localize `Shared provider added successfully.`);
                    this.dialogRef.close({success: res});
                }
            });
    }

}
