<div class="p-5">
  <div class="flex flex-row">
    <div class="basis-1/2">
      <drdp-title title="Sites" i18n-title></drdp-title>
    </div>
    <div class="basis-1/2">
      <div class="flex justify-end items-end">
        <drdp-btn *drdpCheckPermission="Permission.CreateSites" (click)="openModal()">
          <i class="uil uil-plus"></i>
          <span i18n>Add New Site</span>
        </drdp-btn>
      </div>
    </div>
  </div>
  <drdp-container>
    <drdp-search
      (search)="searchSite($event)"
      (clear)="onClear()"
      [searchInput]="searchInput"
      [reload]="reload"
      textInputPlaceholder="Site Name"
      i18n-textInputPlaceholder>
    </drdp-search>
    <div class="p-5 w-full">
      <drdp-add-edit-table
        class="whitespace-pre-wrap"
        [dataSource]="dataSource"
        [tableData]="tableData"
        [columns]="tableColumns" 
        [isSuperAdmin]="hasAccessToAll"
        (editActionItem)="openModal($event)"
        (toggleSwitchActionItem)="changeActiveStatus($event)"
        (deleteActionItem)="deleteModal($event)"
        toggleMsg="Are you sure you want to deactivate this site?"
        i18n-toggleMsg>
      </drdp-add-edit-table>
    </div>
  </drdp-container>
  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [length]="totalData"
    [pageIndex]="pageIndex"
    (page)="paginate(false)">
  </mat-paginator>
</div>
