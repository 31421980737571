import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { UserDropMenuService } from '@core/services/user-dropmenu.service';
import { Locale, LocaleService } from "@core/services/locale.service";

@Component({
  selector: 'drdp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user = this.authService.getCurrentUser();
  currentLocale = this._localeService.getLocale();
  availableLocales = this._localeService.getAvailableLocales();

  constructor(
    private userDropmenu: UserDropMenuService,
    public authService: AuthService,
    private _localeService: LocaleService
  ) {}

  headerBtns = this.userDropmenu.headerBtns;
  dropMenuItems = this.user.sites;

  userDropMenu: any = {
    label: this.user.userName,
    labelTwo: this.user.agencyName,
  };

  ngOnInit(): void {

  }

  logout(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser) this.authService.logout().pipe(take(1)).subscribe();
  }

  languageSelected(locale: Locale): void {
    this._localeService.changeLocale(locale);
  }
}
