<div class="rounded-lg">

    <div class="flex justify-between border-b">
        <h1 i18n class="text-[25px] font-bold">Remove Provider</h1>
        <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>
    <div id="delete-child-shared-provider" mat-dialog-content>
        <div i18n class="my-5">Are you sure you want to remove this provider from child?</div>
    </div>
    <div mat-dialog-actions class="flex justify-end align-bottom">
        <button i18n mat-button mat-dialog-close>Cancel</button>
        <drdp-btn i18n mat-button [disabled]="" cdkFocusInitial
            (click)="onDelete()">Remove Provider</drdp-btn>
    </div>

</div>