import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Permission } from '@core/enums/permissions';
import { SelectType } from '@core/enums/select';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { IModalEmitData } from '@core/interfaces/imodal';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ITableColumn } from '@core/interfaces/itable';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { PermissionService } from '@core/services/permission.service';
import { RatingPeriodService } from '@core/services/rating-period.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';
import { TransferChildComponent } from '../manage-enrollment/transfer-child/transfer-child.component';
import { DropChildComponent } from '../manage-enrollment/drop-child/drop-child.component';
import { MatDialog } from '@angular/material/dialog';
import { TableColumnType } from '@core/enums/table';

const tableIconAction = {
  transferChild: 'transferChild',
  dropChild: 'dropChild',
  viewChild: 'viewChild',
};

@Component({
  selector: 'drdp-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss'],
})
export class ChildrenComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  pageSizeOptions: number[] = [2, 10, 25, 50, 100];
  pageSize: number = 10;
  totalData: number = 0;
  pageIndex: number = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  tableData?: any;
  searchForm: FormGroup | any;

  @ViewChild('classroomSelect') classroomSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('sitesSelect') sitesSelect: FilterSelectComponent | undefined;
  @ViewChild('ratingPeriodSelect') ratingPeriodSelect:
    | FilterSelectComponent
    | undefined;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;

  get stateId() {
    return this.searchForm.get('stateId');
  }

  get agencyId() {
    return this.searchForm.get('agencyId');
  }
  get siteId() {
    return this.searchForm.get('siteId');
  }
  get ratingPeriodId() {
    return this.searchForm.get('ratingPeriodId');
  }
  get classId() {
    return this.searchForm.get('classId');
  }

  user = this.auth.getCurrentUser();

  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  classroomList: IClassroomAssignment[] = [];
  ratingPeriodList: IRatingPeriod[] = [];
  select = SelectType;
  clearState = false;
  clearAgency = false;
  clearSite = false;
  isSuperUser = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );

  tableColumns: ITableColumn[] = [
    {
      columnDef: 'lastName',
      header: $localize `Last Name`,
      type: TableColumnType.text,
      isSortable: true,
    },
    {
      columnDef: 'firstName',
      header: $localize `First Name`,
      type: TableColumnType.text,
      isSortable: true,
    },
    {
      columnDef: 'dob',
      header: 'DOB',
      type: TableColumnType.date,
      isSortable: true,
    },
    {
      columnDef: 'agency',
      header: $localize `Agency`,
      type: TableColumnType.text,
      isSortable: true,
    },
    {
      columnDef: 'site',
      header: $localize `Site`,
      type: TableColumnType.text,
      isSortable: true,
    },
    {
      columnDef: 'class',
      header: $localize `Class`,
      type: TableColumnType.text,
      isSortable: true,
    },
    {
      columnDef: 'teachers',
      header: $localize `Teacher`,
      type: TableColumnType.bulletList,
      isSortable: true,
    },
    {
      columnDef: 'enrollmentStartDate',
      header: $localize `Agency Enrollment`,
      type: TableColumnType.date,
      isSortable: true,
    },
    {
      columnDef: 'enrollmentEndDate',
      header: $localize `Agency Withdrawal`,
      type: TableColumnType.date,
      isSortable: true,
    },
    {
      columnDef: 'ageGradeInstrumentName',
      header: $localize `Age/Instrument`,
      type: TableColumnType.text,
      isSortable: true,
    },
    {
      columnDef: tableIconAction.viewChild,
      type: TableColumnType.actionIcon,
      action: tableIconAction.viewChild,
      header: $localize `View Student`,
      icon: 'file',
      cursorClass: 'cursor-pointer',
      colorClass: 'text-drdpblue-300',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private toastService: ToastService,
    private permissionService: PermissionService,
    private ratingPeriodService: RatingPeriodService,
    private classService: ClassService,
    private router: Router,
    public modal: MatDialog
  ) {}

  ngOnInit(): void {
    this.initializeForm();

    if (this.initAgencyId) {
      this.agencyId.setValue(this.initAgencyId);
      this.getRatingPeriod();
    }
  }

  initializeForm(): void {
    this.searchForm = this.fb.group({
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null],
      ratingPeriodId: [null],
      siteId: [null],
      classId: [null],
    });
  }

  getRatingPeriod(): void {
    this.ratingPeriodService
      .getByAgency(this.agencyId.value)
      .pipe(take(1))
      .subscribe((req) => {
        this.ratingPeriodList = req;
      });
  }

  search() {
    if (this.searchForm.invalid) {
      this.toastService.error($localize `Please enter all required fields.`);
      return;
    }
    this.paginateRatings(true);
  }

  paginateRatings(resetPage?: boolean) {
    const payload = {
      agencyId: this.agencyId.value,
      ratingPeriodId: this.ratingPeriodId.value,
      siteId: this.siteId.value,
      classId: this.classId.value,
      pageIndex: resetPage ? 0 : this.paginator.pageIndex + 1,
      itemsPerPage: resetPage ? 10 : this.paginator.pageSize,
    };

    this.classService
      .getActiveClassStudentsByPermissions(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res) {
          if (resetPage) this.paginator.firstPage();
          this.dataSource = new MatTableDataSource(res.items);
          this.tableData = res.items;
          this.totalData = res.totalData;
        }
      });
  }

  onIconClick(event: any): void {
    event.data.classId = this.classId.value;
    event.data.agencyId = this.agencyId.value;
    event.data.ratingPeriodId = this.ratingPeriodId.value;
    event.data.siteId = this.siteId.value;
    switch (event.action) {
      case tableIconAction.transferChild:
        this.transferChildModal(event);
        break;
      case tableIconAction.dropChild:
        this.dropChildModal(event);
        break;
      case tableIconAction.viewChild:
        this.router.navigate(['/view-child/' + event.data.id]);
        break;
      default:
        break;
    }
  }

  transferChildModal(event: IModalEmitData) {
    if (event.data.id > 0) {
      const modalRef = this.modal.open(TransferChildComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe((success) => {
        if (success) {
          this.search();
        }
      });
    } else {
      this.toastService.error($localize `No Student Data Present`);
    }
  }

  dropChildModal(event: IModalEmitData) {
    if (event.data.id > 0) {
      const modalRef = this.modal.open(DropChildComponent, {
        data: event ? event : undefined,
      });
      modalRef.afterClosed().subscribe((success) => {
        if (success) {
          this.search();
        }
      });
    } else {
      this.toastService.error($localize `No Student Data Present`);
    }
  }

  handleState(state: any) {
    this.stateId.setValue(state?.id);
    this.agencyId.setValue(null);
  }

  handleChosenAgency(agency: any): void {
    this.agencyId.setValue(agency?.id);
    this.classId.setValue(null);
    this.ratingPeriodId.setValue(null);
    this.classroomList = [];
    if (agency) {
      this.getRatingPeriod();
    } else {
      this.ratingPeriodList = [];
      this.classroomList = [];
    }
  }

  handleChosenSite(site: any): void {
    this.siteId.setValue(site?.id);
    this.updateClassroomDropdown();
  }

  handleChosenClassroom(classroom: any): void {
    this.classId.setValue(classroom?.id);
  }

  handleChosenRatingPeriod(ratingPeriod: any): void {
    this.ratingPeriodId.setValue(ratingPeriod?.id);
    this.updateClassroomDropdown();
  }

  updateClassroomDropdown(): void {
    this.classroomList = [];
    const payload: any = {};
    payload.siteIds = this.siteId.value ? [this.siteId.value] : null;
    payload.ratingPeriodId = this.ratingPeriodId.value ?? '';
    payload.itemsPerPage = 10;
    payload.useClassId = true;

    if (payload.siteIds?.length > 0 && payload.ratingPeriodId > 0) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomList = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id)
          );
        });
    }
  }

  clear(): void {
    this.classroomSelect?.clear();
    this.ratingPeriodSelect?.clear();
    this.clearSite = true;
    this.classroomList = [];
    if (this.isSuperUser) {
      this.initStateId = 0;
      this.stateId.setValue(null);
      this.stateSelect?.clear();
      this.agencyId.setValue(null);
      this.agencySelect?.clear();
      this.sitesSelect?.clear();
      this.ratingPeriodList = [];
      this.clearState = true;
      this.clearAgency = true;
    }
  }
}
