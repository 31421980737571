import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SearchStudentAction, TableColumnType } from '@core/enums/table';
import { AuthService } from '@core/services/auth.service';
import { StudentService } from '@core/services/student.service';
import { ToastService } from '@core/services/toast.service';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { SelectCountyComponent } from '@shared/components/dropdowns/select-county/select-county.component';
import { isNumber } from '@core/services/helper.service';
import { ReleaseStudentComponent } from './request-release-student/request-release-student.component';
import { StateService } from '@core/services/state.service';
import { take, tap } from 'rxjs';
import { IStateConfiguration } from '@core/interfaces/istate-configurations';
import { StudentDemographic } from '@core/enums/student-demographic-form';
import { States } from '@core/enums/states';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';
import { ITableColumn } from '@core/interfaces/itable';
import { StudentStatus } from '@core/enums/student-request-reason';
import { DualEnrollChildComponent } from '../dual-enroll-child/dual-enroll-child.component';
import { SiteService } from '@core/services/site.service';
import { AdditionalFilters } from '@core/enums/additional-filters';

@Component({
  selector: 'drdp-add-search-child',
  templateUrl: './add-search-child.component.html',
  styleUrls: ['./add-search-child.component.scss']
})
export class AddSearchChildComponent implements OnInit {
  @ViewChild('countySelect') countySelect: SelectCountyComponent | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  payload: any = {};
  searchForm: FormGroup | any;
  userStateId?: number;

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex: number = 0;
  pageSize: number = 10;
  totalData = 0;
  tableData?: any;
  ssidLabel = `SSID`;
  ssidPlaceholder = `SSID`;
  isNewHampshire = false;
  showCheckbox = false;
  disableAddChildBtn = true;
  dualEnrollmentExists = false;

  get firstName() {
    return this.searchForm.get('firstName');
  }
  get lastName() {
    return this.searchForm.get('lastName');
  }
  get dob() {
    return this.searchForm.get('dob');
  }
  get SSID() {
    return this.searchForm.get('ssid');
  }
  get drdpId() {
    return this.searchForm.get('drdpId');
  }
  get countyOfProgramId() {
    return this.searchForm.get('countyOfProgramId');
  }
  get isDifferentChild() {
    return this.searchForm.get('isDifferentChild');
  }
  get additionalFilter() {
    return this.searchForm.get('additionalFilter');
  }
  get subFilter() {
    return AdditionalFilters;
  }

  tableColumns: ITableColumn[] = [
    {
      columnDef: 'firstName',
      header: $localize `First Name`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'lastName',
      header: $localize `Last Name`,
      type: TableColumnType.text
    },
    {
      columnDef: 'dob',
      header: $localize `DOB`,
      dateFormat: 'mediumDate',
      type: TableColumnType.date,
    },
    { columnDef: 'ssid', header: 'SSID', type: TableColumnType.text },
    { columnDef: 'drdpId', header: 'DRDP ID', type: TableColumnType.text },
    {
      columnDef: 'previousCurrentSite',
      header: $localize `Previous/Current Site`,
      type: TableColumnType.text,
    },
  ];

  constructor(
    public modal: MatDialog,
    private fb: FormBuilder,
    public authService: AuthService,
    private studentService: StudentService,
    private router: Router,
    private toastService: ToastService,
    private stateService: StateService,
    private permission: PermissionService,
    private siteService: SiteService
  ) {}

  ngOnInit(): void {
    if (this.permission.checkPermission(Permission.ViewCurrentEnrollment)) {
      this.tableColumns.push(
        {
          columnDef: 'previousCurrentAgency',
          header: $localize `Previous/Current Agency`,
          type: TableColumnType.text,
        }
      );
    }

    this.tableColumns.push(
      { columnDef: 'status', header: $localize `Status`, type: TableColumnType.text },
      { columnDef: 'action', header: $localize `Action`, type: TableColumnType.action }
    );

    const userSites = this.authService.getCurrentUser()?.sites;
    const canDualEnroll = userSites?.some((site: any) => site.site?.isDualEnrollment);

    if (this.permission.checkPermission(Permission.ManageDualEnrollment) && canDualEnroll) {
      this.tableColumns.push(
        {
          columnDef: 'hideIcon',
          type: TableColumnType.actionIcon,
          action: 'dualEnroll',
          header: $localize `Dual Enrollment`,
          icon: 'users-alt',
          cursorClass: 'cursor-pointer',
          colorClass: 'text-drdpblue-300',
          info: $localize `As a Dual Enrollment Site, you can enroll an already enrolled child into your program by clicking on the Dual Enrollment icon below.`,
          tooltip: $localize `Request Dual Enrollment`
        }
      );
    }
    if (this.permission.checkPermission(Permission.Access2025StudentOptions)) {
      this.tableColumns.push(
        {
          columnDef: 'hidePilotIcon',
          type: TableColumnType.actionIcon,
          action: 'pilotEnroll',
          header: '2025 Pilot',
          icon: 'users-alt',
          cursorClass: 'cursor-pointer',
          colorClass: 'text-drdpblue-300',
        }
      );
    }

    this.userStateId = this.authService.getUserStateId();
    this.isNewHampshire = this.userStateId == States.newHampshire;
    this.getSsidLabel(this.userStateId);
    this.initializeForm();
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      firstName: [null, [Validators.required, Validators.maxLength(100)]],
      lastName: [null, [Validators.required, Validators.maxLength(100)]],
      dob: [null, [Validators.required]],
      ssid: [null],
      drdpId: [null],
      countyOfProgramId: [null],
      isDifferentChild: [false],
      additionalFilter: AdditionalFilters.None
    });

    this.additionalFilter.valueChanges.subscribe((val: AdditionalFilters) => {
      if (val == AdditionalFilters.None) {
        this.searchForm.patchValue({
          ssid: null,
          drdpId: null,
          countyOfProgramId: null
        });
      }
    });
  }

  getSsidLabel(stateId: number): void {
    this.stateService.getStateConfigurations(stateId).pipe(
      take(1),
      tap((config: IStateConfiguration[]) => {
        const ssid = config.find(
          (x: any) => x.columnName === StudentDemographic.SSID
        );
        this.ssidLabel = ssid?.label ? ssid?.label : this.ssidLabel;
        this.ssidPlaceholder = ssid?.label ? ssid?.label : this.ssidLabel;

        const ssidColumnIndex = this.tableColumns.findIndex((col: any) => col.header === 'SSID');
        if (this.isNewHampshire) {
          this.tableColumns[ssidColumnIndex].header = 'SASID';
        }
      })
      ).subscribe();
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return isNumber(charCode);
  }

  search() {
    if (this.searchForm.invalid) {
      this.toastService.error($localize `:addSearchChild:Please enter all required fields.`);
      return;
    }

    this.payload = this.searchForm.value;

    this.payload.pageIndex = 0;
    this.payload.itemsPerPage = 10;
    this.paginateTable(false);
  }

  initializeTableData(res?: any): void {
    const modifiedItems = res?.items.map((item: any) => {
      let iconName = '';
      let tooltipText = '';
      let actionId = 0;
      let hideIcon = false;
      if (item?.isAddChild === true) {
        iconName = 'uil-user-plus';
        tooltipText = $localize `:addSearchChild:Add Child`;
        actionId = SearchStudentAction.AddChild;
        hideIcon = item?.isDualEnrollment;
      } else if (item?.isReleaseRequest === true) {
        iconName = 'uil-sign-out-alt';
        tooltipText = $localize `:addSearchChild:Request Release`;
        actionId = SearchStudentAction.RequestRelease;
      } else {
        iconName = 'uil-file';
        tooltipText = $localize `:addSearchChild:View Student`;
        actionId = SearchStudentAction.ViewStudent;
      }
      return {
        ...item,
        actionIcon: iconName,
        tooltipText: tooltipText,
        actionId,
        hideIcon
      };
    });
    this.dataSource = new MatTableDataSource(modifiedItems);
    this.totalData = res.totalData;
    this.tableData = modifiedItems;
  }

  clear() {
    this.searchForm.reset({ additionalFilter: AdditionalFilters.None });
    this.countySelect?.clearSelected();
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
    this.pageSize = 10;
    this.paginator.pageSize = 10;
  }

  paginateTable(resetPage: boolean): void {
    this.studentService.addSearch(this.payload).subscribe((res: any) => {
      if (!res) {
        return;
      }

      if (res?.items.length < 1) {
        this.openNoResultsModal();
        return;
      }

      this.showCheckbox = true;
      this.dualEnrollmentExists = res?.items.some((item: any) => 
        item?.isDualEnrollment && item?.status === this.getLocalizedStudentStatus(StudentStatus.StillEnrolled));
      if (resetPage) this.paginator.firstPage();
      this.initializeTableData(res);
      if (res.totalData > 25) this.pageSizeOptions.push(res.totalData);
    });
  }

  handleChosenCounty(event: any) {
    this.countyOfProgramId.setValue(event?.id);
  }

  handleAddChildBtn() {
    if (this.disableAddChildBtn) return;

    this.router.navigate(['/add-child'], {
      state: { searchValues: this.searchForm.value },
    });
  }

  handleCheckbox() {
    this.disableAddChildBtn = !this.disableAddChildBtn;
    this.isDifferentChild.setValue(!this.isDifferentChild.value);
  }

  openNoResultsModal(): void {
    var event = { data: { modalInfo: {} } };
    event.data.modalInfo = {
      title: $localize `:addSearchChild:No Results Found`,
      message: $localize `:addSearchChild:There were no results found.  Please check the full and accurate spelling of the name of the child.  Proceed to Add New Child.`,
      primaryBtnClass: 'blue',
      primaryBtnText: $localize `Add New Child`,
      showCancel: true,
      cancelText: $localize `Return to Search`,
    };
    const modalRef = this.modal.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.router.navigate(['/add-child'], {
          state: { searchValues: this.searchForm.value },
        });
      }
    });
  }

  getLocalizedStudentStatus(status: StudentStatus): string {
    switch (status) {
      case StudentStatus.StillEnrolled:
        return $localize `Still Enrolled`;
      default:
        console.log(status);
        return '';
    }
  }

  openEnrollmentModal(event: any): void {
    if (event.action === 'dualEnroll') {
      const { isDualEnrollment, status } = event.data;
      if ((isDualEnrollment && status === this.getLocalizedStudentStatus(StudentStatus.StillEnrolled)) || this.dualEnrollmentExists) 
      {
        this.toastService.error('Student is still enrolled and currently in a Dual Enrollment.');
        return;
      }
    }
    else if (event.action === 'pilotEnroll') {
      if (event.data.isAddChild) {
        this.toastService.error('Primary Enrollment can not be added to a 2025 Pilot Classroom');
        return;
      }
      if (event.data.isDualEnrollment || event.data.isPilotEnrolled) {
        this.toastService.error('This child is already dual enrolled and can not be added to the 2025 Pilot.');
        return;
      }
      if (!event.data.isPilotTest) {
        this.toastService.error('This child has not been pre-chosen as a 2025 Pilot child.');
        return;
      }
    }
    const modalRef = this.modal.open(DualEnrollChildComponent, {
      data: event ? event : undefined,
    });

    modalRef.afterClosed().subscribe((res) => {
      if (res) {
        this.search();
      }
    });
  }

  actionItem(event: any) {
    if (!event) {
      return;
    }
    if (event?.data.actionId == SearchStudentAction.AddChild) {
      this.router.navigateByUrl(`view-child/${event?.data?.id}`, {
        state: {
          isEdit: true,
          enrollDroppedChild: true,
        },
      });
    } else if (event?.data.actionId == SearchStudentAction.RequestRelease) {
      this.openRequestReleaseModal(event);
    } else if (event?.data.actionId == SearchStudentAction.ViewStudent) {
      this.router.navigateByUrl(`view-child/${event?.data?.id}`);
    } else {
      this.toastService.error($localize `:addSearchChild:No action found.`);
    }
  }

  openRequestReleaseModal(event: any) {
    if (event.data.id < 1) {
      this.toastService.error($localize `No Student Data Present`);
    } else if (!event.data.isDualEnrollment){
        const modalRef = this.modal.open(ReleaseStudentComponent, {
          data: event ? event : undefined,
        });
    } else {
      this.siteService.validateUserSite().pipe(take(1)).subscribe((res: boolean) => {
        if (res) {
          const modalRef = this.modal.open(ReleaseStudentComponent, {
            data: event ? event : undefined,
          });
        }
        else {
          this.toastService.error($localize `In order to request the release of a dual enrolled child, you must be a dual enrollment site.`);
        }
      });
    }
  }

  handleDate(event: any): void {
    this.dob.setValue(event);
  }
}
