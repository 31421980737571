import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRatingDomainDto } from '../../interfaces/irating-domain';
import { IStudentEnrollmentInfo } from '../../interfaces/istudent-enrollment-info';
import { IRatingMeasureDto } from '../../interfaces/irating-measure';
import {
  MeasureCondition,
  MeasureConditionName,
} from '@core/enums/measure-condition';
import { MeasureReason } from '@core/enums/measure-reason';
import { IRatingReason } from '../../interfaces/irating-reason';
import { IMeasureCondition } from '../../interfaces/imeasure-condition';

@Component({
  selector: 'drdp-scantron',
  templateUrl: './scantron.component.html',
  styleUrls: ['./scantron.component.scss'],
})
export class ScantronComponent implements OnInit {
  private _studentDomains: IRatingDomainDto[] = [];
  @Input() carReasons: IRatingReason[] = [];
  @Input() isKinder: boolean = false;
  @Input() studentInfo?: IStudentEnrollmentInfo;
  otherText = $localize `Other`;
  @Input()
  set studentDomains(value: IRatingDomainDto[]) {
    this._studentDomains = value;
    this.processDomains();
  }
  get studentDomains(): IRatingDomainDto[] {
    return this._studentDomains;
  }

  @Output() measureUpdated = new EventEmitter<{
    domain: IRatingDomainDto;
    measure: IRatingMeasureDto;
  }>();
  public get measureCondition() {
    return MeasureCondition;
  }
  constructor() {}

  ngOnInit(): void {}

  processDomains() {
    if (this.studentDomains.length > 0) {
      this.studentDomains.forEach((domain) => {
        domain.notYet = domain.domainConditions.find(
          (d) => d.id == MeasureCondition.NotYet
        );
        domain.conditional = domain.domainConditions.find(
          (d) => d.id == MeasureCondition.Conditional
        );
        domain.EM = domain.domainConditions.find(
          (d) => d.id == MeasureCondition.Emerging
        );
        null;
        domain.UR = domain.domainConditions.find(
          (d) => d.id == MeasureCondition.Unratable
        );

        domain.measures.forEach((measure) => {
          if (measure.ratingValue) {
            measure.canEmerging =
              measure.ratingValue != (measure?.maxLevel ?? 0);
          } else if (measure.measureConditionId) {
            measure.showComment =
              measure.childAssessmentRatingReasonId == MeasureReason.Other;
            measure.canEmerging =
              measure.measureConditionId == 0 ||
              measure.measureConditionId == MeasureCondition.Emerging;
          }

          measure.displayLevels = [];
          domain.domainLevels.forEach((domainLevel) => {
            const level = measure.measureLevels?.find(
              (m) => m.levelName === domainLevel.levelName
            );
            const mdl = level
              ? { isDisabled: false, value: level.id, name: level.levelName }
              : { isDisabled: true, value: 0, name: null };

            measure.displayLevels.push(mdl);
          });

          measure.notYet = measure.measureConditions?.find(
            (m) => m.measureConditionId == MeasureCondition.NotYet
          )?.measureCondition;
          measure.conditional = measure.measureConditions?.find(
            (m) => m.measureConditionId == MeasureCondition.Conditional
          )?.measureCondition;
          measure.EM = measure.measureConditions?.find(
            (m) => m.measureConditionId == MeasureCondition.Emerging
          )?.measureCondition;
          measure.UR = measure.measureConditions?.find(
            (m) => m.measureConditionId == MeasureCondition.Unratable
          )?.measureCondition;

          measure.isUR =
            measure.measureConditionId == MeasureCondition.Unratable;
          measure.isEM =
            measure.measureConditionId == MeasureCondition.Emerging;
          measure.isNotYet =
            measure.measureConditionId == MeasureCondition.NotYet;
        });
      });
    }
  }

  selectLevel(
    domain: IRatingDomainDto,
    measure: IRatingMeasureDto,
    level: any
  ) {
    if (this.studentInfo?.isLocked) return;
    measure.canEmerging = level.value != (measure.maxLevel ?? 0);
    measure.ratingValue = level.value;
    measure.selectedLevelName = level.name;
    if (
      measure.canEmerging &&
      measure.measureConditionId == MeasureCondition.Emerging
    ) {
      return;
    }
    measure.measureConditionId = null;
    measure.isUR = false;
    measure.isNotYet = false;
    measure.isEM = false;
    this.emitMeasureChange(domain, measure);
  }

  selectCondition(
    domain: IRatingDomainDto,
    measure: IRatingMeasureDto,
    condition: IMeasureCondition
  ) {
    if (this.studentInfo?.isLocked) return;
    if (condition.id == MeasureCondition.Emerging) {
      if (!measure.canEmerging) return;
      else measure.measureConditionId = condition.id;
    } else {
      measure.canEmerging = false;
      measure.measureConditionId = condition.id;
      measure.ratingValue = null;
      measure.selectedLevelName = condition.measureConditionName;
      measure.measureConditionName = condition.measureConditionName;
    }
    measure.isNotYet = false;
    measure.isEM = false;
    measure.isUR = false;
    this.emitMeasureChange(domain, measure);
  }

  toggleMeasureUR(
    domain: IRatingDomainDto,
    measure: IRatingMeasureDto,
    condition: IMeasureCondition
  ) {
    if (measure.isUR) {
      measure.ratingValue = null;
      measure.canEmerging = false;
      measure.selectedLevelName = condition.measureConditionName;
      measure.measureConditionId = MeasureCondition.Unratable;
      measure.measureConditionName = MeasureConditionName.Unratable;
      measure.isNotYet = false;
      measure.isEM = false;
    } else {
      measure.measureConditionId = null;
      measure.measureConditionName = null;
      measure.selectedLevelName = null;
      measure.canEmerging = true;
    }

    this.emitMeasureChange(domain, measure);
  }

  toggleMeasureNotYet(
    domain: IRatingDomainDto,
    measure: IRatingMeasureDto,
    condition: IMeasureCondition
  ) {
    if (measure.isNotYet) {
      measure.ratingValue = null;
      measure.canEmerging = false;
      measure.selectedLevelName = condition.measureConditionName;
      measure.measureConditionId = MeasureCondition.NotYet;
      measure.measureConditionName = MeasureConditionName.NotYet;
      measure.isUR = false;
      measure.isEM = false;
    } else {
      measure.measureConditionId = null;
      measure.measureConditionName = null;
      measure.selectedLevelName = null;
    }

    this.emitMeasureChange(domain, measure);
  }

  toggleMeasureEM(domain: IRatingDomainDto, measure: IRatingMeasureDto) {
    if (measure.isEM) {
      measure.measureConditionId = MeasureCondition.Emerging;
      measure.measureConditionName = MeasureConditionName.Emerging;
      measure.isUR = false;
      measure.isNotYet = false;
    } else {
      measure.measureConditionId = null;
      measure.measureConditionName = null;
      measure.selectedLevelName = null;
    }

    this.emitMeasureChange(domain, measure);
  }

  selectReason(
    domain: IRatingDomainDto,
    measure: IRatingMeasureDto,
    reason: IRatingReason
  ) {
    measure.childAssessmentRatingReasonId = reason.id;
    measure.showComment = reason.reasonComment == this.otherText;
    if (!measure.showComment) measure.comment = null;
    this.emitMeasureChange(domain, measure);
  }

  emitMeasureChange(domain: IRatingDomainDto, measure: IRatingMeasureDto) {
    this.measureUpdated.emit({ domain: domain, measure: measure });
  }
}
