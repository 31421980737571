<div class="p-5">
    <div class="basis-1/2">
        <h1 i18n class="pb-3 text-3xl font-semibold">Global Rating Periods</h1>
    </div>
    <div class="mb-10">
        <drdp-add-global-rating-period [currentYear]="currentYear" [currentPeriods]="currentPeriods">
        </drdp-add-global-rating-period>
    </div>
    <div>
        <drdp-past-global-rating-periods [pastYears]="pastYears" [pastPeriods]="pastPeriods">
        </drdp-past-global-rating-periods>
    </div>
</div>
