import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  @ViewChild('canvas') canvas?: ElementRef;
  public chart: any;
  chartId: string = '';
  constructor() { }

  ngOnInit() {
    this.chartId = 'bar_' + Math.random().toString(36).substr(2, 9); // Generate a unique ID
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart() {
    if(this.canvas === undefined) return;
    const ctx: CanvasRenderingContext2D = this.canvas.nativeElement.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['A', 'B', 'C', 'D', 'E'],
        datasets: [
          {
            label: '',
            data: ['5', '33', '7', '18', '44'],
            backgroundColor: 'black'
          },
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true
      }
    });
  }
}
