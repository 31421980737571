<div class="rounded-lg fixActionRow w-96">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{measureCode}} {{title}}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content>
    <div class="my-5">
      <div *ngFor="let option of options" class="flex items-center mt-3">
        <ng-container *ngIf="option.isDisabled">
          <label class="ml-2 font-normal inline-block"><b>N/A -</b> {{option.name}}</label>
        </ng-container>
        <ng-container *ngIf="!option.isDisabled">
          <img (click)="onOptionClick(option.id, option.isMultiLevel, option.name, option.iconName)" 
               src="assets/images/portfolio/{{option.iconName}}-{{option.id === selectedId ? selectedLevel : 1}}.svg" 
               class="inline-block cursor-pointer" />
          <label class="ml-2 font-normal inline-block">{{option.name}}</label>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <drdp-btn i18n color="cancel" class="mr-2" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn i18n mat-button cdkFocusInitial (click)="onSubmit()">Select Preliminary Rating</drdp-btn>
  </div>
</div>