import { Component, OnInit, ViewChild } from "@angular/core";
import * as XLSX from 'xlsx';
import { ToastService } from "@core/services/toast.service";
import { Router } from "@angular/router";
import { IUploadChildSaveResult, IUploadValidateHeaders } from "@core/interfaces/iupload-center";
import { UploadService } from "@core/services/upload.service";
import { take } from "rxjs";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmationModalComponent } from "@shared/components/confirmation-modal/confirmation-modal.component";
import { Modal } from "@core/enums/modal";
import { MatDialog } from "@angular/material/dialog";
@Component({
    selector: 'drdp-upload-center-class',
    templateUrl: './upload-center-class.component.html',
    styleUrls: ['./upload-center-class.component.scss'],
})



export class UploadCenterClassComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    private readonly checkFileMessage: string = $localize `Press click 'CHECK FILE' button to scan file for errors.`;
    private readonly submitFileMessage: string = $localize `No errors found. Click the submit button to proceed.`;
    private readonly checkFileHasErrorMessage: string = $localize `We found some errors in the file, please fix them and check the file again or reupload the updated file.`;
    headerNames?: any;
    xlsxData?: any;
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    headerData?: any;
    cardText: string = this.checkFileMessage;
    cardColor: string = 'card-info';
    isFormValid: boolean = false;
    isFormValidated: boolean = false;
    isFormHasError: boolean = false;
    isHeaderValid: boolean = true;
    pageSizeOptions: number[] = [10, 25, 50, 100];

    constructor(
        private uploadService: UploadService,
        private toast: ToastService,
        private router: Router,
        public modal: MatDialog
    ) { }

    ngOnInit(): void {
        this.readData();
    }

    onDataChange(event: any) {
        this.showCheckFileCard(this.checkFileMessage);
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    showErrorCard(message: string): void {
        this.isFormHasError = true;
        this.isFormValid = false;
        this.isFormValidated = true;
        this.cardText = message;
        this.cardColor = 'card-warn'
    }

    showSubmitCard(message: string): void {
        this.isFormHasError = false;
        this.isFormValid = true;
        this.isFormValidated = true;
        this.cardText = message;
        this.cardColor = 'card-success'
    }

    showSuccessCard(message: string): void {
        this.isFormValidated = false;
        this.isFormHasError = false;
        this.isFormValid = true;
        this.cardText = message;
        this.cardColor = 'card-success'
    }

    showCheckFileCard(message: string): void {
        this.isFormValidated = false;
        this.isFormHasError = false;
        this.isFormValid = false;
        this.cardText = message;
        this.cardColor = 'card-info'
    }

    getMatCardClass(): string {
        return this.cardColor;
    }

    checkFile(): void {
        this.uploadService.checkFileClass(this.dataSource.data).pipe(take(1)).subscribe((res: any) => {
            if (res) {
                this.setData(res);

                if (this.dataSource.data.some(e => e.hasError === true)) {
                    this.showErrorCard(this.checkFileHasErrorMessage);
                } else {
                    this.showSubmitCard(this.submitFileMessage);
                }
            }
        });
    }

    validateHeaders(headers: any): void {
        var message = '';
        this.uploadService.validateClassHeaders(headers).pipe(take(1)).subscribe((res: IUploadValidateHeaders) => {
            if (!res.isValidUpload) {
                if (res.missingColumns.length > 0) {
                    message = $localize `There are [${res.missingColumns.length}] columns that are missing. Please make sure you have [${res.missingColumns.join(',')}] in your upload file.`;
                }

                if (res.invalidColumns.length > 0) {
                    if (message !== '')
                        message += '<br><br>';

                    message += $localize `The following columns are not valid columns : [${res.invalidColumns.join(',')}]. Please remove from the upload file.`
                }
                this.showErrorCard(message);
                this.toast.error($localize `Error in headers`);
            }
            else {
                this.processData(this.xlsxData);
            }
        });

    }

    readData(): void {
        const file = this.uploadService.getUploadFile();

        if (!file) {
            this.toast.error($localize `File can't be null`);
            this.router.navigate(['/upload-center'])
            return;
        }

        const reader = new FileReader();

        reader.onload = (e: any) => {

            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });

            const sheetNames = wb.SheetNames;

            // Assuming the first sheet is the one you want
            const firstSheetName = sheetNames[0];

            // Access the first sheet
            const ws = wb.Sheets[firstSheetName];

            if (!ws) {
                this.toast.error($localize `Worksheet can't be null`);
                return;
            }

            this.headerNames = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
            this.xlsxData = XLSX.utils.sheet_to_json(ws, { raw: false });

            this.validateHeaders(this.headerNames);
        }
        reader.readAsBinaryString(file);

    }

    processData(xlsxData: any): void {

        if (!xlsxData) {
            this.toast.error($localize `Xlsx data can't be null`);
            return;
        }

        var allXlsxData = xlsxData;
        var headers = this.headerNames;
        var tArr = [];

        for (var i = 0; i < headers.length; i++) {
            tArr.push({ columnDef: headers[i].replace(/[^a-zA-Z0-9 ]/g, "").replaceAll(" ", "").trim(), data: headers[i].replaceAll(" ", "") });
        }
        this.headerData = tArr;

        this.setData(this.uploadService.parseExcelToJson(allXlsxData, headers));

        this.cardText = this.checkFileMessage;
    }

    setData(data: any) {
        data.sort((a: { hasError: boolean; }, b: { hasError: boolean; }) => {
            if (a.hasError === b.hasError) {
                return 0;
            }
            if (a.hasError) {
                return -1;
            }
            return 1;
        });
        this.dataSource.data = data;
    }

    save(): void {
        const file: File = this.uploadService.getUploadFile();
        this.uploadService.saveUploadClass(file, this.dataSource.data).pipe(take(1)).subscribe((res: any[]) => {
            if (res.some((e: { isSuccess: boolean; }) => e.isSuccess === false)) {
                this.toast.error(res[0].errorMessage)
            } else {
                this.openSuccessModal();
            }
        });
    }

    uploadNewFile() {
        this.router.navigate(['/upload-center']);
    }

    translateOk(ok: any): string {
        switch (ok) {
          case Modal.OK: 
            return $localize `Ok`;
          default:
            return '';
        }
      }

    openSuccessModal(): void {
        var event = { data: { modalInfo: {} } };
        event.data.modalInfo = {
            title: $localize `Success`,
            message: $localize `The file successfully submitted.`,
            primaryBtnClass: 'blue',
            showCancel: false,
            primaryBtnText: this.translateOk(Modal.OK)
        };
        const modalRef = this.modal.open(ConfirmationModalComponent, { data: event ? event : undefined });
        modalRef.afterClosed().subscribe(() => {
            this.router.navigate(['/upload-center']);
        });
    }

}
