<div class="flex flex-col p-5 justify-center">
  <drdp-title i18n-title title="Add New Child" class="pt-5"></drdp-title>
  <drdp-container [additionClasses]="'mx-auto flex p-8 mb-5'">
    <form (ngSubmit)="validateFormData($event)">
      <ng-container [formGroup]="addChildForm">
        <h2 class="font-semibold text-lg" i18n>Child Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <label class="font-normal required" for="firstName" i18n>First Name</label>
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="First Name"
                i18n-placeholder
                matInput
                autocomplete="given-name"
                type="text"
                formControlName="firstName"
                maxlength="50"
                required
              />
              <mat-error *ngIf="firstName.invalid" i18n>{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="middleInitial" i18n>Middle Initial</label>
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="Middle Initial"
                i18n-placeholder
                matInput
                autocomplete="off"
                type="text"
                formControlName="middleInitial"
                maxlength="2"
              />
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal required" for="lastName" i18n>Last Name</label>
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="Last Name"
                i18n-placeholder
                matInput
                autocomplete="family-name"
                type="text"
                formControlName="lastName"
                maxlength="50"
                required
              />
              <mat-error *ngIf="lastName.invalid" i18n>{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="SSID">
              {{ ssidLabel }}
              <span class="required"></span>
              <br />
            </label>
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="Required if Agency/District ID is blank"
                i18n-placeholder
                matInput
                autocomplete="off"
                type="text"
                formControlName="SSID"
                [maxlength]="ssidmaxLength"
              />
              <mat-error *ngIf="SSID.hasError('pattern')" i18n>{{ ssidPatternErrorMsg }}</mat-error>
              <mat-error *ngIf="SSID.hasError('required')" i18n>{{ ssidLocalIdReqErrorMsg }}</mat-error>
              <mat-error *ngIf="SSID.hasError('duplicateSSID')">{{ ssidErrorMsg }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="localID">
              {{ agencySidLabel }}
              <span class="required"></span>
              <br />
            </label>
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="Required if Statewide Student Identifier is blank"
                i18n-placeholder
                matInput
                autocomplete="off"
                type="text"
                formControlName="localID"
                maxlength="25"
              />
              <mat-error *ngIf="localID.hasError('required')" i18n>{{ ssidLocalIdReqErrorMsg }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <drdp-date-picker
              label="DOB"
              i18n-label
              [initialDate]="dob?.value"
              [isReadOnly]="false"
              [isRequired]="true"
              labelClasses="pb-5 mb-5"
              (emitDate)="handleDate($event, 'dob')"
            >
            </drdp-date-picker>
          </div>
          <div>
            <drdp-select-age-grade
              #ageGroupInstrumentSelect
              [initialageGradeId]="ageGradeInstrumentId.value"
              (ageGrade)="handleAgeGrade($event)"
              [stateId]="userStateId"
              [multiSelect]="false"
              [required]="true"
              label="Child Age/Grade Instrument"
              i18n-label
            >
            </drdp-select-age-grade>
          </div>
          <div>
            <label class="font-normal" for="socialEconomic" i18n>Child's Socioeconomic Status</label>
            <mat-checkbox i18n
              [color]="'primary'"
              class="example-margin font-normal"
              formControlName="socialEconomic"
            >
              Qualify for free or reduced meals?
            </mat-checkbox>
          </div>
          <div class="form-input-label-group">
            <div class="col-span-1 col-end-3">
              <label class="font-normal required" for="genderID" i18n>Gender</label>
              <mat-radio-group
                aria-label="Select an option"
                formControlName="genderID"
              >
                <mat-radio-button
                  [color]="'primary'"
                  *ngFor="let g of genders"
                  [value]="g.id"
                >
                  {{ g.genderName }}
                </mat-radio-button>
                <mat-error *ngIf="genderID.touched && genderID.invalid" i18n>{{ required }}</mat-error>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 md:pt-4 py-5">
          <div
            *ngFor="
              let custom of customStateConfig
                | groupDemographicSection : demographicSections.childInformation
            "
          >
            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                  multiple
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.text"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="text"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.number"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="number"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.date"
              class="form-input-label-group"
            >
              <drdp-date-picker
                [label]="custom.label"
                [isReadOnly]="false"
                [isRequired]="custom.isRequired"
                labelClasses="pb-10 mb-5"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )
                "
              >
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>Child's Race and Ethnicity Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <drdp-select-yes-no
              label="Is the child Hispanic or Latino?"
              i18n-label
              [initialId]="latino.value"
              [required]="true"
              (optionSelected)="handleHispanic($event)"
              additionalClasses="pb-12"
            >
            </drdp-select-yes-no>
          </div>
          <div class="form-input-label-group">
            <label for="ethnicities">
              <span class="font-normal" i18n>What is the child's race?</span>
              <span class="required"></span>
              <span class="font-normal" i18n>
                Mark one or more races to indicate what the child considers
                himself or herself to be:
              </span>
            </label>
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="ethnicities"
                multiple
                placeholder="Choose all that apply"
                i18n-placeholder
              >
                <mat-option
                  *ngFor="let ethnicity of ethnicities"
                  [value]="ethnicity.id"
                  [disabled]="
                    isEthnicityDisabled &&
                    ethnicity.id !== ethnicityvalues.Intentionally_left_blank
                  "
                  (click)="handleChosenEthnicity(ethnicity)"
                >
                  <span
                    *ngIf="isNewHampshire"
                    [matTooltip]="ethnicity.ethnicityName ?? ''"
                    >{{ ethnicity.ethnicityName }}</span
                  >
                  <span *ngIf="!isNewHampshire">{{
                    ethnicity.ethnicityName
                  }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="ethnicity.invalid" i18n>{{ required }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8 py-5">
          <div
            *ngFor="
              let custom of customStateConfig
                | groupDemographicSection
                  : demographicSections.childRaceEthnicity
            "
          >
            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                  multiple
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.text"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="text"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.number"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="number"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.date"
              class="form-input-label-group"
            >
              <drdp-date-picker
                [label]="custom.label"
                [isReadOnly]="false"
                [isRequired]="custom.isRequired"
                labelClasses="pb-10 mb-5"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )
                "
              >
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>Child's Language Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <label class="font-normal required" for="languages" i18n>What is the child's home language?</label>
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="languages"
                multiple
                placeholder="Choose all that apply"
                i18n-placeholder
              >
                <mat-option
                  *ngFor="let language of languagesList"
                  [value]="language.id"
                >
                  {{ language.languageName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="languages.invalid" i18n>{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <drdp-select-yes-no
              label="Is a language other than English spoken in the child’s home?"
              i18n-label
              [initialId]="eld.value"
              [required]="true"
              (optionSelected)="handleEld($event)"
            >
            </drdp-select-yes-no>
          </div>
          <div class="self-end hidden md:block"></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-5">
          <div
            *ngFor="
              let custom of customStateConfig
                | groupDemographicSection
                  : demographicSections.childLanguageInfo
            "
          >
            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                  multiple
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.text"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="text"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.number"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="number"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" 18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.date"
              class="form-input-label-group"
            >
              <drdp-date-picker
                [label]="custom.label"
                [isReadOnly]="false"
                [isRequired]="custom.isRequired"
                labelClasses="pb-10 mb-5"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )
                "
              >
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>Child's Program Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <label class="font-normal required" for="enrollmentPrograms" i18n>Program Type</label>
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="enrollmentPrograms"
                [placeholder]="enrollmentProgramPlaceholder"
                multiple
                required="true"
                [disabled]="!enrollmentProgramTypesFiltered"
              >
                <mat-option *ngFor="let ept of enrollmentProgramTypesFiltered" [value]="ept.enrollmentProgramId">
                  {{ ept.enrollmentProgramType?.enrollmentProgramName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="enrollmentPrograms.invalid" i18n>{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group" *ngIf="!otherRequired"></div>
          <div class="form-input-label-group" *ngIf="otherRequired">
            <label class="font-normal" for="other"><span i18n>Other</span><span class="required"></span></label>
            <mat-form-field class="bg-white" appearance="fill">
              <input
                matInput
                type="text"
                formControlName="other"
                placeholder="Other"
                i18n-placeholder
                aria-label="other"
              />
              <mat-error *ngIf="other.invalid" i18n>Field required ('Other' selected as program type).</mat-error>
            </mat-form-field>
          </div>
          <div class="self-end hidden md:block"></div>
          <div class="relative">
            <div class="form-input-label-group">
              <drdp-date-picker
                label="Agency Enrollment Date"
                i18n-label
                [initialDate]="enrollmentStartDate?.value"
                [isReadOnly]="false"
                [isRequired]="true"
                (emitDate)="handleDate($event, 'enrollmentStartDate')"
              >
              </drdp-date-picker>
            </div>
            <div  class="absolute top-0 left-full mt-2 ml-[-2px]">
              <i class="uil uil-info-circle text-xs" [matTooltip]=agencyEnrollmentInfo></i>
            </div>
          </div>
          <div class="form-input-label-group">
            <label i18n class="font-normal" for="enrollmentEndDate">
              Agency Withdrawal Date
              <i class="uil uil-info-circle text-xs" [matTooltip]=withdrawlInfo></i>
            </label>
            <mat-form-field
              class="bg-white"
              appearance="fill"
            >
              <input
                matInput
                readonly=true
                type="text"
                [value]="enrollmentEndDate.value | date:'MM/dd/yyyy'"
              />
            </mat-form-field>
          </div>
          <div class="self-end hidden md:block"></div>
          <div class="form-input-label-group">
            <drdp-select-county
              (county)="handleChosenCountyOfProgram($event)"
              [stateId]="userStateId"
              [initialCountyIds]="initialCountyId"
              label="County of Program"
              i18n-label
              [multiSelect]="false"
            >
            </drdp-select-county>
          </div>
          <div class="form-input-label-group">
            <drdp-select-county
              (county)="handleChosenCountyOfResidence($event)"
              [stateId]="userStateId"
              label="County of Residence"
              i18n-label
              [multiSelect]="false"
            >
            </drdp-select-county>
          </div>
          <div class="self-end hidden md:block"></div>
          <div class="form-input-label-group">
            <label class="font-normal" for="tuitionFeeId" i18n>Child's tuition fees are:</label>
            <mat-checkbox-group
              aria-label="Select an option"
              i18n-aria-label
              class="flex flex-col"
            >
              <mat-checkbox
                class="rounded-checkboxes mb-3"
                [color]="'primary'"
                [checked]="tuitionFeeId.value == tf.id"
                *ngFor="let tf of tuitionFee"
                (change)="deselectCheck(tf.id)"
              >
                {{ tf.tuitionFeeName }}
              </mat-checkbox>
            </mat-checkbox-group>
          </div>
          <div class="self-end hidden md:block"></div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 py-5">
          <div
            *ngFor="
              let custom of customStateConfig
                | groupDemographicSection : demographicSections.childProgramInfo
            "
          >
            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                  multiple
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.text"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="text"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.number"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="number"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.date"
              class="form-input-label-group"
            >
              <drdp-date-picker
                [label]="custom.label"
                [isReadOnly]="false"
                [isRequired]="custom.isRequired"
                labelClasses="pb-10 mb-5"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )
                "
              >
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>Special Education Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pb-4 md:pb-8">
          <div class="form-input-label-group">
            <drdp-select-iep-plan
              [initialId]="iepPlanId.value"
              [required]="true"
              (optionSelected)="handleIep($event)"
            >
            </drdp-select-iep-plan>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isPrimaryDisabilityEnabled"
          >
            <label class="font-normal" for="primaryDisabilityId" i18n>Primary Disability</label>
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="primaryDisabilityId"
                placeholder="Primary Disability"
                i18n-placeholder
              >
                <mat-option
                  *ngFor="let disability of disabilities"
                  [value]="disability.id"
                >
                  {{ disability.primaryDisabilityName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="isNewHampshire && specialEdInfo.isDistrictLiabilityEnabled" class="self-end hidden md:block"></div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isDistrictLiabilityEnabled"
          >
            <label class="font-normal" for="districtOfLiabilityId"
              [ngClass]="{'required': isNewHampshire && iepPlanId.value == iepPlan.Yes}" i18n>District of Liability</label
            >
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="districtOfLiabilityId"
                placeholder="District of Liability"
                i18n-placeholder
              >
                <mat-option
                  *ngFor="let dol of districtsOfLiability"
                  [value]="dol.id"
                >
                  {{ dol.districtOfLiabilityName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="districtOfLiabilityId.invalid">{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="isNewHampshire && specialEdInfo.isDistrictLiabilityEnabled" 
            class="form-input-label-group">
            <label class="font-normal"
              >District ID</label
            >
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="District ID"
                matInput
                type="text"
                [value]="districtId"
                readonly="true"
              />
            </mat-form-field>
          </div>
          <div *ngIf="isNewHampshire && specialEdInfo.isDistrictLiabilityEnabled" 
            class="form-input-label-group">
            <label class="font-normal"
              >SAU ID</label
            >
            <mat-form-field class="bg-white" appearance="fill">
              <input
                placeholder="SAU ID"
                matInput
                type="text"
                [value]="sauId"
                readonly="true"
              />
            </mat-form-field>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isSelpaEnabled"
          >
            <drdp-select-selpa
              (selpa)="handleChosenSelpa($event)"
              additionalClasses="pb-12"
            >
            </drdp-select-selpa>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="adaptations" i18n>Adaptations</label>
            <mat-form-field appearance="fill" class="drdp-input">
              <mat-select
                formControlName="adaptations"
                multiple
                placeholder="Choose all that apply"
                i18n-placeholder
              >
                <mat-option
                  *ngFor="let adaptation of adaptations"
                  [value]="adaptation.id"
                  >{{ adaptation.adaptationTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isSpecialEdEntryEnabled"
          >
            <drdp-date-picker
              label="Special Education Entry Date"
              i18n-label
              [initialDate]="seEntryDate?.value"
              [isReadOnly]="false"
              [isRequired]="iepPlanId.value == iepPlan.Yes"
              labelClasses="pb-10 mb-5"
              (emitDate)="handleDate($event, 'seEntryDate')"
            >
            </drdp-date-picker>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isAnticipatedDateEnabled"
          >
            <drdp-date-picker
              label="Special Education Anticipate Exit Date"
              i18n-label
              [initialDate]="seAnticipateExitDate?.value"
              [isReadOnly]="false"
              labelClasses="pb-5 mb-5"
              (emitDate)="handleDate($event, 'seAnticipateExitDate')"
            >
            </drdp-date-picker>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isSpecialEdExitEnabled"
          >
            <label i18n class="font-normal" for="seExitDate">
              Special Education Exit Date
              <i class="uil uil-info-circle text-xs" [matTooltip]="specialEdExitInfo"></i>
            </label>
            <mat-form-field
              class="bg-white md:pb-2"
              appearance="fill"
            >
              <input
                matInput
                readonly=true
                type="text"
                [value]="seExitDate?.value | date:'MM/dd/yyyy'"
              />
            </mat-form-field>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isInitialAssessmentToolEnabled">
            <drdp-select-initial-assessment-tool-type
              label="Initial Assessment Tool"
              [initialId]="initialAssessmentToolId?.value"
              (optionSelected)="handleInitAssessment($event)"
              additionalClasses="pb-12">
            </drdp-select-initial-assessment-tool-type>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isOutcome1Enabled">
            <drdp-select-outcome
              label="Outcome 1"
              [initialId]="outcome1Id?.value"
              (optionSelected)="handleOutcome($event, 'outcome1Id')">
            </drdp-select-outcome>
            </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isOutcome2Enabled">
            <drdp-select-outcome
              label="Outcome 2"
              [initialId]="outcome2Id?.value"
              (optionSelected)="handleOutcome($event, 'outcome2Id')">
            </drdp-select-outcome>
            </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isOutcome3Enabled">
            <drdp-select-outcome
              label="Outcome 3"
              [initialId]="outcome3Id?.value"
              (optionSelected)="handleOutcome($event, 'outcome3Id')">
            </drdp-select-outcome>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8 py-5"
        >
          <div
            *ngFor="
              let custom of customStateConfig
                | groupDemographicSection : demographicSections.specialEdInfo
            "
          >
            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select
                  [formControlName]="custom.label"
                  placeholder="Select"
                  i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                  multiple
                >
                  <mat-option
                    *ngFor="let option of custom.options | option : '|'"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.text"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="text"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.number"
              class="form-input-label-group"
            >
              <label
                class="font-normal"
                [ngClass]="{ required: custom.isRequired }"
                [for]="custom.label"
                >{{ custom.label }}</label
              >
              <mat-form-field class="bg-white" appearance="fill">
                <input
                  placeholder=""
                  matInput
                  type="number"
                  [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )
                  "
                />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid" i18n>{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div
              [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.date"
              class="form-input-label-group"
            >
              <drdp-date-picker
                [label]="custom.label"
                [isReadOnly]="false"
                [isRequired]="custom.isRequired"
                labelClasses="pb-10 mb-5"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )
                "
              >
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr />
        <div class="mt-10 mb-2 flex justify-end">
          <drdp-btn class="pr-4" (click)="resetAddChildForm()" color="cancel" type="button" i18n>Cancel & Reset</drdp-btn>
          <drdp-btn [disabled]="addChildForm.invalid" type="submit" cdkFocusInitial i18n>Save & Enroll</drdp-btn>
        </div>
      </ng-container>
    </form>
  </drdp-container>
</div>
