<div class="p-5 min-h-screen">
  <div class="flex justify-between">
    <drdp-title title="Permissions" i18n-title></drdp-title>
    <span class="align-bottom">
      <drdp-btn i18n class=" pr-2" [color]="'outline'" (click)="openFilterDialog()">
        Filter Roles
      </drdp-btn>
      <drdp-btn i18n [color]="'outline'" (click)="downloadRoleReport()">Export</drdp-btn>
    </span>
  </div>

  <div *ngFor="let group of permissionGroup$ | async" class=" pb-6">
    <drdp-permissions-management [group]="group" [roles]="roles" (permissionAssignment)="updatePermission($event)"
      [assignmentList]="assignmentList">
    </drdp-permissions-management>
  </div>
  <drdp-permissions-management [isUncategorized]="true" [uncategorizedList]="uncategorizedList" [roles]="roles" (permissionAssignment)="updatePermission($event)"
      [assignmentList]="assignmentList">
  </drdp-permissions-management>
</div>