import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, take, tap } from 'rxjs';
import { IState } from '@core/interfaces/istate';
import { StateService } from '@core/services/state.service';
import { AddEditComponent } from '@shared/components/drdp-tables/add-edit/add-edit.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CustomConfigurationComponent } from './custom-configuration/custom-configuration.component';
import { SchoolYearTargetComponent } from './school-year-target/school-year-target.component';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { IStateSchoolYearTarget } from '@core/interfaces/istateSchoolYearTarget';

@Component({
  selector: 'drdp-state-configuration',
  templateUrl: './state-configuration.component.html',
  styleUrls: ['./state-configuration.component.scss']
})
export class StateConfigurationComponent implements OnInit {
  @ViewChild(AddEditComponent) table?: AddEditComponent;
  subscriptions  = new Subscription();
  title = $localize `State Configuration for `
  currentState?: IState;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  targetTableData?: any;

  tableColumns = [
    { columnDef: 'demographicInformation.description', header: $localize `Demographics Section`, type: 'text'},
    { columnDef: 'label', header: $localize `Field Label`, type: 'text'},
    { columnDef: 'customFieldType.name', header: $localize `Field Type`, type: 'text'},
    { columnDef: 'isActiveLabel', header: $localize `Active`, type: 'text'},
    { columnDef: 'isRequired', header: $localize `Required`, type: 'text'},
    { columnDef: 'options', header: $localize `Field Type Options`, type: 'text'},
    { columnDef: 'edit', header: $localize `Edit`, type: 'edit'}
  ];

  targetColumns = [
    { columnDef: 'schoolYearName', header: 'School Year', type: 'text'},
    { columnDef: 'indicator7a1', header: '7A1', type: 'text', applyDecimalPipe: true},
    { columnDef: 'indicator7a2', header: '7A2', type: 'text', applyDecimalPipe: true},
    { columnDef: 'indicator7b1', header: '7B1', type: 'text', applyDecimalPipe: true},
    { columnDef: 'indicator7b2', header: '7B2', type: 'text', applyDecimalPipe: true},
    { columnDef: 'indicator7c1', header: '7C1', type: 'text', applyDecimalPipe: true},
    { columnDef: 'indicator7c2', header: '7C2', type: 'text', applyDecimalPipe: true},
    { columnDef: 'edit', header: 'Edit', type: 'edit'},
    { columnDef: 'delete', header: 'Delete', type: 'delete'}
  ];

  constructor(
    private activatedRoute:ActivatedRoute, 
    private stateService: StateService, 
    public modal: MatDialog,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.subscriptions.add(this.activatedRoute.params.subscribe(params => {
      let { stateId } = params;

      this.stateService.getStatesById(stateId).pipe(
        take(1),
        tap((state: IState) => {
          this.currentState = state;
          this.title = this.title.concat(this.currentState.stateName);
        })
      ).subscribe();
      this.getCustomConfigs(stateId);
      this.getTargets(stateId);
    }));
  }

  getCustomConfigs(stateId: number): void {

    this.stateService.getCustomStateConfigurations(stateId).pipe(
      take(1)
    ).subscribe((configs: any) => {
      if (configs) {
        configs.forEach((config: any) => config.isActiveLabel = config.isActive ? $localize `Active` : '');
        this.tableData = configs;
        this.table?.reRender(this.tableData);
      }
    });
  }

  openModal(editData?: any, stateId?: number) {
    const modalRef = this.modal.open(CustomConfigurationComponent, { data: editData ?? stateId });

    modalRef.afterClosed().subscribe((payload: any) => {
      if (payload) {
        this.stateService.updateStateConfigurations(payload).pipe(
          take(1),
          tap(_ => {
            this.getCustomConfigs(this.currentState?.id ?? editData.data.stateId);
          })
        ).subscribe();
      }
    });
  }
  
  getTargets(stateId: number): void {
    this.stateService.getStateSchoolYearTargets(stateId).pipe(
      take(1)
    ).subscribe((targets: IStateSchoolYearTarget[]) => {
      if (targets) this.targetTableData = targets;
    });
  }

  openTargetModal(editData?: any, stateId?: number): void {
    const modalRef = this.modal.open(SchoolYearTargetComponent, { 
      data: { editData: editData?.data, stateId: stateId } 
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res && this.currentState?.id) this.getTargets(this.currentState.id);
    });
  }

  deleteTargetModal(event: any): void {    
    event.data.modalInfo = {
      title: `Delete ${event.data.schoolYearName}`,
      message: `Are you sure you want to want to delete this OSEP Target?`,
      name: event.data.schoolYearName,
      primaryBtnClass: 'red',
      primaryBtnText: 'Yes',
      cancelText: 'No',
      showCancel: true
    };
    const modalRef = this.dialog.open(ConfirmationModalComponent, {
      data: event ? event : undefined,
    });
    modalRef.afterClosed().subscribe((res) => {
      if (res) this.removeTarget(event.data.id, event.data.schoolYearName);
    });
  }

  removeTarget(id: number, name: string): void {
    this.stateService.deleteStateSchoolYearTarget(id, `School Year ${name} OSEP Target Deleted.`).pipe(
      take(1)
    ).subscribe((res: boolean) => {
      if (res && this.currentState?.id) this.getTargets(this.currentState.id);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
