import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IReportAction } from '@core/interfaces/ireport-type';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';

@Component({
  selector: 'drdp-select-report-action',
  templateUrl: './select-report-action.component.html',
  styleUrls: ['./select-report-action.component.scss']
})
export class SelectReportActionComponent implements OnInit {
  @Input() label: string = $localize `Report Type`;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() clear: boolean = false;
  @Input() required: boolean = false;
  @Output() onSelected = new EventEmitter<IReportAction | null>();

  reportActionOptions: IReportAction[] = [];
  public select = SelectType;

  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getReportActions();
  }

  getReportActions(): void {
    this.lookupService.getReportActions().pipe(take(1)).subscribe((res: IReportAction[]) => {
      this.reportActionOptions = res;
    });
  }

  emitSelectedReportAction(selectedAction: IReportAction): void {
    this.onSelected.emit(selectedAction);
  }
}
