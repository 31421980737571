<div class="fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold" i18n>Re-Enroll</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div *ngIf="displayResult" mat-dialog-content>
    <div *ngIf="ratingOptions.length != 0; else noRatings" class="content py-3">
      <form [formGroup]="reEnrollmentForm">
        <div class="flex flex-row justify-between">
          <div class="mr-12">
            <label class="font-normal" i18n>Classroom</label>
            <p>{{ data.name }}</p>
          </div>
          <div>
            <label class="font-normal" i18n>Rating Period</label>
            <mat-select placeholder="Select Rating Period" i18n-placeholder formControlName="ratingPeriodId">
              <mat-option *ngFor="let rating of ratingOptions" [value]="rating.id">
                {{ rating.ratingPeriodName }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </form>
    </div>
    <ng-template #noRatings>
      <div class="flex flex-row mt-2">
        <p i18n>No rating periods left to re-enroll for this classroom.</p>
      </div>
    </ng-template>
  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions class="flex flex-row justify-end">
    <drdp-btn i18n color="cancel" class="mr-3" mat-button mat-dialog-close>
      Cancel
    </drdp-btn>
    <drdp-btn color="blue" [disabled]="ratingOptions.length == 0  || isProcessing" mat-button (click)="submit()">
      {{ submitBtnText }}
    </drdp-btn>
  </div>
</div>