<div id="selectRating" class="rounded-lg  modal-body">
  <div mat-dialog-title>
    <h3><span i18n>Viewing Students</span> {{classroomName}}</h3>
  </div>
  <div class="mt-2 ml-2" *ngIf="canSelectAll">
    <drdp-btn
      color="outline"
      (click)="selectAllStudents()"
      additionalClasses="text-sm"
      i18n>Select All</drdp-btn
    >
  </div>
  <div mat-dialog-content>
    <drdp-container>
      <div class="w-full">
        <drdp-add-edit-table [dataSource]="dataSource" [tableData]="tableData" [columns]="tableColumns"
                             (toggleSwitchActionItem)="onToggleSwitchActionItem($event)" [checkboxSpace] = true [breakSpace] = true>
        </drdp-add-edit-table>
      </div>
      <mat-paginator class="mb-12" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
                     (page)="paginate($event)">
      </mat-paginator>
    </drdp-container>
  </div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <div class="flex justify-end align-bottom">
      <drdp-btn i18n color="cancel" class="mr-2" mat-dialog-close>Cancel</drdp-btn>
      <drdp-btn i18n type="button" (click)="selectStudents()" [disabled]="!isStudentSelected()">Select</drdp-btn>
    </div>
  </div>
</div>
