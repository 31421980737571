import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectType } from '@core/enums/select';
import { IAgencyCodeType } from '@core/interfaces/iagency';
import { LookupService } from '@core/services/lookup.service';
import { take } from 'rxjs';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'drdp-select-site-code',
  templateUrl: './select-site-code.component.html',
  styleUrls: ['./select-site-code.component.scss']
})
export class SelectSiteCodeComponent implements OnInit {
  @ViewChild('codeSelect') codeSelect: FilterSelectComponent | undefined;
  @Output() code = new EventEmitter<any>();
  @Input() initialCodeId?: number | null;
  @Input() initialCodeIds?: number | null;
  @Input() multiSelectInitVals: boolean = false;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = $localize `Agency Code Type`;
  codeOptions?: IAgencyCodeType[] = [];
  public get select() {return SelectType }
  constructor(private lookupService: LookupService) { }

  ngOnInit(): void {
    this.getCode();
  }

  getCode(): void {
    this.lookupService.getSiteCodeTypes()
      .pipe(take(1))
      .subscribe((code: IAgencyCodeType[]) => {
        this.codeOptions = code;
      });
  }

  onCodeSelect(event: IAgencyCodeType): void {
    this.code.emit(event);
  }
}
