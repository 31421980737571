<div class="p-5">
  <div>
    <drdp-title title="Child Progress Report" i18n-title></drdp-title>
    <div>
      <p i18n>
        Select a rating period and classroom to generate a report for all the children in the classroom.
        The report will include scaled scores for these children in all rating periods in the current year.
      </p>
    </div>
  </div>
  <drdp-report-search [multiSelectClassrooms]="false"
                      [multiSelectSites]="false"
                      [showLocale]="true"
                      [showStudentSelect]="true"
                      [onlyLocked]="true"
                      [canSelectAll]="true"
                      (showReportClicked)="showReport($event)"
                      (downloadReportClicked)="downloadReport($event)"
                      [requiredFields]="requiredFields"
                      (clearClicked)="handleClear()"></drdp-report-search>
  <div *ngIf="!!reportData" [innerHtml]="reportData! | safeHtml"></div>
</div>
