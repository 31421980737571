<div class="rounded-lg fix-action-row modal-body">
    <div class="flex justify-between border-b pb-5">
      <h1 class="text-[25px] font-bold">{{title}}</h1>
      <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>

    <div mat-dialog-content>
      <form class="grid grid-cols-2 gap-4 gap-x-10" [formGroup]="customConfigForm">

        <div class="col-span-1 items-center w-[100%]">
          <label i18n class="font-bold required" for="Demographics Section">Demographics Section</label>
          <div class="col-span-1 items-center w-[100%]">
            <mat-form-field id="Demographics" appearance="fill">
              <mat-select formControlName="sectionId" placeholder="Select an option" i18n-placeholder>
                <mat-option *ngFor="let section of sectionOptions$ | async" [value]="section.id"> {{ section.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label i18n class="font-bold required" for="Field Label">Field Label</label>
          <mat-form-field class="bg-white" appearance="fill">
            <input matInput
                   placeholder="Add Field Label"
                   i18n-placeholder
                   autocomplete="off"
                   id="label"
                   type="text"
                   formControlName="label"
                   maxlength="100">
          </mat-form-field>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label i18n class="font-bold required" for="Field Type">Field Type</label>
          <div class="col-span-1 items-center w-[100%]">
            <mat-form-field id="Field" appearance="fill">
                <mat-select formControlName="customFieldTypeId" placeholder="Select an option" i18n-placeholder (selectionChange)="onfieldTypeChange($event)">
                  <mat-option *ngFor="let type of fieldTypeOptions$ | async" [value]="type.id"> {{ type.name }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label i18n class="font-bold required" for="Sort Order">Sort Order</label>
          <mat-form-field class="bg-white" appearance="fill">
            <input matInput
                   placeholder="Add Sort Order"
                   i18n-placeholder
                   autocomplete="off"
                   id="orderBy"
                   type="number"
                   formControlName="orderBy"
                   maxlength="5">
          </mat-form-field>
        </div>

        <div class="col-span-1 items-center w-[100%]">
          <label i18n class="font-bold" for="Maximum Length of Characters" [ngClass]="{ 'required' : isMaxRequired}">Maximum Length of Characters</label>
          <mat-form-field class="bg-white" appearance="fill">
            <input matInput
                   placeholder="Add Maximum Length"
                   i18n-placeholder
                   autocomplete="off"
                   id="maxLength"
                   type="number"
                   formControlName="maxLength"
                   max="1000"
                   maxlength="4">
          </mat-form-field>
          <mat-error i18n *ngIf="maxLength.value > 1000">Maximum allowed is 1000. </mat-error>
        </div>

        <div class="flex w-[100%]">
          <div class="flex flex-col pr-5">
            <label i18n class="font-bold" for="Status">Active</label>
            <mat-slide-toggle formControlName="isActive"
                              [color]="'primary'">
            </mat-slide-toggle>
          </div>
          <div class="flex flex-col">
            <label i18n class="font-bold" for="v">Required</label>
            <mat-slide-toggle formControlName="isRequired"
                              [color]="'primary'">
            </mat-slide-toggle>
          </div>
        </div>

        <div class="col-span-2 items-center w-[100%]">
          <label i18n class="font-normal" [ngClass]="{ 'required' : isOptionRequired}" for="Field Type Options">Field Type Options</label>
          <mat-form-field class="bg-white" appearance="fill">
            <input matInput
                   placeholder="Add Field Type"
                   i18n-placeholder
                   autocomplete="off"
                   id="options"
                   type="text"
                   formControlName="options"
                   maxlength="50">
          </mat-form-field>
          <mat-error i18n *ngIf="options.errors?.error">Options must be seperated by pipes.</mat-error>
        </div>
      </form>

    </div>
    <div class="spacer"></div>
    <div mat-dialog-actions class="flex justify-end align-bottom">
      <button i18n mat-button mat-dialog-close>Cancel</button>
      <drdp-btn i18n *ngIf="customConfigForm.valid" mat-button [mat-dialog-close]="onSubmit()" [disabled]="false" cdkFocusInitial>Save and Submit</drdp-btn>
      <drdp-btn i18n *ngIf="customConfigForm.invalid" mat-button [disabled]="true" cdkFocusInitial>Save</drdp-btn>
    </div>
  </div>

