<div class="p-5">
    <div class="flex flex-row">
      <div class="basis-1/2">
        <drdp-title [title]="title"></drdp-title>
      </div>
    </div>

    <drdp-container [additionClasses]="'min-h-[44rem]'">
      <drdp-container color="lightBlue">
        <form class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 lg:gap-8" [formGroup]="userSearch"
          (ngSubmit)="search()">
          <div class="self-start my-auto">
            <label i18n class="font-bold">Search by:</label>
            <div class="border-b border-black">
              <input matInput placeholder="Name or Email" i18n-placeholder formControlName="searchStr"
                class="w-full bg-drdpblue-200 border-b border-black focus:outline-none focus:border-drdpblue-300" />
            </div>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-state #stateSelect [initialStateId]="stateId.value" (state)="chosenState($event)" [clear]="clearSharedDropdowns">
            </drdp-select-state>
          </div>
          
          <div class="col-span-1 my-auto">
            <drdp-select-agency #agencySelect [initialAgencyId]="agencyId.value" (agency)="chosenAgency($event)"
              [stateId]="stateId.value" [clear]="clearSharedDropdowns">
            </drdp-select-agency>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-site [initialSiteIds]="siteIds.value" (site)="chosenSites($event)" [agencyId]="agencyId.value"
              [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearSharedDropdowns">
            </drdp-select-site>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-status [initialStatusId]="userStatusIds.value" [disabled]="false" (status)="handleStatus($event)" [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearSharedDropdowns">
            </drdp-select-status>
          </div>

          <div class="col-span-1 my-auto">
            <drdp-select-role label="Role" [initialRolesIds]="roleIds.value" [multiSelect]="true" [multiSelectInitVals]="true" [clear]="clearSharedDropdowns" (role)="handleroles($event)">
            </drdp-select-role>
          </div>

          <div class="lg:col-span-2 flex flex-row md:flex-col-1 gap-5 lg:flex-row lg:justify-start lg:items-end">
            <div class="mb-0 md:mb-2 lg:mb-0">
              <drdp-btn i18n type="submit" class="lg:mr-2">Search</drdp-btn>
            </div>
            <div>
              <drdp-btn i18n color="outline" type="button" (click)="clear()">Clear</drdp-btn>
            </div>
          </div>
        </form>
      </drdp-container>
      <div class="p-5">
        <drdp-add-edit-table
          [dataSource]="dataSource"
          [tableData]="tableData"
          [columns]="tableColumns"
          (iconActionItem)="onIconClick($event)">
        </drdp-add-edit-table>
      </div>
    </drdp-container>
    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [length]="totalData"
      [pageIndex]="pageIndex"
      (page)="pageChange()">
    </mat-paginator>
</div>