import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Permission } from '@core/enums/permissions';
import { RequestStatusType } from '@core/enums/student-request-reason';
import { TableColumnType } from '@core/enums/table';
import { IAgency } from '@core/interfaces/iagency';
import { IClassroomAssignment } from '@core/interfaces/iclasses';
import { IRatingPeriod } from '@core/interfaces/iratingperiod';
import { ISites } from '@core/interfaces/isites';
import { IState } from '@core/interfaces/istate';
import { AuthService } from '@core/services/auth.service';
import { ClassService } from '@core/services/class.service';
import { PermissionService } from '@core/services/permission.service';
import { StudentRequestService } from '@core/services/student-request.service';
import { ToastService } from '@core/services/toast.service';
import { FilterSelectComponent } from '@shared/components/dropdowns/filter-select/filter-select.component';
import { take } from 'rxjs';
@Component({
  selector: 'drdp-student-request',
  templateUrl: './child-request.component.html',
  styleUrls: ['./child-request.component.scss']
})
export class ChildRequestComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('stateSelect') stateSelect: FilterSelectComponent | undefined;
  @ViewChild('agencySelect') agencySelect: FilterSelectComponent | undefined;
  @ViewChild('requestResponseSelect') requestResponseSelect: FilterSelectComponent | undefined;
  @ViewChild('requestStatusType') requestStatusType: FilterSelectComponent | undefined;

  pageSizeOptions: number[] = [2, 10, 25, 50, 100];
  pageSize: number = 10;
  totalData: number = 0;
  pageIndex: number = 0;
  tableData?: any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  ratingPeriodList: IRatingPeriod[] = [];
  agencyList: IAgency[] = [];
  classroomList: IClassroomAssignment[] = [];
  sites!: ISites[];
  requestReasonList?: any;

  user = this.authService.getCurrentUser();
  hasAccessToAllAgencies = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );

  initStateId: number | null = this.user.stateId;
  initAgencyId: number | null = this.user.agencyId;
  clearDropdowns = false;
  searchPayload: any;
  childRequestForm: FormGroup | any;;

  tableColumns = [
    {
      columnDef: 'requestReason',
      header: $localize `Request Reason`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'formattedRequestedBy',
      header: $localize `Requester`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'fromClassroomName',
      header: $localize `From Classroom`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'toClassroom',
      header: $localize `To Classroom`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'studentName',
      header: $localize `Child Name`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'comment',
      header: $localize `Comment`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'releaseReason',
      header: $localize  `Reason`,
      type: TableColumnType.text,
    },
    {
      columnDef: 'formattedStatus',
      header: $localize  `Status`,
      type: TableColumnType.text,
    },
  ];

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private permissionService: PermissionService,
    private fb: FormBuilder,
    private classService: ClassService,
    private studentRequestService: StudentRequestService,
  ) { }

  get stateId() {
    return this.childRequestForm.get('stateId');
  }
  get agencyId() {
    return this.childRequestForm.get('agencyId');
  }
  get ratingPeriodId() {
    return this.childRequestForm.get('ratingPeriodId');
  }
  get siteId() {
    return this.childRequestForm.get('siteId');
  }
  get classroomId() {
    return this.childRequestForm.get('classroomId');
  }
  get searchString() {
    return this.childRequestForm.get('searchString');
  }
  get requestReasonId() {
    return this.childRequestForm.get('requestReasonId');
  }
  get requestReason() {
    return this.childRequestForm.get('requestReason');
  }
  get releaseReason() {
    return this.childRequestForm.get('releaseReason');
  }
  get requestStatusTypeId() {
    return this.childRequestForm.get('requestStatusTypeId');
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = '';
  }

  initializeForm(): void {
    this.childRequestForm = this.fb.group({
      stateId: [this.user.stateId ?? null, [Validators.required]],
      agencyId: [this.user.agencyId ?? null, [Validators.required]],
      ratingPeriodId: [null],
      siteId: [null],
      classroomId: [null],
      searchString: [null, [Validators.maxLength(100)]],
      requestReasonId: [null],
      status: [null],
      studentName: [null],
      requestStatusTypeId: [null],
    });
  }

  updateClassroomDropdown(): void {
    this.classroomList = [];
    const payload: any = {};
    payload.siteIds = this.siteId.value ?? null;
    payload.ratingPeriodId = this.ratingPeriodId.value ?? '';
    payload.itemsPerPage = 10;
    payload.useClassId = false;

    if (this.siteId.value?.length > 0 && payload.ratingPeriodId > 0) {
      this.classService
        .getClassesBySitesAndRatingPeriod(payload)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.classroomList = res.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id)
          );
        });
    } else {
      this.classroomList = [];
    }
  }

  handleState(state: IState): void {
    this.stateId.setValue(state.id);
    this.agencyId.setValue(null);
  }

  handleAgency(agency: IAgency): void {
    this.agencyId.setValue(agency?.id);
    this.classroomId.setValue(null);
    this.ratingPeriodId.setValue(null);
    this.classroomList = [];
    this.ratingPeriodList = [];
  }

  handleRatingPeriod(ratingPeriod: IRatingPeriod): void {
    this.ratingPeriodId.setValue(ratingPeriod?.id);
    this.updateClassroomDropdown();
  }

  handleSite(site: ISites): void {
    this.siteId.setValue(site?.id);
    this.updateClassroomDropdown();
  }

  handleClassroom(classroom: any): void {
    this.classroomId.setValue(classroom?.id);
  }

  handleRequestReason(selectedRequestReason: any): void {
    this.requestReasonId.setValue(selectedRequestReason?.id);
  }

  handleRequestStatusType(selectedRequestStatusType: any): void {
    this.requestStatusTypeId.setValue(selectedRequestStatusType?.id);
  }

  handleFormSubmit(event: Event): void {
    event.preventDefault();

    if (this.childRequestForm.invalid) {
      this.toastService.error($localize `Please enter all required fields.`);
      return;
    }

    this.search(this.childRequestForm.value);
  }

  search(event: any): void {
    this.searchPayload = {
      requestReasonId: event.requestReasonId,
      requestReason: event.requestReason,
      releaseReason: event.releaseReason,
      stateId: event.stateId,
      agencyId: event.agencyId,
      classroomId: event.classroomId,
      status: event.status,
      searchString: event.searchString,
      requestStatusTypeId: event.requestStatusTypeId,
      pageIndex: this.pageIndex,
      itemsPerPage: this.pageSize
    };
    this.paginateTable(true);
  }

  paginateTable(resetPage?: boolean): void {
    this.searchPayload.pageIndex = resetPage ? 0 : this.paginator.pageIndex + 1;
    this.searchPayload.itemsPerPage = resetPage ? 10 : this.paginator.pageSize;

    this.getStudentRequest(this.searchPayload);
  }

  getStudentRequest(params: any): void {
    this.studentRequestService
      .getStudentRequests(params)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.tableData = res.items.map((item: any) => ({
          ...item,
          stateId: item.stateId ?? null,
          agencyId: item.agencyId ?? null,
          releaseReason: item.releaseReason ?? null,
          requestReasonId: item.requestReasonId ?? null,
          status: item.status ?? null,
          toClassroom: item.toClassroom ?? null,
          requestStatusTypeId: RequestStatusType[item.requestStatusTypeId] ?? null,
        }));

        this.dataSource = new MatTableDataSource<any>(this.tableData);
        this.totalData = res.totalData;
      });
  }

  clearOptions(): void {
    this.sites = [];
    this.classroomList = [];
    this.searchString.setValue(null);
  }

  clearTable(): void {
    const empty: any = [];
    this.dataSource = new MatTableDataSource(empty);
    this.totalData = 0;
    this.tableData = [];
  }

  clear(): void {
    this.clearDropdowns = true;
    this.clearOptions();
    this.ratingPeriodId.setValue(null);

    if (!this.stateSelect?.disabled) {
      this.initStateId = null;
      this.stateId.setValue(this.initStateId);
    }

    if (!this.agencySelect?.disabled) {
      this.initAgencyId = null;
      this.agencyId.setValue(this.initAgencyId);
      this.ratingPeriodList = [];
    }

    this.clearTable();
  }
}
