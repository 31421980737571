import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
  FormControl,
} from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { EncryptionService } from '@core/services/encryption.service';
import { ToastService } from '@core/services/toast.service';
import { Subscription, take } from 'rxjs';
@Component({
  selector: 'drdp-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  passwordForm: FormGroup | any;
  submitAttempt = false;
  hidden: boolean = true;
  confirmHidden: boolean = true;
  hiddenConfirm: boolean = true;
  private subscriptions = new Subscription();
  userInfo: any;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toast: ToastService,
    private encryptionService: EncryptionService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.passwordForm = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [
        null,
        Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50), this.passwordValidator]),
      ],
      confirmPassword: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(50),
          (control) => this.compareValidator(control, 'newPassword'),
        ]),
      ],
    });
  }

  invalid(control: AbstractControl) {
    if (
      control === undefined ||
      control == null ||
      typeof control === 'undefined'
    ) {
      return false;
    } else {
      return (
        (!control.valid && control.touched) ||
        (!control.valid && control.untouched && this.submitAttempt)
      );
    }
  }

  passwordValidator(control: AbstractControl) {
    // {6,100}           - Assert password is between 8 and 50 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (String(control.value).match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,50}$/)) {
      return null;
    } else {
      // Password must be at least 6 characters long, and contain a number.
      return { invalid: true };
    }
  }

  compareValidator(control: AbstractControl, otherControlName: string) {
    if (!control.parent) {
      return null;
    }
    const otherControl = control.parent.get(otherControlName) as FormControl;

    let thisControl = control;

    if (!thisControl) {
      if (!otherControl) {
        throw new Error(
          'compareValidator(): other control is not found in parent group'
        );
      }
      this.subscriptions.add(
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        }));
    }
    if (!otherControl) {
      return null;
    }
    if (otherControl.value !== thisControl.value) {
      return {
        invalid: true,
      };
    }
    return null;
  }

  onSubmit() {
    this.submitAttempt = true;
    if (this.passwordForm.invalid) {
      this.validateAllFormFields(this.passwordForm);
    } else {
      var updatePassword = {
        OldPassword: this.encryptionService.encryptPassword(this.passwordForm.get('oldPassword').value),
        NewPassword: this.encryptionService.encryptPassword(this.passwordForm.get('newPassword').value),
        UserId: this.authService.getCurrentUser().userId,
      };

      this.authService.updatePassword(updatePassword, $localize `Password has been updated.`).pipe(take(1)).subscribe((success) => {
        if (success) {
          this.logout();
        }
      });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  logout(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser) this.authService.logout().pipe(take(1)).subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
