<div class="flex flex-col px-5 justify-center">
  <drdp-title *ngIf="isAdd" title="Add New Child" i18n-title class="py-5"></drdp-title>
  <div *ngIf="isLoading; else form"></div>
  <ng-template #form>
    <drdp-container [additionClasses]="isAdd ? 'mx-auto flex p-8' : 'mx-auto shadow-none flex p-8'">
      <form [formGroup]="addChildForm">
        <h2 class="font-semibold text-lg" i18n>Child Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <label class="font-normal" for="firstName" i18n>First Name<span class="required"></span></label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="First Name" i18n-placeholder matInput [readonly]="isView" autocomplete="given-name" type="text"
                formControlName="firstName" maxlength="50" required />
              <mat-error *ngIf="firstName.invalid">{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="middleInitial" i18n>Middle Initial</label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="Middle Initial" i18n-placeholder matInput [readonly]="isView" autocomplete="off" type="text"
                formControlName="middleInitial" maxlength="2" />
              <mat-error *ngIf="middleInitial.invalid && !isView" i18n>Child's Middle Initial can only be 2
                characters.</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="lastName" i18n>Last Name<span class="required"></span></label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="Last Name" i18n-placeholder matInput [readonly]="isView" autocomplete="family-name" type="text"
                formControlName="lastName" maxlength="50" required />
              <mat-error *ngIf="lastName.invalid">{{ required }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="SSID">
              {{ ssidLabel }}
              <span class="required"></span>
              <br />
              <span *ngIf="isAdd" class="addl-instructions">
                <span class="required"></span>
              </span>
            </label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="Required if Agency/District ID is blank" i18n-placeholder matInput [readonly]="isView"
                autocomplete="off" type="text" formControlName="ssid" [maxlength]="ssidmaxLength" />
              <mat-error *ngIf="ssid.hasError('pattern') && !isView">{{ ssidPatternErrorMsg }}</mat-error>
              <mat-error *ngIf="ssid.hasError('required') && !isView">{{ ssidLocalIdReqErrorMsg }}</mat-error>
              <mat-error *ngIf="ssid.hasError('duplicateSSID') && !isView">{{ ssidErrorMsg }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="localId">
              {{ agencySidLabel }}
              <span class="required"></span>
              <br />
              <span *ngIf="isAdd" class="addl-instructions">
                <span class="required"></span>
              </span>
            </label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="Required if Statewide Student Identifier is blank" i18n-placeholder matInput [readonly]="isView"
                autocomplete="off" type="text" formControlName="localId" maxlength="25" />
              <mat-error *ngIf="localId.hasError('required')">{{ ssidLocalIdReqErrorMsg }}</mat-error>
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="drdpId">
              DRDP ID
              <br />
            </label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="DRDP ID" matInput autocomplete="off" type="text" formControlName="drdpId"
                maxlength="25" readonly="" />
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal required" for="DOB">DOB</label>
              <p class="text-sm">{{ dob?.value | date : "MM/dd/yyyy" }}</p>
            </div>
            <div *ngIf="!isView">
              <drdp-date-picker label="DOB" [initialDate]="dob?.value" [isReadOnly]="isView" [isRequired]="true"
                (emitDate)="handleDate($event, 'dob')">
              </drdp-date-picker>
            </div>
          </div>
          <div *ngIf="!isView">
            <drdp-select-age-grade #ageGroupInstrumentSelect [initialageGradeId]="ageGradeInstrumentId.value"
              (ageGrade)="handleAgeGrade($event)" [stateId]="userStateId" [multiSelect]="false" [required]="true"
              label="Child Age/Grade Instrument" i18n-label>
            </drdp-select-age-grade>
            <mat-error *ngIf="ageGradeInstrumentId.invalid" class="absolute text-xs">{{ required }}</mat-error>
          </div>
          <div *ngIf="isView" class="self-start">
            <label class="font-normal" for="firstName" i18n>Child Age/Grade Instrument<span class="required"></span></label>
            <div class="bg-white removeLine" appearance="fill">
              <p>{{ childData?.ageGradeInstrumentName }}</p>
            </div>
          </div>
          <div>
            <label class="font-normal" for="socialEconomic" i18n>Child's Socioeconomic Status</label>
            <mat-checkbox [color]="'primary'" class="example-margin" formControlName="socialEconomic"
              [disabled]="!isAdd && !isEdit" i18n>Qualify for free or reduced meals?</mat-checkbox>
          </div>
          <div *ngIf="!isView">
            <div class="col-span-1 col-end-3">
              <label class="font-normal" for="genderId" i18n>Gender<span class="required"></span></label>
              <mat-radio-group aria-label="Select an option" formControlName="genderId">
                <mat-radio-button [color]="'primary'" *ngFor="let g of genders" [value]="g.id">
                  {{ g.genderName }}
                </mat-radio-button>
                <mat-error *ngIf="genderId.touched && genderId.invalid">{{ required }}</mat-error>
              </mat-radio-group>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="isView">
            <div class="col-span-1 col-end-3">
              <label class="font-normal" for="firstName" i18n>Gender<span class="required"></span></label>
              <div class="bg-white removeLine" appearance="fill">
                <p>{{ childData?.genderName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8">
          <div class="form-input-label-group"
            *ngFor="let custom of customStateConfig | groupDemographicSection : demographicSections.childInformation">
            <div [id]="custom.label" *ngIf="isView" class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">{{
                custom.label }}</label>
              <mat-form-field class="bg-white" ngClass="removeLine" appearance="fill">
                <input matInput [readonly]="true" autocomplete="off" [formControlName]="custom.label" maxlength="10" />
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">{{
                custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )">
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">{{
                custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select"
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )"
                    multiple>
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.text && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">{{
                custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="text" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.number && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">{{
                custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="number" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.date && !isView"
              class="form-input-label-group">
              <drdp-date-picker [label]="custom.label" [initialDate]="addChildForm.get(custom.label)?.value"
                [isReadOnly]="isView" [isRequired]="custom.isRequired"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )">
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>
          Child's Race and Ethnicity Information
        </h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group" *ngIf="!isView">
            <drdp-select-yes-no label="Is the child Hispanic or Latino?" i18n-label [initialId]="latino.value" [required]="true"
              (optionSelected)="handleHispanic($event)" additionalClasses="pb-12">
            </drdp-select-yes-no>
            <div>
              <mat-error *ngIf="latino.invalid" class="absolute text-xs">{{ required }}</mat-error>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="isView">
            <label class="font-normal required" for="latino"i18n>Is the child Hispanic or Latino?</label>
            <mat-form-field class="bg-white removeLine" appearance="fill">
              <input matInput readonly="true" [value]="childData.latino ? 'Yes' : 'No'" type="text"
                formControlName="latino" />
            </mat-form-field>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="ethnicities">
              <span i18n>What is the child's race?</span>
              <span class="required"></span>
              <span i18n *ngIf="!isView" class="addl-instructions">
                Mark one or more races to indicate what the child considers
                himself or herself to be:
              </span>
            </label>
            <mat-form-field appearance="fill" *ngIf="!isView" class="drdp-input">
              <mat-select formControlName="ethnicityIds" multiple placeholder="Choose all that apply" i18n-placeholder>
                <mat-option *ngFor="let ethnicity of ethnicities" [value]="ethnicity.id" 
                  [disabled]="isEthnicityDisabled && ethnicity.id !== ethnicityvalues.Intentionally_left_blank" 
                  (click)="handleChosenEthnicity(ethnicity)">
                  <span *ngIf="isNewHampshire" [matTooltip]="ethnicity.ethnicityName ?? ''">
                    {{ ethnicity.ethnicityName }}</span>
                  <span *ngIf="!isNewHampshire">
                    {{ ethnicity.ethnicityName }}</span>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="ethnicity.touched && ethnicity.invalid">{{ required }}</mat-error>
            </mat-form-field>
            <div *ngIf="isView && childData?.ethnicities">
              <ul>
                <li
                  *ngFor="let ethnicity of childData?.ethnicities | option : ','">
                  {{ ethnicity }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8">
          <div class="form-input-label-group"
            *ngFor="let custom of customStateConfig | groupDemographicSection : demographicSections.childRaceEthnicity">
            <div [id]="custom.label" *ngIf="isView" class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" ngClass="removeLine" appearance="fill">
                <input matInput [readonly]="true" autocomplete="off" [formControlName]="custom.label" maxlength="10" />
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )">
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )"
                    multiple>
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.text && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="text" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.number && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="number" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.date && !isView"
              class="form-input-label-group">
              <drdp-date-picker [label]="custom.label" [initialDate]="addChildForm.get(custom.label)?.value"
                [isReadOnly]="isView" [isRequired]="custom.isRequired"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )">
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>Child's Language Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <label class="font-normal" for="languages" i18n>What is the child's home language?<span
                class="required"></span></label>
            <mat-form-field appearance="fill" id="languageSelect" *ngIf="!isView" class="drdp-input">
              <mat-select formControlName="languageIds" multiple placeholder="Choose all that apply" i18n-placeholder>
                <mat-option *ngFor="let language of languagesList" [value]="language.id">
                  {{ language.languageName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="languageIds.invalid">{{ required }}</mat-error>
            </mat-form-field>
            <div *ngIf="isView && childData?.languages.length > 1">
              <ul>
                <li *ngFor="let language of childData?.languages | option : ','">
                  {{ language }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-input-label-group">
            <div *ngIf="!isView">
              <drdp-select-yes-no label="Is a language other than English spoken in the child’s home?" i18n-label
                [initialId]="eld.value" [required]="true" (optionSelected)="handleEld($event)">
              </drdp-select-yes-no>
              <mat-error *ngIf="eld.invalid" class="absolute text-xs">{{ required }}</mat-error>
            </div>
            <mat-form-field *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label i18n class="font-normal mt-0" for="eld">
                Is a language other than English spoken in the child’s home?
                <span class="required"></span>
              </label>
              <input matInput readonly="true" [value]="childData?.eld" type="text" formControlName="eld" required />
            </mat-form-field>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8">
          <div class="form-input-label-group"
            *ngFor="let custom of customStateConfig | groupDemographicSection : demographicSections.childLanguageInfo">
            <div [id]="custom.label" *ngIf="isView" class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" ngClass="removeLine" appearance="fill">
                <input matInput [readonly]="true" autocomplete="off" [formControlName]="custom.label" maxlength="10" />
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )">
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )"
                    multiple>
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.text && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="text" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.number && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="number" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.date && !isView"
              class="form-input-label-group">
              <drdp-date-picker [label]="custom.label" [initialDate]="addChildForm.get(custom.label)?.value"
                [isReadOnly]="isView" [isRequired]="custom.isRequired"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )">
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 class="font-semibold text-lg" i18n>Child's Program Information</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div class="form-input-label-group">
            <label class="font-normal required" for="enrollmentPrograms" i18n>Program Type</label>
            <mat-form-field *ngIf="!isView" appearance="fill" id="programSelect" class="drdp-input">
              <mat-select formControlName="enrollmentProgramIds" [placeholder]="enrollmentProgramPlaceholder"
                required="true" multiple>
                <mat-option *ngFor="let ept of enrollmentProgramTypesFiltered ?? enrollmentProgramTypes" [value]="ept.enrollmentProgramId">
                  {{ ept.enrollmentProgramType?.enrollmentProgramName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="enrollmentProgramIds.invalid">{{ required }}</mat-error>
            </mat-form-field>
            <div *ngIf="isView && childData?.enrollmentProgramNames.length > 1">
              <ul>
                <li *ngFor="let ep of childData?.enrollmentProgramNames | option : ','">
                  {{ ep }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-input-label-group">
            <div *ngIf="otherRequired || other?.value?.length > 1">
              <label class="font-normal" for="other" i18n>Other<span class="required"></span></label>
              <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
                <input matInput type="text" formControlName="other" palceholder="Other" />
                <mat-error *ngIf="other.invalid" i18n>Field required ('Other' selected as program type).</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="self-end hidden md:block"></div>
          <div class="relative">
            <div class="form-input-label-group">
              <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
                <label class="font-normal required" for="Agency Enrollment Date" i18n>Agency Enrollment Date</label>
                <p class="text-sm">
                  {{ enrollmentStartDate.value | date : "MM/dd/yyyy" }}
                </p>
              </div>
              <div *ngIf="!isView">
                <drdp-date-picker label="Agency Enrollment Date" i18n-label [initialDate]="enrollmentStartDate.value"
                  [isReadOnly]="isView" [isRequired]="true" (emitDate)="handleDate($event, 'enrollmentStartDate')">
                </drdp-date-picker>
              </div>
            </div>
            <div *ngIf="!isView" class="absolute top-0 left-full mt-2 ml-[-2px]">
              <i class="uil uil-info-circle text-xs" [matTooltip]=agencyEnrollmentInfo></i>
            </div>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="enrollmentEndDate">
              <span i18n>Agency Withdrawal Date</span>
              <i *ngIf="!isView" class="uil uil-info-circle text-xs" [matTooltip]=withdrawlInfo></i>
            </label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input matInput readonly=true type="text" [value]="enrollmentEndDate.value | date:'MM/dd/yyyy'" />
            </mat-form-field>
          </div>
          <div class="self-end hidden md:block"></div>
          <div class="form-input-label-group">
            <div *ngIf="!isView">
              <drdp-select-county (county)="handleChosenCountyOfProgram($event)"
                [initialCountyIds]="countyOfProgramId.value" [stateId]="userStateId" [multiSelect]="false"
                label="County of Program" i18n-label [multiSelect]="false">
              </drdp-select-county>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="countyOfProgram" i18n>County of Program</label>
              <p>{{ chosenCountyOfProgram }}</p>
            </div>
          </div>
          <div class="form-input-label-group">
            <div *ngIf="!isView">
              <drdp-select-county (county)="handleChosenCountyOfResidence($event)"
                [initialCountyIds]="countyOfResidenceId.value" [stateId]="userStateId" [multiSelect]="false"
                label="County of Residence" i18n-label [multiSelect]="false">
              </drdp-select-county>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="countyOfResidence" i18n>County of Residence</label>
              <p>{{ chosenCountyOfResidence }}</p>
            </div>
          </div>
          <div class="self-end hidden md:block"></div>
          <div class="self-end md:col-span-2">
            <label class="font-normal" for="tuitionFeeId" i18n>Child's tuition fees are:</label>
            <mat-checkbox-group aria-label="Select an option" i18n-aria-label *ngIf="!isView" class="flex flex-col">
              <mat-checkbox class="rounded-checkboxes mb-3" [color]="'primary'" [checked]="tuitionFeeId.value == tf.id"
                *ngFor="let tf of tuitionFee" (change)="deselectCheck(tf.id)">
                {{ tf.tuitionFeeName }}
              </mat-checkbox>
            </mat-checkbox-group>

            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <p>{{ childData?.tuitionFeeName }}</p>
            </div>
          </div>
          <div class="self-end hidden md:block"></div>
          <div *ngIf="bypassDualEnrollment || canViewDualEnrollment" class="self-end md:col-span-1">
            <label class="font-normal" for="enrollmentPrograms" i18n>Dual Enrollment Program</label>
            <mat-form-field *ngIf="!isView" appearance="fill" id="programSelect" class="drdp-input">
              <mat-select formControlName="dualEnrollmentProgramIds" [placeholder]="enrollmentProgramPlaceholder"
                multiple>
                <mat-option
                  *ngFor="let ept of enrollmentProgramTypesFiltered ?? enrollmentProgramTypes"
                  [value]="ept.enrollmentProgramId">
                  {{ ept.enrollmentProgramType?.enrollmentProgramName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isView && childData?.dualEnrollmentProgramNames">
              <ul>
                <li *ngFor="let ep of childData?.dualEnrollmentProgramNames | option : ','">
                  {{ ep }}
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="bypassDualEnrollment || canViewDualEnrollment">
            <div class="form-input-label-group">
              <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
                <label class="font-normal" for="Dual Agency Enrollment Date" i18n>Dual Agency Enrollment Date
                  <!-- <i *ngIf="!isView" class="uil uil-info-circle text-xs" [matTooltip]=agencyEnrollmentInfo></i> -->
                </label>
                <p class="text-sm">
                  {{ dualEnrollmentStartDate.value | date : "MM/dd/yyyy" }}
                </p>
              </div>
              <div *ngIf="!isView">
                <drdp-date-picker label="Dual Agency Enrollment Date" i18n-label [initialDate]="dualEnrollmentStartDate.value"
                  [isReadOnly]="isView" [isRequired]="false" (emitDate)="handleDate($event, 'dualEnrollmentStartDate')">
                </drdp-date-picker>
              </div>
            </div>
          </div>
          <div *ngIf="bypassDualEnrollment || canViewDualEnrollment" class="form-input-label-group">
            <label i18n class="font-normal" for="dualEnrollmentEndDate">
              Dual Agency Withdrawal Date
              <!-- <i *ngIf="!isView" class="uil uil-info-circle text-xs" [matTooltip]=withdrawlInfo></i> -->
            </label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input matInput readonly=true type="text" [value]="dualEnrollmentEndDate.value | date:'MM/dd/yyyy'" />
            </mat-form-field>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8">
          <div class="form-input-label-group"
            *ngFor="let custom of customStateConfig | groupDemographicSection : demographicSections.childProgramInfo">
            <div [id]="custom.label" *ngIf="isView" class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" ngClass="removeLine" appearance="fill">
                <input matInput [readonly]="true" autocomplete="off" [formControlName]="custom.label" maxlength="10" />
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )">
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select" i18n-placeholder
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )"
                    multiple>
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.text && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="text" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.number && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="number" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.date && !isView"
              class="form-input-label-group">
              <drdp-date-picker [label]="custom.label" [initialDate]="addChildForm.get(custom.label)?.value"
                [isReadOnly]="isView" [isRequired]="custom.isRequired"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )">
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr class="my-5" />
        <h2 *ngIf="bypassDualEnrollment || isPrimaryAgency" class="font-semibold text-lg" i18n>Special Education Information
        </h2>
        <div *ngIf="bypassDualEnrollment || isPrimaryAgency"
          class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pb-4 md:pb-8">
          <div [ngClass]="{ 'form-input-label-group': !isView }">
            <label class="font-normal required" for="iepPlanId" *ngIf="isView" i18n>
              Has Individualized Education Plan (IEP) or Individual Family Service Plan (IFSP)
            </label>
            <div *ngIf="!isView">
              <drdp-select-iep-plan [initialId]="iepPlanId.value" [required]="true"
                (optionSelected)="handleIep($event, true)">
              </drdp-select-iep-plan>
              <mat-error *ngIf="iepPlanId.invalid" class="absolute text-xs">{{ required }}</mat-error>
            </div>
            <mat-form-field appearance="fill" class="bg-white removeLine" *ngIf="isView">
              <input matInput readonly="true" [value]="childData?.iepPlanName" type="text"
                formControlName="iepPlanId" />
            </mat-form-field>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isPrimaryDisabilityEnabled">
            <label class="font-normal" for="primaryDisabilityId" i18n>Primary Disability</label>
            <mat-form-field appearance="fill" id="primaryDisabilitySelect" *ngIf="!isView" class="drdp-input">
              <mat-select formControlName="primaryDisabilityId" placeholder="Primary Disability" i18n-placeholder>
                <mat-option *ngFor="let disability of disabilities" [value]="disability.id">
                  {{ disability.primaryDisabilityName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <p>{{ childData?.primaryDisabilityName }}</p>
            </div>
          </div>
          <div *ngIf="isNewHampshire && specialEdInfo.isDistrictLiabilityEnabled" class="self-end hidden md:block">
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isDistrictLiabilityEnabled">
            <label class="font-normal" for="districtOfLiabilityId"
              [ngClass]="{'required': isDistrictLiabilityRequired}" i18n>District of Liability</label>
            <mat-form-field appearance="fill" *ngIf="!isView" id="liabilitySelect" class="drdp-input">
              <mat-select formControlName="districtOfLiabilityId" placeholder="District of Liability" i18n-placeholder>
                <mat-option *ngFor="let dol of districtsOfLiability" [value]="dol.id">
                  {{ dol.districtOfLiabilityName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="districtOfLiabilityId.invalid">{{ required }}</mat-error>
            </mat-form-field>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <p>{{ childData?.districtOfLiabilityName }}</p>
            </div>
          </div>
          <div *ngIf="isNewHampshire && specialEdInfo.isDistrictLiabilityEnabled" class="form-input-label-group">
            <label i18n class="font-normal">District ID</label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="District ID" i18n-placeholder matInput type="text" [value]="districtId" [readonly]="true" />
            </mat-form-field>
          </div>
          <div *ngIf="isNewHampshire && specialEdInfo.isDistrictLiabilityEnabled" class="form-input-label-group">
            <label class="font-normal">SAU ID</label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input placeholder="SAU ID" matInput type="text" [value]="sauId" readonly="true" />
            </mat-form-field>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isSelpaEnabled">
            <div *ngIf="!isView">
              <drdp-select-selpa (selpa)="handleChosenSelpa($event, true)" [initialSelpaId]="selpaId.value"
                additionalClasses="pb-12">
              </drdp-select-selpa>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal pb-14" for="selpa">SELPAS</label>
              <p>{{ childData?.selpaName }}</p>
            </div>
          </div>
          <div class="form-input-label-group">
            <label i18n class="font-normal" for="adaptations">Adaptations</label>
            <mat-form-field appearance="fill" id="adaptationSelect" *ngIf="!isView" class="drdp-input">
              <mat-select formControlName="adaptationIds" multiple placeholder="Choose all that apply" i18n-placeholder>
                <mat-option *ngFor="let adaptation of adaptations" [value]="adaptation.id">{{
                  adaptation.adaptationTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isView && childData?.adaptations.length > 1">
              <ul>
                <li *ngFor="let adaptation of childData?.adaptations | option : ','">
                  {{ adaptation }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isSpecialEdEntryEnabled">
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label i18n class="font-normal" for="seEntryDate">Special Education Entry Date</label>
              <p class="text-sm">
                {{ seEntryDate?.value | date : "MM/dd/yyyy" }}
              </p>
            </div>
            <div *ngIf="!isView">
              <drdp-date-picker
                label="Special Education Entry Date"
                i18n-label
                [initialDate]="seEntryDate?.value"
                [isReadOnly]="isView"
                [isRequired]="iepPlanId.value == iepPlan.Yes"
                labelClasses="pb-10 mb-5"
                (emitDate)="handleDate($event, 'seEntryDate')"
              >
              </drdp-date-picker>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isAnticipatedDateEnabled">
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="seAnticipateExitDate" i18n>Special Education Anticipate Exit Date</label>
              <p class="text-sm">
                {{ seAnticipateExitDate?.value | date : "MM/dd/yyyy" }}
              </p>
            </div>
            <div *ngIf="!isView">
              <drdp-date-picker label="Special Education Anticipate Exit Date" i18n-label
                [initialDate]="seAnticipateExitDate.value" [isReadOnly]="isView" labelClasses="pb-5 mb-5"
                (emitDate)="handleDate($event, 'seAnticipateExitDate')">
              </drdp-date-picker>
            </div>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo?.isSpecialEdExitEnabled"
          >
            <div class="bg-white" appearance="fill">
              <label i18n class="font-normal" for="seExitDate">
                Special Education Exit Date
                <i *ngIf="!isView" class="uil uil-info-circle text-xs" [matTooltip]="specialEdExitInfo"></i>
              </label>
              <mat-form-field
                class="bg-white"
                [ngClass]="{ removeLine: isView, 'pt-10': !isView }"
                appearance="fill"
              >
                <input
                  matInput
                  readonly=true
                  type="text"
                  [value]="seExitDate.value | date:'MM/dd/yyyy'"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            class="form-input-label-group"
            *ngIf="specialEdInfo.isInitialAssessmentToolEnabled">
            <div *ngIf="!isView">
              <drdp-select-initial-assessment-tool-type label="Initial Assessment Tool" i18n-label
                [initialId]="initialAssessmentToolId?.value"
                (optionSelected)="handleInitAssessment($event, 'initialAssessmentToolId')">
              </drdp-select-initial-assessment-tool-type>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="initialAssessmentTool" i18n>Initial Assessment Tool</label>
              <p class="text-sm">{{ childData?.initialAssessmentToolTypeName }}</p>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isOutcome1Enabled">
            <div *ngIf="!isView">
              <drdp-select-outcome label="Outcome 1" [initialId]="outcome1Id?.value"
                (optionSelected)="handleOutcome($event, 'outcome1Id')">
              </drdp-select-outcome>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="outcome1" i18n>Outcome 1</label>
              <p class="text-sm">{{ childData?.outcome1Name }}</p>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isOutcome2Enabled">
            <div *ngIf="!isView">
              <drdp-select-outcome label="Outcome 2" i18n-label [initialId]="outcome2Id?.value"
                (optionSelected)="handleOutcome($event, 'outcome2Id')">
              </drdp-select-outcome>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="outcome2" i18n>Outcome 2</label>
              <p class="text-sm">{{ childData?.outcome2Name }}</p>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isOutcome3Enabled">
            <div *ngIf="!isView">
              <drdp-select-outcome label="Outcome 3" i18n-label [initialId]="outcome3Id?.value"
                (optionSelected)="handleOutcome($event, 'outcome3Id')">
              </drdp-select-outcome>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="outcome3" i18n>Outcome 3</label>
              <p class="text-sm">{{ childData?.outcome3Name }}</p>
            </div>
          </div>
        </div>
        <h2 i18n *ngIf="canViewDualSped" class="font-semibold text-lg">
          Dual Special Education Information</h2>
        <div *ngIf="canViewDualSped"
          class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pb-4 md:pb-8">
          <div [ngClass]="{ 'form-input-label-group': !isView }">
            <label class="font-normal required" for="iepPlanId" *ngIf="isView" i18n>
              Has Individualized Education Plan (IEP) or Individual Family Service Plan (IFSP)
            </label>
            <div *ngIf="!isView">
              <drdp-select-iep-plan [initialId]="dualIepPlanId.value" [required]="true"
                (optionSelected)="handleIep($event, false)">
              </drdp-select-iep-plan>
            </div>
            <mat-form-field appearance="fill" class="bg-white removeLine" *ngIf="isView">
              <input matInput readonly="true" [value]="childData?.dualEnrollmentIepPlanName" type="text"
                formControlName="dualIepPlanId" />
            </mat-form-field>
          </div>
          <div class="form-input-label-group"
            *ngIf="specialEdInfo.isSelpaEnabled && !specialEdInfo.isPrimaryDisabilityEnabled">
            <div *ngIf="!isView">
              <drdp-select-selpa (selpa)="handleChosenSelpa($event, false)" [initialSelpaId]="dualSelpaId.value"
                additionalClasses="pb-12">
              </drdp-select-selpa>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal pb-14" for="selpa">SELPAS</label>
              <p>{{ childData?.dualEnrollmentSelpaName }}</p>
            </div>
          </div>
          <div class="form-input-label-group">
            <label class="font-normal" for="adaptations" i18n>Adaptations</label>
            <mat-form-field appearance="fill" id="adaptationSelect" *ngIf="!isView" class="drdp-input">
              <mat-select formControlName="dualAdaptationIds" multiple placeholder="Choose all that apply" i18n-placeholder>
                <mat-option *ngFor="let adaptation of adaptations" [value]="adaptation.id">
                  {{ adaptation.adaptationTypeName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isView && childData?.dualAdaptations">
              <ul>
                <li *ngFor="let adaptation of childData?.dualAdaptations | option : ','">
                  {{ adaptation }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isPrimaryDisabilityEnabled || primaryDisabilityReq">
            <label i18n class="font-normal" for="dualPrimaryDisabilityId">Primary Disability</label>
            <mat-form-field appearance="fill" id="primaryDisabilitySelect" *ngIf="!isView" class="drdp-input">
              <mat-select formControlName="dualPrimaryDisabilityId" [placeholder]="selectedDisability ?? 'Primary Disability'"
              (selectionChange)="handlePrimaryDisability($event)">
                <mat-option *ngFor="let disability of disabilities" [value]="disability">
                  {{ disability.primaryDisabilityName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <p class="text-sm">{{ childData?.dualPrimaryDisabilityName }}</p>
            </div>
          </div>
          <div *ngIf="primaryDisabilityReq">
            <div *ngIf="(!isView && hasFollowUp)">
              <div class="col-span-1 col-end-3">
                <label class="font-normal" for="dualExtensiveSupportId">Does the child have extensive support needs?</label>
                <mat-radio-group aria-label="Select an option" formControlName="dualExtensiveSupportId">
                  <mat-radio-button color="primary" *ngFor="let es of extensiveSupports" [value]="es.id">
                    {{ es.extensiveSupportOption }}
                  </mat-radio-button>
                  <mat-error *ngIf="dualExtensiveSupportId.touched && dualExtensiveSupportId.invalid">{{ required }}</mat-error>
                </mat-radio-group>
              </div>
            </div>
            <div class="form-input-label-group" *ngIf="isView && hasFollowUp">
              <div class="col-span-1 col-end-3">
                <label class="font-normal" for="firstName">Does the child have extensive support needs?</label>
                <div class="bg-white removeLine" appearance="fill">
                  <p class="text-small">{{ childData?.extensiveSupportOption }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isDistrictLiabilityEnabled">
            <label class="font-normal" for="dualDistrictOfLiabilityId"
              [ngClass]="{'required': dualIepPlanId.value == iepPlan.Yes}" i18n>District of Liability</label>
            <mat-form-field appearance="fill" *ngIf="!isView" id="liabilitySelect" class="drdp-input">
              <mat-select formControlName="dualDistrictOfLiabilityId" placeholder="District of Liability" i18n-placeholder>
                <mat-option *ngFor="let dol of districtsOfLiability" [value]="dol.id">
                  {{ dol.districtOfLiabilityName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="dualDistrictOfLiabilityId.invalid">{{ required }}</mat-error>
            </mat-form-field>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <p>{{ childData?.dualDistrictOfLiabilityName }}</p>
            </div>
          </div>
          <div *ngIf="specialEdInfo.isDistrictLiabilityEnabled" class="form-input-label-group">
            <label i18n class="font-normal">District ID</label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input matInput type="text" [value]="dualDistrictId" [readonly]="true" />
            </mat-form-field>
          </div>
          <div *ngIf="specialEdInfo.isDistrictLiabilityEnabled" class="form-input-label-group">
            <label class="font-normal">SAU ID</label>
            <mat-form-field class="bg-white" [ngClass]="{ removeLine: isView }" appearance="fill">
              <input matInput type="text" [value]="dualSauId" readonly="true" />
            </mat-form-field>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isSpecialEdEntryEnabled">
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label i18n class="font-normal" for="dualSeEntryDate">Special Education Entry Date</label>
              <p class="text-sm">
                {{ dualSeEntryDate?.value | date : "MM/dd/yyyy" }}
              </p>
            </div>
            <div *ngIf="!isView">
              <drdp-date-picker label="Special Education Entry Date" i18n-label [initialDate]="dualSeEntryDate?.value"
                [isReadOnly]="isView" [isRequired]="false" labelClasses="pb-10 mb-5"
                (emitDate)="handleDate($event, 'dualSeEntryDate')">
              </drdp-date-picker>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isAnticipatedDateEnabled">
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label i18n class="font-normal" for="dualSeAnticipateExitDate">Special Education Anticipate Exit Date</label>
              <p class="text-sm">
                {{ dualSeAnticipateExitDate?.value | date : "MM/dd/yyyy" }}
              </p>
            </div>
            <div *ngIf="!isView">
              <drdp-date-picker label="Special Education Anticipate Exit Date" i18n-label
                [initialDate]="dualSeAnticipateExitDate?.value" [isReadOnly]="isView" [isRequired]="false"
                labelClasses="pb-5 mb-5" (emitDate)="handleDate($event, 'dualSeAnticipateExitDate')">
              </drdp-date-picker>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isInitialAssessmentToolEnabled">
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label i18n class="font-normal" for="dualInitialAssessmentToolId">Initial Assessment Tool</label>
              <p class="text-sm">{{ dualInitialAssessmentToolId.value }}</p>
            </div>
            <div *ngIf="!isView">
              <drdp-select-initial-assessment-tool-type label="Initial Assessment Tool"
                [initialId]="dualInitialAssessmentToolId?.value"
                (optionSelected)="handleInitAssessment($event, 'dualInitialAssessmentToolId')">
              </drdp-select-initial-assessment-tool-type>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isOutcome1Enabled">
            <div *ngIf="!isView">
              <drdp-select-outcome label="Outcome 1" i18n-label [initialId]="dualOutcome1Id?.value"
                (optionSelected)="handleOutcome($event, 'dualOutcome1Id')">
              </drdp-select-outcome>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="dualOutcome1Id" i18n>Outcome 1</label>
              <p class="text-sm">{{ dualOutcome1Id.value }}</p>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isOutcome2Enabled">
            <div *ngIf="!isView">
              <drdp-select-outcome label="Outcome 2" i18n-label [initialId]="dualOutcome2Id?.value"
                (optionSelected)="handleOutcome($event, 'dualOutcome2Id')">
              </drdp-select-outcome>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="dualOutcome2Id" i18n>Outcome 2</label>
              <p class="text-sm">{{ dualOutcome2Id.value }}</p>
            </div>
          </div>
          <div class="form-input-label-group" *ngIf="specialEdInfo.isOutcome3Enabled">
            <div *ngIf="!isView">
              <drdp-select-outcome label="Outcome 3" i18n-label [initialId]="dualOutcome3Id?.value"
                (optionSelected)="handleOutcome($event, 'dualOutcome3Id')">
              </drdp-select-outcome>
            </div>
            <div *ngIf="isView" class="bg-white removeLine" appearance="fill">
              <label class="font-normal" for="dualOutcome3Id" i18n>Outcome 3</label>
              <p class="text-sm">{{ dualOutcome3Id.value }}</p>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 pt-4 md:pt-8">
          <div class="form-input-label-group"
            *ngFor="let custom of customStateConfig | groupDemographicSection : demographicSections.specialEdInfo">
            <div [id]="custom.label" *ngIf="isView" class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" ngClass="removeLine" appearance="fill">
                <input matInput [readonly]="true" autocomplete="off" [formControlName]="custom.label" maxlength="10" />
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownSingle && !isView" class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select"
                  (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )">
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label"
              *ngIf="custom.customFieldTypeId === fieldType.dropdownMultiple && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field appearance="fill" class="drdp-input">
                <mat-select [formControlName]="custom.label" placeholder="Select"
                (selectionChange)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" multiple>
                  <mat-option *ngFor="let option of custom.options | option : '|'" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.text && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="text" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.number && !isView"
              class="form-input-label-group">
              <label class="font-normal" [ngClass]="{ required: custom.isRequired }" [for]="custom.label">
                {{ custom.label }}</label>
              <mat-form-field class="bg-white" appearance="fill">
                <input placeholder="" matInput type="number" [formControlName]="custom.label"
                  [maxlength]="custom.maxLength"
                  (input)="
                    buildCustomConfigObj(
                      custom.label,
                      custom.id,
                      custom.customFieldTypeId
                    )" />
                <mat-error *ngIf="addChildForm.controls[custom.label].invalid">{{ required }}</mat-error>
              </mat-form-field>
            </div>

            <div [id]="custom.label" *ngIf="custom.customFieldTypeId === fieldType.date && !isView"
              class="form-input-label-group">
              <drdp-date-picker [label]="custom.label" [initialDate]="addChildForm.get(custom.label)?.value"
                [isReadOnly]="isView" [isRequired]="custom.isRequired"
                (emitDate)="
                  buildCustomConfigObj(
                    custom.label,
                    custom.id,
                    custom.customFieldTypeId,
                    $event
                  )">
              </drdp-date-picker>
            </div>
          </div>
        </div>
        <hr *ngIf="!isView" />
        <div class="mt-10 mb-2 flex justify-end" *ngIf="!isView">
          <drdp-btn i18n class="pr-4" (click)="resetAddChildForm()" color="cancel">
            Cancel
          </drdp-btn>
          <drdp-btn (click)="handleSubmit($event)" [disabled]="!addChildForm.valid" cdkFocusInitial>
            {{ submitBtnLabel }}
          </drdp-btn>
        </div>
      </form>
    </drdp-container>
  </ng-template>
</div>
