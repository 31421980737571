<div class="fix-action-row modal-body">
    <div class="flex justify-between border-b">
        <h1 class="text-[20px] font-bold">{{user.firstName + ' ' + user.lastName}}'s <span i18n>Profile</span></h1>
        <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
    </div>

    <div mat-dialog-content>
        <div class="grid grid-cols-2 gap-4 gap-x-10 mt-5">
            <div class="mb-5">
                <h3 i18n class="font-bold mb-2">Account Email</h3>
                <p>{{user.emailName}}</p>
            </div>
            <div class="mb-5">
                <h3 i18n class="font-bold mb-2">Account Status</h3>
                <p>{{user.statusTypeName}}</p>
            </div>
        </div>

        <h2 i18n class="text-[20px] font-bold mb-3">Contact Information</h2>

        <div class="grid grid-cols-2 gap-4 gap-x-10">
            <div class="mb-5">
                <h3 i18n class="font-bold mb-2">Name</h3>
                <p>{{user.firstName + ' ' + user.lastName}}</p>
            </div>

            <div class="mb-5">
                <h3 i18n class="font-bold mb-2">Work Title</h3>
                <p>{{user.jobTitle}}</p>
            </div>

            <div>
                <h3 i18n class="font-bold mb-2">System Roles</h3>
                <p *ngFor="let role of user.roles">{{role}}</p>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="flex flex-row justify-end">
        <drdp-btn i18n color="outline" mat-button mat-dialog-close>Close</drdp-btn>
    </div>
</div>
